import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const FirstStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.firstContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-1',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    // <div
    //   onClick={(e) => {
    //     handleSectionClick(e, sectionIndex, section?.type);
    //     openAddSection(e, 'static_content-1', 'setting');
    //     selectSection(e, section?.id, section?.type, sectionIndex);
    //     setElementorId(section?.id);
    //   }}
    //   ref={dropContainerRef}
    // >
    <div className='w-full'>
      <ReactDragListView {...dragProps}>
        {filteredSections?.map((obj, itemIndex) => {
          const isActive =
            editSection?.section_type === obj?.blockName &&
            editSection?.section_id === obj?.id &&
            editSection?.section_index === itemIndex;

          console.log(obj?.style, 'obj=-==-=->>>>');

          return (
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-col text-center w-full mb-20'>
                  {obj?.content?.htmlTag === 'h1' ? (
                    <h1
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h1>
                  ) : obj?.content?.htmlTag === 'h2' ? (
                    <h2
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h2>
                  ) : obj?.content?.htmlTag === 'h3' ? (
                    <h3
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h3>
                  ) : obj?.content?.htmlTag === 'h4' ? (
                    <h4
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h4>
                  ) : obj?.content?.htmlTag === 'h5' ? (
                    <h5
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h5>
                  ) : obj?.content?.htmlTag === 'h6' ? (
                    <h6
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </h6>
                  ) : obj?.content?.htmlTag === 'div' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'span' ? (
                    <span
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </span>
                  ) : obj?.content?.htmlTag === 'p' ? (
                    <p
                      style={{
                        fontWeight: obj?.style?.sub_title_font_weight,
                        fontSize: `${obj?.style?.sub_title_font_size}px`,
                        lineHeight: `${obj?.style?.sub_title_font_height}px`,
                        color: obj?.style?.sub_title_font_color,
                        textAlign: obj?.style?.sub_title_font_align,
                        fontStyle: obj?.style?.sub_title_font_style,
                        textDecoration: obj?.style?.sub_title_font_decoration,
                        textTransform: obj?.style?.sub_title_font_capitalize,
                        wordBreak: obj?.style?.sub_title_font_break,
                        textWrap: obj?.style?.sub_title_font_wrap,
                        letterSpacing: `${obj?.style?.sub_title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.sub_title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textStroke: `${obj?.style?.sub_title_text_stroke}px ${obj?.style?.sub_title_stroke_color}`,
                        textShadow: `${obj?.style?.sub_title_text_shadow_horizontal}px ${obj?.style?.sub_title_text_shadow_vertical}px ${obj?.style?.sub_title_text_shadow_blur}px ${obj?.style?.sub_title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.sub_title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </p>
                  ) : (
                    <></>
                  )}
                  {obj?.content?.subHtmlTag === 'h1' ? (
                    <h1
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h1>
                  ) : obj?.content?.subHtmlTag === 'h2' ? (
                    <h2
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h2>
                  ) : obj?.content?.subHtmlTag === 'h3' ? (
                    <h3
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h3>
                  ) : obj?.content?.subHtmlTag === 'h4' ? (
                    <h4
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h4>
                  ) : obj?.content?.subHtmlTag === 'h5' ? (
                    <h5
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h5>
                  ) : obj?.content?.subHtmlTag === 'h6' ? (
                    <h6
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </h6>
                  ) : obj?.content?.subHtmlTag === 'div' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </div>
                  ) : obj?.content?.subHtmlTag === 'span' ? (
                    <span
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </span>
                  ) : obj?.content?.subHtmlTag === 'p' ? (
                    <p
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.subTitle}
                    </p>
                  ) : (
                    <></>
                  )}
                  <span
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    className='line-clamp-2'
                    dangerouslySetInnerHTML={{ __html: obj?.content?.description || '-' }}
                  ></span>
                </div>
                <div class='flex flex-wrap'>
                  {obj?.content?.item?.map((value) => {
                    return (
                      <div
                        className={`${getWidthClass(
                          obj?.content?.gridValue?.toString(),
                        )} px-8 py-6 border-l-2 border-gray-200 border-opacity-60`}
                      >
                        {value?.htmlTag === 'h1' ? (
                          <h1 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h1>
                        ) : value?.htmlTag === 'h2' ? (
                          <h2 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h2>
                        ) : value?.htmlTag === 'h3' ? (
                          <h3 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h3>
                        ) : value?.htmlTag === 'h4' ? (
                          <h4 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h4>
                        ) : value?.htmlTag === 'h5' ? (
                          <h5 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h5>
                        ) : value?.htmlTag === 'h6' ? (
                          <h6 class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </h6>
                        ) : value?.htmlTag === 'div' ? (
                          <div class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </div>
                        ) : value?.htmlTag === 'span' ? (
                          <span class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </span>
                        ) : value?.htmlTag === 'p' ? (
                          <p class='text-lg sm:text-xl text-gray-900 font-medium title-font mb-2'>
                            {value?.title}
                          </p>
                        ) : (
                          <></>
                        )}
                        <span
                          dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                        ></span>
                        <a class='text-indigo-500 inline-flex items-center gap-x-1'>
                          {value?.button_name}
                          {value?.icon && React.isValidElement(value?.icon) ? (
                            React.cloneElement(value?.icon, {
                              width: '50px',
                              height: '50px',
                              color: '#6366f1',
                            })
                          ) : (
                            <SvgSection
                              svgContent={value?.icon}
                              width={'24px'}
                              height={'24px'}
                              fill={'#6366f1'}
                              stroke={'#6366f1'}
                            />
                          )}
                        </a>
                      </div>
                    );
                  })}
                </div>
                <button
                  style={{
                    color: obj?.style?.button_text_color,
                    backgroundColor: obj?.style?.button_color,
                  }}
                  className='flex mx-auto mt-16 border-0 py-2 px-8 focus:outline-none rounded text-lg'
                >
                  {obj?.content?.button}
                </button>
              </div>
            </section>
          );
        })}
      </ReactDragListView>
    </div>
    // </div>
  );
};

export default FirstStaticContentSection;
