import React from 'react';
import EditButtonStyleContainer from './EditButtonStyleContainer';

const EditButtonStyleSection = ({previewImage, buttonDesign, setButtonDesign ,section_id,updateSectionStyle,saveElementorData,
  btnLoading,}) => {
  return (
    <>
      <EditButtonStyleContainer
        buttonDesign={buttonDesign}
        setButtonDesign={setButtonDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditButtonStyleSection;
