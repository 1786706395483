import React from 'react';
import EditTextEditorStyleContainer from './EditTextEditorStyleContainer';

const EditTextEditorStyleSection = ({previewImage, textEditorDesign, setTextEditorDesign, handleApplyChangesElementor ,section_id,updateSectionStyle,saveElementorData,
  btnLoading,}) => {
  return (
    <>
      <EditTextEditorStyleContainer
        textEditorDesign={textEditorDesign}
        setTextEditorDesign={setTextEditorDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditTextEditorStyleSection;
