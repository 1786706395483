import Minus from 'feather-icons-react/build/IconComponents/Minus';
import React, { useRef, useState } from 'react';
import { PlusLg } from 'react-bootstrap-icons';
import AddSectionSixGrid from './AddSectionSixGrid';
import AccordionSection from '../../../../controllers/AccordionSection';
import TabsComponent from '../../../../controllers/TabsComponent';
import NumberAnimation from '../../../../controllers/NumberAnimation';
import StarRating from '../../../../controllers/StarRating';
import ReactDragListView from 'react-drag-listview';
import SvgSection from '../../../../controllers/SvgSection';
import '../../../../../src/EditSectionStyle.css';
import VideoSection from '../../../../controllers/VideoSection';
import { RotateCcw } from 'feather-icons-react/build/IconComponents';
import {
  getAlertClass,
  mapPositionToCSS,
  getHeightForAspectRatio,
} from '../../../../controllers/DynamicEditorSwitchCase';
import ContextMenuItem, {
  handleDragOver,
  RenderContextMenu,
} from '../../../../controllers/ContextMenuItem';

const FirstSecondSectionAdd = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  sectionAddSecondGrid,
  sectionIndex,
  section,
  gridValue,
  handleHeadingClick,
  activeHeadingIndex,
  activeHeading,
  setSectionAdd,
  handleSubDivClick,
  selectedIndex,
  selectSection,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  setSelectedItem,
  setElementorId,
  dropPosition,
  setDropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections =
    sectionAdd?.firstGrid?.[sectionIndex]?.filter((obj) => obj?.type === 'flex_cols') || [];

  return (
    <>
      <div
        className={`relative m-5 p-1 flex flex-col justify-center ${
          filteredSections?.length > 0 ? '' : 'items-center'
        } border-[1px] border-dashed border-[#d5d8dc] group`}
        onClick={(e) => {
          handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
          openAddSection(e, 'firstGrid', 'add_section');
          selectSection(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
          );
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            'flex_cols',
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
          getPrevSecId(e, sectionIndex);
        }}
        onContextMenu={(e) => {
          const menuX = e.clientX;
          const menuY = e.clientY;
          setMenuPosition({ x: menuX, y: menuY });
          setMenuVisible(true);
          selectSection(e, section?.id || section?.section_id, 'flex_cols', sectionIndex);
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            'flex_cols',
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
        }}
        ref={dropContainerRef}
        onDragEnter={(e) => {
          handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
          openAddSection(e, 'firstGrid', 'add_section'),
            selectSection(
              e,
              section?.id || section?.section_id,
              section?.type || section?.section_type,
              sectionIndex,
            );
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        {filteredSections?.length > 0 ? (
          <div className='w-full'>
            <ReactDragListView {...dragProps}>
              {filteredSections?.map((obj, itemIndex) => {
                const isActive =
                  editSection?.section_type === obj?.blockName &&
                  editSection?.section_id === obj?.id &&
                  editSection?.section_index === itemIndex;

                const currentActiveSection =
                  destinationId === itemIndex &&
                  editSection?.section_type === 'flex_cols' &&
                  editSection?.section_index === sectionIndex;

                const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                const alertType = obj?.content?.alertType || 'info';
                const handleClick = (e) => {
                  if (
                    e.target.tagName === 'INPUT' ||
                    e.target.type === 'file' ||
                    e.target.tagName === 'A'
                  ) {
                    e.stopPropagation();
                    return;
                  }
                  selectSection(e, obj.id, obj.blockName, itemIndex);
                  openAddSection(e, 'firstGrid', 'setting');
                };
                if (obj?.blockName === 'grid') {
                  return (
                    <div
                      key={itemIndex}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                      }  hover:border-2 hover:border-blue-500`}
                      onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                    >
                      <AddSectionSixGrid
                        flexBoxValue={flexBoxValue}
                        setSectionAdd={setSectionAdd}
                        removeSection={removeSection}
                        gridValue={gridValue}
                        handleFlexBoxClick={handleFlexBoxClick}
                        openAddSection={openAddSection}
                        activeIndex={activeIndex}
                        handleSectionClick={handleSectionClick}
                        sectionAdd={sectionAdd}
                        editSection={editSection}
                        sectionIndex={sectionIndex}
                        section={section}
                        handleHeadingClick={handleHeadingClick}
                        activeHeadingIndex={activeHeadingIndex}
                        activeHeading={activeHeading}
                        handleSubDivClick={handleSubDivClick}
                        selectedIndex={selectedIndex}
                        selectSection={selectSection}
                        dragProps={dragProps}
                        gridDestinationId={gridDestinationId}
                        setGridDestinationId={setGridDestinationId}
                        dropContainerRef={dropContainerRef}
                        setElementorId={setElementorId}
                        setDropPosition={setDropPosition}
                        setDestinationId={setDestinationId}
                        dropPosition={dropPosition}
                        handleRemoveSingleItem={handleRemoveSingleItem}
                        handleDuplicateSingleItem={handleDuplicateSingleItem}
                        handleCopySingleItem={handleCopySingleItem}
                        handleCutSingleItem={handleCutSingleItem}
                        getPrevSecId={getPrevSecId}
                        menuVisible={menuVisible}
                        setMenuVisible={setMenuVisible}
                        menuPosition={menuPosition}
                        setMenuPosition={setMenuPosition}
                        selectedSingleItem={selectedSingleItem}
                        handleRightClick={handleRightClick}
                        handleMenuItemClick={handleMenuItemClick}
                        newItem={newItem}
                        cutItem={cutItem}
                        setCutItem={setCutItem}
                        setCopiedItem={setCopiedItem}
                        handleCopyItem={handleCopyItem}
                        copyItem={copyItem}
                        isEdit={isEdit}
                        setEditItem={setEditItem}
                        getContextMenuItem={getContextMenuItem}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className={`cursor-pointer
                    ${
                      abovePosition || belowPosition
                        ? ''
                        : isActive
                        ? 'border-2 border-blue-500'
                        : 'border-2 border-transparent'
                    }
                    ${
                      abovePosition && currentActiveSection
                        ? 'border-t-2 border-red-700 border-dashed'
                        : ''
                    }
                    ${
                      belowPosition && currentActiveSection
                        ? 'border-b-2 border-green-700 border-dashed'
                        : ''
                    }
                    draggable-item hover:border-2 hover:border-blue-500`}
                    // className={`cursor-pointer border-[2px] transition-all duration-300 ${
                    //   isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                    // } hover:border-2 hover:border-blue-500 draggable-item `}
                    onClick={(e) => {
                      selectSection(e, obj?.id, obj?.blockName, itemIndex);
                      openAddSection(e, 'firstGrid', 'setting');
                      handleCopyItem(e, obj, 'firstGrid', itemIndex, sectionIndex);
                      getContextMenuItem(
                        e,
                        section?.id || section?.section_id,
                        'flex_cols',
                        sectionIndex,
                        section?.section_type,
                      );
                      setElementorId(section?.id || section?.section_id);
                    }}
                    onDragOver={(e) => {
                      handleDragOver(
                        e,
                        'flex_cols',
                        sectionIndex,
                        itemIndex,
                        editSection?.section_type,
                        editSection?.section_index,
                        setDropPosition,
                        setDestinationId,
                        setGridDestinationId,
                      );
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setDestinationId(null);
                      setDropPosition(null);
                    }}
                    onContextMenu={(e) => {
                      handleRightClick(e, obj, 'flex_cols', itemIndex, sectionIndex),
                        // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                        getContextMenuItem(
                          e,
                          section?.id || section?.section_id,
                          'flex_cols',
                          sectionIndex,
                          section?.section_type,
                        );
                      setEditItem({
                        id: obj?.id,
                        blockName: obj?.blockName,
                        index: itemIndex,
                        sectionType: 'firstGrid',
                        type: 'setting',
                      });
                    }}
                  >
                    {obj?.blockName === 'heading' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                        }}
                      >
                        {obj?.content?.htmlTag === 'h1' ? (
                          <h1
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h1>
                        ) : obj?.content?.htmlTag === 'h2' ? (
                          <h2
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat:
                                obj?.style?.mask_size === 'fit'
                                  ? 'repeat-x'
                                  : obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h2>
                        ) : obj?.content?.htmlTag === 'h3' ? (
                          <h3
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h3>
                        ) : obj?.content?.htmlTag === 'h4' ? (
                          <h4
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h4>
                        ) : obj?.content?.htmlTag === 'h5' ? (
                          <h5
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h5>
                        ) : obj?.content?.htmlTag === 'h6' ? (
                          <h6
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </h6>
                        ) : obj?.content?.htmlTag === 'span' ? (
                          <span
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </span>
                        ) : obj?.content?.htmlTag === 'div' ? (
                          <div
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </div>
                        ) : (
                          <p
                            style={{
                              fontWeight: obj?.style?.font_weight,
                              fontSize: `${obj?.style?.font_size}px`,
                              lineHeight: `${obj?.style?.font_height}px`,
                              color: obj?.style?.font_color,
                              textAlign: obj?.style?.font_align,
                              fontStyle: obj?.style?.font_style,
                              textDecoration: obj?.style?.font_decoration,
                              textTransform: obj?.style?.font_capitalize,
                              wordBreak: obj?.style?.font_break,
                              textWrap: obj?.style?.font_wrap,
                              letterSpacing: `${obj?.style?.letter_spacing}px`,
                              wordSpacing: `${obj?.style?.word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                              textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                              transform: `rotate(${obj?.style?.text_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                            }}
                          >
                            {obj?.content?.title}
                          </p>
                        )}
                      </div>
                    ) : obj?.blockName === 'image' ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent:
                            obj?.style?.image_align === 'right'
                              ? 'flex-end'
                              : obj?.style?.image_align === 'left'
                              ? 'flex-start'
                              : obj?.style?.image_align === 'center'
                              ? 'center'
                              : undefined,
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <img
                          src={obj?.content?.url}
                          alt='Content'
                          // width={obj?.content?.imageSize?.width}
                          // height={obj?.content?.imageSize?.height}
                          style={{
                            width: `${obj?.style?.image_width}%`,
                            height: `${obj?.style?.image_height}px`,
                            maxWidth: `${obj?.style?.image_maxWidth}%`,
                            opacity: obj?.style?.opacity,
                            transform: `rotate(${obj?.style?.image_rotate}deg)`,
                          }}
                        />
                      </div>
                    ) : obj?.blockName === 'textEditor' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                        }}
                      >
                        <div
                          style={{
                            fontWeight: obj?.style?.font_weight,
                            fontSize: `${obj?.style?.font_size}px`,
                            lineHeight: `${obj?.style?.font_height}px`,
                            color: obj?.style?.font_color,
                            textAlign: obj?.style?.font_align,
                            fontStyle: obj?.style?.font_style,
                            textDecoration: obj?.style?.font_decoration,
                            textTransform: obj?.style?.font_capitalize,
                            wordBreak: obj?.style?.font_break,
                            textWrap: obj?.style?.font_wrap,
                            letterSpacing: `${obj?.style?.letter_spacing}px`,
                            wordSpacing: `${obj?.style?.word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                            transform: `rotate(${obj?.style?.text_rotate}deg)`,
                            background:
                              obj?.style?.background_gradient === 'gradient'
                                ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                : obj?.style?.background_image
                                ? `url(${obj?.style?.background_image})`
                                : obj?.style?.background,
                            backgroundRepeat: obj?.style?.background_repeat,
                            backgroundAttachment: obj?.style?.background_attachment,
                            objectFit: obj?.style?.background_fit,
                            backgroundSize:
                              obj?.style?.background_fit === 'cover' ||
                              obj?.style?.background_fit === 'contain'
                                ? obj?.style?.background_fit
                                : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                            backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                          }}
                          dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                        ></div>
                      </div>
                    ) : obj?.blockName === 'video' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                        }}
                      >
                        <VideoSection
                          obj={obj}
                          mapPositionToCSS={mapPositionToCSS}
                          getHeightForAspectRatio={getHeightForAspectRatio}
                        />
                      </div>
                    ) : obj?.blockName === 'button' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          display: 'flex',
                          justifyContent:
                            obj?.style?.font_align === 'center'
                              ? 'center'
                              : obj?.style?.font_align === 'right'
                              ? 'flex-end'
                              : obj?.style?.font_align === 'left'
                              ? 'flex-start'
                              : obj?.style?.font_align === 'justify'
                              ? 'normal'
                              : '',
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <button
                          className='p-3 rounded-lg text-white flex'
                          style={{
                            paddingTop: `${obj?.style?.button_padding_top}px`,
                            paddingBottom: `${obj?.style?.button_padding_bottom}px`,
                            paddingLeft: `${obj?.style?.button_padding_left}px`,
                            paddingRight: `${obj?.style?.button_padding_right}px`,
                            fontWeight: obj?.style?.font_weight,
                            fontSize: `${obj?.style?.font_size}px`,
                            lineHeight: `${obj?.style?.font_height}px`,
                            color: obj?.style?.font_color,
                            fontStyle: obj?.style?.font_style,
                            textDecoration: obj?.style?.font_decoration,
                            textTransform: obj?.style?.font_capitalize,
                            wordBreak: obj?.style?.font_break,
                            textWrap: obj?.style?.font_wrap,
                            letterSpacing: `${obj?.style?.letter_spacing}px`,
                            wordSpacing: `${obj?.style?.word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
                            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
                            transform: `rotate(${obj?.style?.text_rotate}deg)`,
                            borderTop: `${obj?.style?.button_border_top}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
                            borderBottom: `${obj?.style?.button_border_bottom}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
                            borderLeft: `${obj?.style?.button_border_left}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
                            borderRight: `${obj?.style?.button_border_right}px ${obj?.style?.button_border_style} ${obj?.style?.button_border_color}`,
                            borderTopLeftRadius: `${obj?.style?.button_border_radius_top}px`,
                            borderTopRightRadius: `${obj?.style?.button_border_radius_right}px`,
                            borderBottomLeftRadius: `${obj?.style?.button_border_radius_left}px`,
                            borderBottomRightRadius: `${obj?.style?.button_border_radius_bottom}px`,
                            background:
                              obj?.style?.button_background_gradient === 'button_gradient'
                                ? `linear-gradient(${obj?.style?.button_gradient_angle}deg, ${obj?.style?.button_gradient_color_start} ${obj?.style?.button_gradient_start_position}%, ${obj?.style?.button_gradient_color_end} ${obj?.style?.button_gradient_end_position}%)`
                                : obj?.style?.button_background_image
                                ? `url(${obj?.style?.button_background_image})`
                                : obj?.style?.button_background,
                            boxShadow: `${obj?.style?.button_box_shadow_horizontal}px ${obj?.style?.button_box_shadow_vertical}px ${obj?.style?.button_box_shadow_blur}px ${obj?.style?.button_box_shadow_spread}px ${obj?.style?.button_box_shadow_color}`,
                          }}
                        >
                          {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                            React.cloneElement(obj?.content?.icon, {
                              width: '24px',
                              height: '24px',
                            })
                          ) : (
                            <SvgSection
                              svgContent={obj?.content?.icon}
                              width='24px'
                              height='24px'
                              fill={obj?.style?.font_color ? obj?.style?.font_color : '#ffffff'}
                              stroke={obj?.style?.font_color ? obj?.style?.font_color : '#ffffff'}
                            />
                          )}
                          {obj?.content?.text}
                        </button>
                      </div>
                    ) : obj?.blockName === 'divider' ? (
                      <div
                        className={`flex justify-${obj?.content?.align} items-center m-4`}
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          justifyContent:
                            obj?.style?.divider_align === 'end'
                              ? 'flex-end'
                              : obj?.style?.divider_align === 'start'
                              ? 'flex-start'
                              : obj?.style?.divider_align === 'center'
                              ? 'center'
                              : undefined,
                        }}
                      >
                        <div
                          className='flex items-center'
                          style={{
                            width: `${obj?.content?.width}%`,
                            height: `${obj?.style?.height}px`,
                            paddingTop: `${obj?.style?.gap}px`,
                            paddingBottom: `${obj?.style?.gap}px`,
                            transform: `rotate(${obj?.style?.divider_rotate}deg)`,
                            background:
                              obj?.style?.background_gradient === 'gradient'
                                ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                : obj?.style?.background_image
                                ? `url(${obj?.style?.background_image})`
                                : obj?.style?.background,
                            backgroundRepeat: obj?.style?.background_repeat,
                            backgroundAttachment: obj?.style?.background_attachment,
                            objectFit: obj?.style?.background_fit,
                            backgroundSize:
                              obj?.style?.background_fit === 'cover' ||
                              obj?.style?.background_fit === 'contain'
                                ? obj?.style?.background_fit
                                : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                            backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                          }}
                        >
                          <hr
                            className={`border-black border-${obj?.content?.style}`}
                            style={{
                              flexGrow: 1,
                              borderWidth: `${obj?.style?.divider_weight}px`,
                              borderColor: `${obj?.style?.divider_color}`,
                            }}
                          />
                          {obj?.content?.element === 'text' ? (
                            <span className='mx-2'>{obj?.content?.text}</span>
                          ) : obj?.content?.element === 'upload' ? (
                            // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                            ''
                          ) : obj?.content?.element === 'none' ? (
                            ''
                          ) : (
                            ''
                          )}

                          <hr
                            className={`border-black border-${obj?.content?.style}`}
                            style={{
                              flexGrow: 1,
                              borderWidth: `${obj?.style?.divider_weight}px`,
                              borderColor: `${obj?.style?.divider_color}`,
                            }}
                          />
                        </div>
                      </div>
                    ) : obj?.blockName === 'spacer' ? (
                      <div
                        style={{
                          height: `${obj?.content?.width}px`,
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      ></div>
                    ) : obj?.blockName === 'googleMap' ? (
                      <div
                        style={{
                          display: 'flex',
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          justifyContent:
                            obj?.style?.map_align === 'end'
                              ? 'flex-end'
                              : obj?.style?.map_align === 'start'
                              ? 'flex-start'
                              : obj?.style?.map_align === 'center'
                              ? 'center'
                              : undefined,
                          transform: `rotate(${obj?.style?.map_rotate}deg)`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <iframe
                          src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${
                            obj?.content?.longitude
                          }&hl=en-US&z=${
                            obj?.style?.zoom ? `${obj?.style?.zoom}` : '15'
                          }&output=embed`}
                          width={obj?.style?.width ? `${obj.style.width}%` : '80%'}
                          height={obj?.style?.height ? `${obj.style.height}px` : '500'}
                          frameBorder='0'
                          style={{ border: 'none' }}
                          allowFullScreen
                          aria-hidden='false'
                          tabIndex='0'
                          loading='lazy'
                          title='Description of the content'
                        ></iframe>
                      </div>
                    ) : obj?.blockName === 'icon' ? (
                      <div
                        style={{
                          display: 'flex',
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          justifyContent:
                            obj?.style?.icon_align === 'end'
                              ? 'flex-end'
                              : obj?.style?.icon_align === 'start'
                              ? 'flex-start'
                              : obj?.style?.icon_align === 'center'
                              ? 'center'
                              : undefined,
                          transform: `rotate(${obj?.style?.icon_rotate}deg)`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                          React.cloneElement(obj?.content?.icon, {
                            width: obj?.style?.width ? `${obj.style.width}px` : '50px',
                            height: obj?.style?.height ? `${obj.style.height}px` : '50px',
                            color: obj?.style?.icon_color,
                          })
                        ) : (
                          <SvgSection
                            svgContent={obj?.content?.icon}
                            width={obj?.style?.width ? `${obj.style.width}px` : '50px'}
                            height={obj?.style?.height ? `${obj.style.height}px` : '50px'}
                            fill={obj?.style?.icon_color}
                            stroke={obj?.style?.icon_color}
                          />
                        )}
                      </div>
                    ) : obj?.blockName === 'imageBox' ? (
                      <div
                        // className='flex flex-col items-center'
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <div
                          style={{
                            flexDirection:
                              obj?.style?.image_position === 'top'
                                ? 'column'
                                : obj?.style?.image_position === 'right'
                                ? 'row-reverse'
                                : obj?.style?.image_position === 'left'
                                ? 'row'
                                : 'column',
                            justifyContent:
                              obj?.style?.vertical_alignment === 'top'
                                ? 'flex-start'
                                : obj?.style?.vertical_alignment === 'middle'
                                ? 'center'
                                : obj?.style?.vertical_alignment === 'bottom'
                                ? 'flex-end'
                                : '',

                            display: 'flex',
                            alignItems:
                              obj?.style?.image_position === 'left' ||
                              obj?.style?.image_position === 'right'
                                ? obj?.style?.vertical_alignment === 'top'
                                  ? 'flex-start'
                                  : obj?.style?.vertical_alignment === 'middle'
                                  ? 'center'
                                  : obj?.style?.vertical_alignment === 'bottom'
                                  ? 'flex-end'
                                  : 'center'
                                : obj?.style?.content_alignment === 'left'
                                ? 'flex-start'
                                : obj?.style?.content_alignment === 'center'
                                ? 'center'
                                : obj?.style?.content_alignment === 'right'
                                ? 'flex-end'
                                : obj?.style?.content_alignment === 'justify'
                                ? 'flex-start'
                                : 'center',
                          }}
                        >
                          <img
                            style={{
                              borderTop: `${obj?.style?.image_border_top}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                              borderBottom: `${obj?.style?.image_border_bottom}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                              borderLeft: `${obj?.style?.image_border_left}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                              borderRight: `${obj?.style?.image_border_right}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                              borderTopLeftRadius: `${obj?.style?.image_border_radius_top}px`,
                              borderTopRightRadius: `${obj?.style?.image_border_radius_right}px`,
                              borderBottomLeftRadius: `${obj?.style?.image_border_radius_left}px`,
                              borderBottomRightRadius: `${obj?.style?.image_border_radius_bottom}px`,
                              marginBottom:
                                obj?.style?.image_position === 'top'
                                  ? `${obj?.style?.image_spacing}px`
                                  : '',
                              marginLeft:
                                obj?.style?.image_position === 'right'
                                  ? `${obj?.style?.image_spacing}px`
                                  : '',
                              marginRight:
                                obj?.style?.image_position === 'left'
                                  ? `${obj?.style?.image_spacing}px`
                                  : '',
                            }}
                            src={obj?.content?.url}
                            alt='Content'
                            width={
                              obj?.style?.image_width
                                ? obj.style.image_width
                                : obj?.content?.imageSize?.width
                            }
                            height={
                              obj?.style?.image_height
                                ? obj.style.image_height
                                : obj?.content?.imageSize?.height
                            }
                          />
                          <div
                            style={{
                              textAlign:
                                obj?.style?.content_alignment === 'left'
                                  ? 'left'
                                  : obj?.style?.content_alignment === 'center'
                                  ? 'center'
                                  : obj?.style?.content_alignment === 'right'
                                  ? 'right'
                                  : obj?.style?.content_alignment === 'justify'
                                  ? 'justify'
                                  : '',
                              //  marginTop: obj?.style?.image_position === 'top' ? `${obj?.style?.image_spacing}px` : ''
                            }}
                          >
                            {obj?.content?.htmlTag === 'h1' ? (
                              <h1
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h1>
                            ) : obj?.content?.htmlTag === 'h2' ? (
                              <h2
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h2>
                            ) : obj?.content?.htmlTag === 'h3' ? (
                              <h3
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h3>
                            ) : obj?.content?.htmlTag === 'h4' ? (
                              <h4
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h4>
                            ) : obj?.content?.htmlTag === 'h5' ? (
                              <h5
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h5>
                            ) : obj?.content?.htmlTag === 'h6' ? (
                              <h6
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h6>
                            ) : obj?.content?.htmlTag === 'span' ? (
                              <span
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </span>
                            ) : obj?.content?.htmlTag === 'div' ? (
                              <div
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </div>
                            ) : (
                              <p
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </p>
                            )}
                            <p
                              // className='mt-4'
                              style={{
                                fontWeight: obj?.style?.desc_font_weight,
                                fontSize: `${obj?.style?.desc_font_size}px`,
                                lineHeight: `${obj?.style?.desc_font_height}px`,
                                color: obj?.style?.desc_font_color,
                                textAlign: obj?.style?.desc_font_align,
                                fontStyle: obj?.style?.desc_font_style,
                                textDecoration: obj?.style?.desc_font_decoration,
                                textTransform: obj?.style?.desc_font_capitalize,
                                wordBreak: obj?.style?.desc_font_break,
                                textWrap: obj?.style?.desc_font_wrap,
                                letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
                                wordSpacing: `${obj?.style?.desc_word_spacing}px`,
                                WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                                textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                                textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
                                marginTop: `${obj?.style?.content_spacing}px`,
                              }}
                            >
                              {obj?.content?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : obj?.blockName === 'generalIconBox' ? (
                      <div
                        // className='flex flex-col items-center'
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <div
                          style={{
                            flexDirection:
                              obj?.style?.icon_position === 'top'
                                ? 'column'
                                : obj?.style?.icon_position === 'right'
                                ? 'row-reverse'
                                : obj?.style?.icon_position === 'left'
                                ? 'row'
                                : 'column',
                            justifyContent:
                              obj?.style?.vertical_alignment === 'top'
                                ? 'flex-start'
                                : obj?.style?.vertical_alignment === 'middle'
                                ? 'center'
                                : obj?.style?.vertical_alignment === 'bottom'
                                ? 'flex-end'
                                : '',

                            display: 'flex',
                            alignItems:
                              obj?.style?.icon_position === 'left' ||
                              obj?.style?.icon_position === 'right'
                                ? obj?.style?.vertical_alignment === 'top'
                                  ? 'flex-start'
                                  : obj?.style?.vertical_alignment === 'middle'
                                  ? 'center'
                                  : obj?.style?.vertical_alignment === 'bottom'
                                  ? 'flex-end'
                                  : 'center'
                                : obj?.style?.content_alignment === 'left'
                                ? 'flex-start'
                                : obj?.style?.content_alignment === 'center'
                                ? 'center'
                                : obj?.style?.content_alignment === 'right'
                                ? 'flex-end'
                                : obj?.style?.content_alignment === 'justify'
                                ? 'flex-start'
                                : 'center',
                          }}
                        >
                          <div
                            style={{
                              borderTop: `${obj?.style?.icon_border_top}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderBottom: `${obj?.style?.icon_border_bottom}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderLeft: `${obj?.style?.icon_border_left}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderRight: `${obj?.style?.icon_border_right}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderTopLeftRadius: `${obj?.style?.icon_border_radius_top}px`,
                              borderTopRightRadius: `${obj?.style?.icon_border_radius_right}px`,
                              borderBottomLeftRadius: `${obj?.style?.icon_border_radius_left}px`,
                              borderBottomRightRadius: `${obj?.style?.icon_border_radius_bottom}px`,
                              marginBottom:
                                obj?.style?.icon_position === 'top'
                                  ? `${obj?.style?.icon_spacing}px`
                                  : '',
                              marginLeft:
                                obj?.style?.icon_position === 'right'
                                  ? `${obj?.style?.icon_spacing}px`
                                  : '',
                              marginRight:
                                obj?.style?.icon_position === 'left'
                                  ? `${obj?.style?.icon_spacing}px`
                                  : '',
                            }}
                          >
                            {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                              React.cloneElement(obj?.content?.icon, {
                                width: obj?.style?.icon_width
                                  ? `${obj.style.icon_width}px`
                                  : '50px',
                                height: obj?.style?.icon_width
                                  ? `${obj.style.icon_width}px`
                                  : '50px',
                              })
                            ) : (
                              <SvgSection
                                svgContent={obj?.content?.icon}
                                width={
                                  obj?.style?.icon_width ? `${obj.style.icon_width}px` : '50px'
                                }
                                height={
                                  obj?.style?.icon_width ? `${obj.style.icon_width}px` : '50px'
                                }
                              />
                            )}
                          </div>
                          <div
                            style={{
                              textAlign:
                                obj?.style?.content_alignment === 'left'
                                  ? 'left'
                                  : obj?.style?.content_alignment === 'center'
                                  ? 'center'
                                  : obj?.style?.content_alignment === 'right'
                                  ? 'right'
                                  : obj?.style?.content_alignment === 'justify'
                                  ? 'justify'
                                  : '',
                              //  marginTop: obj?.style?.image_position === 'top' ? `${obj?.style?.image_spacing}px` : ''
                            }}
                          >
                            {obj?.content?.htmlTag === 'h1' ? (
                              <h1
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h1>
                            ) : obj?.content?.htmlTag === 'h2' ? (
                              <h2
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h2>
                            ) : obj?.content?.htmlTag === 'h3' ? (
                              <h3
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h3>
                            ) : obj?.content?.htmlTag === 'h4' ? (
                              <h4
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h4>
                            ) : obj?.content?.htmlTag === 'h5' ? (
                              <h5
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h5>
                            ) : obj?.content?.htmlTag === 'h6' ? (
                              <h6
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </h6>
                            ) : obj?.content?.htmlTag === 'span' ? (
                              <span
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </span>
                            ) : obj?.content?.htmlTag === 'div' ? (
                              <div
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </div>
                            ) : (
                              <p
                                className='mt-6'
                                style={{
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {obj?.content?.title}
                              </p>
                            )}
                            <p
                              // className='mt-6'
                              style={{
                                fontWeight: obj?.style?.desc_font_weight,
                                fontSize: `${obj?.style?.desc_font_size}px`,
                                lineHeight: `${obj?.style?.desc_font_height}px`,
                                color: obj?.style?.desc_font_color,
                                textAlign: obj?.style?.desc_font_align,
                                fontStyle: obj?.style?.desc_font_style,
                                textDecoration: obj?.style?.desc_font_decoration,
                                textTransform: obj?.style?.desc_font_capitalize,
                                wordBreak: obj?.style?.desc_font_break,
                                textWrap: obj?.style?.desc_font_wrap,
                                letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
                                wordSpacing: `${obj?.style?.desc_word_spacing}px`,
                                WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                                textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                                textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
                                marginTop: `${obj?.style?.content_spacing}px`,
                              }}
                            >
                              {obj?.content?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : obj?.blockName === 'generalIconList' ? (
                      <div
                        className='flex flex-col items-center'
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        {obj?.content?.item?.map((list, index) => (
                          <div
                            key={index}
                            className='w-full flex flex-col items-center'
                            style={{
                              alignItems:
                                obj?.style?.item_align === 'right'
                                  ? 'flex-end'
                                  : obj?.style?.item_align === 'left'
                                  ? 'flex-start'
                                  : obj?.style?.item_align === 'center'
                                  ? 'center'
                                  : undefined,
                            }}
                          >
                            <div
                              key={index}
                              className='flex items-center mt-4'
                              style={{
                                marginBottom: `${obj?.style?.space_between}px`,
                              }}
                            >
                              {list?.icon && React.isValidElement(list?.icon) ? (
                                React.cloneElement(list?.icon, {
                                  width: obj?.style?.icon_width
                                    ? `${obj.style.icon_width}px`
                                    : '20px',
                                  height: obj?.style?.icon_width
                                    ? `${obj.style.icon_width}px`
                                    : '20px',
                                  fill: obj?.style?.icon_color ? obj.style.icon_color : '#000000',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={list?.icon}
                                  fill={obj?.style?.icon_color ? obj?.style?.icon_color : '#000000'}
                                  stroke={
                                    obj?.style?.icon_color ? obj?.style?.icon_color : '#000000'
                                  }
                                  width={
                                    obj?.style?.icon_width ? `${obj.style.icon_width}px` : '20px'
                                  }
                                  height={
                                    obj?.style?.icon_width ? `${obj.style.icon_width}px` : '20px'
                                  }
                                />
                              )}
                              <h6
                                className='ml-2'
                                style={{
                                  marginLeft: `${obj?.style?.icon_spacing}px`,
                                  fontWeight: obj?.style?.title_font_weight,
                                  fontSize: `${obj?.style?.title_font_size}px`,
                                  lineHeight: `${obj?.style?.title_font_height}px`,
                                  color: obj?.style?.title_font_color,
                                  textAlign: obj?.style?.title_font_align,
                                  fontStyle: obj?.style?.title_font_style,
                                  textDecoration: obj?.style?.title_font_decoration,
                                  textTransform: obj?.style?.title_font_capitalize,
                                  wordBreak: obj?.style?.title_font_break,
                                  textWrap: obj?.style?.title_font_wrap,
                                  letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                  wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                  WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                  textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                  textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                }}
                              >
                                {list?.text}
                              </h6>
                            </div>
                            {index < obj?.content?.item?.length - 1 && (
                              <div
                                style={{
                                  borderBottomWidth: obj?.style?.divider_visible
                                    ? `${obj?.style?.divider_weight}px`
                                    : '0',
                                  borderBottomColor: obj?.style?.divider_visible
                                    ? obj?.style?.divider_color
                                    : '',
                                  borderBottomStyle: obj?.style?.divider_visible
                                    ? obj?.style?.divider_style
                                    : 'none',
                                  width: obj?.style?.divider_width
                                    ? `${obj?.style?.divider_width}%`
                                    : '',
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : obj?.blockName === 'counter' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <NumberAnimation
                          obj={obj}
                          start={obj?.content?.start}
                          end={obj?.content?.end}
                          duration={obj?.content?.duration}
                          title={obj?.content?.title}
                          htmlTag={obj?.content?.htmlTag}
                          prefix={obj?.content?.prefix}
                          suffix={obj?.content?.suffix}
                        />
                      </div>
                    ) : obj?.blockName === 'testimonial' ? (
                      <div
                        className={`flex flex-col ${
                          obj?.content?.alignment === 'left'
                            ? 'items-baseline'
                            : obj?.content?.alignment === 'center'
                            ? 'items-center'
                            : 'items-end'
                        }`}
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <h4
                          style={{
                            textAlign:
                              obj?.content?.alignment === 'left'
                                ? 'left'
                                : obj?.content?.alignment === 'center'
                                ? 'center'
                                : 'end',
                            fontWeight: obj?.style?.desc_font_weight,
                            fontSize: `${obj?.style?.desc_font_size}px`,
                            lineHeight: `${obj?.style?.desc_font_height}px`,
                            color: obj?.style?.desc_font_color,
                            fontStyle: obj?.style?.desc_font_style,
                            textDecoration: obj?.style?.desc_font_decoration,
                            textTransform: obj?.style?.desc_font_capitalize,
                            wordBreak: obj?.style?.desc_font_break,
                            textWrap: obj?.style?.desc_font_wrap,
                            letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.desc_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                            textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
                            textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                          }}
                        >
                          {obj?.content?.description}
                        </h4>
                        <div
                          className={`space-x-3 flex items-center mt-6 ${
                            obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                          }`}
                        >
                          <div>
                            <img
                              style={{
                                borderTop: `${obj?.style?.image_border_top}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                                borderBottom: `${obj?.style?.image_border_bottom}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                                borderLeft: `${obj?.style?.image_border_left}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                                borderRight: `${obj?.style?.image_border_right}px ${obj?.style?.image_border_style} ${obj?.style?.image_border_color}`,
                                borderTopLeftRadius: obj?.style?.image_border_radius_top
                                  ? `${obj?.style?.image_border_radius_top}px`
                                  : '50%',
                                borderTopRightRadius: obj?.style?.image_border_radius_right
                                  ? `${obj?.style?.image_border_radius_right}px`
                                  : '50%',
                                borderBottomLeftRadius: obj?.style?.image_border_radius_left
                                  ? `${obj?.style?.image_border_radius_left}px`
                                  : '50%',
                                borderBottomRightRadius: obj?.style?.image_border_radius_bottom
                                  ? `${obj?.style?.image_border_radius_bottom}px`
                                  : '50%',
                                width: obj?.style?.image_width
                                  ? `${obj?.style?.image_width}px`
                                  : '50px',
                                height: obj?.style?.image_width
                                  ? `${obj?.style?.image_width}px`
                                  : '50px',
                                objectFit: 'cover',
                              }}
                              src={obj?.content?.url}
                              alt='Testimonial'
                            />
                          </div>

                          <div className='flex flex-col items-center'>
                            <h5
                              style={{
                                fontWeight: obj?.style?.name_font_weight,
                                fontSize: `${obj?.style?.name_font_size}px`,
                                lineHeight: `${obj?.style?.name_font_height}px`,
                                color: obj?.style?.name_font_color,
                                fontStyle: obj?.style?.name_font_style,
                                textDecoration: obj?.style?.name_font_decoration,
                                textTransform: obj?.style?.name_font_capitalize,
                                wordBreak: obj?.style?.name_font_break,
                                textWrap: obj?.style?.name_font_wrap,
                                letterSpacing: `${obj?.style?.name_letter_spacing}px`,
                                wordSpacing: `${obj?.style?.name_word_spacing}px`,
                                WebkitTextStroke: `${obj?.style?.name_text_stroke}px ${obj?.style?.name_stroke_color}`,
                                textShadow: `${obj?.style?.name_shadow_horizontal}px ${obj?.style?.name_shadow_vertical}px ${obj?.style?.name_shadow_blur}px ${obj?.style?.name_shadow_color}`,
                                textStroke: `${obj?.style?.name_text_stroke}px ${obj?.style?.name_stroke_color}`,
                              }}
                            >
                              {obj?.content?.name}
                            </h5>
                            <p
                              style={{
                                fontWeight: obj?.style?.title_font_weight,
                                fontSize: `${obj?.style?.title_font_size}px`,
                                lineHeight: `${obj?.style?.title_font_height}px`,
                                color: obj?.style?.title_font_color,
                                textAlign: obj?.style?.title_font_align,
                                fontStyle: obj?.style?.title_font_style,
                                textDecoration: obj?.style?.title_font_decoration,
                                textTransform: obj?.style?.title_font_capitalize,
                                wordBreak: obj?.style?.title_font_break,
                                textWrap: obj?.style?.title_font_wrap,
                                letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                                wordSpacing: `${obj?.style?.title_word_spacing}px`,
                                WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                                textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                                textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              }}
                            >
                              {obj?.content?.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : obj?.blockName === 'tabs' ? (
                      <TabsComponent obj={obj} />
                    ) : obj?.blockName === 'accordion' ? (
                      <AccordionSection key={section?.id} section={obj?.content} obj={obj} />
                    ) : obj?.blockName === 'socialIcon' ? (
                      <div
                        className='flex justify-center space-x-4'
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                          gap: `${obj?.style?.icon_spacing}px`,
                          justifyContent:
                            obj?.style?.icon_align === 'right'
                              ? 'flex-end'
                              : obj?.style?.icon_align === 'left'
                              ? 'flex-start'
                              : obj?.style?.icon_align === 'center'
                              ? 'center'
                              : undefined,
                        }}
                      >
                        {obj?.content?.item?.map((data, idx) => (
                          // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                          <div
                            style={{
                              padding: `${obj?.style?.icon_padding}px`,
                              borderTop: `${obj?.style?.icon_border_top}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderBottom: `${obj?.style?.icon_border_bottom}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderLeft: `${obj?.style?.icon_border_left}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderRight: `${obj?.style?.icon_border_right}px ${obj?.style?.icon_border_style} ${obj?.style?.icon_border_color}`,
                              borderTopLeftRadius: `${obj?.style?.icon_border_radius_top}px`,
                              borderTopRightRadius: `${obj?.style?.icon_border_radius_right}px`,
                              borderBottomLeftRadius: `${obj?.style?.icon_border_radius_left}px`,
                              borderBottomRightRadius: `${obj?.style?.icon_border_radius_bottom}px`,
                            }}
                          >
                            {data?.icon && React.isValidElement(data?.icon) ? (
                              React.cloneElement(data?.icon, {
                                width: obj?.style?.icon_width
                                  ? `${obj.style?.icon_width}px`
                                  : '50px',
                                height: obj?.style?.icon_width
                                  ? `${obj.style?.icon_width}px`
                                  : '50px',
                                fill: obj?.style?.icon_color
                                  ? `${obj.style?.icon_color}`
                                  : data?.icon?.props?.fill,

                                stroke: obj?.style?.icon_color
                                  ? `${obj.style?.icon_color}`
                                  : data?.icon?.props?.stroke,
                              })
                            ) : (
                              <SvgSection
                                svgContent={data?.icon}
                                width={
                                  obj?.style?.icon_width ? `${obj?.style.icon_width}px` : '50px'
                                }
                                height={
                                  obj?.style?.icon_width ? `${obj?.style?.icon_width}px` : '50px'
                                }
                                fill={obj?.style?.icon_color}
                                stroke={obj?.style?.icon_color}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : obj?.blockName === 'alert' ? (
                      <div
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                          backgroundColor: obj?.style?.alert_background,
                        }}
                      >
                        <div
                          key={obj.id}
                          className={`relative p-3 border rounded-lg ${getAlertClass(alertType)}`}
                        >
                          {obj?.content?.showIcon && (
                            <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: obj?.style?.icon_width
                                    ? `${obj.style.icon_width}px`
                                    : '15px',
                                  height: obj?.style?.icon_width
                                    ? `${obj.style.icon_width}px`
                                    : '15px',
                                  fill: obj?.style?.icon_color
                                    ? `${obj.style?.icon_color}`
                                    : obj?.icon?.props?.fill,

                                  stroke: obj?.style?.icon_color
                                    ? `${obj.style?.icon_color}`
                                    : obj?.icon?.props?.stroke,
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width={
                                    obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px'
                                  }
                                  height={
                                    obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px'
                                  }
                                  fill={obj?.style?.icon_color}
                                  stroke={obj?.style?.icon_color}
                                />
                              )}
                            </button>
                          )}
                          <h5
                            style={{
                              fontWeight: obj?.style?.title_font_weight,
                              fontSize: `${obj?.style?.title_font_size}px`,
                              lineHeight: `${obj?.style?.title_font_height}px`,
                              color: obj?.style?.title_font_color,
                              textAlign: obj?.style?.title_font_align,
                              fontStyle: obj?.style?.title_font_style,
                              textDecoration: obj?.style?.title_font_decoration,
                              textTransform: obj?.style?.title_font_capitalize,
                              wordBreak: obj?.style?.title_font_break,
                              textWrap: obj?.style?.title_font_wrap,
                              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.title_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                              textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
                              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            }}
                          >
                            {obj?.content?.title}
                          </h5>
                          <p
                            className='mt-2'
                            style={{
                              fontWeight: obj?.style?.desc_font_weight,
                              fontSize: `${obj?.style?.desc_font_size}px`,
                              lineHeight: `${obj?.style?.desc_font_height}px`,
                              color: obj?.style?.desc_font_color,
                              textAlign: obj?.style?.desc_font_align,
                              fontStyle: obj?.style?.desc_font_style,
                              textDecoration: obj?.style?.desc_font_decoration,
                              textTransform: obj?.style?.desc_font_capitalize,
                              wordBreak: obj?.style?.desc_font_break,
                              textWrap: obj?.style?.desc_font_wrap,
                              letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
                              wordSpacing: `${obj?.style?.desc_word_spacing}px`,
                              WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                              textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
                              textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
                              marginTop: `${obj?.style?.content_spacing}px`,
                            }}
                          >
                            {obj?.content?.content}
                          </p>
                        </div>
                      </div>
                    ) : obj?.blockName === 'html' ? (
                      <>
                        {obj?.content?.item ? (
                          <div
                            onClick={handleClick}
                            className='text-center'
                            style={{
                              paddingTop: `${obj?.style?.padding_top}px`,
                              paddingBottom: `${obj?.style?.padding_bottom}px`,
                              paddingLeft: `${obj?.style?.padding_left}px`,
                              paddingRight: `${obj?.style?.padding_right}px`,
                              marginTop: `${obj?.style?.margin_top}px`,
                              marginBottom: `${obj?.style?.margin_bottom}px`,
                              marginLeft: `${obj?.style?.margin_left}px`,
                              marginRight: `${obj?.style?.margin_right}px`,
                              borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                              borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                              borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                              borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                              borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                              borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                              borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                              borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                              boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,

                              textAlign:
                                obj?.style?.html_align === 'end'
                                  ? 'right'
                                  : obj?.style?.html_align === 'start'
                                  ? 'left'
                                  : obj?.style?.html_align === 'center'
                                  ? 'center'
                                  : obj?.style?.html_align === 'stretch'
                                  ? 'justify'
                                  : undefined,
                              transform: `rotate(${obj?.style?.html_rotate}deg)`,
                              background:
                                obj?.style?.background_gradient === 'gradient'
                                  ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                                  : obj?.style?.background_image
                                  ? `url(${obj?.style?.background_image})`
                                  : obj?.style?.background,
                              backgroundRepeat: obj?.style?.background_repeat,
                              backgroundAttachment: obj?.style?.background_attachment,
                              objectFit: obj?.style?.background_fit,
                              backgroundSize:
                                obj?.style?.background_fit === 'cover' ||
                                obj?.style?.background_fit === 'contain'
                                  ? obj?.style?.background_fit
                                  : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                              backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                              paddingBottom: getHeightForAspectRatio(obj?.style?.aspectRatio),
                            }}
                            dangerouslySetInnerHTML={{
                              __html: obj?.content?.item,
                            }}
                          />
                        ) : (
                          <p className='text-center'>Write Your Code Here...</p>
                        )}
                      </>
                    ) : obj?.blockName === 'rating' ? (
                      <div
                        className={`flex ${
                          obj?.content?.alignment === 'left'
                            ? 'justify-start'
                            : obj?.content?.alignment === 'center'
                            ? 'justify-center'
                            : obj?.content?.alignment === 'right'
                            ? 'justify-end'
                            : 'justify-start'
                        }`}
                        style={{
                          paddingTop: `${obj?.style?.padding_top}px`,
                          paddingBottom: `${obj?.style?.padding_bottom}px`,
                          paddingLeft: `${obj?.style?.padding_left}px`,
                          paddingRight: `${obj?.style?.padding_right}px`,
                          marginTop: `${obj?.style?.margin_top}px`,
                          marginBottom: `${obj?.style?.margin_bottom}px`,
                          marginLeft: `${obj?.style?.margin_left}px`,
                          marginRight: `${obj?.style?.margin_right}px`,
                          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                          transform: `rotate(${obj?.style?.text_rotate}deg)`,
                          background:
                            obj?.style?.background_gradient === 'gradient'
                              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                              : obj?.style?.background_image
                              ? `url(${obj?.style?.background_image})`
                              : obj?.style?.background,
                          backgroundRepeat: obj?.style?.background_repeat,
                          backgroundAttachment: obj?.style?.background_attachment,
                          objectFit: obj?.style?.background_fit,
                          backgroundSize:
                            obj?.style?.background_fit === 'cover' ||
                            obj?.style?.background_fit === 'contain'
                              ? obj?.style?.background_fit
                              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                        }}
                      >
                        <StarRating
                          initialRate={obj?.content?.initialRate}
                          totalRate={obj?.content?.totalRate}
                          svgData={obj?.content?.icon}
                          obj={obj}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </ReactDragListView>
          </div>
        ) : (
          <div className='cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='28'
              height='28'
              fill='#9da5ae'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
            </svg>
          </div>
        )}
        <RenderContextMenu
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          handleMenuItemClick={handleMenuItemClick}
          selectedSingleItem={selectedSingleItem}
          setMenuVisible={setMenuVisible}
          setCopiedItem={setCopiedItem}
          handleRemoveSingleItem={handleRemoveSingleItem}
          handleDuplicateSingleItem={handleDuplicateSingleItem}
          sectionIndex={sectionIndex}
          handleCopySingleItem={handleCopySingleItem}
          handleCutSingleItem={handleCutSingleItem}
          newItem={newItem}
          cutItem={cutItem}
          setCutItem={setCutItem}
          copyItem={copyItem}
          selectSection={selectSection}
          openAddSection={openAddSection}
          isEdit={isEdit}
        />
        <div className='absolute -top-14 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, 'flex_cols')} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'flex_cols')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default FirstSecondSectionAdd;
