import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ChevronDown, Edit, Trash } from 'feather-icons-react/build/IconComponents';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import Notification from '../Notification';
import EditAdditionalLicencesField from './EditAdditionalLicencesField';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const AdditionalLicensesField = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [severity, setSeverity] = useState();
  const [additionalLicensesField, setAdditionalLicensesField] = useState({
    field_name: '',
    value: '',
  });
  const [editAdditionalLicensesField, setEditAdditionalLicensesField] = useState({
    field_name: '',
    value: '',
  });
  const [storeAdditionalLicensesField, setStoreAdditionalLicensesField] = useState([]);
  const [openEditField, setOpenEditField] = useState(false);
  const [editId, setEditId] = useState();
  const [deleteId, setDeleteId] = useState();
  const handleChange = (e) => {
    setAdditionalLicensesField({ ...additionalLicensesField, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId?.length > 0) {
        const payload = encryptData(editAdditionalLicensesField);
        const apiCall = await API.put(
          `/admin/client/updateAdditionalLicensesFields/${editId}`,
          payload,
        );
        if (apiCall.status === 200) {
          getAdditionalLicensesField();
          setIsNotification(true);
          setNotificationMsg('Field update successfully!');
          setSeverity('success');
          setEditAdditionalLicensesField({
            field_name: '',
            value: '',
          });
          setOpenEditField(false);
          setEditId();
        }
      } else {
        const payload = await encryptData({ additional_licenses: additionalLicensesField });
        const apiCall = await API.post('/admin/client/addAdditionalLicensesFields', payload);
        if (apiCall.status === 200) {
          getAdditionalLicensesField();
          setIsNotification(true);
          setNotificationMsg('Field added successfully!');
          setSeverity('success');
          setAdditionalLicensesField({
            field_name: '',
            value: '',
          });
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getAdditionalLicensesField = async () => {
    try {
      const apiCall = await API.get('/admin/client/GetAdditionalLicensesFields');
      const result = decryptData(apiCall?.data);
      setStoreAdditionalLicensesField(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getAdditionalLicensesField();
  }, []);
  const handleEditField = async (data) => {
    setOpenEditField(true);
    setEditId(data?._id);
    setEditAdditionalLicensesField({
      field_name: data?.field_name,
      value: data?.value,
    });
  };
  const handleDeleteField = async () => {
    try {
      const apiCall = await API.delete(`/admin/client/deleteAdditionalLicensesFields/${deleteId}`);
      if (apiCall.status === 200) {
        getAdditionalLicensesField();
        setIsNotification(true);
        setNotificationMsg('Field delete successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Additional_Licenses_Fields')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_additional_licenses_fields_for_show_information')}
            </p>
          </div>
        </div>
        <>
          <form
            method='post'
            className='w-full text-sm mx-auto rounded-[6px] pb-[25px]'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className='mb-4'>
              <label htmlFor='title'>{`${t('lang_Additional_Licenses')} ${t('lang_Field_Name')}`}</label>
              <input
                className={`w-full px-3 py-2 border ${
                  additionalLicensesField?.field_name?.split(' ').length < 60
                    ? 'main-border-color'
                    : 'border-red-500'
                } rounded-[6px] focus:outline-none`}
                type='text'
                maxLength={40}
                value={
                  additionalLicensesField?.field_name?.length
                    ? additionalLicensesField?.field_name?.split(' ').slice(0, 40).join(' ')
                    : ''
                }
                name='field_name'
                placeholder='Enter field name'
                onChange={(e) => {
                  handleChange(e);
                }}
                required
              />
              <p className='w-full text-xs mt-0 text-gray-400'>
                {t('lang_Only_Character_Allowed', {
                  character: `${additionalLicensesField?.field_name?.length}/40`,
                })}
              </p>
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='instagram' className='flex items-center justify-between'>
                  {`${t('lang_Additional_Licenses')} ${t('lang_value')}`}
                </label>
              </div>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='url'
                value={additionalLicensesField?.value}
                name='value'
                placeholder='Enter value'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='w-full flex justify-end'>
              <button
                type='submit'
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              >
                {t('lang_Add_Additional_Licenses_Field')}
              </button>
            </div>
          </form>
          {storeAdditionalLicensesField?.length > 0 ? (
            <>
              <h6 className='text-lg font-medium my-4'>
                {t('lang_Additional_Licenses_Fields')}
              </h6>
              <div className='space-y-4'>
                {storeAdditionalLicensesField?.map((field, index) => {
                  return (
                    <div className=' w-full h-auto ' key={index}>
                      <div className='flex items-center w-full justify-between rounded-[6px] border main-border-color py-2 px-3 space-x-4 text-sm'>
                        <div className='grid table-text grid-cols-1 md:grid-cols-2 w-full gap-5'>
                          <p className='me-8 overflow-auto'>
                            <span className='font-medium'>{t('lang_Field_Name')} :</span>
                            <span className='ms-2 '>{field.field_name}</span>
                          </p>
                          <p className='overflow-auto'>
                            <span className='font-medium'>{t('lang_value')} :</span>
                            <span className='ms-2'>{field.value}</span>
                          </p>
                        </div>
                        <div className='flex mx-4 items-center space-x-4'>
                          <Tooltip title='Edit' arrow>
                            <button onClick={() => handleEditField(field)}>
                              <Edit className='w-4 h-4 text-black-500' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setDeleteId(field?._id);
                                setConfirmationPopup(true);
                              }}
                            >
                              <Trash className='w-4 h-4 text-red-500' />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      </div>
      {confirmationPopup && (
        <ConfirmationPopup
          heading='Delete Additional Licenses Field'
          subheading='Do you really want to delete this additional licenses field from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={handleDeleteField}
        />
      )}
      {openEditField && (
        <EditAdditionalLicencesField
          openEditField={openEditField}
          setOpenEditField={setOpenEditField}
          editAdditionalLicensesField={editAdditionalLicensesField}
          setEditAdditionalLicensesField={setEditAdditionalLicensesField}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default AdditionalLicensesField;
