import React from 'react';
import EditSocialIconStyleContainer from './EditSocialIconStyleContainer';

const EditSocialIconStyleSection = ({
  previewImage,
  socialIconDesign,
  setSocialIconDesign,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
}) => {
  return (
    <>
      <EditSocialIconStyleContainer
        socialIconDesign={socialIconDesign}
        setSocialIconDesign={setSocialIconDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditSocialIconStyleSection;
