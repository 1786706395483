import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const CounterSettingSection = ({ section_id, numberCounter, handleCounterChange,saveElementorData,
  btnLoading, }) => {
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(numberCounter?.htmlTag || 'h2');

  const NumberValueChange = (e) => {
    const { name, value } = e.target;
    const numericFields = ['start', 'end', 'duration'];
    const updatedValue = numericFields.includes(name) ? parseInt(value, 10) : value;

    handleCounterChange(section_id, name, updatedValue);
  };
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  useEffect(() => {
    setSelectedHeadingLevel(numberCounter?.htmlTag);
  }, [numberCounter?.htmlTag]);
  return (
    <>
      <div className='w-full mb-4 mt-2'>
        <div className='w-full mb-4'>
          <label htmlFor='start'>Starting Number</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='start'
            name='start'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={numberCounter?.start}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='end'>Ending Number</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='end'
            name='end'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={numberCounter?.end}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='duration'>Animaton Duration</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='duration'
            name='duration'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={numberCounter?.duration}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='prefix'>Number Prefix</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='prefix'
            name='prefix'
            placeholder='Enter Prefix...'
            onChange={NumberValueChange}
            value={numberCounter?.prefix || ''}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='suffix'>Number Suffix</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='suffix'
            name='suffix'
            placeholder='Enter Suffix...'
            onChange={NumberValueChange}
            value={numberCounter?.suffix || ''}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='Title'>Title</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='title'
            name='title'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={numberCounter?.title}
          />
        </div>
        <div className='w-full'>
          <label>HTML Tag</label>
          <div className='relative'>
            <select
              className='w-full mt-2'
              name='htmlTag'
              value={selectedHeadingLevel}
              onChange={NumberValueChange}
            >
              {dropDownValue?.map((obj) => (
                <option key={obj?.value} value={obj?.value}>
                  {obj?.label}
                </option>
              ))}
            </select>
            <div className='select-arrow'></div>
          </div>
        </div>
        <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
      </div>
    </>
  );
};

export default CounterSettingSection;
