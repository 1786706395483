import API from "../API";
import { decryptData } from "./encryptionUtils";
export let clientData;
export let installedExtentionData;
export const GetClient = async () => {
    await API.get('/admin/client/getClient').then((res) => {
        if (res?.data) {
            const result = decryptData(res?.data)
            clientData = result?.data
        }
    }).catch((error) => {
        if (error?.response?.status === 881) {
            localStorage.removeItem('token');
        }
    });
}

export const GetInstalledExtentionData = async () => {
    await API.get('/admin/extension/GetInstalledExtensions').then((res) => {
        if (res?.data) {
            const result = decryptData(res?.data)
            installedExtentionData = result?.data
        }
    }).catch((error) => {
        console.log(error);
    });
}