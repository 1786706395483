import { Tooltip } from '@mui/material';
import { ChevronDown, Link } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import ColorPicker from '../../../../Color/ColorPicker';

const EditIconBoxStyleContainer = ({
  iconBoxDesign,
  setIconBoxDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  saveElementorData,
  btnLoading,
}) => {
  const [openState, setOpenState] = useState('');
  const [showContent, setShowContent] = useState('');

  const dropDownValue = [
    { value: { width: '150', height: '150' }, label: 'Thumbnail - 150 X 150' },
    { value: { width: '300', height: '300' }, label: 'Medium - 300 X 300' },
    { value: { width: '768', height: '0' }, label: 'Medium Large - 768 X 0' },
    { value: { width: '1024', height: '1024' }, label: 'Large - 1024 X 1024' },
  ];

  const positionValue = [
    { value: 'default', label: 'Default' },
    { value: 'center-center', label: 'Center Center' },
    { value: 'center-left', label: 'Center Left' },
    { value: 'center-right', label: 'Center Right' },
    { value: 'top-center', label: 'Top Center' },
    { value: 'top-left', label: 'Top Left' },
    { value: 'top-right', label: 'Top Right' },
    { value: 'bottom-center', label: 'Bottom Center' },
    { value: 'bottom-left', label: 'Bottom Left' },
    { value: 'bottom-right', label: 'Bottom Right' },
  ];

  const handleSizeChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    setIconBoxDesign({ ...iconBoxDesign, background_image_size: selectedSize });
    updateSectionStyle(section_id, { background_image_size: selectedSize });
  };

  const handlePositionChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    setIconBoxDesign({ ...iconBoxDesign, background_position: selectedSize });
    updateSectionStyle(section_id, { background_position: selectedSize });
  };

  const currentImageSizeString = JSON.stringify(iconBoxDesign?.background_image_size);
  const currentPositionString = JSON.stringify(iconBoxDesign?.background_position);

  const handleImageUpload = (event, section_id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageUrl = reader.result;

      setIconBoxDesign({ ...iconBoxDesign, background_image: imageUrl });

      updateSectionStyle(section_id, { background_image: imageUrl });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleMarginChange = (value) => {
    if (iconBoxDesign?.margin_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handlePaddingChange = (value) => {
    if (iconBoxDesign?.padding_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleBorderChange = (value) => {
    if (iconBoxDesign?.border_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        border_top: value,
        border_right: value,
        border_bottom: value,
        border_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        border_top: value,
        border_right: value,
        border_bottom: value,
        border_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleBorderRadiusChange = (value) => {
    if (iconBoxDesign?.border_radius_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        border_radius_top: value,
        border_radius_right: value,
        border_radius_bottom: value,
        border_radius_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        border_radius_top: value,
        border_radius_right: value,
        border_radius_bottom: value,
        border_radius_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleColorChange = (value) => {
    if (openState === 'title_font_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_font_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'title_stroke_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'desc_font_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          desc_font_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'desc_stroke_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          desc_stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'title_shadow_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          title_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'desc_shadow_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          desc_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'icon_border_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          icon_border_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'border_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          border_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'stroke_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          stroke_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'text_shadow_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          text_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'background') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          background: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'box_shadow_color') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          box_shadow_color: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'gradient_color_start') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          gradient_color_start: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    } else if (openState === 'gradient_color_end') {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          gradient_color_end: value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  const handleTitleFontWeightChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_weight: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontsizeChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_size: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontHeightChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_height: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontAlignChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_align: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontStyleChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_style: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontDecorationChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_decoration: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontCapitalizeChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_capitalize: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontBreakChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_break: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleFontWrapChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_font_wrap: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontWeightChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_weight: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontsizeChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_size: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontHeightChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_height: e.target.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontAlignChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_align: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontStyleChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_style: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontDecorationChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_decoration: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontCapitalizeChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_capitalize: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontBreakChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_break: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescFontWrapChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_font_wrap: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescLetterSpacingChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_letter_spacing: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescWordSpacingChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_word_spacing: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescTextStrokeChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_text_stroke: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleHeadingBorderStyleChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        border_style: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleLetterSpacingChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_letter_spacing: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleWordSpacingChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_word_spacing: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleTextStrokeChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_text_stroke: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleShadowBlurChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_shadow_blur: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleShadowHorizontalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_shadow_horizontal: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTitleShadowVerticalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        title_shadow_vertical: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleTextRotateBlurChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        text_rotate: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgRepeatChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_repeat: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgAttachmentChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_attachment: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBgFitChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_fit: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleShowContent = (value) => {
    setShowContent(value);
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        background_gradient: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowBlurChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_blur: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowHorizontalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_horizontal: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowVerticalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_vertical: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleBoxShadowSpreadChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        box_shadow_spread: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleStartPositionChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_start_position: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleEndPositionChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_end_position: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleGradientAngleChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        gradient_angle: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleImageBoxShadowBlurChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        icon_box_shadow_blur: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleIconBorderChange = (value) => {
    if (iconBoxDesign?.icon_border_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        icon_border_top: value,
        icon_border_right: value,
        icon_border_bottom: value,
        icon_border_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        icon_border_top: value,
        icon_border_right: value,
        icon_border_bottom: value,
        icon_border_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleIconBorderRadiusChange = (value) => {
    if (iconBoxDesign?.icon_border_radius_all === 'block') {
      setIconBoxDesign({
        ...iconBoxDesign,
        icon_border_radius_top: value,
        icon_border_radius_right: value,
        icon_border_radius_bottom: value,
        icon_border_radius_left: value,
      });
      updateSectionStyle(section_id, {
        ...iconBoxDesign,
        icon_border_radius_top: value,
        icon_border_radius_right: value,
        icon_border_radius_bottom: value,
        icon_border_radius_left: value,
      });
    } else {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          ...value,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleIconBorderStyleChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        icon_border_style: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleIconSpacingChange = (e) => {
    let newSpace = parseInt(e.target.value);
    if (newSpace >= 1 && newSpace <= 100) {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          icon_spacing: newSpace,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleContentSpacingChange = (e) => {
    let newSpace = parseInt(e.target.value);
    if (newSpace >= 1 && newSpace <= 100) {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          content_spacing: newSpace,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleIconWidthChange = (e) => {
    let newWidth = parseInt(e.target.value);
    if (newWidth >= 1 && newWidth <= 300) {
      setIconBoxDesign((prevState) => {
        const updateValue = {
          ...prevState,
          icon_width: newWidth,
        };
        updateSectionStyle(section_id, updateValue);
        return updateValue;
      });
    }
  };

  const handleAlignmentPositionChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        content_alignment: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleIconPositionChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        icon_position: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleVerticalPositionChange = (value) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        vertical_alignment: value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescShadowBlurChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_shadow_blur: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescShadowHorizontalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_shadow_horizontal: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  const handleDescShadowVerticalChange = (e) => {
    setIconBoxDesign((prevState) => {
      const updateValue = {
        ...prevState,
        desc_shadow_vertical: e?.target?.value,
      };
      updateSectionStyle(section_id, updateValue);
      return updateValue;
    });
  };

  return (
    <div className='relative'>
      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_spacing')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='2xl:flex justify-between mt-2 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_margin')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.margin_top}
                  onChange={(e) =>
                    handleMarginChange(
                      iconBoxDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Top</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.margin_right}
                  onChange={(e) =>
                    handleMarginChange(
                      iconBoxDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Right</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.margin_bottom}
                  onChange={(e) =>
                    handleMarginChange(
                      iconBoxDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Bottom</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.margin_left}
                  onChange={(e) =>
                    handleMarginChange(
                      iconBoxDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>Left</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    iconBoxDesign?.margin_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setIconBoxDesign((prevState) => ({
                      ...prevState,
                      margin_all: prevState.margin_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
          <div className='2xl:flex justify-between mt-3 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>Padding</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.padding_top}
                  onChange={(e) =>
                    handlePaddingChange(
                      iconBoxDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.padding_right}
                  onChange={(e) =>
                    handlePaddingChange(
                      iconBoxDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.padding_bottom}
                  onChange={(e) =>
                    handlePaddingChange(
                      iconBoxDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.padding_left}
                  onChange={(e) =>
                    handlePaddingChange(
                      iconBoxDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    iconBoxDesign?.padding_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setIconBoxDesign((prevState) => ({
                      ...prevState,
                      padding_all: prevState.padding_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
        </details>
      </div>
      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_box')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='flex mb-2 mt-2 justify-between items-center'>
            <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
              {t('lang_icon_position')}
            </p>
            <ul className='grid gap-2 grid-cols-3 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
              <Tooltip title='Left' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.icon_position === 'left'
                        ? 'active bg-[#555555] text-white hovered'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleIconPositionChange('left')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      fill='currentColor'
                      class='bi bi-align-start'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M1.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5'
                      />
                      <path d='M3 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z' />
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Top' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.icon_position === 'top'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => {
                      handleIconPositionChange('top');
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      fill='currentColor'
                      class='bi bi-align-top'
                      viewBox='0 0 16 16'
                    >
                      <rect width='4' height='12' rx='1' transform='matrix(1 0 0 -1 6 15)' />
                      <path d='M1.5 2a.5.5 0 0 1 0-1zm13-1a.5.5 0 0 1 0 1zm-13 0h13v1h-13z' />
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Right' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.icon_position === 'right'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => handleIconPositionChange('right')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      fill='currentColor'
                      class='bi bi-align-end'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M14.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5'
                      />
                      <path d='M13 7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1z' />
                    </svg>
                  </a>
                </li>
              </Tooltip>
            </ul>
          </div>
          {iconBoxDesign?.icon_position !== 'top' && iconBoxDesign?.icon_position !== '' ? (
            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_vertical_alignment')}
              </p>
              <ul className='grid gap-2 grid-cols-3 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Top' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.vertical_alignment === 'top'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleVerticalPositionChange('top')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-top'
                        viewBox='0 0 16 16'
                      >
                        <rect width='4' height='12' rx='1' transform='matrix(1 0 0 -1 6 15)' />
                        <path d='M1.5 2a.5.5 0 0 1 0-1zm13-1a.5.5 0 0 1 0 1zm-13 0h13v1h-13z' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Middle' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.vertical_alignment === 'middle'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleVerticalPositionChange('middle')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-middle'
                        viewBox='0 0 16 16'
                      >
                        <path d='M6 13a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zM1 8a.5.5 0 0 0 .5.5H6v-1H1.5A.5.5 0 0 0 1 8m14 0a.5.5 0 0 1-.5.5H10v-1h4.5a.5.5 0 0 1 .5.5' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Bottom' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.vertical_alignment === 'bottom'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleVerticalPositionChange('bottom')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-bottom'
                        viewBox='0 0 16 16'
                      >
                        <rect width='4' height='12' x='6' y='1' rx='1' />
                        <path d='M1.5 14a.5.5 0 0 0 0 1zm13 1a.5.5 0 0 0 0-1zm-13 0h13v-1h-13z' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>
          ) : null}
          <div className='flex mb-2 mt-2 justify-between items-center'>
            <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
              {t('lang_alignment')}
            </p>
            <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
              <Tooltip title='Left' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.content_alignment === 'left'
                        ? 'active bg-[#555555] text-white hovered'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleAlignmentPositionChange('left')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-align-left'
                    >
                      <line x1='17' y1='10' x2='3' y2='10'></line>
                      <line x1='21' y1='6' x2='3' y2='6'></line>
                      <line x1='21' y1='14' x2='3' y2='14'></line>
                      <line x1='17' y1='18' x2='3' y2='18'></line>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Center' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.content_alignment === 'center'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => handleAlignmentPositionChange('center')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-align-center'
                    >
                      <line x1='18' y1='10' x2='6' y2='10'></line>
                      <line x1='21' y1='6' x2='3' y2='6'></line>
                      <line x1='21' y1='14' x2='3' y2='14'></line>
                      <line x1='18' y1='18' x2='6' y2='18'></line>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Right' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.content_alignment === 'right'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => handleAlignmentPositionChange('right')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-align-right'
                    >
                      <line x1='21' y1='10' x2='7' y2='10'></line>
                      <line x1='21' y1='6' x2='3' y2='6'></line>
                      <line x1='21' y1='14' x2='3' y2='14'></line>
                      <line x1='21' y1='18' x2='7' y2='18'></line>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Justify' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.content_alignment === 'justify'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => handleAlignmentPositionChange('justify')}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-align-justify'
                    >
                      <line x1='21' y1='10' x2='3' y2='10'></line>
                      <line x1='21' y1='6' x2='3' y2='6'></line>
                      <line x1='21' y1='14' x2='3' y2='14'></line>
                      <line x1='21' y1='18' x2='3' y2='18'></line>
                    </svg>
                  </a>
                </li>
              </Tooltip>
            </ul>
          </div>
          <div className='flex p-1 mb-2 items-center'>
            <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
              {t('lang_icon_spacing')}
            </p>
            <input
              type='number'
              className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
              value={iconBoxDesign?.icon_spacing}
              onChange={handleIconSpacingChange}
              min={1}
              max={100}
            />
          </div>
          <div className='flex p-1 items-center'>
            <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
              {t('lang_content_spacing')}
            </p>
            <input
              type='number'
              className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
              value={iconBoxDesign?.content_spacing}
              onChange={handleContentSpacingChange}
              min={1}
              max={100}
            />
          </div>
        </details>
      </div>
      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0'>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_Title')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-[28%] table-text editor-title'>
                {t('lang_weight')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.title_font_weight}
                  onChange={handleTitleFontWeightChange}
                >
                  <option value={100}>100-{t('lang_thin')}</option>
                  <option value={200}>200-{t('lang_extra_light')}</option>
                  <option value={300}>300-{t('lang_light')}</option>
                  <option value={400}>400-{t('lang_Normal')}</option>
                  <option value={500}>500-{t('lang_Medium')}</option>
                  <option value={600}>600-{t('lang_semi_bold')}</option>
                  <option value={700}>700-{t('lang_bold')}</option>
                  <option value={800}>800-{t('lang_extra_bold')}</option>
                  <option value={900}>900-{t('lang_black')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>

            <div className='flex mt-2 items-center'>
              <div className='flex p-1 items-center'>
                <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                  {t('lang_size')}
                </p>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.title_font_size}
                  onChange={handleTitleFontsizeChange}
                />
              </div>
              <div className='flex p-1 items-center'>
                <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                  {t('lang_height')}
                </p>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.title_font_height}
                  onChange={handleTitleFontHeightChange}
                />
              </div>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('title_font_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.title_font_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.title_font_color}
                  </span>
                </div>
                {openState === 'title_font_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.title_font_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_align')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Left' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_align === 'left'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleTitleFontAlignChange('left')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-left'
                      >
                        <line x1='17' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='17' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Center' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_align === 'center'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontAlignChange('center')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-center'
                      >
                        <line x1='18' y1='10' x2='6' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='18' y1='18' x2='6' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Right' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_align === 'right'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontAlignChange('right')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-right'
                      >
                        <line x1='21' y1='10' x2='7' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='7' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Justify' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_align === 'justify'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontAlignChange('justify')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-justify'
                      >
                        <line x1='21' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid gap-2 grid-cols-2 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Normal' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_style === 'normal'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleTitleFontStyleChange('normal')}
                    >
                      I
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Italic' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_style === 'italic'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontStyleChange('italic')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-italic'
                      >
                        <line x1='19' y1='4' x2='10' y2='4'></line>
                        <line x1='14' y1='20' x2='5' y2='20'></line>
                        <line x1='15' y1='4' x2='9' y2='20'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_decoration')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_decoration === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleTitleFontDecorationChange('none')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-slash'
                      >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='line-through' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_decoration === 'line-through'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontDecorationChange('line-through')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-type-strikethrough'
                        viewBox='0 0 16 16'
                      >
                        <path d='M6.333 5.686c0 .31.083.581.27.814H5.166a2.8 2.8 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Underline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_decoration === 'underline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontDecorationChange('underline')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-underline'
                      >
                        <path d='M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3'></path>
                        <line x1='4' y1='21' x2='20' y2='21'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Overline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_decoration === 'overline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontDecorationChange('overline')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-top'
                        viewBox='0 0 16 16'
                      >
                        <rect width='4' height='12' rx='1' transform='matrix(1 0 0 -1 6 15)' />
                        <path d='M1.5 2a.5.5 0 0 1 0-1zm13-1a.5.5 0 0 1 0 1zm-13 0h13v1h-13z' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_Capitalize')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_capitalize === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleTitleFontCapitalizeChange('none')}
                    >
                      X
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Uppercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_capitalize === 'uppercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontCapitalizeChange('uppercase')}
                    >
                      AA
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Capitalize' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_capitalize === 'capitalize'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontCapitalizeChange('capitalize')}
                    >
                      Aa
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Lowercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.title_font_capitalize === 'lowercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleTitleFontCapitalizeChange('lowercase')}
                    >
                      aa
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex items-center mt-2 gap-6'>
              <p className='text-[#555555] font-medium text- w-[28%] table-text editor-title whitespace-nowrap'>
                {t('lang_word_breaking')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.title_font_break}
                  onChange={handleTitleFontBreakChange}
                >
                  <option value={'auto-phrase'}>{t('lang_auto_phrase')}</option>
                  <option value={'break-all'}>{t('lang_break_all')}</option>
                  <option value={'break-word'}>{t('lang_break_word')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>

            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                {t('lang_word_wrap')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.title_font_wrap}
                  onChange={handleTitleFontWrapChange}
                >
                  <option value={'nowrap'}>{t('lang_nowrap')}</option>
                  <option value={'balance'}>{t('lang_balance')}</option>
                  <option value={'pretty'}>{t('lang_pretty')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_letter_spacing')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_letter_spacing}
                onChange={handleTitleLetterSpacingChange}
              />
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_word_spacing')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_word_spacing}
                onChange={handleTitleWordSpacingChange}
              />
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_text_stroke')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_text_stroke}
                onChange={handleTitleTextStrokeChange}
              />
            </div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_stroke_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('title_stroke_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-2'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.title_stroke_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.title_stroke_color}
                  </span>
                </div>
                {openState === 'title_stroke_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.title_stroke_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0'>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_Description')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-[28%] table-text editor-title'>
                {t('lang_weight')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.desc_font_weight}
                  onChange={handleDescFontWeightChange}
                >
                  <option value={100}>100-{t('lang_thin')}</option>
                  <option value={200}>200-{t('lang_extra_light')}</option>
                  <option value={300}>300-{t('lang_light')}</option>
                  <option value={400}>400-{t('lang_Normal')}</option>
                  <option value={500}>500-{t('lang_Medium')}</option>
                  <option value={600}>600-{t('lang_semi_bold')}</option>
                  <option value={700}>700-{t('lang_bold')}</option>
                  <option value={800}>800-{t('lang_extra_bold')}</option>
                  <option value={900}>900-{t('lang_black')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>

            <div className='flex mt-2 items-center'>
              <div className='flex p-1 items-center'>
                <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                  {t('lang_size')}
                </p>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.desc_font_size}
                  onChange={handleDescFontsizeChange}
                />
              </div>
              <div className='flex p-1 items-center'>
                <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                  {t('lang_height')}
                </p>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.desc_font_height}
                  onChange={handleDescFontHeightChange}
                />
              </div>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('desc_font_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.desc_font_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.desc_font_color}
                  </span>
                </div>
                {openState === 'desc_font_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.desc_font_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_align')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Left' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_align === 'left'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleDescFontAlignChange('left')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-left'
                      >
                        <line x1='17' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='17' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Center' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_align === 'center'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontAlignChange('center')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-center'
                      >
                        <line x1='18' y1='10' x2='6' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='18' y1='18' x2='6' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Right' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_align === 'right'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontAlignChange('right')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-right'
                      >
                        <line x1='21' y1='10' x2='7' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='7' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Justify' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_align === 'justify'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontAlignChange('justify')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-align-justify'
                      >
                        <line x1='21' y1='10' x2='3' y2='10'></line>
                        <line x1='21' y1='6' x2='3' y2='6'></line>
                        <line x1='21' y1='14' x2='3' y2='14'></line>
                        <line x1='21' y1='18' x2='3' y2='18'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid gap-2 grid-cols-2 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='Normal' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_style === 'normal'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleDescFontStyleChange('normal')}
                    >
                      I
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Italic' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_style === 'italic'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontStyleChange('italic')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-italic'
                      >
                        <line x1='19' y1='4' x2='10' y2='4'></line>
                        <line x1='14' y1='20' x2='5' y2='20'></line>
                        <line x1='15' y1='4' x2='9' y2='20'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_decoration')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_decoration === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleDescFontDecorationChange('none')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-slash'
                      >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='line-through' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_decoration === 'line-through'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontDecorationChange('line-through')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-type-strikethrough'
                        viewBox='0 0 16 16'
                      >
                        <path d='M6.333 5.686c0 .31.083.581.27.814H5.166a2.8 2.8 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Underline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_decoration === 'underline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontDecorationChange('underline')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-underline'
                      >
                        <path d='M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3'></path>
                        <line x1='4' y1='21' x2='20' y2='21'></line>
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Overline' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_decoration === 'overline'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontDecorationChange('overline')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        class='bi bi-align-top'
                        viewBox='0 0 16 16'
                      >
                        <rect width='4' height='12' rx='1' transform='matrix(1 0 0 -1 6 15)' />
                        <path d='M1.5 2a.5.5 0 0 1 0-1zm13-1a.5.5 0 0 1 0 1zm-13 0h13v1h-13z' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_Capitalize')}
              </p>
              <ul className='grid gap-2 grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
                <Tooltip title='None' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_capitalize === 'none'
                          ? 'active bg-[#555555] text-white hovered'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleDescFontCapitalizeChange('none')}
                    >
                      X
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Uppercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_capitalize === 'uppercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontCapitalizeChange('uppercase')}
                    >
                      AA
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Capitalize' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_capitalize === 'capitalize'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontCapitalizeChange('capitalize')}
                    >
                      Aa
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Lowercase' arrow>
                  <li className='w-full'>
                    <a
                      className={`p-[1px] w-full transition-all duration-150  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.desc_font_capitalize === 'lowercase'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      } `}
                      onClick={() => handleDescFontCapitalizeChange('lowercase')}
                    >
                      aa
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            <div className='flex items-center mt-2 gap-6'>
              <p className='text-[#555555] font-medium text- w-[28%] table-text editor-title whitespace-nowrap'>
                {t('lang_word_breaking')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.desc_font_break}
                  onChange={handleDescFontBreakChange}
                >
                  <option value={'auto-phrase'}>{t('lang_auto_phrase')}</option>
                  <option value={'break-all'}>{t('lang_break_all')}</option>
                  <option value={'break-word'}>{t('lang_break_word')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>

            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                {t('lang_word_wrap')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                  value={iconBoxDesign?.desc_font_wrap}
                  onChange={handleDescFontWrapChange}
                >
                  <option value={'nowrap'}>{t('lang_nowrap')}</option>
                  <option value={'balance'}>{t('lang_balance')}</option>
                  <option value={'pretty'}>{t('lang_pretty')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_letter_spacing')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_letter_spacing}
                onChange={handleDescLetterSpacingChange}
              />
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_word_spacing')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_word_spacing}
                onChange={handleDescWordSpacingChange}
              />
            </div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_text_stroke')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_text_stroke}
                onChange={handleDescTextStrokeChange}
              />
            </div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_stroke_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('desc_stroke_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-2'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.desc_stroke_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.desc_stroke_color}
                  </span>
                </div>
                {openState === 'desc_stroke_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.desc_stroke_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0'>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_icon')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='editor-details py-4'>
            <div>
              {/* <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-left'
                  value={iconBoxDesign?.border_width}
                  onChange={handleHeadingBorderChange}
                />
              </div>
            </div> */}
              <div className='flex p-1 items-center'>
                <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                  {t('lang_width')}
                </p>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                  value={iconBoxDesign?.icon_width}
                  onChange={handleIconWidthChange}
                  min={1}
                  max={300}
                />
              </div>
              <div className='2xl:flex justify-between mt-3 items-center'>
                <p className='text-[#555555] font-medium text-[12px] table-text'>
                  {t('lang_border')}
                </p>
                <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_top}
                      onChange={(e) =>
                        handleIconBorderChange(
                          iconBoxDesign?.icon_border_all === 'block'
                            ? e.target.value
                            : { border_top: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_right}
                      onChange={(e) =>
                        handleIconBorderChange(
                          iconBoxDesign?.icon_border_all === 'block'
                            ? e.target.value
                            : { border_right: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_bottom}
                      onChange={(e) =>
                        handleIconBorderChange(
                          iconBoxDesign?.icon_border_all === 'block'
                            ? e.target.value
                            : { border_bottom: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>
                      {t('lang_bottom')}
                    </p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_left}
                      onChange={(e) =>
                        handleIconBorderChange(
                          iconBoxDesign?.icon_border_all === 'block'
                            ? e.target.value
                            : { border_left: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                  </div>
                  <div className='w-full -mt-2.5'>
                    <button
                      className={`unit-input border focus:outline-none sm:text-sm text-center ${
                        iconBoxDesign?.icon_border_all === 'block'
                          ? 'bg-[#555555] text-white'
                          : 'primary-bg-color'
                      }`}
                      onClick={() =>
                        setIconBoxDesign((prevState) => ({
                          ...prevState,
                          icon_border_all: prevState.icon_border_all === 'block' ? '' : 'block',
                        }))
                      }
                    >
                      <Link className='w-4 h-4 mx-auto' />
                    </button>
                  </div>
                </div>
              </div>
              <div className='2xl:flex justify-between mt-3 items-center'>
                <p className='text-[#555555] font-medium text-[12px] table-text'>
                  {t('lang_border_radius')}
                </p>
                <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_radius_top}
                      onChange={(e) =>
                        handleIconBorderRadiusChange(
                          iconBoxDesign?.icon_border_radius_all === 'block'
                            ? e.target.value
                            : { icon_border_radius_top: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_radius_right}
                      onChange={(e) =>
                        handleIconBorderRadiusChange(
                          iconBoxDesign?.icon_border_radius_all === 'block'
                            ? e.target.value
                            : { icon_border_radius_right: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_radius_bottom}
                      onChange={(e) =>
                        handleIconBorderRadiusChange(
                          iconBoxDesign?.icon_border_radius_all === 'block'
                            ? e.target.value
                            : { icon_border_radius_bottom: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>
                      {t('lang_bottom')}
                    </p>
                  </div>
                  <div className='w-full'>
                    <input
                      type='number'
                      className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                      value={iconBoxDesign?.icon_border_radius_left}
                      onChange={(e) =>
                        handleIconBorderRadiusChange(
                          iconBoxDesign?.icon_border_radius_all === 'block'
                            ? e.target.value
                            : { icon_border_radius_left: e.target.value },
                        )
                      }
                    />
                    <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                  </div>
                  <div className='w-full -mt-2.5'>
                    <button
                      className={`unit-input border focus:outline-none sm:text-sm text-center ${
                        iconBoxDesign?.icon_border_radius_all === 'block'
                          ? 'bg-[#555555] text-white'
                          : 'primary-bg-color'
                      }`}
                      onClick={() =>
                        setIconBoxDesign((prevState) => ({
                          ...prevState,
                          icon_border_radius_all:
                            prevState.icon_border_radius_all === 'block' ? '' : 'block',
                        }))
                      }
                    >
                      <Link className='w-4 h-4 mx-auto' />
                    </button>
                  </div>
                </div>
              </div>

              <div className='flex mb-2 mt-2 justify-between items-center'>
                <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                  {t('lang_style')}
                </p>
                <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.icon_border_style === 'none'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleIconBorderStyleChange('none')}
                    >
                      {t('lang_none')}
                    </a>
                  </li>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.icon_border_style === 'solid'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleIconBorderStyleChange('solid')}
                    >
                      {t('lang_solid')}
                    </a>
                  </li>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.icon_border_style === 'dashed'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleIconBorderStyleChange('dashed')}
                    >
                      {t('lang_dashed')}
                    </a>
                  </li>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        iconBoxDesign?.icon_border_style === 'dotted'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleIconBorderStyleChange('dotted')}
                    >
                      {t('lang_dotted')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className='w-full flex mt-2'>
                <p className='text-[#555555] font-medium text-base table-text editor-title'>
                  {t('lang_Color')}
                </p>
                <div className='relative w-full ml-5'>
                  <div
                    onClick={() => setOpenState('icon_border_color')}
                    className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                  >
                    <div
                      className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                      style={{
                        backgroundColor: iconBoxDesign?.icon_border_color,
                        height: '18px',
                        width: '29px',
                      }}
                    ></div>
                    <span className='text-sm font-medium table-text'>
                      {iconBoxDesign?.border_color}
                    </span>
                  </div>
                  {openState === 'icon_border_color' && (
                    <ColorPicker
                      handleChange={handleColorChange}
                      setting={iconBoxDesign?.icon_border_color}
                      handleCloseColor={handleCloseColor}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_title_shadow')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('title_shadow_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.title_shadow_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.title_shadow_color}
                  </span>
                </div>
                {openState === 'title_shadow_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.title_shadow_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_blur')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_shadow_blur}
                onChange={handleTitleShadowBlurChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_horizontal')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_shadow_horizontal}
                onChange={handleTitleShadowHorizontalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_vertical')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.title_shadow_vertical}
                onChange={handleTitleShadowVerticalChange}
              />
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_desc_shadow')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('desc_shadow_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.desc_shadow_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.desc_shadow_color}
                  </span>
                </div>
                {openState === 'desc_shadow_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.desc_shadow_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_blur')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_shadow_blur}
                onChange={handleDescShadowBlurChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_horizontal')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_shadow_horizontal}
                onChange={handleDescShadowHorizontalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_vertical')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.desc_shadow_vertical}
                onChange={handleDescShadowVerticalChange}
              />
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_icon_box_transform')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_rotate')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.text_rotate}
                onChange={handleTextRotateBlurChange}
              />
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_background')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_background_type')}
              </p>
              <ul className='grid grid-cols-2 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <Tooltip title='Classic' arrow>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        showContent === 'classic'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleShowContent('classic')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-brush-fill'
                        viewBox='0 0 16 16'
                      >
                        <path d='M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
                <Tooltip title='Gradient' arrow>
                  <li className='w-full'>
                    <a
                      className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                        showContent === 'gradient'
                          ? 'active bg-[#555555] text-white'
                          : 'hover:text-white'
                      }`}
                      onClick={() => handleShowContent('gradient')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-shadows'
                        viewBox='0 0 16 16'
                      >
                        <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8 7a.5.5 0 0 1 0-1h3.5q.048 0 .093.009A7 7 0 0 0 12.9 13H8a.5.5 0 0 1 0-1h5.745q.331-.474.581-1H8a.5.5 0 0 1 0-1h6.71a7 7 0 0 0 .22-1H8a.5.5 0 0 1 0-1h7q0-.51-.07-1H8a.5.5 0 0 1 0-1h6.71a7 7 0 0 0-.384-1H8a.5.5 0 0 1 0-1h5.745a7 7 0 0 0-.846-1H8a.5.5 0 0 1 0-1h3.608A7 7 0 1 0 8 15' />
                      </svg>
                    </a>
                  </li>
                </Tooltip>
              </ul>
            </div>

            {showContent === 'classic' ? (
              <div>
                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_Color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('background')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: iconBoxDesign?.background,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {iconBoxDesign?.background}
                      </span>
                    </div>
                    {openState === 'background' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={iconBoxDesign?.background}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>
                <div>
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt='Uploaded Preview'
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                  )}

                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleImageUpload(e, section_id)}
                    style={{ display: 'block', marginBottom: '10px' }}
                  />

                  <div className='w-full'>
                    <label>Image Resolution</label>
                    <div className='relative'>
                      <select
                        className='w-full mt-2'
                        onChange={handleSizeChange}
                        value={currentImageSizeString}
                      >
                        {dropDownValue?.map((obj) => (
                          <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                            {obj?.label}
                          </option>
                        ))}
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                      {t('lang_repeat')}
                    </p>
                    <div className='relative w-full primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={iconBoxDesign?.background_repeat}
                        onChange={handleBgRepeatChange}
                      >
                        <option value={'no-repeat'}>{t('lang_no_repeat')}</option>
                        <option value={'repeat'}>{t('lang_repeat')}</option>
                        <option value={'repeat-x'}>{t('lang_repeat_x')}</option>
                        <option value={'repeat-y'}>{t('lang_repeat_y')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium text- w-[60%] table-text editor-title'>
                      {t('lang_attachment')}
                    </p>
                    <div className='relative w-full primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={iconBoxDesign?.background_attachment}
                        onChange={handleBgAttachmentChange}
                      >
                        <option value={'local'}>{t('lang_default')}</option>
                        <option value={'fixed'}>{t('lang_fixed')}</option>
                        <option value={'scroll'}>{t('lang_scroll')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>

                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium w-[45%] table-text editor-title'>
                      {t('lang_fit')}
                    </p>
                    <div className='relative w-[100%] primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={iconBoxDesign?.background_fit}
                        onChange={handleBgFitChange}
                      >
                        <option>{t('lang_fill')}</option>
                        <option>{t('lang_contain')}</option>
                        <option>{t('lang_cover')}</option>
                        <option>{t('lang_none')}</option>
                        <option>{t('lang_scale_down')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div className='flex items-center mt-2'>
                    <p className='text-[#555555] font-medium w-[45%] table-text editor-title'>
                      {t('lang_position')}
                    </p>
                    <div className='relative w-[100%] primary-bg-color'>
                      <select
                        className='edit-select outline-none primary-bg-color rounded-[6px] table-text w-full capitalize'
                        value={currentPositionString}
                        onChange={handlePositionChange}
                      >
                        {positionValue?.map((obj) => (
                          <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                            {obj?.label}
                          </option>
                        ))}
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : showContent === 'gradient' ? (
              <div>
                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_Color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('gradient_color_start')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: iconBoxDesign?.gradient_color_start,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {iconBoxDesign?.gradient_color_start}
                      </span>
                    </div>
                    {openState === 'gradient_color_start' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={iconBoxDesign?.gradient_color_start}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_Location')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={setIconBoxDesign?.gradient_start_position}
                    onChange={handleStartPositionChange}
                  />
                </div>

                <div className='w-full flex mt-2'>
                  <p className='text-[#555555] font-medium text-base table-text editor-title'>
                    {t('lang_second_color')}
                  </p>
                  <div className='relative w-full'>
                    <div
                      onClick={() => setOpenState('gradient_color_end')}
                      className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                    >
                      <div
                        className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                        style={{
                          backgroundColor: iconBoxDesign?.gradient_color_end,
                          height: '18px',
                          width: '29px',
                        }}
                      ></div>
                      <span className='text-sm font-medium table-text'>
                        {iconBoxDesign?.gradient_color_end}
                      </span>
                    </div>
                    {openState === 'gradient_color_end' && (
                      <ColorPicker
                        handleChange={handleColorChange}
                        setting={iconBoxDesign?.gradient_color_end}
                        handleCloseColor={handleCloseColor}
                      />
                    )}
                  </div>
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_Location')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={setIconBoxDesign?.gradient_end_position}
                    onChange={handleEndPositionChange}
                  />
                </div>

                <div className='flex p-1 items-center'>
                  <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                    {t('lang_gradient_angle')}
                  </p>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.gradient_angle}
                    onChange={handleGradientAngleChange}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_border')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            {/* <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-left'
                  value={iconBoxDesign?.border_width}
                  onChange={handleHeadingBorderChange}
                />
              </div>
            </div> */}

            <div className='2xl:flex justify-between mt-3 items-center'>
              <p className='text-[#555555] font-medium text-[12px] table-text'>
                {t('lang_border')}
              </p>
              <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_top}
                    onChange={(e) =>
                      handleBorderChange(
                        iconBoxDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_top: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_right}
                    onChange={(e) =>
                      handleBorderChange(
                        iconBoxDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_right: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_bottom}
                    onChange={(e) =>
                      handleBorderChange(
                        iconBoxDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_bottom: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_left}
                    onChange={(e) =>
                      handleBorderChange(
                        iconBoxDesign?.border_all === 'block'
                          ? e.target.value
                          : { border_left: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                </div>
                <div className='w-full -mt-2.5'>
                  <button
                    className={`unit-input border focus:outline-none sm:text-sm text-center ${
                      iconBoxDesign?.border_all === 'block'
                        ? 'bg-[#555555] text-white'
                        : 'primary-bg-color'
                    }`}
                    onClick={() =>
                      setIconBoxDesign((prevState) => ({
                        ...prevState,
                        border_all: prevState.border_all === 'block' ? '' : 'block',
                      }))
                    }
                  >
                    <Link className='w-4 h-4 mx-auto' />
                  </button>
                </div>
              </div>
            </div>
            <div className='2xl:flex justify-between mt-3 items-center'>
              <p className='text-[#555555] font-medium text-[12px] table-text'>
                {t('lang_border_radius')}
              </p>
              <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_radius_top}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        iconBoxDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_top: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_radius_right}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        iconBoxDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_right: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_radius_bottom}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        iconBoxDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_bottom: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
                </div>
                <div className='w-full'>
                  <input
                    type='number'
                    className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                    value={iconBoxDesign?.border_radius_left}
                    onChange={(e) =>
                      handleBorderRadiusChange(
                        iconBoxDesign?.border_radius_all === 'block'
                          ? e.target.value
                          : { border_radius_left: e.target.value },
                      )
                    }
                  />
                  <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
                </div>
                <div className='w-full -mt-2.5'>
                  <button
                    className={`unit-input border focus:outline-none sm:text-sm text-center ${
                      iconBoxDesign?.border_radius_all === 'block'
                        ? 'bg-[#555555] text-white'
                        : 'primary-bg-color'
                    }`}
                    onClick={() =>
                      setIconBoxDesign((prevState) => ({
                        ...prevState,
                        border_radius_all: prevState.border_radius_all === 'block' ? '' : 'block',
                      }))
                    }
                  >
                    <Link className='w-4 h-4 mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.border_style === 'none'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('none')}
                  >
                    {t('lang_none')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.border_style === 'solid'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('solid')}
                  >
                    {t('lang_solid')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.border_style === 'dashed'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('dashed')}
                  >
                    {t('lang_dashed')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      iconBoxDesign?.border_style === 'dotted'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => handleHeadingBorderStyleChange('dotted')}
                  >
                    {t('lang_dotted')}
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.border_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_box_shadow')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full'>
                <div
                  onClick={() => setOpenState('box_shadow_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px] ml-5'
                >
                  <div
                    className='w-[40px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: iconBoxDesign?.box_shadow_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {iconBoxDesign?.box_shadow_color}
                  </span>
                </div>
                {openState === 'box_shadow_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={iconBoxDesign?.box_shadow_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_blur')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.box_shadow_blur}
                onChange={handleBoxShadowBlurChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_horizontal')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.box_shadow_horizontal}
                onChange={handleBoxShadowHorizontalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_vertical')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.box_shadow_vertical}
                onChange={handleBoxShadowVerticalChange}
              />
            </div>

            <div className='flex p-1 items-center'>
              <p className='text-[#555555] font-medium text-base w-full table-text editor-title'>
                {t('lang_spread')}
              </p>
              <input
                type='number'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={iconBoxDesign?.box_shadow_spread}
                onChange={handleBoxShadowSpreadChange}
              />
            </div>
          </div>
        </details>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default EditIconBoxStyleContainer;
