import React from 'react';
import TabsComponent from '../../../../controllers/TabsComponent';
import AccordionSection from '../../../../controllers/AccordionSection';
import NumberAnimation from '../../../../controllers/NumberAnimation';
import StarRating from '../../../../controllers/StarRating';
import ReactDragListView from 'react-drag-listview';
import SvgSection from '../../../../controllers/SvgSection';
import VideoSection from '../../../../controllers/VideoSection';
import { handleDragOver } from '../../../../controllers/ContextMenuItem';

const AddSixSectionSixGrid = ({
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  sectionAdd,
  sectionIndex,
  handleHeadingClick,
  activeHeading,
  setSectionAdd,
  selectedIndex,
  editSection,
  selectSection,
  section,
  dragProps,
  gridDestinationId,
  setGridDestinationId,
  dropContainerRef,
  setElementorId,
  setDropPosition,
  setDestinationId,
  dropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections = sectionAdd?.subSixSectionFirstGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const filteredSecondSections = sectionAdd?.subSixSectionSecondGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const filteredThreeSections = sectionAdd?.subSixSectionThirdGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const filteredFourSections = sectionAdd?.subSixSectionForthGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const filteredFiveSections = sectionAdd?.subSixSectionFifthGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const filteredSixSections = sectionAdd?.subSixSectionSixGrid?.[sectionIndex]?.filter(
    (obj) =>
      obj?.type === 'flex_cols' ||
      'flex_rows' ||
      'flex_two_grid' ||
      'flex_grid_two' ||
      'flex_four_grid' ||
      'flex_three_grid_three' ||
      'flex_four_grid_four' ||
      'flex_two_grid_one' ||
      'flex_one_grid_two' ||
      'flex_six_grid_six' ||
      'flex_five_grid' ||
      'flex_two_grid_two' ||
      'grid_two_rows' ||
      'grid_two_cols' ||
      'grid_three_rows' ||
      'grid_three_cols' ||
      'grid_three_for_three',
  );

  const getAlertClass = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-400 text-green-800';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-800';
      case 'danger':
        return 'bg-red-100 border-red-400 text-red-800';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-400 text-black-800';
    }
  };

  return (
    <>
      <div
        className='relative grid grid-rows-[200px_minmax(1000,_1fr)_100px] grid-cols-3 items-center text-center'
        ref={dropContainerRef}
      >
        <div
          className={`${
            editSection?.section_type === 'subSixSectionFirstGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionFirstGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionFirstGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFirstGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionFirstGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFirstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionFirstGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionFirstGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFirstGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;
                      const alertType = obj?.content?.alertType || 'info';

                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionFirstGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionFirstGrid', 'setting');
                            handleCopyItem(
                              e,
                              obj,
                              'subSixSectionFirstGrid',
                              itemIndex,
                              sectionIndex,
                            );
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionFirstGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            handleDragOver(
                              e,
                              'subSixSectionFirstGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionFirstGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionFirstGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionFirstGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixSectionSecondGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionSecondGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionSecondGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSecondGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionSecondGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSecondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionSecondGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionSecondGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSecondGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSecondSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredSecondSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;
                      const alertType = obj?.content?.alertType || 'info';

                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionSecondGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionSecondGrid', 'setting');
                            handleCopyItem(
                              e,
                              obj,
                              'subSixSectionSecondGrid',
                              itemIndex,
                              sectionIndex,
                            );
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionSecondGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            handleDragOver(
                              e,
                              'subSixSectionSecondGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionSecondGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionSecondGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionSecondGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixSectionThirdGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionThirdGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionThirdGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionThirdGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionThirdGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionThirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionThirdGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionThirdGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionThirdGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredThreeSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredThreeSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;
                      const alertType = obj?.content?.alertType || 'info';

                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionThirdGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionThirdGrid', 'setting');
                            handleCopyItem(
                              e,
                              obj,
                              'subSixSectionThirdGrid',
                              itemIndex,
                              sectionIndex,
                            );
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionThirdGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(
                              e,
                              'subSixSectionThirdGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionThirdGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionThirdGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionThirdGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixSectionForthGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionForthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionForthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionForthGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionForthGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionForthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionForthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionForthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionForthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredFourSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredFourSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;
                      const alertType = obj?.content?.alertType || 'info';
                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionForthGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionForthGrid', 'setting');
                            handleCopyItem(
                              e,
                              obj,
                              'subSixSectionForthGrid',
                              itemIndex,
                              sectionIndex,
                            );
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionForthGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            handleDragOver(
                              e,
                              'subSixSectionForthGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionForthGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionForthGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionForthGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixSectionFifthGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionFifthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionFifthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFifthGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionFifthGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFifthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionFifthGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionFifthGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionFifthGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredFiveSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredFiveSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;

                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionFifthGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const alertType = obj?.content?.alertType || 'info';
                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionFifthGrid', 'setting');
                            handleCopyItem(
                              e,
                              obj,
                              'subSixSectionFifthGrid',
                              itemIndex,
                              sectionIndex,
                            );
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionFifthGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            handleDragOver(
                              e,
                              'subSixSectionFifthGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionFifthGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionFifthGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionFifthGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`${
            editSection?.section_type === 'subSixSectionSixGrid' &&
            editSection?.section_index === sectionIndex
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } relative m-3 p-5  `}
          onClick={(e) => {
            openAddSection(e, 'subSixSectionSixGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionSixGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSixGrid',
              sectionIndex,
              section?.section_type,
            );
          }}
          onContextMenu={(e) => {
            const menuX = e.clientX;
            const menuY = e.clientY;
            setMenuPosition({ x: menuX, y: menuY });
            setMenuVisible(true);
            selectSection(
              e,
              section?.id || section?.section_id,
              'subSixSectionSixGrid',
              sectionIndex,
            );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSixGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragEnter={(e) => {
            openAddSection(e, 'subSixSectionSixGrid', 'add_section'),
              selectSection(
                e,
                section?.id || section?.section_id,
                'subSixSectionSixGrid',
                sectionIndex,
              );
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              'subSixSectionSixGrid',
              sectionIndex,
              section?.section_type,
            );
            setElementorId(section?.id || section?.section_id);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='flex justify-center items-center p-1 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSixSections?.length > 0 ? (
              <>
                <div>
                  <ReactDragListView {...dragProps}>
                    {filteredSixSections.map((obj, itemIndex) => {
                      const isActive =
                        editSection?.section_type === obj?.blockName &&
                        editSection?.section_id === obj?.id &&
                        editSection?.section_index === itemIndex;
                      const alertType = obj?.content?.alertType || 'info';

                      const currentActiveSection =
                        gridDestinationId === itemIndex &&
                        editSection?.section_type === 'subSixSectionSixGrid' &&
                        editSection?.section_index === sectionIndex;

                      const abovePosition =
                        dropPosition === 'above' && itemIndex === gridDestinationId;
                      const belowPosition =
                        dropPosition === 'below' && itemIndex === gridDestinationId;

                      const handleClick = (e) => {
                        if (
                          e.target.tagName === 'INPUT' ||
                          e.target.type === 'file' ||
                          e.target.tagName === 'A'
                        ) {
                          e.stopPropagation();
                          return;
                        }
                        selectSection(e, obj.id, obj.blockName, itemIndex);
                      };
                      return (
                        <div
                          key={itemIndex}
                          onClick={(e) => {
                            selectSection(e, obj?.id, obj?.blockName, itemIndex);
                            openAddSection(e, 'subSixSectionSixGrid', 'setting');
                            handleCopyItem(e, obj, 'subSixSectionSixGrid', itemIndex, sectionIndex);
                            getContextMenuItem(
                              e,
                              section?.id || section?.section_id,
                              'subSixSectionSixGrid',
                              sectionIndex,
                              section?.section_type,
                            );
                          }}
                          onDragOver={(e) => {
                            handleDragOver(
                              e,
                              'subSixSectionSixGrid',
                              sectionIndex,
                              itemIndex,
                              editSection?.section_type,
                              editSection?.section_index,
                              setDropPosition,
                              setDestinationId,
                              setGridDestinationId,
                            );
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault();
                            setGridDestinationId(null);
                            setDropPosition(null);
                          }}
                          onContextMenu={(e) => {
                            handleRightClick(
                              e,
                              obj,
                              'subSixSectionSixGrid',
                              itemIndex,
                              sectionIndex,
                            ),
                              // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                              getContextMenuItem(
                                e,
                                section?.id || section?.section_id,
                                'subSixSectionSixGrid',
                                sectionIndex,
                                section?.section_type,
                              );
                            setEditItem({
                              id: obj?.id,
                              blockName: obj?.blockName,
                              index: itemIndex,
                              sectionType: 'subSixSectionSixGrid',
                              type: 'setting',
                            });
                          }}
                          className={`cursor-pointer
                            ${
                              abovePosition || belowPosition
                                ? ''
                                : isActive
                                ? 'border-2 border-blue-500'
                                : 'border-2 border-transparent'
                            }
                            ${
                              abovePosition && currentActiveSection
                                ? 'border-t-2 border-red-700 border-dashed'
                                : ''
                            }
                            ${
                              belowPosition && currentActiveSection
                                ? 'border-b-2 border-green-700 border-dashed'
                                : ''
                            }
                            draggable-item hover:border-2 hover:border-blue-500`}
                        >
                          {obj?.blockName === 'heading' ? (
                            <>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div>{obj?.content?.title}</div>
                              ) : (
                                <p>{obj?.content?.title}</p>
                              )}
                            </>
                          ) : obj?.blockName === 'image' ? (
                            <div>
                              <img
                                src={obj?.content?.url}
                                alt='Content'
                                width={obj?.content?.imageSize?.width}
                                height={obj?.content?.imageSize?.height}
                              />
                            </div>
                          ) : obj?.blockName === 'textEditor' ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                            ></div>
                          ) : obj?.blockName === 'video' ? (
                            <div>
                              <VideoSection obj={obj} />
                            </div>
                          ) : obj?.blockName === 'button' ? (
                            <div>
                              <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                                {obj?.content?.icon}
                                {obj?.content?.text}
                              </button>
                            </div>
                          ) : obj?.blockName === 'divider' ? (
                            <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                              <div
                                className='flex items-center'
                                style={{ width: `${obj?.content?.width}%` }}
                              >
                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                                {obj?.content?.element === 'text' ? (
                                  <span className='mx-2'>{obj?.content?.text}</span>
                                ) : obj?.content?.element === 'upload' ? (
                                  // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                                  ''
                                ) : obj?.content?.element === 'none' ? (
                                  ''
                                ) : (
                                  ''
                                )}

                                <hr
                                  className={`border-black border-${obj?.content?.style}`}
                                  style={{ flexGrow: 1 }}
                                />
                              </div>
                            </div>
                          ) : obj?.blockName === 'spacer' ? (
                            <div style={{ height: `${obj?.content?.width}px` }}></div>
                          ) : obj?.blockName === 'googleMap' ? (
                            <div>
                              <iframe
                                src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                                width='80%'
                                height='500'
                                frameBorder='0'
                                style={{ border: 'none' }}
                                allowFullScreen
                                aria-hidden='false'
                                tabIndex='0'
                                loading='lazy'
                                title='Description of the content'
                              ></iframe>
                            </div>
                          ) : obj?.blockName === 'icon' ? (
                            <div>
                              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                React.cloneElement(obj?.content?.icon, {
                                  width: '50px',
                                  height: '50px',
                                })
                              ) : (
                                <SvgSection
                                  svgContent={obj?.content?.icon}
                                  width='50px'
                                  height='50px'
                                />
                              )}
                            </div>
                          ) : obj?.blockName === 'imageBox' ? (
                            <div className='flex flex-col items-center'>
                              {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                              <div>
                                <img
                                  src={obj?.content?.url}
                                  alt='Content'
                                  width={obj?.content?.imageSize?.width}
                                  height={obj?.content?.imageSize?.height}
                                />
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-4'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconBox' ? (
                            <div className='flex flex-col items-center'>
                              <div>
                                {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                                  React.cloneElement(obj?.content?.icon, {
                                    width: '50px',
                                    height: '50px',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={obj?.content?.icon}
                                    width='50px'
                                    height='50px'
                                  />
                                )}
                              </div>
                              {obj?.content?.htmlTag === 'h1' ? (
                                <h1 className='mt-6'>{obj?.content?.title}</h1>
                              ) : obj?.content?.htmlTag === 'h2' ? (
                                <h2 className='mt-6'>{obj?.content?.title}</h2>
                              ) : obj?.content?.htmlTag === 'h3' ? (
                                <h3 className='mt-6'>{obj?.content?.title}</h3>
                              ) : obj?.content?.htmlTag === 'h4' ? (
                                <h4 className='mt-6'>{obj?.content?.title}</h4>
                              ) : obj?.content?.htmlTag === 'h5' ? (
                                <h5 className='mt-6'>{obj?.content?.title}</h5>
                              ) : obj?.content?.htmlTag === 'h6' ? (
                                <h6 className='mt-6'>{obj?.content?.title}</h6>
                              ) : obj?.content?.htmlTag === 'span' ? (
                                <span className='mt-6'>{obj?.content?.title}</span>
                              ) : obj?.content?.htmlTag === 'div' ? (
                                <div className='mt-6'>{obj?.content?.title}</div>
                              ) : (
                                <p className='mt-6'>{obj?.content?.title}</p>
                              )}
                              <p className='mt-6'>{obj?.content?.description}</p>
                            </div>
                          ) : obj?.blockName === 'generalIconList' ? (
                            <div className='flex flex-col items-center'>
                              {obj?.content?.item?.map((list, index) => (
                                <div key={index} className='flex items-center mt-4'>
                                  {list?.icon && React.isValidElement(list?.icon) ? (
                                    React.cloneElement(list?.icon, {
                                      width: '20px',
                                      height: '20px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={list?.icon}
                                      width='20px'
                                      height='20px'
                                    />
                                  )}
                                  <h6 className='ml-2'>{list?.text}</h6>
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'counter' ? (
                            <div>
                              <NumberAnimation
                                start={obj?.content?.start}
                                end={obj?.content?.end}
                                duration={obj?.content?.duration}
                                title={obj?.content?.title}
                                htmlTag={obj?.content?.htmlTag}
                                prefix={obj?.content?.prefix}
                                suffix={obj?.content?.suffix}
                              />
                            </div>
                          ) : obj?.blockName === 'testimonial' ? (
                            <div
                              className={`flex flex-col ${
                                obj?.content?.alignment === 'left'
                                  ? 'items-baseline'
                                  : obj?.content?.alignment === 'center'
                                  ? 'items-center'
                                  : 'items-end'
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign:
                                    obj?.content?.alignment === 'left'
                                      ? 'left'
                                      : obj?.content?.alignment === 'center'
                                      ? 'center'
                                      : 'end',
                                }}
                              >
                                {obj?.content?.description}
                              </h4>
                              <div
                                className={`space-x-3 flex items-center mt-6 ${
                                  obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                                }`}
                              >
                                <div>
                                  <img
                                    src={obj?.content?.url}
                                    className='rounded-full w-12 h-12'
                                    alt='Testimonial'
                                  />
                                </div>
                                <div className='flex flex-col items-center'>
                                  <h5>{obj?.content?.name}</h5>
                                  <p>{obj?.content?.title}</p>
                                </div>
                              </div>
                            </div>
                          ) : obj?.blockName === 'tabs' ? (
                            <TabsComponent obj={obj} />
                          ) : obj?.blockName === 'accordion' ? (
                            <AccordionSection key={section?.id} section={obj?.content} />
                          ) : obj?.blockName === 'socialIcon' ? (
                            <div className='flex justify-center space-x-4'>
                              {obj?.content?.item?.map((data, idx) => (
                                // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                                <div>
                                  {data?.icon && React.isValidElement(data?.icon) ? (
                                    React.cloneElement(data?.icon, {
                                      width: '50px',
                                      height: '50px',
                                    })
                                  ) : (
                                    <SvgSection
                                      svgContent={data?.icon}
                                      width='50px'
                                      height='50px'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : obj?.blockName === 'alert' ? (
                            <div
                              key={obj.id}
                              className={`relative p-3 border rounded-lg ${getAlertClass(
                                alertType,
                              )}`}
                            >
                              {obj?.content?.showIcon && (
                                <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                                  {obj?.content?.icon &&
                                    React.cloneElement(obj?.content?.icon, {
                                      width: '15px',
                                      height: '15px',
                                    })}
                                </button>
                              )}
                              <h5>{obj?.content?.title}</h5>
                              <p className='mt-2'>{obj?.content?.content}</p>
                            </div>
                          ) : obj?.blockName === 'html' ? (
                            <>
                              {obj?.content?.item ? (
                                <div
                                  onClick={handleClick}
                                  className='text-center'
                                  dangerouslySetInnerHTML={{
                                    __html: obj?.content?.item,
                                  }}
                                />
                              ) : (
                                <p className='text-center'>Write Your Code Here...</p>
                              )}
                            </>
                          ) : obj?.blockName === 'rating' ? (
                            <div
                              className={`flex ${
                                obj?.content?.alignment === 'left'
                                  ? 'justify-start'
                                  : obj?.content?.alignment === 'center'
                                  ? 'justify-center'
                                  : obj?.content?.alignment === 'right'
                                  ? 'justify-end'
                                  : 'justify-start'
                              }`}
                            >
                              <StarRating
                                initialRate={obj?.content?.initialRate}
                                totalRate={obj?.content?.totalRate}
                                svgData={obj?.content?.icon}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </ReactDragListView>
                </div>
              </>
            ) : (
              <>
                <div
                  className='cursor-pointer'
                  // onClick={(e) => openAddSection(e,'subfirstGrid')}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSixSectionSixGrid;
