export const getAlertClass = (type) => {
  switch (type) {
    case 'success':
      return 'bg-green-100 border-green-400 text-green-800';
    case 'warning':
      return 'bg-yellow-100 border-yellow-400 text-yellow-800';
    case 'danger':
      return 'bg-red-100 border-red-400 text-red-800';
    case 'info':
    default:
      return 'bg-blue-100 border-blue-400 text-black-800';
  }
};

export const mapPositionToCSS = (position) => {
  switch (position) {
    case 'center-center':
      return 'center center';
    case 'center-left':
      return 'center left';
    case 'center-right':
      return 'center right';
    case 'top-center':
      return 'top center';
    case 'top-left':
      return 'top left';
    case 'top-right':
      return 'top right';
    case 'bottom-center':
      return 'bottom center';
    case 'bottom-left':
      return 'bottom left';
    case 'bottom-right':
      return 'bottom right';
    default:
      return 'initial';
  }
};

export const getHeightForAspectRatio = (aspectRatio, width) => {
  switch (aspectRatio) {
    case '1:1':
      return width;
    case '3:2':
      return (2 / 3) * width;
    case '4:3':
      return (3 / 4) * width;
    case '16:9':
      return (9 / 16) * width;
    case '21:9':
      return (9 / 21) * width;
    case '9:16':
      return (16 / 9) * width;
    default:
      return (9 / 16) * width;
  }
};

export const getWidthClass = (gridValue) => {
  switch (gridValue) {
    case '1':
      return 'xl:w-full';
    case '2':
      return 'xl:w-1/2';
    case '3':
      return 'xl:w-1/3';
    case '4':
      return 'xl:w-1/4';
    case '5':
      return 'xl:w-1/5';
    case '6':
      return 'xl:w-1/6';
    default:
      return 'xl:w-1/4';
  }
};
