import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';

const SecondStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.secondContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-2',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    <div className='w-full'>
      <ReactDragListView {...dragProps}>
        {filteredSections?.map((obj, itemIndex) => {
          const isActive =
            editSection?.section_type === obj?.blockName &&
            editSection?.section_id === obj?.id &&
            editSection?.section_index === itemIndex;

          console.log(obj?.style, 'obj=-==-=->>>>');

          return (
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-wrap w-full mb-20'>
                  <div class='lg:w-1/2 w-full mb-6 lg:mb-0'>
                    {obj?.content?.htmlTag === 'h1' ? (
                      <h1
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h1>
                    ) : obj?.content?.htmlTag === 'h2' ? (
                      <h2
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h2>
                    ) : obj?.content?.htmlTag === 'h3' ? (
                      <h3
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h3>
                    ) : obj?.content?.htmlTag === 'h4' ? (
                      <h4
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h4>
                    ) : obj?.content?.htmlTag === 'h5' ? (
                      <h5
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h5>
                    ) : obj?.content?.htmlTag === 'h6' ? (
                      <h6
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </h6>
                    ) : obj?.content?.htmlTag === 'div' ? (
                      <div
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </div>
                    ) : obj?.content?.htmlTag === 'span' ? (
                      <span
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </span>
                    ) : obj?.content?.htmlTag === 'p' ? (
                      <p
                        style={{
                          fontWeight: obj?.style?.title_font_weight,
                          fontSize: `${obj?.style?.title_font_size}px`,
                          lineHeight: `${obj?.style?.title_font_height}px`,
                          color: obj?.style?.title_font_color,
                          textAlign: obj?.style?.title_font_align,
                          fontStyle: obj?.style?.title_font_style,
                          textDecoration: obj?.style?.title_font_decoration,
                          textTransform: obj?.style?.title_font_capitalize,
                          wordBreak: obj?.style?.title_font_break,
                          textWrap: obj?.style?.title_font_wrap,
                          letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.title_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                          textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                        }}
                      >
                        {obj?.content?.title}
                      </p>
                    ) : (
                      <></>
                    )}
                    <div class='h-1 w-20 bg-indigo-500 rounded'></div>
                  </div>
                  <span
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    class='lg:w-1/2 w-full leading-relaxed text-gray-500'
                    dangerouslySetInnerHTML={{ __html: obj?.content?.description || '-' }}
                  ></span>
                </div>
                <div class='flex flex-wrap -m-4'>
                  {obj?.content?.item?.map((value) => {
                    return (
                      <div class={`${getWidthClass(obj?.content?.gridValue?.toString())} p-4`}>
                        <div class='bg-gray-100 p-6 rounded-lg'>
                          <img
                            class='h-40 rounded w-full object-cover object-center mb-6'
                            src={value?.image}
                            alt='content'
                            style={{
                              objectFit: value?.objectFit,
                            }}
                          />
                          {value?.sub_title_htmlTag === 'h1' ? (
                            <h1 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h1>
                          ) : value?.sub_title_htmlTag === 'h2' ? (
                            <h2 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h2>
                          ) : value?.sub_title_htmlTag === 'h3' ? (
                            <h3 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h3>
                          ) : value?.sub_title_htmlTag === 'h4' ? (
                            <h4 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h4>
                          ) : value?.sub_title_htmlTag === 'h5' ? (
                            <h5 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h5>
                          ) : value?.sub_title_htmlTag === 'h6' ? (
                            <h6 class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </h6>
                          ) : value?.sub_title_htmlTag === 'div' ? (
                            <div class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </div>
                          ) : value?.sub_title_htmlTag === 'span' ? (
                            <span class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </span>
                          ) : value?.sub_title_htmlTag === 'p' ? (
                            <p class='tracking-widest text-indigo-500 text-xs font-medium title-font'>
                              {value?.sub_title}
                            </p>
                          ) : (
                            <></>
                          )}
                          {value?.htmlTag === 'h1' ? (
                            <h1 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h1>
                          ) : value?.htmlTag === 'h2' ? (
                            <h2 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h2>
                          ) : value?.htmlTag === 'h3' ? (
                            <h3 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h3>
                          ) : value?.htmlTag === 'h4' ? (
                            <h4 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h4>
                          ) : value?.htmlTag === 'h5' ? (
                            <h5 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h5>
                          ) : value?.htmlTag === 'h6' ? (
                            <h6 class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </h6>
                          ) : value?.htmlTag === 'p' ? (
                            <p class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </p>
                          ) : value?.htmlTag === 'span' ? (
                            <span class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </span>
                          ) : value?.htmlTag === 'div' ? (
                            <div class='text-lg text-gray-900 font-medium title-font mb-4'>
                              {value?.title}
                            </div>
                          ) : (
                            <></>
                          )}
                          <span
                            dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                          ></span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          );
        })}
      </ReactDragListView>
    </div>
  );
};

export default SecondStaticContentSection;
