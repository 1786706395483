import React, { useEffect, useState } from 'react'
import { Checkbox, Dialog, Pagination } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const SelectLocation = ({ openLocation, closeLocationModal, alreadySelected, locationData, getSelectedLocation }) => {
    const [selectedLocation, setSelectedLocation] = useState(alreadySelected ?? [])

    const handleSelectLocation = (data) => {
        if (data) {
            const cat = selectedLocation?.length > 0 && selectedLocation?.find((x) => x?._id === data?._id);
            if (!cat) {
                setSelectedLocation([...selectedLocation, data]);
            } else {
                const removeSelected = selectedLocation?.filter((x) => x?._id !== data?._id)
                setSelectedLocation(removeSelected)
            }
        }
    }
    return (
        <Dialog
            open={openLocation}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeLocationModal}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='secondary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
                <div className='flex border-b main-border-color pb-3 items-center justify-between'>
                    <h6 className='font-medium text-xl'>{t('lang_Select_Location')}</h6>
                    <button className='close-btn' onClick={closeLocationModal}><X className="w-4 h-4" /></button>
                </div>

                <div className='h-[450px] overflow-y-auto divide-y'>
                    {locationData && locationData?.length > 0 ? locationData?.map((item, index) => {
                        return (
                            <label key={index} className='flex main-border-color items-center py-2 space-x-3'>
                                <Checkbox
                                    onChange={(e) => handleSelectLocation(item)}
                                />
                                <div>
                                    <p className='text-sm font-medium'>{item?.name}</p>
                                </div>
                            </label>
                        )
                    }
                    ) :
                        <div className='w-full flex items-center justify-center border px-2 py-2'>
                            {t('lang_Data_not_found')}
                        </div>
                    }
                </div>
                <div className='flex justify-between pagination items-center flex-wrap'>
                    <button className={`flex px-5 py-2 btn rounded-s text-sm items-center text-white transition duration-300 ${selectedLocation?.length <= 0 && 'inline-block cursor-not-allowed opacity-50 bg-btn'}`} disabled={selectedLocation?.length <= 0} onClick={(e) => { e.preventDefault(); getSelectedLocation(selectedLocation); closeLocationModal() }}>Add Location</button>
                </div>
            </div>
        </Dialog>
    )
}

export default SelectLocation