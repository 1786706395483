import React from 'react';
import AddSectionSixGrid from './AddSectionSixGrid';
import TabsComponent from '../../../../controllers/TabsComponent';
import AccordionSection from '../../../../controllers/AccordionSection';
import NumberAnimation from '../../../../controllers/NumberAnimation';
import StarRating from '../../../../controllers/StarRating';
import ReactDragListView from 'react-drag-listview';
import SvgSection from '../../../../controllers/SvgSection';
import { handleDragOver, RenderContextMenu } from '../../../../controllers/ContextMenuItem';
const { DragColumn } = ReactDragListView;

const SecondSectionAdd = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  sectionAddSecondGrid,
  sectionIndex,
  section,
  gridValue,
  activeHeadingIndex,
  handleHeadingClick,
  activeHeading,
  selectedIndex,
  setSectionAdd,
  handleSubDivClick,
  selectSection,
  setEditSection,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  setElementorId,
  dropPosition,
  setDropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections = sectionAdd?.secondGridRow?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_rows',
  );
  const getAlertClass = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-400 text-green-800';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-800';
      case 'danger':
        return 'bg-red-100 border-red-400 text-red-800';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-400 text-black-800';
    }
  };

  return (
    <>
      <div
        className={`relative m-5 p-1 flex flex-col justify-center ${
          filteredSections?.length > 0 ? '' : 'items-center'
        } border-[1px] border-dashed border-[#d5d8dc] group`}
        onClick={(e) => {
          handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
          openAddSection(e, 'secondGridRow', 'add_section');
          selectSection(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
          );
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            'flex_rows',
            sectionIndex,
            section?.section_type
          );
          setElementorId(section?.id || section?.section_id);
          getPrevSecId(e, sectionIndex);
        }}
        ref={dropContainerRef}
        onContextMenu={(e) => {
          const menuX = e.clientX;
          const menuY = e.clientY;
          setMenuPosition({ x: menuX, y: menuY });
          setMenuVisible(true);
          selectSection(e, section?.id || section?.section_id, 'flex_rows', sectionIndex);
          getContextMenuItem(e, section?.id || section?.section_id, 'flex_rows', sectionIndex,section?.section_type);
          setElementorId(section?.id || section?.section_id);
        }}
        onDragEnter={(e) => {
          e.preventDefault(),
            handleSectionClick(e, sectionIndex, section?.type || section?.section_type),
            openAddSection(e, 'secondGridRow', 'add_section'),
            selectSection(e, section?.id, section?.type || section?.section_type, sectionIndex);
            getContextMenuItem(
              e,
              section?.id || section?.section_id,
              section?.type || section?.section_type,
              sectionIndex,
              section?.section_type
            );
          setElementorId(section?.id || section?.section_id);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        {filteredSections?.length > 0 ? (
          <DragColumn {...dragProps}>
            <div className='w-full flex overflow-auto'>
              {filteredSections?.map((obj, itemIndex) => {
                const isActive =
                  editSection?.section_type === obj?.blockName &&
                  editSection?.section_id === obj?.id &&
                  editSection?.section_index === itemIndex;
                console?.log(obj?.content?.imageSize, 'sectionIndex=-=-=-=>>>');

                const currentActiveSection =
                destinationId === itemIndex &&
                editSection?.section_type === 'flex_rows' &&
                editSection?.section_index === sectionIndex;

              const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
              const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                const alertType = obj?.content?.alertType || 'info';
                const handleClick = (e) => {
                  if (
                    e.target.tagName === 'INPUT' ||
                    e.target.type === 'file' ||
                    e.target.tagName === 'A'
                  ) {
                    e.stopPropagation();
                    return;
                  }
                  selectSection(e, obj.id, obj.blockName, itemIndex);
                };

                if (obj?.blockName === 'grid') {
                  return (
                    <div
                      key={itemIndex}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                      }  hover:border-2 hover:border-blue-500`}
                      onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                    >
                      <AddSectionSixGrid
                        flexBoxValue={flexBoxValue}
                        setSectionAdd={setSectionAdd}
                        removeSection={removeSection}
                        gridValue={gridValue}
                        handleFlexBoxClick={handleFlexBoxClick}
                        openAddSection={openAddSection}
                        activeIndex={activeIndex}
                        handleSectionClick={handleSectionClick}
                        sectionAdd={sectionAdd}
                        editSection={editSection}
                        sectionIndex={sectionIndex}
                        section={section}
                        handleHeadingClick={handleHeadingClick}
                        activeHeadingIndex={activeHeadingIndex}
                        activeHeading={activeHeading}
                        handleSubDivClick={handleSubDivClick}
                        selectedIndex={selectedIndex}
                        selectSection={selectSection}
                        dragProps={dragProps}
                        gridDestinationId={gridDestinationId}
                        setGridDestinationId={setGridDestinationId}
                        dropContainerRef={dropContainerRef}
                        setElementorId={setElementorId}
                        setDropPosition={setDropPosition}
                        setDestinationId={setDestinationId}
                        dropPosition={dropPosition}
                        handleRemoveSingleItem={handleRemoveSingleItem}
                        handleDuplicateSingleItem={handleDuplicateSingleItem}
                        handleCopySingleItem={handleCopySingleItem}
                        handleCutSingleItem={handleCutSingleItem}
                        getPrevSecId={getPrevSecId}
                        menuVisible={menuVisible}
                        setMenuVisible={setMenuVisible}
                        menuPosition={menuPosition}
                        setMenuPosition={setMenuPosition}
                        selectedSingleItem={selectedSingleItem}
                        handleRightClick={handleRightClick}
                        handleMenuItemClick={handleMenuItemClick}
                        newItem={newItem}
                        cutItem={cutItem}
                        setCutItem={setCutItem}
                        setCopiedItem={setCopiedItem}
                        handleCopyItem={handleCopyItem}
                        copyItem={copyItem}
                        isEdit={isEdit}
                        setEditItem={setEditItem}
                        getContextMenuItem={getContextMenuItem}
                      />
                    </div>
                  );
                }
                return (
                  <div
                  className={`cursor-pointer
                    ${
                      abovePosition || belowPosition
                        ? ''
                        : isActive
                        ? 'border-2 border-blue-500'
                        : 'border-2 border-transparent'
                    }
                    ${
                      abovePosition && currentActiveSection
                        ? 'border-l-2 border-red-700 border-dashed'
                        : ''
                    }
                    ${
                      belowPosition && currentActiveSection
                        ? 'border-r-2 border-green-700 border-dashed'
                        : ''
                    }
                    draggable-item hover:border-2 hover:border-blue-500`}
                    onClick={(e) => {
                      selectSection(e, obj?.id, obj?.blockName, itemIndex);
                      openAddSection(e, 'secondGridRow', 'setting');
                      handleCopyItem(e, obj, 'secondGridRow', itemIndex, sectionIndex);
                      getContextMenuItem(
                        e,
                        section?.id || section?.section_id,
                        'flex_rows',
                        sectionIndex,
                        section?.section_type
                      );
                      setElementorId(section?.id || section?.section_id);
                    }}
                    onDragOver={(e) => {
                      handleDragOver(
                        e,
                        'flex_rows',
                        sectionIndex,
                        itemIndex,
                        editSection?.section_type,
                        editSection?.section_index,
                        setDropPosition,
                        setDestinationId,
                        setGridDestinationId
                      );
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setDestinationId(null);
                      setDropPosition(null);
                    }}
                    onContextMenu={(e) => {
                      handleRightClick(e, obj, 'flex_rows', itemIndex, sectionIndex),
                        // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                        getContextMenuItem(
                          e,
                          section?.id || section?.section_id,
                          'flex_rows',
                          sectionIndex,
                        );
                      setEditItem({
                        id: obj?.id,
                        blockName: obj?.blockName,
                        index: itemIndex,
                        sectionType: 'secondGridRow',
                        type: 'setting',
                      });
                    }}
                  >
                    {obj?.blockName === 'heading' ? (
                      <>
                        {obj?.content?.htmlTag === 'h1' ? (
                          <h1>{obj?.content?.title}</h1>
                        ) : obj?.content?.htmlTag === 'h2' ? (
                          <h2>{obj?.content?.title}</h2>
                        ) : obj?.content?.htmlTag === 'h3' ? (
                          <h3>{obj?.content?.title}</h3>
                        ) : obj?.content?.htmlTag === 'h4' ? (
                          <h4>{obj?.content?.title}</h4>
                        ) : obj?.content?.htmlTag === 'h5' ? (
                          <h5>{obj?.content?.title}</h5>
                        ) : obj?.content?.htmlTag === 'h6' ? (
                          <h6>{obj?.content?.title}</h6>
                        ) : obj?.content?.htmlTag === 'span' ? (
                          <span>{obj?.content?.title}</span>
                        ) : obj?.content?.htmlTag === 'div' ? (
                          <div>{obj?.content?.title}</div>
                        ) : (
                          <p>{obj?.content?.title}</p>
                        )}
                      </>
                    ) : obj?.blockName === 'image' ? (
                      <div>
                        <img
                          src={obj?.content?.url}
                          alt='Content'
                          width={obj?.content?.imageSize?.width}
                          height={obj?.content?.imageSize?.height}
                        />
                      </div>
                    ) : obj?.blockName === 'textEditor' ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: obj?.content?.textEditorValue }}
                      ></div>
                    ) : obj?.blockName === 'video' ? (
                      <div>
                        <video controls autoPlay>
                          <source src={obj?.content} />
                        </video>
                      </div>
                    ) : obj?.blockName === 'button' ? (
                      <div>
                        <button className='bg-[#61ce70] p-3 rounded-lg text-white flex'>
                          {obj?.content?.icon}
                          {obj?.content?.text}
                        </button>
                      </div>
                    ) : obj?.blockName === 'divider' ? (
                      <div className={`flex justify-${obj?.content?.align} items-center m-4`}>
                        <div
                          className='flex items-center'
                          style={{ width: `${obj?.content?.width}%` }}
                        >
                          <hr
                            className={`border-black border-${obj?.content?.style}`}
                            style={{ flexGrow: 1 }}
                          />
                          {obj?.content?.element === 'text' ? (
                            <span className='mx-2'>{obj?.content?.text}</span>
                          ) : obj?.content?.element === 'upload' ? (
                            // <div dangerouslySetInnerHTML={{ __html: obj?.content?.icon }} />
                            ''
                          ) : obj?.content?.element === 'none' ? (
                            ''
                          ) : (
                            ''
                          )}

                          <hr
                            className={`border-black border-${obj?.content?.style}`}
                            style={{ flexGrow: 1 }}
                          />
                        </div>
                      </div>
                    ) : obj?.blockName === 'spacer' ? (
                      <div style={{ height: `${obj?.content?.width}px` }}></div>
                    ) : obj?.blockName === 'googleMap' ? (
                      <div>
                        <iframe
                          src={`https://maps.google.com/maps?q=${obj?.content?.latitude},${obj?.content?.longitude}&hl=en-US&z=14&output=embed`}
                          width='80%'
                          height='500'
                          frameBorder='0'
                          style={{ border: 'none' }}
                          allowFullScreen
                          aria-hidden='false'
                          tabIndex='0'
                          loading='lazy'
                          title='Description of the content'
                        ></iframe>
                      </div>
                    ) : obj?.blockName === 'icon' ? (
                      <div>
                        {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                          React.cloneElement(obj?.content?.icon, { width: '50px', height: '50px' })
                        ) : (
                          <SvgSection svgContent={obj?.content?.icon} width='50px' height='50px' />
                        )}
                      </div>
                    ) : obj?.blockName === 'imageBox' ? (
                      <div className='flex flex-col items-center'>
                        {/* <img src={obj?.content?.url} alt='Image Box' /> */}
                        <div>
                          <img
                            src={obj?.content?.url}
                            alt='Content'
                            width={obj?.content?.imageSize?.width}
                            height={obj?.content?.imageSize?.height}
                          />
                        </div>
                        {obj?.content?.htmlTag === 'h1' ? (
                          <h1 className='mt-6'>{obj?.content?.title}</h1>
                        ) : obj?.content?.htmlTag === 'h2' ? (
                          <h2 className='mt-6'>{obj?.content?.title}</h2>
                        ) : obj?.content?.htmlTag === 'h3' ? (
                          <h3 className='mt-6'>{obj?.content?.title}</h3>
                        ) : obj?.content?.htmlTag === 'h4' ? (
                          <h4 className='mt-6'>{obj?.content?.title}</h4>
                        ) : obj?.content?.htmlTag === 'h5' ? (
                          <h5 className='mt-6'>{obj?.content?.title}</h5>
                        ) : obj?.content?.htmlTag === 'h6' ? (
                          <h6 className='mt-6'>{obj?.content?.title}</h6>
                        ) : obj?.content?.htmlTag === 'span' ? (
                          <span className='mt-6'>{obj?.content?.title}</span>
                        ) : obj?.content?.htmlTag === 'div' ? (
                          <div className='mt-6'>{obj?.content?.title}</div>
                        ) : (
                          <p className='mt-6'>{obj?.content?.title}</p>
                        )}
                        <p className='mt-4'>{obj?.content?.description}</p>
                      </div>
                    ) : obj?.blockName === 'generalIconBox' ? (
                      <div className='flex flex-col items-center'>
                        <div>
                          {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                            React.cloneElement(obj?.content?.icon, {
                              width: '50px',
                              height: '50px',
                            })
                          ) : (
                            <SvgSection
                              svgContent={obj?.content?.icon}
                              width='50px'
                              height='50px'
                            />
                          )}
                        </div>
                        {obj?.content?.htmlTag === 'h1' ? (
                          <h1 className='mt-6'>{obj?.content?.title}</h1>
                        ) : obj?.content?.htmlTag === 'h2' ? (
                          <h2 className='mt-6'>{obj?.content?.title}</h2>
                        ) : obj?.content?.htmlTag === 'h3' ? (
                          <h3 className='mt-6'>{obj?.content?.title}</h3>
                        ) : obj?.content?.htmlTag === 'h4' ? (
                          <h4 className='mt-6'>{obj?.content?.title}</h4>
                        ) : obj?.content?.htmlTag === 'h5' ? (
                          <h5 className='mt-6'>{obj?.content?.title}</h5>
                        ) : obj?.content?.htmlTag === 'h6' ? (
                          <h6 className='mt-6'>{obj?.content?.title}</h6>
                        ) : obj?.content?.htmlTag === 'span' ? (
                          <span className='mt-6'>{obj?.content?.title}</span>
                        ) : obj?.content?.htmlTag === 'div' ? (
                          <div className='mt-6'>{obj?.content?.title}</div>
                        ) : (
                          <p className='mt-6'>{obj?.content?.title}</p>
                        )}
                        <p className='mt-6'>{obj?.content?.description}</p>
                      </div>
                    ) : obj?.blockName === 'generalIconList' ? (
                      <div className='flex flex-col items-center'>
                        {obj?.content?.item?.map((list, index) => (
                          <div key={index} className='flex items-center mt-4'>
                            {list?.icon && React.isValidElement(list?.icon) ? (
                              React.cloneElement(list?.icon, {
                                width: '20px',
                                height: '20px',
                              })
                            ) : (
                              <SvgSection svgContent={list?.icon} width='20px' height='20px' />
                            )}
                            <h6 className='ml-2'>{list?.text}</h6>
                          </div>
                        ))}
                      </div>
                    ) : obj?.blockName === 'counter' ? (
                      <div>
                        <NumberAnimation
                          start={obj?.content?.start}
                          end={obj?.content?.end}
                          duration={obj?.content?.duration}
                          title={obj?.content?.title}
                          htmlTag={obj?.content?.htmlTag}
                          prefix={obj?.content?.prefix}
                          suffix={obj?.content?.suffix}
                        />
                      </div>
                    ) : obj?.blockName === 'testimonial' ? (
                      <div
                        className={`flex flex-col ${
                          obj?.content?.alignment === 'left'
                            ? 'items-baseline'
                            : obj?.content?.alignment === 'center'
                            ? 'items-center'
                            : 'items-end'
                        }`}
                      >
                        <h4
                          style={{
                            textAlign:
                              obj?.content?.alignment === 'left'
                                ? 'left'
                                : obj?.content?.alignment === 'center'
                                ? 'center'
                                : 'end',
                          }}
                        >
                          {obj?.content?.description}
                        </h4>
                        <div
                          className={`space-x-3 flex items-center mt-6 ${
                            obj?.content?.position === 'top' ? 'flex-col' : 'flex-row'
                          }`}
                        >
                          <div>
                            <img
                              src={obj?.content?.url}
                              className='rounded-full w-12 h-12'
                              alt='Testimonial'
                            />
                          </div>
                          <div className='flex flex-col items-center'>
                            <h5>{obj?.content?.name}</h5>
                            <p>{obj?.content?.title}</p>
                          </div>
                        </div>
                      </div>
                    ) : obj?.blockName === 'tabs' ? (
                      <TabsComponent obj={obj} />
                    ) : obj?.blockName === 'accordion' ? (
                      <AccordionSection key={section?.id} section={obj?.content} />
                    ) : obj?.blockName === 'socialIcon' ? (
                      <div className='flex justify-center space-x-4'>
                        {obj?.content?.item?.map((data, idx) => (
                          // <img key={idx} src={data?.icon} width={40} alt='Social Icon' />
                          <div>
                            {data?.icon && React.isValidElement(data?.icon) ? (
                              React.cloneElement(data?.icon, {
                                width: '50px',
                                height: '50px',
                              })
                            ) : (
                              <SvgSection svgContent={data?.icon} width='50px' height='50px' />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : obj?.blockName === 'alert' ? (
                      <div
                        key={obj.id}
                        className={`relative p-3 border rounded-lg ${getAlertClass(alertType)}`}
                      >
                        {obj?.content?.showIcon && (
                          <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
                            {obj?.content?.icon &&
                              React.cloneElement(obj?.content?.icon, {
                                width: '15px',
                                height: '15px',
                              })}
                          </button>
                        )}
                        <h5>{obj?.content?.title}</h5>
                        <p className='mt-2'>{obj?.content?.content}</p>
                      </div>
                    ) : obj?.blockName === 'html' ? (
                      <>
                        {obj?.content?.item ? (
                          <div
                            onClick={handleClick}
                            className='text-center'
                            dangerouslySetInnerHTML={{
                              __html: obj?.content?.item,
                            }}
                          />
                        ) : (
                          <p className='text-center'>Write Your Code Here...</p>
                        )}
                      </>
                    ) : obj?.blockName === 'rating' ? (
                      <div
                        className={`flex ${
                          obj?.content?.alignment === 'left'
                            ? 'justify-start'
                            : obj?.content?.alignment === 'center'
                            ? 'justify-center'
                            : obj?.content?.alignment === 'right'
                            ? 'justify-end'
                            : 'justify-start'
                        }`}
                      >
                        <StarRating
                          initialRate={obj?.content?.initialRate}
                          totalRate={obj?.content?.totalRate}
                          svgData={obj?.content?.icon}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </DragColumn>
        ) : (
          <div className='cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='28'
              height='28'
              fill='#9da5ae'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
            </svg>
          </div>
        )}
         <RenderContextMenu
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          handleMenuItemClick={handleMenuItemClick}
          selectedSingleItem={selectedSingleItem}
          setMenuVisible={setMenuVisible}
          setCopiedItem={setCopiedItem}
          handleRemoveSingleItem={handleRemoveSingleItem}
          handleDuplicateSingleItem={handleDuplicateSingleItem}
          sectionIndex={sectionIndex}
          handleCopySingleItem={handleCopySingleItem}
          handleCutSingleItem={handleCutSingleItem}
          newItem={newItem}
          cutItem={cutItem}
          setCutItem={setCutItem}
          copyItem={copyItem}
          selectSection={selectSection}
          openAddSection={openAddSection}
          isEdit={isEdit}
        />
        <div className='absolute -top-14 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, 'flex_rows')} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'flex_rows')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default SecondSectionAdd;
