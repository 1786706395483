import { Modal } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import { clientData } from '../../controllers/GetClient';
import AdminPlanRazorpay from './AdminPlanRazorpay';
import Notification from '../Notification';

const PurchaseAdminPlan = ({ isAdminPlanOpen, closeAdminPlanPopup, type, permission }) => {
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [subscriptiondata, setSubscriptiondata] = useState()
    const [spinner, setSpinner] = useState(false)
    const GetSubscriptionPlans = async () => {
        try {
            const ApiCall = await API.get(`/admin/plan/getPlanList`);
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const res = decryptData(ApiCall?.data)
                setSubscriptiondata(res);
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    };
    useEffect(() => {
        GetSubscriptionPlans();
    }, []);
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const clientInfo = clientData
    let defaultPlan = null;
    const [select, setSelect] = useState({ id: null, planId: null })
    const [spinnerId, setSpinnerId] = useState()
    const handlePrchasePlan = async (e, elem, checkPlan) => {
        e.preventDefault();
        setSpinner(true)
        setSpinnerId(elem?._id)
        if (elem?.free_plan === true && elem?.free_plan_used === false) {
            try {
                const payload = encryptData({ planId: elem?._id })
                await API.post(`/admin/plan/purchasePlan`, payload)
                setIsNotification(true)
                setNotificationMsg("Plan Purchase Successfully!")
                setSeverity("success")
                closeAdminPlanPopup("success")
                window.location.href = '/dashboard'
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity("error")
            } finally {
                setSpinner(false)
                setSpinnerId()
            }
        } else {
            AdminPlanRazorpay(elem, checkPlan, closeAdminPlanPopup, closeSpinner)
        }
    }
    const calculateDiscount = (monthlyCost, planCost, months) => {
        const costOfIndividualMonths = monthlyCost * months;
        const discountAmount = costOfIndividualMonths - planCost;
        return ((discountAmount / costOfIndividualMonths) * 100).toFixed(2);
    };
    const [checkPlan, setCheckPlan] = useState("Monthly")
    const closeSpinner = () => {
        setSpinner(false)
        setSpinnerId()
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            {
                permission === true ?
                    <div className='flex w-fit mx-auto flex-wrap gap-5 md:flex-nowrap justify-center'>
                        {
                            subscriptiondata?.data?.records?.map((elem, index) => {
                                const filteredPlan = elem?.plan?.filter(item => item.price >= 0 && item.price !== '' && item.price !== null && item.price !== undefined)
                                const monthlyPrice = filteredPlan?.find((x) => x?.plan_type === "Monthly")
                                defaultPlan = elem?.plan?.length > 0 ? elem?.plan?.find((x) => x?.plan_type === 'Monthly') : elem
                                { () => setSelect({ id: elem?._id, planId: elem?.plan?.find((x) => x?.plan_type === 'Monthly')?.price }) }
                                return (
                                    <div
                                        key={index}
                                        className={`min-w-full max-w-full sm:min-w-[320px] flex flex-col justify-between sm:max-w-[320px] mx-auto rounded-[6px] secondary-bg-color shadow-[0px 1px 2px #E1E3E5] border main-border-color ${elem?.free_plan_used === true ? elem?.free_plan === true ? "hidden" : 'block' : 'block'}`}
                                    >
                                        <div className="p-[20px] text-left">
                                            <div className='flex mb-4 items-center justify-between'>
                                                <div>
                                                    <span className="text-primary text-lg font-semibold">
                                                        {elem.name}
                                                    </span>
                                                    <p className="table-text text-[12px] ">
                                                        {elem.description}
                                                    </p>
                                                </div>
                                                {elem?.active &&
                                                    <span className='text-green-700 inline-flex items-center font-medium bg-green-200 px-3 py-[3px] text-[11px] rounded-md'>
                                                        <div className='w-[5px] h-[5px] me-1.5 rounded-full bg-green-700'></div>
                                                        Active
                                                    </span>
                                                }
                                            </div>
                                            <div>
                                                <div className='my-5 '>
                                                    <p className="table-text text-[18px] leading-[28px] font-medium flex items-center space-x-1">
                                                        <p className="text-primary text-[23px] font-medium">
                                                            {select?.id === elem?._id ?
                                                                select?.planId?.plan_type === "Free" ? "Free" :
                                                                    FormatAmount(select?.planId?.price)
                                                                : FormatAmount(defaultPlan?.price)}
                                                        </p>
                                                        <sub className='text-[15px] font-medium'>
                                                            {
                                                                select?.id === elem?._id ?
                                                                    select?.planId?.plan_type === "Free" ? <span className='text-[13px]'>/ for {select?.planId?.duration} days</span> :
                                                                        <>{select?.planId?.plan_type === "Quarterly" ? '/ 3 month' : select?.planId?.plan_type === "Semi Yearly" ? '/ 6 month' : select?.planId?.plan_type === "Yearly" ? '/ year' : "/ month"}</>
                                                                    : <>/ month</>
                                                            }
                                                        </sub>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='flex items-start flex-col flex-wrap gap-1.5 mt-1.5 justify-start'>
                                                {
                                                    filteredPlan?.map((x) => {
                                                        const month = x?.plan_type === "Quarterly" ? 3 : x?.plan_type === "Semi Yearly" ? 6 : x?.plan_type === "Yearly" ? 12 : 1
                                                        return (
                                                            x?.plan_type !== "Free" &&
                                                            <label className='flex cursor-pointer items-center space-x-1'>
                                                                <input
                                                                    id='default-radio-1'
                                                                    type='radio'
                                                                    name='default-radio'
                                                                    checked={select?.id === elem?._id ? select?.planId?._id === x?._id : defaultPlan?._id === x?._id}
                                                                    onClick={() => { setSelect({ id: elem?._id, planId: x }); setCheckPlan(x?.plan_type) }}
                                                                    className='w-4 h-4 inline-block'
                                                                />
                                                                <span className='table-text text-sm'>
                                                                    {x?.plan_type}
                                                                    {x?.plan_type !== "Monthly" && <span className='text-xs ms-1'>({calculateDiscount(monthlyPrice?.price, x?.price, month)} % off)</span>}
                                                                </span>
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                elem?.features?.length > 0 &&
                                                <ul className='list-disc space-y-1 px-5 pt-5 table-text text-sm block text-[15px] leading-[28px]'>
                                                    {elem?.features.map((x, index) => (
                                                        <li key={index} className='text-left text-sm w-full'>
                                                            {x}
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        </div>
                                        <div className="px-[20px] pb-[20px]">
                                            <button disabled={spinner} className="btn w-full flex items-center justify-center rounded-[6px] capitalize px-6 py-3 text-[#fff] text-sm" onClick={(e) => { handlePrchasePlan(e, elem, checkPlan) }}>
                                                {spinner && spinnerId === elem?._id ? <>
                                                    <div className='animate-spin me-2 inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                                                    Loading...
                                                </> : 'Purchase Plan'}
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <Modal
                        open={isAdminPlanOpen}
                        onClose={() => { closeAdminPlanPopup() }}
                        closeAfterTransition
                        className={`${type === 'expired' ? 'bg plan-popup' : ''}`}
                    >
                        <div className={`flex  max-w-screen outline-none justify-center z-10 absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] overflow-hidden max-h-screen`}>
                            <div className="text-center outline-none secondary-bg-color my-4 overflow-y-auto w-auto rounded-[6px] p-5">
                                <div className='flex items-center justify-between mb-3'>
                                    <h6 className='text-xl text-center inline-block font-medium'>
                                        {clientInfo?.billing_status ?
                                            clientInfo?.billing_detail?.plan_type === 'Free' ? "Unlock more features by upgrading your plan" :
                                                'Your current plan will expire upon upgrading.' :
                                            'Choose plan'}
                                    </h6>
                                    <button className='focus:border rounded-[6px] w-[22px] h-[22px] focus:border-black' onClick={(e) => { e.preventDefault(); closeAdminPlanPopup() }}><X className="w-[18px] h-[18px]" /></button>
                                </div>
                                <hr className='my-4 main-border-color' />
                                <div className='flex mt-5 flex-wrap gap-5 md:flex-nowrap justify-between'>
                                    {
                                        subscriptiondata?.data?.records?.map((elem, index) => {
                                            const filteredPlan = elem?.plan?.filter(item => item.price >= 0 && item.price !== '' && item.price !== null && item.price !== undefined)
                                            const monthlyPrice = filteredPlan?.find((x) => x?.plan_type === "Monthly")
                                            defaultPlan = elem?.plan?.length > 0 ? elem?.plan?.find((x) => x?.plan_type === 'Monthly') : elem
                                            { () => setSelect({ id: elem?._id, planId: elem?.plan?.find((x) => x?.plan_type === 'Monthly')?.price }) }
                                            return (
                                                <div
                                                    key={index}
                                                    className={`min-w-full max-w-full sm:min-w-[320px] flex flex-col justify-between sm:max-w-[320px] mx-auto rounded-[6px] secondary-bg-color shadow-[0px 1px 2px #E1E3E5] border main-border-color ${elem?.free_plan_used === true ? elem?.free_plan === true ? "hidden" : 'block' : 'block'}`}
                                                >
                                                    <div className="p-[20px] text-left">
                                                        <div className='flex mb-4 items-center justify-between'>
                                                            <div>
                                                                <span className="text-primary text-lg font-semibold">
                                                                    {elem.name}
                                                                </span>
                                                                <p className="table-text text-[12px] ">
                                                                    {elem.description}
                                                                </p>
                                                            </div>
                                                            {elem?.active &&
                                                                <span className='text-green-700 inline-flex items-center font-medium bg-green-200 px-3 py-[3px] text-[11px] rounded-md'>
                                                                    <div className='w-[5px] h-[5px] me-1.5 rounded-full bg-green-700'></div>
                                                                    Active
                                                                </span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className='my-5 '>
                                                                <p className="table-text text-[18px] leading-[28px] font-medium flex items-center space-x-1">
                                                                    <p className="text-primary text-[23px] font-medium">
                                                                        {select?.id === elem?._id ?
                                                                            select?.planId?.plan_type === "Free" ? "Free" :
                                                                                FormatAmount(select?.planId?.price)
                                                                            : FormatAmount(defaultPlan?.price)}
                                                                    </p>
                                                                    <sub className='text-[15px] font-medium'>
                                                                        {
                                                                            select?.id === elem?._id ?
                                                                                select?.planId?.plan_type === "Free" ? <span className='text-[13px]'>/ for {select?.planId?.duration} days</span> :
                                                                                    <>{select?.planId?.plan_type === "Quarterly" ? '/ 3 month' : select?.planId?.plan_type === "Semi Yearly" ? '/ 6 month' : select?.planId?.plan_type === "Yearly" ? '/ year' : "/ month"}</>
                                                                                : <>/ month</>
                                                                        }
                                                                    </sub>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-start flex-col flex-wrap gap-1.5 mt-1.5 justify-start'>
                                                            {
                                                                filteredPlan?.map((x) => {
                                                                    const month = x?.plan_type === "Quarterly" ? 3 : x?.plan_type === "Semi Yearly" ? 6 : x?.plan_type === "Yearly" ? 12 : 1
                                                                    return (
                                                                        x?.plan_type !== "Free" &&
                                                                        <label className='flex cursor-pointer items-center space-x-1'>
                                                                            <input
                                                                                id='default-radio-1'
                                                                                type='radio'
                                                                                name='default-radio'
                                                                                checked={select?.id === elem?._id ? select?.planId?._id === x?._id : defaultPlan?._id === x?._id}
                                                                                onClick={() => { setSelect({ id: elem?._id, planId: x }); setCheckPlan(x?.plan_type) }}
                                                                                className='w-4 h-4 inline-block p-0'
                                                                            />
                                                                            <span className='table-text text-sm'>
                                                                                {x?.plan_type}
                                                                                {x?.plan_type !== "Monthly" && <span className='text-xs ms-1'>({calculateDiscount(monthlyPrice?.price, x?.price, month)} % off)</span>}
                                                                            </span>
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            elem?.features?.length > 0 &&
                                                            <ul className='list-disc space-y-1 px-5 pt-5 table-text text-sm block text-[15px] leading-[28px]'>
                                                                {elem?.features.map((x, index) => (
                                                                    <li key={index} className='text-left text-sm w-full'>
                                                                        {x}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        }
                                                    </div>
                                                    <div className="px-[20px] pb-[20px]">
                                                        <button disabled={spinner} className="btn w-full flex items-center justify-center rounded-[6px] capitalize px-6 py-3 text-[#fff] text-sm" onClick={(e) => { handlePrchasePlan(e, elem, checkPlan) }}>
                                                            {spinner && spinnerId === elem?._id ? <>
                                                                <div className='animate-spin me-2 inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                                                                Loading...
                                                            </> : 'Purchase Plan'}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>
            }
        </>
    )
}

export default PurchaseAdminPlan