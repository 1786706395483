import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import module from '../../../../../controllers/QuillToolBar';
import IconList from '../IconList';
import SvgSection from '../../../../../controllers/SvgSection';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  isHovered,
  setIsHovered,
  handleIconClick,
  handleSvgClick,
  newIcon,
  setNewIcon,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });

  console.log(item, 'item=-=-=>>');

  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='description'>Description</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>
          <div className='w-full mb-4'>
            <label htmlFor='button'>Button</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='button'
              name='button'
              placeholder='Enter button...'
              value={item?.button || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full mb-4'>
            <label htmlFor='button_link'>Button Link</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='button_link'
              name='button_link'
              placeholder='Enter button link...'
              value={item?.button_link || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className='mt-2'>
            <label htmlFor='text'>Title Icon</label>
            <div
              className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
              onMouseEnter={() => {
                setIsHovered(true), setNewIcon('titleIcon');
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              {item?.titleIcon &&
                (React.isValidElement(item?.titleIcon) ? (
                  React.cloneElement(item?.titleIcon, { width: '100px', height: '100px' })
                ) : (
                  <SvgSection svgContent={item?.titleIcon} width='100px' height='100px' />
                ))}

              {isHovered && newIcon === 'titleIcon' && (
                <>
                  <button
                    className='absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleIconClick('iconList')}
                  >
                    Icon library
                  </button>
                  <button
                    className='absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleSvgClick()}
                  >
                    SVG Icon
                  </button>
                </>
              )}
            </div>

            <label htmlFor='text' className='mt-4'>
              Link Icon
            </label>
            <div
              className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
              onMouseEnter={() => {
                setIsHovered(true), setNewIcon('linkIcon');
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              {item?.linkIcon &&
                (React.isValidElement(item?.linkIcon) ? (
                  React.cloneElement(item?.linkIcon, { width: '100px', height: '100px' })
                ) : (
                  <SvgSection svgContent={item?.linkIcon} width='100px' height='100px' />
                ))}

              {isHovered && newIcon === 'linkIcon' && (
                <>
                  <button
                    className='absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleIconClick('iconList')}
                  >
                    Icon library
                  </button>
                  <button
                    className='absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleSvgClick()}
                  >
                    SVG Icon
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticFirstFeatureSection = ({
  section_id,
  firstFeatureValue,
  setFirstFeatureValue,
  handleFirstFeatureValue,
  tabIndex,
  setTabIndex,
  handleAddFirstFeatureItem,
  handleCopyFirstFeatureItem,
  handleFirstFeatureDataRemoveValue,
  saveElementorData,
  btnLoading,
  sectionGridContent,
  handleFirstFeatureGridValueChange,
  sectionContent,
  handleFirstFeatureTitleValueChange,
  section_type,
  subTitleheadingLevel,
  handleFirstFeatureTitleTagChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];
  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];
  const [gridValue, setGridValue] = useState(sectionGridContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [selectedSubTitleHeadingLevel, setSelectedSubTitleHeadingLevel] = useState(
    subTitleheadingLevel || 'h2',
  );
  
  const fileInputRef = useRef(null);

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleFirstFeatureTitleValueChange(section_id, value);
  };

  const onTitleContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleFirstFeatureTitleValueChange(section_id, newValue);
  };

  const handleSubTitleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedSubTitleHeadingLevel(newTag);
    handleFirstFeatureTitleTagChange(section_id, newTag);
  };

  useEffect(() => {
    setGroupTitle(sectionContent);
  }, [sectionContent]);

  useEffect(() => {
    setSelectedSubTitleHeadingLevel(subTitleheadingLevel);
  }, [subTitleheadingLevel]);

  useEffect(() => {
    setGridValue(sectionGridContent);
  }, [sectionGridContent]);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...firstFeatureValue,
      item: firstFeatureValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setFirstFeatureValue(updatedfirstContentValue);
    handleFirstFeatureValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = firstFeatureValue?.item?.length
      ? firstFeatureValue?.item?.[firstFeatureValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      description: 'Lorem ipsum',
      button: 'Button',
      htmlTag: 'h2',
      button_link: '#',
      isEdit: false,
      titleIcon: ` <svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-6 h-6'
      viewBox='0 0 24 24'
    >
      <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
  </svg>`,
      linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
    viewBox='0 0 24 24'
    >
    <path d='M5 12h14M12 5l7 7-7 7'></path>
  </svg>`,
      link: '',
    };
    const updatedItems = firstFeatureValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddFirstFeatureItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyFirstFeatureItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleFirstFeatureDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...firstFeatureValue,
      item: firstFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setFirstFeatureValue(updatedfirstContentValue);
    handleFirstFeatureValue(section_id, updatedfirstContentValue);
  };

  const handleQuillChange = (content) => {
    console.log(content, 'content=-=-=-=>>>');
    const updatedfirstContentValue = {
      ...firstFeatureValue,
      item: firstFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setFirstFeatureValue(updatedfirstContentValue);
    handleFirstFeatureValue(section_id, updatedfirstContentValue);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    console.log(newValue, 'newValue=-=-=-=>>');
    setGridValue(newValue);
    handleFirstFeatureGridValueChange(section_id, newValue);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [newIcon, setNewIcon] = useState(null);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    console.log('handleIconSelect', iconComponent);
    const updatedFirstContentValue = {
      ...firstFeatureValue,
      item: firstFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [newIcon]: iconComponent } : item,
      ),
    };

    setFirstFeatureValue(updatedFirstContentValue);
    handleFirstFeatureValue(section_id, updatedFirstContentValue);
    setIsOpen(false);
    setNewIcon(null);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
        setNewIcon(null);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onTitleContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedSubTitleHeadingLevel}
            onChange={handleSubTitleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='mt-4'>
        <label>Section</label>
        {firstFeatureValue?.item?.map((item, index) => (
          <ListItem
            key={index}
            item={item}
            handleItemClick1={() => handleItemClick(index)}
            isSelected={tabIndex === index}
            onCopy={() => copyItem(item, index)}
            onRemove={removeItem}
            handleInputChange={handleInputChange}
            dropDownValue={dropDownValue}
            handleQuillChange={handleQuillChange}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleIconClick={handleIconClick}
            handleIconSelect={handleIconSelect}
            handleSvgClick={handleSvgClick}
            newIcon={newIcon}
            setNewIcon={setNewIcon}
          />
        ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mt-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticFirstFeatureSection;
