import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeyPress } from './useKeyPress';
import { useCookies } from 'react-cookie';
import { Dialog } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';

const ShortCustFunc = ({ inputRef }) => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const [cookie, setCookie, removeCookie] = useCookies(['token']);

    const handleClose = () => {
        setOpen(false);
    };

    useKeyPress(['?'], () => {
        setOpen(true);
    });
    useKeyPress(['Control', '/'], () => {
        if(open){
            setOpen(false);
        }
        inputRef.current.focus();
    });
    const keyMappings = {
        'Alt/g/o': "/orders",
        'Alt/m': "/profile",
        'Alt/g/h': "/dashboard",
        'Alt/g/p': "/products",
        'Alt/g/c': "/customers",
        'Alt/g/n': "/analytics",
        'Alt/s': "/discounts",
        'Alt/g/s': "/store-profile",
        'Alt/a/p': "/products/add-products",
        'Alt/a/s': "/discounts/add-discount",
        'Alt/a/o': "/order/create-order",
        'Alt/a/b': "/blog-post/add-blog-post",
        'Alt/a/g': "/pages/add-page",
        'Alt/p/c': "/categories",
        'Alt/a/c': "/categories/add-category"
    };
    Object.entries(keyMappings).forEach(([keys, path]) => {
        useKeyPress(keys.split('/'), () => {
            if(keys.split('/').includes('Alt')){
                if (window.location.pathname !== path) {
                    navigate(path);
                }
            }            
        });
    });
    useKeyPress(['b', 'y', 'e'], async () => {
        if(open){
            setOpen(false);
        }
        removeCookie('token');
        localStorage.removeItem('token');
        await navigate('/');
    });
    useEffect(() => {
        if(open){
            setOpen(false);
        }
    },[navigate])
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full sm:w-[600px] p-6 rounded-[6px]'>
                <div className='flex items-center justify-between'>
                    <p className='flex items-center text-lg font-medium gap-1'>
                        Keyboard Shortcuts
                        <span className='bg-gray-300 text-gray-500 text-xs font-medium px-2.5 py-0.5 rounded-[6px]'>Shift</span>
                        <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>?</span>
                    </p>
                    <button onClick={handleClose} className='close-btn'>
                        <X className="w-4 h-4" />
                    </button>
                </div>
                <hr className='my-4 border-gray-300'></hr>
                <div className='flex justify-between flex-wrap'>
                    <div className='my-2'>
                        <div>
                            <h6>General shortcuts </h6>
                        </div>
                        <div className='flex flex-col '>
                            <div className='flex justify-between my-2'>
                                <p>Keyboard help</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>?</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Focus search bar</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>ctrl</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>/</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Your profile</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>M</span>
                                </div>
                            </div>
                            <div className='flex justify-between'>
                                <p>Logout</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>B</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Y</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>E</span>
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <div className='my-2'>
                        <div>
                            <h6>Adding items to your store</h6>
                        </div>
                        <div className='flex flex-col '>
                            <div className='flex justify-between my-2'>
                                <p>Add product</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>P</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Add collection</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>C</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Add discount</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>s</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Add order</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>O</span>
                                </div>
                            </div>                   
                            <div className='flex justify-between my-2'>
                                <p>Add blog post</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>B</span>
                                </div>
                            </div>                   
                            <div className='flex justify-between my-2'>
                                <p>Add page</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>A</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                </div>
                            </div>                   
                        </div>
                    </div>
                    <div className='my-2'>
                        <div>
                            <h6>Navigating your admin panel</h6>
                        </div>
                        <div className='flex flex-col '>
                            <div className='flex justify-between my-2'>
                                <p>Go to Home</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>H</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Go to Order</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>O</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Go to Product</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>P</span>
                                </div>
                            </div>
                            <div className='flex justify-between my-2'>
                                <p>Go to Customer</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>C</span>
                                </div>
                            </div>                   
                            <div className='flex justify-between my-2'>
                                <p>Go to Category</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>P</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>C</span>
                                </div>
                            </div>                   
                            <div className='flex justify-between my-2'>
                                <p>Go to Analytics</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>N</span>
                                </div>
                            </div>                   
                            <div className='flex justify-between my-2'>
                                <p>Go to Discount</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>s</span>
                                </div>
                            </div>              
                            <div className='flex justify-between my-2'>
                                <p>Go to Setting</p>
                                <div>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>Alt</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>G</span>
                                    <span className='bg-gray-300 text-gray-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded-[6px]'>S</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ShortCustFunc