import React from 'react';
import EditCounterStyleContainer from './EditCounterStyleContainer';

const EditCounterStyleSection = ({previewImage, counterDesign, setCounterDesign, handleApplyChangesElementor ,section_id,updateSectionStyle,saveElementorData,
  btnLoading,}) => {
  return (
    <>
      <EditCounterStyleContainer
        counterDesign={counterDesign}
        setCounterDesign={setCounterDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditCounterStyleSection;
