import React, { useContext, useEffect, useState } from 'react';
import API from '../../../API';
import { useParams } from 'react-router-dom';
import { Dialog, Skeleton, SwipeableDrawer } from '@mui/material';
import AddLayout from '../../Layout/AddLayout';
import { ChevronDown, ChevronLeft, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import EditProductSection from '../../Layout/EditSection/EditProductSection';
import EditBlogSection from '../../Layout/EditSection/EditBlogSection';
import EditManufactureSection from '../../Layout/EditSection/EditManufactureSection';
import EditReassuranceSection from '../../Layout/EditSection/EditReassuranceSection';
import EditCmsSection from '../../Layout/EditSection/EditCmsSection';
import EditSliderSection from '../../Layout/EditSection/EditSliderSection';
import EditBannerSection from '../../Layout/EditSection/EditBannerSection';
import VisualAnnounccementBar from '../../Layout/AnnouncementBar/VisualAnnounccementBar';
import StoreNavbar from '../../Layout/VisualLayout/StoreNavbar';
import Footer from '../../Layout/VisualLayout/Footer';
import FooterSection from '../../Layout/FooterSection';
import EditCategorySection from '../../Layout/EditSection/EditCategorySection';
import HeaderSection from '../../Layout/HeaderSection';
import AnnouncementSection from '../../Layout/AnnouncementBar/AnnouncementSection';
import Notification from '../../Notification';
import { Transition } from '../../../controllers/Transition';
import styled from 'styled-components';
import { clientData, GetClient } from '../../../controllers/GetClient';
import ProductPageSectionList from '../../Layout/SectionList/ProductPageSectionList';
import OldProductPageEditor from '../../Layout/LiveEditor/OldProductPageEditor';
import ProductPageEditSection from '../../Layout/EditSection/ProductPage/ProductPageEditSection';
import { t } from 'i18next';
import EditProductPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditProductPageStyleSection/EditProductPageStyleSection';
import '../../../EditSectionStyle.css';
import EditReviewPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditReviewPageStyleSection/EditReviewPageStyleSection';
import EditFaqsPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditFaqsPageStyleSection/EditFaqsPageStyleSection';
import EditRelatedPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditRelatedPageStyleSection/EditRelatedPageStyleSection';
import EditCrossProductPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditCrossProductPageStyleSection/EditCrossProductPageStyleSection';
import EditUpSellProductPageStyleSection from '../../Layout/EditSection/ProductPage/EditStyleSectionProduct/EditUpSellProductPageStyleSection/EditUpSellProductPageStyleSection';
import EditProductStyleSection from '../../Layout/EditSection/EditProductStyleSection/EditProductStyleSection';
import EditBlogStyleSection from '../../Layout/EditSection/EditBlogStyleSection/EditBlogStyleSection';
import EditCategoryStyleSection from '../../Layout/EditSection/EditCategoryStyleSection/EditCategoryStyleSection';
import EditManufactureStyleSection from '../../Layout/EditSection/EditManufactureStyleSection/EditManufactureStyleSection';
import EditReassuranceStyleSection from '../../Layout/EditSection/EditReassuranceStyleSection/EditReassuranceStyleSection';
import EditCmsOfferStyleSection from '../../Layout/EditSection/EditCmsOfferStyleSection/EditCmsOfferStyleSection';
import EditSliderStyleSection from '../../Layout/EditSection/EditSliderStyleSection/EditSliderStyleSection';
import EditBannerStyleSection from '../../Layout/EditSection/EditBannerStyleSection/EditBannerStyleSection';
import EditProductTitle from '../../Layout/EditSection/ProductPage/EditProductTitle';
import EditProductDescription from '../../Layout/EditSection/ProductPage/EditProductDescription';
import EditProductRating from '../../Layout/EditSection/ProductPage/EditProductRating';
import EditProductPrice from '../../Layout/EditSection/ProductPage/EditProductPrice';
import EditProductBrand from '../../Layout/EditSection/ProductPage/EditProductBrand';
import EditProductCounter from '../../Layout/EditSection/ProductPage/EditProductCounter';
import EditProductBuyButton from '../../Layout/EditSection/ProductPage/EditProductBuyButton';
import EditProductType from '../../Layout/EditSection/ProductPage/EditProductType';
import EditProductStock from '../../Layout/EditSection/ProductPage/EditProductStock';
import EditProductSKU from '../../Layout/EditSection/ProductPage/EditProductSKU';
import EditProductDescriptionTab from '../../Layout/EditSection/ProductPage/EditProductDescriptionTab';
import EditProductReviewTab from '../../Layout/EditSection/ProductPage/EditProductReviewTab';
import EditProductCustomTab from '../../Layout/EditSection/ProductPage/EditProductCustomTab';
import EditProductCustomFaq from '../../Layout/EditSection/ProductPage/EditProductCustomFaq';
import EditRelatedProducts from '../../Layout/EditSection/ProductPage/EditRelatedProducts';
import EditCrossSellProducts from '../../Layout/EditSection/ProductPage/EditCrossSellProducts';
import EditUpSellProducts from '../../Layout/EditSection/ProductPage/EditUpSellProducts';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import EditProductPageSection from '../../Layout/EditSection/ProductPage/EditProductPageSection';
import EditProductPageReviewSection from '../../Layout/EditSection/ProductPage/EditProductPageReviewSection';
import EditProductNameStyleSection from '../../Layout/EditSection/EditProductNameStyleSection/EditProductNameStyleSection';
import { Extention } from '../../../App';
import EditProductRatingStyleContainer from '../../Layout/EditSection/EditProductRatingStyleSection/EditProductRatingStyleSection';
import EditProductPriceStyleContainer from '../../Layout/EditSection/EditProductPriceStyleSection/EditProductPriceStyleSection';
import EditProductDescriptionStyleContainer from '../../Layout/EditSection/EditProductDescriptionStyleSection/EditProductDescriptionStyleSection';
import EditProductDisconuntStyleSection from '../../Layout/EditSection/EditProductDiscountStyleSection/EditProductDiscountStyleSection';
import EditProductBrandStyleSection from '../../Layout/EditSection/EditProductBrandStyleSection/EditProductBrandStyleSection';
import EditProductStockSection from '../../Layout/EditSection/EditProductStockStyleSection/EditProductStockStyleSection';
import EditProductQuantityStyleSection from '../../Layout/EditSection/EditProductQuantityStyleSection/EditProductQuantityStyleSection';
import EditProductBuyButtonStyleContainer from '../../Layout/EditSection/EditProductBuyButtonStyleSection/EditProductBuyButtonStyleSection';
import EditProductSkuStyleContainer from '../../Layout/EditSection/EditProductSkuStyleSection/EditProductSkuStyleSection';

const ProductPageEditor = ({ commonSetting }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [groupHeaderData, setGroupHeaderData] = useState();
  const isChangeHeader = (value) => {
    setGroupHeaderData(value);
  };
  const [groupFooterData, setGroupFooterData] = useState();
  const isChangeFooter = (value) => {
    setGroupFooterData(value);
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [getLayout, setGetLayout] = useState();
  const [openTab, setOpenTab] = useState('setting');
  const [editSection, setEditSection] = useState({ section_type: '', section_id: '' });

  const selectSection = (e, id, type) => {
    e.preventDefault();
    setEditSection({
      section_id: id,
      section_type: type,
    });
  };

  const { installedExtensions } = useContext(Extention);

  const ultimatorKitExtension = installedExtensions.find(
    (extension) => extension.extension_name === 'Ultimator Kit',
  );

  const [pending, setPending] = useState(true);
  const [isAddSection, setIsAddSection] = useState(false);
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);

  const closeAddSection = () => {
    setIsAddSection(false);
  };
  const params = useParams();
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data);
      setGetLayout(result?.data?.sections);
      closeAddSection();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    handleGetLayout();
  }, [params?.layoutName]);
  const [updateSectionSpinner, setUpdateSectionSpinner] = useState(false);

  // const handleSortProduct = (result) => {
  //   const newSections = [...getLayout];
  //   const [removed] = newSections.splice(result?.source?.index, 1);
  //   newSections.splice(result?.destination?.index, 0, removed);
  //   newSections.forEach((section, index) => {
  //     section.sort_order = index;
  //   });
  //   setGetLayout(newSections);
  //   sortSection(result?.destination?.index, result?.draggableId);
  // };

  const handleSortProduct = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...getLayout];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      newItems?.forEach((section, index) => {
        section.sort_order = index;
      });
      setGetLayout(newItems);
      sortSection(toIndex, filterId);
      setFilterId(null);
      setHoverId(null);
    },
    nodeSelector: '.draggable-item',
    handleSelector: '.draggable-item',
  };

  const UpdateSectionStatus = async (id, value) => {
    setUpdateSectionSpinner(true);
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setUpdateSectionSpinner(false);
    }
  };
  const handleSort = (result) => {
    const newSections = [...getLayout];
    const [removed] = newSections.splice(result?.source?.index, 1);
    newSections.splice(result?.destination?.index, 0, removed);
    newSections.forEach((section, index) => {
      section.sort_order = index;
    });
    setGetLayout(newSections);
    sortSection(result?.destination?.index, result?.draggableId);
  };
  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/layout/updateSection/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [announcement, setAnnouncement] = useState();
  const handleChangeAnnouncement = (value) => {
    setAnnouncement(value);
  };
  const [visualData, setVisualData] = useState({
    product: null,
    blog: null,
    category: null,
    manufacture: null,
    reassurance: null,
    cmsoffer: null,
    slider: null,
    banner: null,
  });
  const getSectionData = (value) => {
    if (value === 'call layout') {
      handleGetLayout();
      setVisualData({
        product: null,
        blog: null,
        category: null,
        manufacture: null,
        reassurance: null,
        cmsoffer: null,
        slider: null,
        banner: null,
      });
      setEditSection({ section_id: '', section_type: '' });
      setOpen(false);
    } else if (value === 'call common setting') {
      getSettingData();
    } else if (value === 'get client') {
      GetClient();
    } else {
      if (editSection?.section_type === 'product') {
        setVisualData({
          ...visualData,
          product: value,
        });
      } else if (editSection?.section_type === 'blog') {
        setVisualData({
          ...visualData,
          blog: value,
        });
      } else if (editSection?.section_type === 'category') {
        setVisualData({
          ...visualData,
          category: value,
        });
      } else if (editSection?.section_type === 'manufacture') {
        setVisualData({
          ...visualData,
          manufacture: value,
        });
      } else if (editSection?.section_type === 'reassurance') {
        setVisualData({
          ...visualData,
          reassurance: value,
        });
      } else if (editSection?.section_type === 'cmsoffer') {
        setVisualData({
          ...visualData,
          cmsoffer: value,
        });
      } else if (editSection?.section_type === 'slider') {
        setVisualData({
          ...visualData,
          slider: value,
        });
      } else if (editSection?.section_type === 'banner') {
        setVisualData({
          ...visualData,
          banner: value,
        });
      }
    }
  };
  const Puller = styled('div')(() => ({
    width: 30,
    height: 6,
    backgroundColor: 'gray',
    borderRadius: 3,
    position: 'absolute',
    top: 10,
    left: 'calc(50% - 15px)',
  }));
  const drawerBleeding = 56;
  const [editProductSection, setEditProductSection] = useState('');
  const [editProductOtherSection, setEditProductOtherSection] = useState('');
  const [productSectionOrderObject, setProductSectionOrderObject] = useState();
  const changeProductPageSectionSortOrder = (value) => {
    setProductSectionOrderObject(value);
  };
  const [productOtherSectionOrderObject, setProductOtherSectionOrderObject] = useState();
  const changeProductPageOtherSectionSortOrder = (value) => {
    setProductOtherSectionOrderObject(value);
  };
  const selectProductSection = (type) => {
    setEditProductSection(type);
  };
  const selectProductOtherSection = (section_name) => {
    setEditProductOtherSection(section_name);
    setEditSection({
      section_id: section_name,
      section_type: section_name,
    });
  };

  const [productSelect, setProductSelect] = useState(false);
  const [productSelectReview, setProductSelectReview] = useState(false);
  const [sectionValue, setSectionValue] = useState();

  const handleClick = () => {
    setEditProductOtherSection('main product');
    if (!sectionValue) {
      setSectionValue('Product name');
    }
    // setEditSection({
    //   section_id: '',
    //   section_type: '',
    // });
  };

  // useEffect(()=> {
  //   if (sectionValue !== 'Product name' || !sectionValue) {
  //     setSectionValue('Product name');
  //   }
  // },[sectionValue]);

  const handleClickReview = () => {
    setEditProductOtherSection('product review');
    if (sectionValue !== 'Description Tab') {
      setSectionValue('Description Tab');
    }
  };

  const [sectionArray, setSectionArray] = useState([
    {
      section_name: 'Product name',
      icon: `https://cdn.shopeasy.ai/1/category/type-1.svg`,
      sort_order: 1,
    },
    {
      section_name: 'Rating',
      icon: `https://cdn.shopeasy.ai/1/category/star-1.svg`,
      sort_order: 2,
    },
    {
      section_name: 'Description',
      icon: `https://cdn.shopeasy.ai/1/category/align-left-1.svg`,
      sort_order: 3,
    },
    {
      section_name: 'Price',
      icon: `https://cdn.shopeasy.ai/1/category/tag.svg`,
      sort_order: 4,
    },
    {
      section_name: 'Discount OR Variants',
      icon: `https://cdn.shopeasy.ai/1/category/tag.svg`,
      sort_order: 5,
    },
    {
      section_name: 'Category & Brand',
      icon: `https://cdn.shopeasy.ai/1/category/box.svg`,
      sort_order: 6,
    },
    {
      section_name: 'Stock',
      icon: `https://cdn.shopeasy.ai/1/category/info.svg`,
      sort_order: 7,
    },
    {
      section_name: 'Quantity selector',
      icon: `https://cdn.shopeasy.ai/1/category/hash.svg`,
      sort_order: 8,
    },
    {
      section_name: 'Buy buttons',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 9,
    },
    {
      section_name: 'SKU & Return Policy',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 10,
    },
    {
      section_name: 'Description Tab',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
    },
    {
      section_name: 'Review Tab',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
    },
    {
      section_name: 'Custom Tab',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
    },
  ]);
  const [otherProductSection, setOtherProductSection] = useState([
    {
      section_name: 'Custom Faq',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 1,
    },
    {
      section_name: 'Related Products',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 2,
    },
    {
      section_name: 'Cross Sell Products',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 3,
    },
    {
      section_name: 'Up Sell Products',
      icon: `https://cdn.shopeasy.ai/1/category/mouse-pointer.svg`,
      sort_order: 4,
    },
  ]);

  const handleUpdateSortOrder = () => {
    const updatedSectionOrderObject = {};
    sectionArray.forEach((section) => {
      updatedSectionOrderObject[
        section.section_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace('&', '')
          .replace(/-+/g, '_')
          .replace('__', '_')
      ] = section.sort_order;
    });
    changeProductPageSectionSortOrder(updatedSectionOrderObject);
  };

  const handleUpdateOtherSectionSortOrder = () => {
    const updatedSectionOrderObject = {};
    otherProductSection.forEach((section) => {
      updatedSectionOrderObject[
        section.section_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace('&', '')
          .replace(/-+/g, '_')
          .replace('__', '_')
      ] = section.sort_order;
    });
    changeProductPageOtherSectionSortOrder(updatedSectionOrderObject);
  };

  const handleSectionUpdate = async () => {
    const payload = {
      sectionArray,
      otherProductSection,
    };

    try {
      const body = encryptData({
        clientInfo: {
          product_section_information: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('section Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSortSection = async (result) => {
  //   const newSections = [...sectionArray];
  //   const [removed] = newSections.splice(result.source.index, 1);
  //   newSections.splice(result.destination.index, 0, removed);
  //   newSections.forEach((section, index) => {
  //     section.sort_order = index + 1;
  //   });
  //   setSectionArray(newSections);
  //   handleUpdateSortOrder(newSections);
  //   handleSectionUpdate();
  // };

  const handleSortSection = {
    async onDragEnd(fromIndex, toIndex) {
      const newItems = [...sectionArray];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      newItems.forEach((section, index) => {
        section.sort_order = index + 1;
      });
      setSectionArray(newItems);
      handleUpdateSortOrder(newItems);
      await handleSectionUpdate();
    },
    nodeSelector: 'div',
    handleSelector: 'div',
  };

  const handleSortOtherSection = {
    async onDragEnd(fromIndex, toIndex) {
      const newItems = [...otherProductSection];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      newItems.forEach((section, index) => {
        section.sort_order = index + 1;
      });
      setOtherProductSection(newItems);
      handleUpdateOtherSectionSortOrder(newItems);
      await handleSectionUpdate();
    },
    nodeSelector: 'div',
    handleSelector: 'div',
  };

  const [productPageData, setProductPageData] = useState({
    showProductName: true,
    productTitleSize: 'medium',
    productTitleStyle: 'normal',
    showProductRating: true,
    productRatingText: 'Write a review',
    showProductDescription: true,
    showProductPrice: true,
    showProductDiscountPrice: true,
    showProductCategory: true,
    categoryTitleText: 'Category',
    showProductBrand: true,
    brandTitleText: 'Brand',
    showProductStock: true,
    stockTitleText: 'Availability',
    showProductCounter: true,
    showBuyButton: true,
    showCheckoutButton: true,
    showProductSKU: true,
    showReturnPolicy: true,
    showReplacementDays: true,
    showProductType: true,
    showProductDescriptionTab: true,
    showProductReviewTab: true,
    showProductCustomTab: true,
    showProductCustomFaq: true,
    showRelatedProducts: true,
    showCrossSellProducts: true,
    showCrossSellProductsTotalPrice: true,
    showCrossSellProductsAllToCart: true,
    showUpSellProducts: true,
    relateProductTitle: 'Related Products',
    crossSellProductTitle: 'Cross Sell Products',
    upSellProductTitle: 'Up Sell Products',
    showProductSection: true,
    showProductReviewSection: true,
  });
  const handleEditProductPageData = (value) => {
    setProductPageData(value);
  };

  const [productSectionDesign, setProductSectionDesign] = useState({
    display: 'flex',
    direction: 'row',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '100',
    width_unit: '%',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: 'auto',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [reviewSectionDesign, setReviewSectionDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [faqsSectionDesign, setFaqsSectionDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [relatedSectionDesign, setRelatedSectionDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [crossProductDesign, setCrossProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [upSellProductDesign, setUpSellProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDesign, setProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    font_weight_mrp: '',
    font_size_mrp: '',
    font_height_mrp: '',
    font_color_mrp: '',
    font_style_mrp: '',
    font_decoration_mrp: '',
    font_capitalize_mrp: '',
  });

  const [blogDesign, setBlogDesign] = useState({
    // display: 'flex',
    // direction: 'column',
    // align_item: '',
    // gap: 0,
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [categoryDesign, setCategoryDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [manufactureDesign, setManufactureDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [reassuranceDesign, setReassuranceDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_align_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    font_break_description: '',
    font_wrap_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cmsOfferDesign, setCmsOfferDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [sliderDesign, setSliderDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [bannerDesign, setBannerDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productNameDesign, setProductNameDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    height: '',
    width: '',
    width_unit: '%',
    height_unit: '%',
    min_width_unit: '%',
    min_height_unit: '%',
    max_width_unit: '%',
    max_height_unit: '%',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: 'auto',
    position: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: 'left',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    // font_break: '',
    // font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productRatingDesign, setProductRatingDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productPriceDesign, setProductPriceDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_weight_mrp: '',
    font_size_mrp: '',
    font_height_mrp: '',
    font_color_mrp: '',
    font_align_mrp: '',
    font_style_mrp: '',
    font_decoration_mrp: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDescriptionDesign, setProductDescriptionDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDiscountDesign, setProductDiscountDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productBrandDesign, setProductBrandDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productStockDesign, setProductStockDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productQuantityDesign, setProductQuantityDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });
  const [productButButtonDesign, setProductBuyButtonDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productSkuDesign, setProductSkuDesign] = useState({
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const handleRatingClick = (id) => {
    setEditSection({
      section_id: id,
      section_type: id,
    });
  };

  useEffect(() => {
    try {
      if (clientData && clientData.product_layout_css) {
        const clientDetails = JSON.parse(clientData.product_layout_css);
        const clientDetailsSetting = JSON.parse(clientData.product_page_setting_layout);

        const applyClientDetails = () => {
          setManufactureDesign(clientDetails?.manufactureDesign);
          setCategoryDesign(clientDetails?.categoryDesign);
          setProductDesign(clientDetails?.productDesign);
          setReassuranceDesign(clientDetails?.reassuranceDesign);
          setBannerDesign(clientDetails?.bannerDesign);
          setSliderDesign(clientDetails?.sliderDesign);
          setCmsOfferDesign(clientDetails?.cmsOfferDesign);
          setBlogDesign(clientDetails?.blogDesign);
          setProductSectionDesign(clientDetails?.productSectionDesign);
          setReviewSectionDesign(clientDetails?.reviewSectionDesign);
          setFaqsSectionDesign(clientDetails?.faqsSectionDesign);
          setRelatedSectionDesign(clientDetails?.relatedSectionDesign);
          setUpSellProductDesign(clientDetails?.upSellProductDesign);
          setCrossProductDesign(clientDetails?.crossProductDesign);
          setProductPageData(clientDetailsSetting);
        };

        applyClientDetails();
      } else {
        console.warn('clientData or home_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  useEffect(() => {
    try {
      if (clientData && clientData.product_layout_css) {
        const clientDetails = JSON.parse(clientData.product_layout_css);
        const ClientDetails = () => {
          setProductNameDesign(clientDetails?.productNameDesign);
          setProductRatingDesign(clientDetails?.productRatingDesign);
          setProductPriceDesign(clientDetails?.productPriceDesign);
          setProductDescriptionDesign(clientDetails?.productDescriptionDesign);
          setProductDiscountDesign(clientDetails?.productDiscountDesign);
          setProductBrandDesign(clientDetails?.productBrandDesign);
          setProductStockDesign(clientDetails?.productStockDesign);
          setProductQuantityDesign(clientDetails?.productQuantityDesign);
          setProductBuyButtonDesign(clientDetails?.productButButtonDesign);
          setProductSkuDesign(clientDetails?.productSkuDesign);
        };

        ClientDetails();
      } else {
        console.warn('clientData or home_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  const handleUpdateCss = async () => {
    const payload = {
      productDesign,
      blogDesign,
      categoryDesign,
      manufactureDesign,
      reassuranceDesign,
      cmsOfferDesign,
      sliderDesign,
      bannerDesign,
      productSectionDesign,
      reviewSectionDesign,
      faqsSectionDesign,
      relatedSectionDesign,
      upSellProductDesign,
      crossProductDesign,
      productNameDesign,
      productRatingDesign,
      productPriceDesign,
      productDescriptionDesign,
      productDiscountDesign,
      productBrandDesign,
      productStockDesign,
      productQuantityDesign,
      productButButtonDesign,
      productSkuDesign,
    };

    try {
      const body = encryptData({
        clientInfo: {
          product_layout_css: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Style Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSetting = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          product_page_setting_layout: JSON.stringify(productPageData),
        },
      });
      await API.put('/admin/client/updateClient', payload);
      setIsNotification(true);
      setNotificationMsg('Setting Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='overflow-hidden relative h-[92.5%]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='relative live-editor w-full flex items-start h-full'>
          {windowWidth > 768 && windowWidth < 1280 && editSection?.section_type ? (
            <div className='hidden md:block xl:hidden md:w-[30%] xl:w-[16%] overflow-y-auto p-3 secondary-bg-color h-full'>
              {editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between'>
                    <p className='font-medium text-primary text-base capitalize'>
                      Update {editSection?.section_type || sectionValue} Section
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-4 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'Product name' ? (
                    <EditProductTitle
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Rating' ? (
                    <EditProductRating
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Description' ? (
                    <EditProductDescription
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Price' ? (
                    <EditProductPrice
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Category & Brand' ? (
                    <EditProductBrand
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Stock' ? (
                    <EditProductStock
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Quantity selector' ? (
                    <EditProductCounter
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Buy buttons' ? (
                    <EditProductBuyButton
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'SKU & Return Policy' ? (
                    <EditProductSKU
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Discount OR Variants' ? (
                    <EditProductType
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Description Tab' ? (
                    <EditProductDescriptionTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Review Tab' ? (
                    <EditProductReviewTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Tab' ? (
                    <EditProductCustomTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Faq' ? (
                    <EditProductCustomFaq
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Related Products' ? (
                    <EditRelatedProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Cross Sell Products' ? (
                    <EditCrossSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Up Sell Products' ? (
                    <EditUpSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : (
                    <></>
                    // <ProductPageEditSection
                    //   editProductSection={editSection?.section_type}
                    //   handleEditProductPageData={handleEditProductPageData}
                    //   productPageData={productPageData}
                    //   selectProductSection={selectProductSection}
                    //   selectProductOtherSection={selectProductOtherSection}
                    //   editProductOtherSection={editProductOtherSection}
                    //   sectionValue={sectionValue}
                    // />
                  )}
                </>
              ) : (
                <></>
              )}{' '}
              :
            </div>
          ) : (
            <div className='hidden md:block md:w-[30%] xl:w-[16%] overflow-y-auto py-3 secondary-bg-color h-full'>
              {pending && !getLayout ? (
                <div className='w-full px-3 h-[800px]'>
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <hr className='my-4 main-border-color' />
                  <div className='grid grid-cols-1 space-y-2'>
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                  </div>
                  <hr className='my-4 main-border-color' />
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              ) : (
                <div className='theme-setting'>
                  <p className='font-medium text-primary text-base px-3'>Product page</p>
                  <hr className='my-3 border-t main-border-color'></hr>
                  <span className='font-medium text-primary text-xs px-3'>Header</span>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>Announcement bar</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
                  </details>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>Header</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <HeaderSection isChangeHeader={isChangeHeader} />
                  </details>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <div className='w-full'>
                    <>
                      <ProductPageSectionList
                        editProductSection={editSection}
                        selectProductSection={selectSection}
                        changeProductPageSectionSortOrder={changeProductPageSectionSortOrder}
                        sectionArray={sectionArray}
                        setSectionArray={setSectionArray}
                        handleSortSection={handleSortSection}
                        handleSortOtherSection={handleSortOtherSection}
                        otherProductSection={otherProductSection}
                        setOtherProductSection={setOtherProductSection}
                        changeProductPageOtherSectionSortOrder={
                          changeProductPageOtherSectionSortOrder
                        }
                        selectProductOtherSection={selectProductOtherSection}
                        editProductOtherSection={editProductOtherSection}
                        getLayout={getLayout}
                        UpdateSectionStatus={UpdateSectionStatus}
                        sectionValue={sectionValue}
                        handleRatingClick={handleRatingClick}
                        handleGetLayout={handleGetLayout}
                        handleSort={handleSort}
                        handleSortProduct={handleSortProduct}
                        hoverId={hoverId}
                        setHoverId={setHoverId}
                        setFilterId={setFilterId}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAddSection(true);
                        }}
                        className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
                      >
                        <PlusCircle className='w-4 h-4 me-1' /> Add section
                      </button>
                    </>
                  </div>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>Footer</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <FooterSection isChangeFooter={isChangeFooter} />
                  </details>
                </div>
              )}
            </div>
          )}
          <div className={`p-0 md:ps-2 lg:px-4 bg-transparent h-full w-full md:w-[70%] xl:w-[68%]`}>
            {pending && !getLayout ? (
              <div className='w-full'>
                <div>
                  <Skeleton
                    className='min-h-[90px] max-h-[90px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[500px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
                <div className='grid mt-6 mx-auto grid-cols-1'>
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[400px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                className='h-full overflow-auto'
              >
                <VisualAnnounccementBar colors={commonSetting} announcement={announcement} />
                <StoreNavbar
                  clientInfo={clientData}
                  colors={commonSetting}
                  groupHeaderData={groupHeaderData}
                />
                <OldProductPageEditor
                  colors={commonSetting}
                  visualData={visualData}
                  editProductSection={editSection}
                  setEditSection={setEditSection}
                  getLayout={getLayout}
                  productPageData={productPageData}
                  productSectionOrderObject={productSectionOrderObject}
                  changeProductPageSectionSortOrder={changeProductPageSectionSortOrder}
                  sectionArray={sectionArray}
                  setSectionArray={setSectionArray}
                  selectProductSection={selectProductSection}
                  otherProductSection={otherProductSection}
                  setOtherProductSection={setOtherProductSection}
                  handleSectionUpdate={handleSectionUpdate}
                  productOtherSectionOrderObject={productOtherSectionOrderObject}
                  changeProductPageOtherSectionSortOrder={changeProductPageOtherSectionSortOrder}
                  selectProductOtherSection={selectProductOtherSection}
                  handleClick={handleClick}
                  productSelect={productSelect}
                  handleClickReview={handleClickReview}
                  productSelectReview={productSelectReview}
                  editProductOtherSection={editProductOtherSection}
                  setProductSelectReview={setProductSelectReview}
                  setProductSelect={setProductSelect}
                  productSectionDesign={productSectionDesign}
                  setProductSectionDesign={setProductSectionDesign}
                  reviewSectionDesign={reviewSectionDesign}
                  setReviewSectionDesign={setReviewSectionDesign}
                  faqsSectionDesign={faqsSectionDesign}
                  setFaqsSectionDesign={setFaqsSectionDesign}
                  relatedSectionDesign={relatedSectionDesign}
                  setRelatedSectionDesign={setRelatedSectionDesign}
                  crossProductDesign={crossProductDesign}
                  setCrossProductDesign={setCrossProductDesign}
                  upSellProductDesign={upSellProductDesign}
                  setUpSellProductDesign={setUpSellProductDesign}
                  setEditProductOtherSection={setEditProductOtherSection}
                  productDesign={productDesign}
                  blogDesign={blogDesign}
                  categoryDesign={categoryDesign}
                  manufactureDesign={manufactureDesign}
                  reassuranceDesign={reassuranceDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  sliderDesign={sliderDesign}
                  bannerDesign={bannerDesign}
                  handleRatingClick={handleRatingClick}
                  sectionValue={sectionValue}
                  UpdateSectionStatus={UpdateSectionStatus}
                  handleSortProduct={handleSortProduct}
                  productNameDesign={productNameDesign}
                  productRatingDesign={productRatingDesign}
                  productPriceDesign={productPriceDesign}
                  productDescriptionDesign={productDescriptionDesign}
                  productDiscountDesign={productDiscountDesign}
                  productBrandDesign={productBrandDesign}
                  productStockDesign={productStockDesign}
                  productQuantityDesign={productQuantityDesign}
                  productButButtonDesign={productButButtonDesign}
                  productSkuDesign={productSkuDesign}
                  hoverId={hoverId}
                  setHoverId={setHoverId}
                  setFilterId={setFilterId}
                />
                <Footer colors={commonSetting} groupFooterData={groupFooterData} />
              </div>
            )}
          </div>
          {windowWidth > 1280 && (
            <div className='hidden xl:block xl:w-[16%] h-full p-5 secondary-bg-color overflow-y-auto edit-style-section'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <li className='text-center me-4 lg:mb-0'>
                    <a
                      className={
                        'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                        (openTab === 'style'
                          ? 'text-link border-b-2 border-b-[#28282B]'
                          : ' border-b-2 table-text border-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab('style');
                      }}
                      data-toggle='tab'
                      href='#link1'
                      role='tablist'
                    >
                      {t('lang_style')}
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-medium text-primary text-base capitalize'>
                      Update {editSection?.section_type || sectionValue} Section
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-4 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'Product name' ? (
                    <EditProductTitle
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Rating' ? (
                    <EditProductRating
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'Description' ? (
                    <EditProductDescription
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Price' ? (
                    <EditProductPrice
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Category & Brand' ? (
                    <EditProductBrand
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'Stock' ? (
                    <EditProductStock
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'Quantity selector' ? (
                    <EditProductCounter
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Buy buttons' ? (
                    <EditProductBuyButton
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'SKU & Return Policy' ? (
                    <EditProductSKU
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Discount OR Variants' ? (
                    <EditProductType
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Description Tab' ? (
                    <EditProductDescriptionTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Review Tab' ? (
                    <EditProductReviewTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Tab' ? (
                    <EditProductCustomTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Faq' ? (
                    <EditProductCustomFaq
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Related Products' ? (
                    <EditRelatedProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'Cross Sell Products' ? (
                    <EditCrossSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'Up Sell Products' ? (
                    <EditUpSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'main product' ? (
                    <EditProductPageSection
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'product review' ? (
                    <EditProductPageReviewSection
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : (
                    <></>
                    // <ProductPageEditSection
                    //   editProductSection={editSection?.section_type}
                    //   handleEditProductPageData={handleEditProductPageData}
                    //   productPageData={productPageData}
                    //   selectProductSection={selectProductSection}
                    //   selectProductOtherSection={selectProductOtherSection}
                    //   editProductOtherSection={editProductOtherSection}
                    //   sectionValue={sectionValue}
                    // />
                  )}
                </>
              ) : openTab === 'style' ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-medium text-primary text-base capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'main product' ? (
                    <div>
                      <EditProductPageStyleSection
                        productSectionDesign={productSectionDesign}
                        setProductSectionDesign={setProductSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'product review' ? (
                    <div>
                      <EditReviewPageStyleSection
                        reviewSectionDesign={reviewSectionDesign}
                        setReviewSectionDesign={setReviewSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Custom Faq' ? (
                    <div>
                      <EditFaqsPageStyleSection
                        faqsSectionDesign={faqsSectionDesign}
                        setFaqsSectionDesign={setFaqsSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Related Products' ? (
                    <div>
                      <EditRelatedPageStyleSection
                        relatedSectionDesign={relatedSectionDesign}
                        setRelatedSectionDesign={setRelatedSectionDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Cross Sell Products' ? (
                    <div>
                      <EditCrossProductPageStyleSection
                        crossProductDesign={crossProductDesign}
                        setCrossProductDesign={setCrossProductDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Up Sell Products' ? (
                    <div>
                      <EditUpSellProductPageStyleSection
                        upSellProductDesign={upSellProductDesign}
                        setUpSellProductDesign={setUpSellProductDesign}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'product' ? (
                    <div>
                      <EditProductStyleSection
                        productDesign={productDesign}
                        setProductDesign={setProductDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'blog' ? (
                    <div>
                      <EditBlogStyleSection
                        blogDesign={blogDesign}
                        setBlogDesign={setBlogDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'category' ? (
                    <div>
                      <EditCategoryStyleSection
                        categoryDesign={categoryDesign}
                        setCategoryDesign={setCategoryDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'manufacture' ? (
                    <div>
                      <EditManufactureStyleSection
                        manufactureDesign={manufactureDesign}
                        setManufactureDesign={setManufactureDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'reassurance' ? (
                    <div>
                      <EditReassuranceStyleSection
                        reassuranceDesign={reassuranceDesign}
                        setReassuranceDesign={setReassuranceDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <div>
                      <EditCmsOfferStyleSection
                        cmsOfferDesign={cmsOfferDesign}
                        setCmsOfferDesign={setCmsOfferDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'slider' ? (
                    <div>
                      <EditSliderStyleSection
                        sliderDesign={sliderDesign}
                        setSliderDesign={setSliderDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'banner' ? (
                    <div>
                      <EditBannerStyleSection
                        bannerDesign={bannerDesign}
                        setBannerDesign={setBannerDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Product name' ? (
                    <div>
                      <EditProductNameStyleSection
                        productNameDesign={productNameDesign}
                        setProductNameDesign={setProductNameDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Rating' ? (
                    <div>
                      <EditProductRatingStyleContainer
                        productRatingDesign={productRatingDesign}
                        setProductRatingDesign={setProductRatingDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Price' ? (
                    <div>
                      <EditProductPriceStyleContainer
                        productPriceDesign={productPriceDesign}
                        setProductPriceDesign={setProductPriceDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Description' ? (
                    <div>
                      <EditProductDescriptionStyleContainer
                        productDescriptionDesign={productDescriptionDesign}
                        setProductDescriptionDesign={setProductDescriptionDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Discount OR Variants' ? (
                    <div>
                      <EditProductDisconuntStyleSection
                        productDiscountDesign={productDiscountDesign}
                        setProductDiscountDesign={setProductDiscountDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Category & Brand' ? (
                    <div>
                      <EditProductBrandStyleSection
                        productBrandDesign={productBrandDesign}
                        setProductBrandDesign={setProductBrandDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Stock' ? (
                    <div>
                      <EditProductStockSection
                        productStockDesign={productStockDesign}
                        setProductStockDesign={setProductStockDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Quantity selector' ? (
                    <div>
                      <EditProductQuantityStyleSection
                        productQuantityDesign={productQuantityDesign}
                        setProductQuantityDesign={setProductQuantityDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'Buy buttons' ? (
                    <div>
                      <EditProductBuyButtonStyleContainer
                        productButButtonDesign={productButButtonDesign}
                        setProductBuyButtonDesign={setProductBuyButtonDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : editSection?.section_type === 'SKU & Return Policy' ? (
                    <div>
                      <EditProductSkuStyleContainer
                        productSkuDesign={productSkuDesign}
                        setProductSkuDesign={setProductSkuDesign}
                        section_id={editSection?.section_id}
                        getSectionData={getSectionData}
                        handleUpdateCss={handleUpdateCss}
                      />
                    </div>
                  ) : (
                    <ProductPageEditSection
                      editProductSection={editSection?.section_type}
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      selectProductSection={selectProductSection}
                      selectProductOtherSection={selectProductOtherSection}
                      editProductOtherSection={editProductOtherSection}
                    />
                  )}
                </>
              ) : (
                <>
                  <p className='text-sm table-text'>
                    Select a section or block in the sidebar to start.
                  </p>
                </>
              )}
            </div>
          )}
        </div>
        {!open && (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
              zIndex: '9999',
            }}
            className='shadow-inner block md:hidden'
          >
            <div className='bg-[#222222] text-white text-xs text-center rounded-t-xl p-2'>
              Select section by tapping on them in preview
            </div>
            <div className='relative h-[65px]  secondary-bg-color flex items-center'>
              <div onMouseDown={() => setOpen(true)} onTouchMove={() => setOpen(true)}>
                <Puller />
              </div>
              {editSection?.section_type ? (
                <div className='flex relative items-center w-full'>
                  <button
                    onClick={(e) => selectSection(e, '', '')}
                    className='flex absolute left-[20px] top-[20px] -translate-y-[20px] text-sm items-center'
                  >
                    <ChevronLeft className='w-4 h-4' />
                    Sections
                  </button>
                  <p
                    className='text-center w-full'
                    onMouseDown={() => setOpen(true)}
                    onTouchMove={() => setOpen(true)}
                  >
                    Edit {editSection?.section_type} Section
                  </p>
                </div>
              ) : (
                <div
                  className='flex relative items-center w-full'
                  onMouseDown={() => setOpen(true)}
                  onTouchMove={() => setOpen(true)}
                >
                  <p className='text-center block w-full'>Show all Sections</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={isAddSection}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsAddSection(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <AddLayout closeAddSection={closeAddSection} getAllLayout={handleGetLayout} />
      </Dialog>
      {windowWidth < 768 && (
        <SwipeableDrawer
          anchor='bottom'
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          className='z-[999] live-editor-responsive p-5 mt-5 block md:hidden'
        >
          <div
            style={{
              padding: '20px',
              height: '100%',
              overflow: 'auto',
              marginTop: '20px',
              borderRadius: '20px 20px 0 0',
            }}
          >
            <Puller />
            <>
              {editSection?.section_type?.length > 0 ? (
                <>
                  <div className='flex items-center justify-between'>
                    <p className='font-medium text-primary text-base capitalize'>
                      Update {editSection?.section_type || sectionValue} Section
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-4 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                    />
                  ) : editSection?.section_type === 'Product name' ? (
                    <EditProductTitle
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Rating' ? (
                    <EditProductRating
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Description' ? (
                    <EditProductDescription
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Price' ? (
                    <EditProductPrice
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Category & Brand' ? (
                    <EditProductBrand
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Stock' ? (
                    <EditProductStock
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Quantity selector' ? (
                    <EditProductCounter
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Buy buttons' ? (
                    <EditProductBuyButton
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'SKU & Return Policy' ? (
                    <EditProductSKU
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Discount OR Variants' ? (
                    <EditProductType
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Description Tab' ? (
                    <EditProductDescriptionTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Review Tab' ? (
                    <EditProductReviewTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Tab' ? (
                    <EditProductCustomTab
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Custom Faq' ? (
                    <EditProductCustomFaq
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Related Products' ? (
                    <EditRelatedProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Cross Sell Products' ? (
                    <EditCrossSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'Up Sell Products' ? (
                    <EditUpSellProducts
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'main product' ? (
                    <EditProductPageSection
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : editSection?.section_type === 'product review' ? (
                    <EditProductPageReviewSection
                      handleEditProductPageData={handleEditProductPageData}
                      productPageData={productPageData}
                      handleUpdateSetting={handleUpdateSetting}
                    />
                  ) : (
                    <></>
                    // <ProductPageEditSection
                    //   editProductSection={editSection?.section_type}
                    //   handleEditProductPageData={handleEditProductPageData}
                    //   productPageData={productPageData}
                    //   selectProductSection={selectProductSection}
                    //   selectProductOtherSection={selectProductOtherSection}
                    //   editProductOtherSection={editProductOtherSection}
                    //   sectionValue={sectionValue}
                    // />
                  )}
                </>
              ) : (
                <div className='w-full overflow-y-auto py-3 secondary-bg-color h-full'>
                  {pending && !getLayout ? (
                    <div className='w-full px-3 h-[800px]'>
                      <Skeleton
                        className='min-h-[30px] max-h-[30px]'
                        variant='rectangular'
                        animation='wave'
                      />
                      <hr className='my-4 main-border-color' />
                      <div className='grid grid-cols-1 space-y-2'>
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                        <Skeleton
                          className='min-h-[50px] max-h-[50px]'
                          variant='rectangular'
                          animation='wave'
                        />
                      </div>
                      <hr className='my-4 main-border-color' />
                      <Skeleton
                        className='min-h-[30px] max-h-[30px]'
                        variant='rectangular'
                        animation='wave'
                      />
                    </div>
                  ) : (
                    <>
                      <p className='font-medium text-primary text-base px-3'>Product page</p>
                      <hr className='my-3 border-t main-border-color'></hr>
                      <div className='w-full'>
                        <>
                          {/* <HomePageSectionList getLayout={getLayout} editSection={editSection} selectSection={selectSection} UpdateSectionStatus={UpdateSectionStatus} handleSort={handleSort} updateSectionSpinner={updateSectionSpinner} handleGetLayout={handleGetLayout} /> */}
                          <div className='text-center'>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setIsAddSection(true);
                              }}
                              className='inline-flex items-center mx-auto text-link hover:underline transition-all duration-150 text-sm'
                            >
                              <PlusCircle className='w-4 h-4 me-1' /> Add section
                            </button>
                          </div>
                        </>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ProductPageEditor;
