import { Dialog, Skeleton } from '@mui/material';
import { ChevronDown, ChevronLeft, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../../API';
import { Extention } from '../../../App';
import AddSectionElementor from '../../../controllers/AddSectionElementor';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import { clientData, GetClient } from '../../../controllers/GetClient';
import SelectedStructureSection from '../../../controllers/SelectedStructureSection';
import { Transition } from '../../../controllers/Transition';
import '../../../EditSectionStyle.css';
import AddLayout from '../../Layout/AddLayout';
import AnnouncementSection from '../../Layout/AnnouncementBar/AnnouncementSection';
import VisualAnnounccementBar from '../../Layout/AnnouncementBar/VisualAnnounccementBar';
import EditBannerSection from '../../Layout/EditSection/EditBannerSection';
import EditBannerStyleSection from '../../Layout/EditSection/EditBannerStyleSection/EditBannerStyleSection';
import EditBlogSection from '../../Layout/EditSection/EditBlogSection';
import EditBlogStyleSection from '../../Layout/EditSection/EditBlogStyleSection/EditBlogStyleSection';
import EditCategorySection from '../../Layout/EditSection/EditCategorySection';
import EditCategoryStyleSection from '../../Layout/EditSection/EditCategoryStyleSection/EditCategoryStyleSection';
import EditCmsOfferStyleSection from '../../Layout/EditSection/EditCmsOfferStyleSection/EditCmsOfferStyleSection';
import EditCmsSection from '../../Layout/EditSection/EditCmsSection';
import EditManufactureSection from '../../Layout/EditSection/EditManufactureSection';
import EditManufactureStyleSection from '../../Layout/EditSection/EditManufactureStyleSection/EditManufactureStyleSection';
import EditProductSection from '../../Layout/EditSection/EditProductSection';
import EditProductStyleSection from '../../Layout/EditSection/EditProductStyleSection/EditProductStyleSection';
import EditReassuranceSection from '../../Layout/EditSection/EditReassuranceSection';
import EditReassuranceStyleSection from '../../Layout/EditSection/EditReassuranceStyleSection/EditReassuranceStyleSection';
import EditSliderSection from '../../Layout/EditSection/EditSliderSection';
import EditSliderStyleSection from '../../Layout/EditSection/EditSliderStyleSection/EditSliderStyleSection';
import FooterSection from '../../Layout/FooterSection';
import HeaderSection from '../../Layout/HeaderSection';
import HomePageSection from '../../Layout/LiveEditor/HomePageSection';
import HomePageSectionList from '../../Layout/SectionList/HomePageSectionList';
import Footer from '../../Layout/VisualLayout/Footer';
import StoreNavbar from '../../Layout/VisualLayout/StoreNavbar';
import Notification from '../../Notification';
import AddElementsStyleSection from '../../Layout/EditSection/AddElements/AddElementsStyleSection';
import bgImageDefault from '../../../Assets/dummy-img.svg';
import placeholder from '../../../Assets/placeholder.png';
import defaultVideo from '../../../Assets/defaultVideo.mp4';
import defaultIcon from '../../../Assets/default-icon.svg';
import listItem1 from '../../../Assets/list-item-1.svg';
import listItem2 from '../../../Assets/list-item-2.svg';
import listItem3 from '../../../Assets/list-item-3.svg';
import facebookIcon from '../../../Assets/facebook-icon.svg';
import twitter from '../../../Assets/twitter.svg';
import youtube from '../../../Assets/youtube.svg';
import { styleSection } from '../../../Assets/StaticData/AddStyleSection';
import HeadingSettingSection from '../../Layout/EditSection/EditElementorSection/HeadingSettingSection';
import ImageSettingSection from '../../Layout/EditSection/EditElementorSection/ImageSettingSection';
import TextEditorSettingSection from '../../Layout/EditSection/EditElementorSection/TextEditorSettingSection';
import ButtonSettingSection from '../../Layout/EditSection/EditElementorSection/ButtonSettingSection';
import DividerSettingSection from '../../Layout/EditSection/EditElementorSection/DividerSettingSection';
import SpacerSettingSection from '../../Layout/EditSection/EditElementorSection/SpacerSettingSection';
import GoogleMapSettingSection from '../../Layout/EditSection/EditElementorSection/GoogleMapSettingSection';
import IconSettingSection from '../../Layout/EditSection/EditElementorSection/IconSettingSection';
import ImageBoxSettingSection from '../../Layout/EditSection/EditElementorSection/ImageBoxSettingSection';
import ImageIconSettingSection from '../../Layout/EditSection/EditElementorSection/ImageIconSettingSection';
import IconListSettingApply from '../../Layout/EditSection/EditElementorSection/IconListSettingApply';
import CounterSettingSection from '../../Layout/EditSection/EditElementorSection/CounterSettingSection';
import TestimonialSetting from '../../Layout/EditSection/EditElementorSection/TestimonialSetting';
import TabSettingSection from '../../Layout/EditSection/EditElementorSection/TabSettingSection';
import AccordionSettingSection from '../../Layout/EditSection/EditElementorSection/AccordionSettingSection';
import AlertSettingSection from '../../Layout/EditSection/EditElementorSection/AlertSettingSection';
import SocialIconSettingSection from '../../Layout/EditSection/EditElementorSection/SocialIconSettingSection';
import HtmlSettingSection from '../../Layout/EditSection/EditElementorSection/HtmlSettingSection';
import RatingSettingSection from '../../Layout/EditSection/EditElementorSection/RatingSettingSection';
import VideoSettingSection from '../../Layout/EditSection/EditElementorSection/VideoSettingSection';
import EditHeadingStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditHeadingStyleSection/EditHeadingStyleSection';
import EditImageStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditImageStyleSection/EditImageStyleSection';
import EditTextEditorStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTextEditorStyleSection/EditTextEditorStyleSection';
import EditVideoStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditVideoStyleSection/EditVideoStyleSection';
import EditButtonStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditButtonStyleSection/EditButtonStyleSection';
import EditDividerStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditDividerStyleSection/EditDividerStyleSection';
import EditImageBoxStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditImageBoxStyleSection/EditImageBoxStyleSection';
import EditMapStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditMapStyleSection/EditMapStyleSection';
import EditIconStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconStyleSection/EditIconStyleSection';
import EditIconBoxStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconBoxStyleSection/EditIconBoxStyleSection';
import EditIconListStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconListStyleSection/EditIconListStyleSection';
import EditCounterStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditCounterStyleSection/EditCounterStyleSection';
import EditTabStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTabStyleSection/EditTabStyleSection';
import EditSpacerStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditSpacerStyleSection/EditSpacerStyleSection';
import EditTestiMonialStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTestiMonialStyleSection/EditTestiMonialStyleSection';
import EditAccordianStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditAccordianStyleSection/EditAccordianStyleSection';
import EditRatingStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditRatingStyleSection/EditRatingStyleSection';
import EditSocialIconStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditSocialIconStyleSection/EditSocialIconStyleSection';
import EditAlertStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditAlertStyleSection/EditAlertStyleSection';
import EditHtmlStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditHtmlStyleSection/EditHtmlStyleSection';
import StaticFirstContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstContentSection';
import EditFirstContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstContentStyleContainer';
import StaticSecondContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondContentSection';
import EditSecondContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondContentStyleContainer';
import StaticThirdContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdContentSection';
import EditThirdContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdContentStyleContainer';
import StaticForthContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthContentSection';
import EditForthContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthContentStyleContainer';
import StaticFifthContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFifthContentSection';
import EditFifthContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFifthContentStyleContainer';
import StaticSixContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSixContentSection';
import EditSixContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSixContentStyleContainer';
import StaticSevenContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSevenContentSection';
import EditSevenContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSevenContentStyleContainer';
import StaticEightContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticEightContentSection';
import EditEightContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditEightContentStyleContainer';
import StaticFirstFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstFeatureSection';
import EditFirstFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstFeatureStyleContainer';
import StaticSecondFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondFeatureSection';
import EditSecondFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondFeatureStyleContainer';
import StaticThirdFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdFeatureSection';
import EditThirdFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdFeatureStyleContainer';
import StaticForthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthFeatureSection';
import EditForthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthFeatureStyleContainer';
import StaticFifthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFifthFeatureSection';
import EditFifthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFifthFeatureStyleContainer';
import StaticSixthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSixthFeatureSection';
import EditSixthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSixthFeatureStyleContainer';
import StaticSevenFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSevenFeatureSection';
import EditSevenFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSevenFeatureStyleContainer';
import StaticEightFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticEightFeatureSection';
import EditEightFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditEightFeatureStyleContainer';
import StaticFirstStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstStepSection';
import EditFirstStepStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstStepStyleContainer';
import StaticThirdStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdStepSection';
import EditThirdStepStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdStepStyleContainer';
import StaticFirstTeamSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstTeamSection';
import EditFirstTeamStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstTeamStyleContainer';
import StaticSecondStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondStepSection';

const HomePageEditor = ({ commonSetting }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [openTab, setOpenTab] = useState('setting');
  const [groupHeaderData, setGroupHeaderData] = useState();
  const isChangeHeader = (value) => {
    setGroupHeaderData(value);
  };
  const [groupFooterData, setGroupFooterData] = useState();
  const isChangeFooter = (value) => {
    setGroupFooterData(value);
  };
  const [open, setOpen] = useState(false);
  const { installedExtensions } = useContext(Extention);
  const [btnLoading, setBtnLoading] = useState(false);

  const ultimatorKitExtension = installedExtensions?.find(
    (extension) => extension?.extension_name === 'Ultimator Kit',
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [getLayout, setGetLayout] = useState([]);
  const [editProductOtherSection, setEditProductOtherSection] = useState('');
  const [editSection, setEditSection] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
  });
  console.log(editSection, 'editSection--=-=->>');
  const [selectContextItem, setSelectContextItem] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
    copy_type: '',
  });
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tabIndex, setTabIndex] = useState(null);
  const [prevSecId, setPrevSecId] = useState(null);
  const [elementorId, setElementorId] = useState(null);
  console.log(elementorId, 'elementorId=-=-=->>>');
  const selectSection = (e, id, type, index) => {
    e.preventDefault();
    e.stopPropagation();
    setEditSection({
      section_id: id,
      section_type: type,
      section_index: index,
    });
    setSelectedItem(null);
    setTabIndex(null);
    const section = findSectionById(id);
    if (section) {
      setHeadingSetting({
        title: section?.content,
        htmlTag: section?.content?.htmlTag || section?.content,
      });
    }
    const selectedSection = findSectionById(id);
    if (selectedSection) {
      setBgImage({
        url: selectedSection?.content?.url || '',
        imageSize: selectedSection?.content?.imageSize || { width: '', height: '' },
        link: selectedSection?.content?.link || '',
      });
    }

    const selectedTextEditorSection = findSectionById(id);
    if (selectedTextEditorSection) {
      setTextEditorValue({
        textEditorValue: selectedTextEditorSection?.content?.textEditorValue || '',
      });
    }

    const selectedVideoSection = findSectionById(id);
    if (selectedVideoSection) {
      setVideoValue({
        source: selectedVideoSection?.content?.item?.source || '',
        link: selectedVideoSection?.content?.item?.link || '',
        startTime: selectedVideoSection?.content?.item?.startTime || '',
        endTime: selectedVideoSection?.content?.item?.endTime || '',
        autoPlay: selectedVideoSection?.content?.item?.autoPlay || '',
        mute: selectedVideoSection?.content?.item?.mute || '',
        loop: selectedVideoSection?.content?.item?.loop || '',
        playerControl: selectedVideoSection?.content?.item?.playerControl || '',
        modestBranding: selectedVideoSection?.content?.item?.modestBranding || '',
        privacyMode: selectedVideoSection?.content?.item?.privacyMode || '',
        lazyLoad: selectedVideoSection?.content?.item?.lazyLoad || '',
        introTitle: selectedVideoSection?.content?.item?.introTitle || '',
        introPortrait: selectedVideoSection?.content?.item?.introPortrait || '',
        introByLine: selectedVideoSection?.content?.item?.introByLine || '',
      });
    }

    const selectedButtonSection = findSectionById(id);
    if (selectedButtonSection) {
      setButtonValue({
        text: selectedButtonSection?.content?.text || '',
        link: selectedButtonSection?.content?.link || '',
        icon: selectedButtonSection?.content?.icon || '',
      });
    }

    const selectedDividerSection = findSectionById(id);
    if (selectedDividerSection) {
      setDividerValue({
        style: selectedDividerSection?.content?.style || '',
        width: selectedDividerSection?.content?.width || '',
        align: selectedDividerSection?.content?.align || '',
        element: selectedDividerSection?.content?.element || '',
        text: selectedDividerSection?.content?.text || '',
        icon: selectedDividerSection?.content?.icon || '',
      });
    }

    const selectedSpacerSection = findSectionById(id);
    if (selectedSpacerSection) {
      setSpacerValue({
        width: selectedSpacerSection?.content?.width || '',
      });
    }

    const selectedLocationSection = findSectionById(id);
    if (selectedLocationSection) {
      setLocationValue({
        location: selectedLocationSection?.content?.location || '',
        longitude: selectedLocationSection?.content?.longitude || '',
        latitude: selectedLocationSection?.content?.latitude || '',
      });
    }

    const selectedIconSection = findSectionById(id);
    if (selectedIconSection) {
      setIconValue({
        icon: selectedIconSection?.content?.icon || '',
        link: selectedIconSection?.content?.link || '',
      });
    }

    const selectedImageBoxSection = findSectionById(id);
    if (selectedImageBoxSection) {
      setImageBox({
        url: selectedImageBoxSection?.content?.url || '',
        imageSize: selectedImageBoxSection?.content?.imageSize || '',
        link: selectedImageBoxSection?.content?.link || '',
        title: selectedImageBoxSection?.content?.title || '',
        htmlTag: selectedImageBoxSection?.content?.htmlTag || '',
        description: selectedImageBoxSection?.content?.description || '',
      });
    }

    const selectedImageIconSection = findSectionById(id);
    if (selectedImageIconSection) {
      setImageIcon({
        icon: selectedImageIconSection?.content?.icon || '',
        link: selectedImageIconSection?.content?.link || '',
        title: selectedImageIconSection?.content?.title || '',
        htmlTag: selectedImageIconSection?.content?.htmlTag || '',
        description: selectedImageIconSection?.content?.description || '',
      });
    }

    const selectedIconListSection = findSectionById(id);
    if (selectedIconListSection) {
      setIconListData({
        item: selectedIconListSection?.content?.item || '',
      });
    }

    const selectedSocialIconSection = findSectionById(id);
    if (selectedSocialIconSection) {
      setsocialIconData({
        item: selectedSocialIconSection?.content?.item || '',
      });
    }

    const selectedCounterSection = findSectionById(id);
    if (selectedCounterSection) {
      setNumberCounter({
        start: selectedCounterSection?.content?.start || 0,
        end: selectedCounterSection?.content?.end || '',
        duration: selectedCounterSection?.content?.duration || '',
        title: selectedCounterSection?.content?.title || '',
        htmlTag: selectedCounterSection?.content?.htmlTag || '',
        prefix: selectedCounterSection?.content?.prefix || '',
        suffix: selectedCounterSection?.content?.suffix || '',
      });
    }

    const selectedTestiMonialSection = findSectionById(id);
    if (selectedTestiMonialSection) {
      setTestiMonialValue({
        url: selectedTestiMonialSection?.content?.url || '',
        imageSize: selectedTestiMonialSection?.content?.imageSize || '',
        name: selectedTestiMonialSection?.content?.name || '',
        title: selectedTestiMonialSection?.content?.title || '',
        position: selectedTestiMonialSection?.content?.position || '',
        alignment: selectedTestiMonialSection?.content?.alignment || '',
        description: selectedTestiMonialSection?.content?.description || '',
      });
    }

    const selectedRatingSection = findSectionById(id);
    if (selectedRatingSection) {
      setRatingValue({
        icon: selectedRatingSection?.content?.icon || '',
        totalRate: selectedRatingSection?.content?.totalRate || '',
        initialRate: selectedRatingSection?.content?.initialRate || '',
      });
    }

    const selectedTabSection = findSectionById(id);
    if (selectedTabSection) {
      setTabValue({
        item: selectedIconListSection?.content?.item || '',
      });
    }

    const selectedAccordionSection = findSectionById(id);
    if (selectedTabSection) {
      setAccordionValue({
        item: selectedAccordionSection?.content?.item || '',
      });
    }

    const selectedAlertSection = findSectionById(id);
    if (selectedAlertSection) {
      setAlertValue({
        alertType: selectedAlertSection?.content?.alertType || '',
        title: selectedAlertSection?.content?.title || '',
        content: selectedAlertSection?.content?.content || '',
        showIcon: selectedAlertSection?.content?.showIcon || '',
        icon: selectedAlertSection?.content?.icon || '',
      });
    }

    const selectedHtmlSection = findSectionById(id);
    if (selectedHtmlSection) {
      setHtmlValue(selectedHtmlSection?.content?.item || '');
    }

    const headingSection = findSectionById(id);
    if (headingSection) {
      setHeadingDesign(headingSection?.style);
    }

    const imageSection = findSectionById(id);
    if (imageSection) {
      setImageDesign(imageSection?.style);
    }

    const textEditorSection = findSectionById(id);
    if (textEditorSection) {
      setTextEditorDesign(textEditorSection?.style);
    }

    const videoSection = findSectionById(id);
    if (videoSection) {
      setVideoDesign(videoSection?.style);
    }

    const buttonSection = findSectionById(id);
    if (buttonSection) {
      setButtonDesign(buttonSection?.style);
    }

    const dividerSection = findSectionById(id);
    if (dividerSection) {
      setDividerDesign(dividerSection?.style);
    }

    const spacerSection = findSectionById(id);
    if (spacerSection) {
      setSpacerDesign(spacerSection?.style);
    }

    const mapSection = findSectionById(id);
    if (mapSection) {
      setMapDesign(mapSection?.style);
    }

    const iconSection = findSectionById(id);
    if (iconSection) {
      setIconDesign(iconSection?.style);
    }

    const imageBoxSection = findSectionById(id);
    if (imageBoxSection) {
      setImageBoxDesign(imageBoxSection?.style);
    }

    const iconBoxSection = findSectionById(id);
    if (iconBoxSection) {
      setIconBoxDesign(iconBoxSection?.style);
    }

    const iconListSection = findSectionById(id);
    if (iconListSection) {
      setIconListDesign(iconListSection?.style);
    }

    const counterSection = findSectionById(id);
    if (counterSection) {
      setCounterDesign(counterSection?.style);
    }

    const testiminialSection = findSectionById(id);
    if (testiminialSection) {
      setTestiMonialDesign(testiminialSection?.style);
    }

    const tabSection = findSectionById(id);
    if (tabSection) {
      setTabDesign(tabSection?.style);
    }

    const accordianSection = findSectionById(id);
    if (accordianSection) {
      setAccordianDesign(accordianSection?.style);
    }

    const socialIconSection = findSectionById(id);
    if (socialIconSection) {
      setSocialIconDesign(socialIconSection?.style);
    }

    const alertSection = findSectionById(id);
    if (alertSection) {
      setAlertDesign(alertSection?.style);
    }

    const htmlSection = findSectionById(id);
    if (htmlSection) {
      setHtmlDesign(htmlSection?.style);
    }

    const ratingSection = findSectionById(id);
    if (ratingSection) {
      setRatingDesign(ratingSection?.style);
    }

    const firstContentSection = findSectionById(id);
    if (firstContentSection) {
      setFirstContentDesign(firstContentSection?.style);
    }

    const secondContentSection = findSectionById(id);
    if (secondContentSection) {
      setSecondContentDesign(secondContentSection?.style);
    }

    const thirdContentSection = findSectionById(id);
    if (thirdContentSection) {
      setThirdContentDesign(thirdContentSection?.style);
    }

    const forthContentSection = findSectionById(id);
    if (forthContentSection) {
      setForthContentDesign(forthContentSection?.style);
    }

    const fifthContentSection = findSectionById(id);
    if (fifthContentSection) {
      setFifthContentDesign(fifthContentSection?.style);
    }

    const sixContentSection = findSectionById(id);
    if (sixContentSection) {
      setSixContentDesign(sixContentSection?.style);
    }

    const sevenContentSection = findSectionById(id);
    if (sevenContentSection) {
      setSevenContentDesign(sevenContentSection?.style);
    }

    const selectedFirstContentSection = findSectionById(id);
    if (selectedFirstContentSection) {
      setFirstContentValue({
        item: selectedFirstContentSection?.content?.item || '',
        title: selectedFirstContentSection?.content?.title || '',
        description: selectedFirstContentSection?.content?.description || '',
        htmlTag: selectedFirstContentSection?.content?.htmlTag || '',
        gridValue: selectedFirstContentSection?.content?.gridValue || '',
        subTitle: selectedFirstContentSection?.content?.subTitle || '',
        subHtmlTag: selectedFirstContentSection?.content?.subHtmlTag || '',
        subDescription: selectedFirstContentSection?.content?.subDescription || '',
        icon: selectedFirstContentSection?.content?.icon || '',
        link: selectedFirstContentSection?.content?.link || '',
      });
    }

    const selectedSecondContentSection = findSectionById(id);
    if (selectedSecondContentSection) {
      setSecondContentValue({
        item: selectedSecondContentSection?.content?.item || '',
        title: selectedSecondContentSection?.content?.title || '',
        description: selectedSecondContentSection?.content?.description || '',
        htmlTag: selectedSecondContentSection?.content?.htmlTag || '',
        gridValue: selectedSecondContentSection?.content?.gridValue || '',
        image: selectedSecondContentSection?.content?.image || '',
        objectFit: selectedSecondContentSection?.content?.objectFit || '',
      });
    }

    const selectedThirdContentSection = findSectionById(id);
    if (selectedThirdContentSection) {
      setThirdContentValue({
        item: selectedThirdContentSection?.content?.item || '',
        title: selectedThirdContentSection?.content?.title || '',
        description: selectedThirdContentSection?.content?.description || '',
        htmlTag: selectedThirdContentSection?.content?.htmlTag || '',
        gridValue: selectedThirdContentSection?.content?.gridValue || '',
        button: selectedThirdContentSection?.content?.button || '',
        icon: selectedThirdContentSection?.content?.icon || '',
        link: selectedThirdContentSection?.content?.link || '',
      });
    }

    const selectedForthContentSection = findSectionById(id);
    if (selectedForthContentSection) {
      setForthContentValue({
        item: selectedForthContentSection?.content?.item || '',
        title: selectedForthContentSection?.content?.title || '',
        description: selectedForthContentSection?.content?.description || '',
        htmlTag: selectedForthContentSection?.content?.htmlTag || '',
        gridValue: selectedForthContentSection?.content?.gridValue || '',
        button: selectedForthContentSection?.content?.button || '',
      });
    }

    const selectedFifthContentSection = findSectionById(id);
    if (selectedFifthContentSection) {
      setFifthContentValue({
        title: selectedFifthContentSection?.content?.title || '',
        description: selectedFifthContentSection?.content?.description || '',
        htmlTag: selectedFifthContentSection?.content?.htmlTag || '',
        button_link: selectedFifthContentSection?.content?.button_link || '',
        button: selectedFifthContentSection?.content?.button || '',
        icon: selectedFifthContentSection?.content?.icon || '',
        link: selectedFifthContentSection?.content?.link || '',
      });
    }

    const selectedSixContentSection = findSectionById(id);
    if (selectedSixContentSection) {
      setSixContentValue({
        title: selectedSixContentSection?.content?.title || '',
        description: selectedSixContentSection?.content?.description || '',
        htmlTag: selectedSixContentSection?.content?.htmlTag || '',
        button_link: selectedSixContentSection?.content?.button_link || '',
        content: selectedSixContentSection?.content?.content || '',
        link_url: selectedSixContentSection?.content?.link_url || '',
        image: selectedSixContentSection?.content?.image || '',
        profile: selectedSixContentSection?.content?.profile || '',
        objectFit: selectedSixContentSection?.content?.objectFit || '',
      });
    }

    const selectedSevenContentSection = findSectionById(id);
    if (selectedSevenContentSection) {
      setSevenContentValue({
        item: selectedSevenContentSection?.content?.item || '',
        gridValue: selectedSevenContentSection?.content?.gridValue || '',
        image: selectedSevenContentSection?.content?.image || '',
        objectFit: selectedSevenContentSection?.content?.objectFit || '',
      });
    }

    const selectedEightContentSection = findSectionById(id);
    if (selectedEightContentSection) {
      setEightContentValue({
        item: selectedEightContentSection?.content?.item || '',
        gridValue: selectedEightContentSection?.content?.gridValue || '',
        title: selectedEightContentSection?.content?.title || '',
        description: selectedEightContentSection?.content?.description || '',
        htmlTag: selectedEightContentSection?.content?.htmlTag || '',
        image: selectedEightContentSection?.content?.image || '',
        icon: selectedEightContentSection?.content?.icon || '',
        objectFit: selectedEightContentSection?.content?.objectFit || '',
      });
    }

    const selectedFirstFeatureSection = findSectionById(id);
    if (selectedFirstFeatureSection) {
      setFirstFeatureValue({
        item: selectedFirstFeatureSection?.content?.item || '',
        gridValue: selectedFirstFeatureSection?.content?.gridValue || '',
        title: selectedFirstFeatureSection?.content?.title || '',
        htmlTag: selectedFirstFeatureSection?.content?.htmlTag || '',
      });
    }

    const selectedSecondFeatureSection = findSectionById(id);
    if (selectedSecondFeatureSection) {
      setSecondFeatureValue({
        item: selectedSecondFeatureSection?.content?.item || '',
        gridValue: selectedSecondFeatureSection?.content?.gridValue || '',
        title: selectedSecondFeatureSection?.content?.title || '',
        htmlTag: selectedSecondFeatureSection?.content?.htmlTag || '',
        description: selectedSecondFeatureSection?.content?.description || '',
        button: selectedSecondFeatureSection?.content?.button || '',
      });
    }

    const selectedThirdFeatureSection = findSectionById(id);
    if (selectedThirdFeatureSection) {
      setThirdFeatureValue({
        item: selectedThirdFeatureSection?.content?.item || '',
        image: selectedThirdFeatureSection?.content?.image || '',
        objectFit: selectedThirdFeatureSection?.content?.objectFit || '',
      });
    }

    const selectedFirstStepSection = findSectionById(id);
    if (selectedFirstStepSection) {
      setFirstStepValue({
        item: selectedFirstStepSection?.content?.item || '',
        image: selectedFirstStepSection?.content?.image || '',
        objectFit: selectedFirstStepSection?.content?.objectFit || '',
      });
    }

    const selectedThirdStepSection = findSectionById(id);
    if (selectedThirdStepSection) {
      setThirdStepValue({
        item: selectedThirdStepSection?.content?.item || '',
      });
    }
    const selectedForthFeatureSection = findSectionById(id);
    if (selectedForthFeatureSection) {
      setForthFeatureValue({
        item: selectedForthFeatureSection?.content?.item || '',
      });
    }

    const selectedFifthFeatureSection = findSectionById(id);
    if (selectedFifthFeatureSection) {
      setFifthFeatureValue({
        item: selectedFifthFeatureSection?.content?.item || '',
        gridValue: selectedFifthFeatureSection?.content?.gridValue || '',
        title: selectedFifthFeatureSection?.content?.title || '',
        htmlTag: selectedFifthFeatureSection?.content?.htmlTag || '',
        button: selectedFifthFeatureSection?.content?.button || '',
        subTitle: selectedFifthFeatureSection?.content?.subTitle || '',
        subHtmlTag: selectedFifthFeatureSection?.content?.subHtmlTag || '',
      });
    }

    const selectedSixthFeatureSection = findSectionById(id);
    if (selectedSixthFeatureSection) {
      setSixthFeatureValue({
        item: selectedSixthFeatureSection?.content?.item || '',
        button: selectedSixthFeatureSection?.content?.button || '',
      });
    }

    const selectedSevenFeatureSection = findSectionById(id);
    if (selectedSevenFeatureSection) {
      setSevenFeatureValue({
        item: selectedSevenFeatureSection?.content?.item || '',
        gridValue: selectedSevenFeatureSection?.content?.gridValue || '',
        title: selectedSevenFeatureSection?.content?.title || '',
        htmlTag: selectedSevenFeatureSection?.content?.htmlTag || '',
        button: selectedSevenFeatureSection?.content?.button || '',
        description: selectedSevenFeatureSection?.content?.description || '',
      });
    }

    const selectedEightFeatureSection = findSectionById(id);
    if (selectedEightFeatureSection) {
      setEightFeatureValue({
        item: selectedEightFeatureSection?.content?.item || '',
        gridValue: selectedEightFeatureSection?.content?.gridValue || '',
        title: selectedEightFeatureSection?.content?.title || '',
        htmlTag: selectedEightFeatureSection?.content?.htmlTag || '',
        button: selectedEightFeatureSection?.content?.button || '',
        description: selectedEightFeatureSection?.content?.description || '',
      });
    }

    const selectedFirstTeamSection = findSectionById(id);
    if (selectedFirstTeamSection) {
      setFirstTeamValue({
        item: selectedFirstTeamSection?.content?.item || '',
        gridValue: selectedFirstTeamSection?.content?.gridValue || '',
        title: selectedFirstTeamSection?.content?.title || '',
        htmlTag: selectedFirstTeamSection?.content?.htmlTag || '',
        description: selectedFirstTeamSection?.content?.description || '',
      });
    }
  };

  const getContextMenuItem = (e, id, type, index, copyType) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectContextItem({
      section_id: id,
      section_type: type,
      section_index: index,
      copy_type: copyType,
    });
  };

  const findSectionById = (id) => {
    const allSections = Object.values(sectionAdd).flat(2);
    return allSections.find((section) => section?.id === id);
  };

  const updateSectionContent = (section_id, newContent) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      Object.keys(updatedSections).forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey].map((sectionArray) => {
            if (Array.isArray(sectionArray)) {
              return sectionArray.map((section) => {
                if (section.id === section_id) {
                  return { ...section, content: { ...section.content, ...newContent } };
                }
                return section;
              });
            }
            return sectionArray;
          });
        }
      });

      return updatedSections;
    });
  };

  const [headingDesign, setHeadingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [mapDesign, setMapDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    zoom: '',
    map_color: '',
    map_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    map_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
    width: '',
  });

  const [spacerDesign, setSpacerDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [testiMonialDesign, setTestiMonialDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    name_font_weight: '',
    name_font_size: '',
    name_font_height: '',
    name_font_color: '',
    name_font_align: '',
    name_font_decoration: '',
    name_font_capitalize: '',
    name_font_break: '',
    name_font_wrap: '',
    name_letter_spacing: '',
    name_word_spacing: '',
    name_text_stroke: '',
    name_stroke_color: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    image_width: '',
    image_border_style: '',
    image_border_radius_top: '',
    image_border_radius_right: '',
    image_border_radius_bottom: '',
    image_border_radius_left: '',
    image_border_top: '',
    image_border_right: '',
    image_border_bottom: '',
    image_border_left: '',
    image_box_shadow_blur: '',
    image_border_color: '',
    image_position: '',
    content_alignment: '',
    vertical_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
    name_shadow_color: '',
    name_shadow_blur: '',
    name_shadow_horizontal: '',
    name_shadow_vertical: '',
  });

  const [socialIconDesign, setSocialIconDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    icon_width: '',
    icon_border_style: '',
    icon_border_radius_top: '',
    icon_border_radius_right: '',
    icon_border_radius_bottom: '',
    icon_border_radius_left: '',
    icon_border_top: '',
    icon_border_right: '',
    icon_border_bottom: '',
    icon_border_left: '',
    icon_border_color: '',
    icon_spacing: '',
    icon_border_radius_all: '',
    icon_padding: '',
    icon_align: '',
    icon_color: '',
  });

  const [alertDesign, setAlertDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    icon_width: '',
    icon_border_color: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
    alert_background: '',
  });

  const [htmlDesign, setHtmlDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    html_align: '',
    html_rotate: '',
    background_position: '',
  });

  const [iconDesign, setIconDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    icon_color: '',
    icon_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    icon_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
    width: '',
  });

  const [iconBoxDesign, setIconBoxDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    icon_width: '',
    icon_border_style: '',
    icon_border_radius_top: '',
    icon_border_radius_right: '',
    icon_border_radius_bottom: '',
    icon_border_radius_left: '',
    icon_border_top: '',
    icon_border_right: '',
    icon_border_bottom: '',
    icon_border_left: '',
    icon_box_shadow_blur: '',
    icon_border_color: '',
    icon_spacing: '',
    content_spacing: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
  });

  const [iconListDesign, setIconListDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    icon_width: '',
    icon_color: '',
    icon_spacing: '',
    content_spacing: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    space_between: '',
    item_align: '',
    divider_visible: '',
    divider_style: '',
    divider_weight: '',
    divider_width: '',
    divider_color: '',
    horizontal_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
  });

  const [imageDesign, setImageDesign] = useState({
    image_align: '',
    image_width: '',
    image_maxWidth: '',
    image_height: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_color: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    opacity: '',
    image_rotate: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [textEditorDesign, setTextEditorDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [videoDesign, setVideoDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    aspect_ratio: '',
    width: '',
    video_align: '',
    video_rotate: '',
    background_position: '',
    aspectRatio: '',
  });

  const [buttonDesign, setButtonDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    text_shadow_color: '',
    font_shadow_blur: '',
    font_shadow_horizontal: '',
    font_shadow_vertical: '',
    font_shadow_spread: '',
    button_padding_top: '',
    button_padding_right: '',
    button_padding_bottom: '',
    button_padding_left: '',
    button_padding_all: '',
    button_border_style: '',
    button_border_top: '',
    button_border_right: '',
    button_border_bottom: '',
    button_border_left: '',
    button_border_all: '',
    button_border_radius_top: '',
    button_border_radius_right: '',
    button_border_radius_bottom: '',
    button_border_radius_left: '',
    button_border_radius_all: '',
    button_border_color: '',
    button_background: '#61CE70',
    button_background_gradient: 'button_classic',
    button_gradient_color_start: '',
    button_gradient_start_position: '',
    button_gradient_color_end: '',
    button_gradient_end_position: '',
    button_gradient_angle: '',
    button_box_shadow_color: '',
    button_box_shadow_blur: '',
    button_box_shadow_horizontal: '',
    button_box_shadow_vertical: '',
    background_position: '',
  });

  const [dividerDesign, setDividerDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    divider_weight: '',
    gap: '',
    divider_color: '',
    divider_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    divider_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
  });

  const [imageBoxDesign, setImageBoxDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    image_width: '',
    image_border_style: '',
    image_border_radius_top: '',
    image_border_radius_right: '',
    image_border_radius_bottom: '',
    image_border_radius_left: '',
    image_border_top: '',
    image_border_right: '',
    image_border_bottom: '',
    image_border_left: '',
    image_box_shadow_blur: '',
    image_border_color: '',
    image_spacing: '',
    content_spacing: '',
    image_position: '',
    content_alignment: '',
    vertical_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
  });

  const [counterDesign, setCounterDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    number_shadow_color: '',
    number_shadow_blur: '',
    number_shadow_horizontal: '',
    number_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    counter_font_weight: '',
    counter_font_size: '',
    counter_font_height: '',
    counter_font_color: '',
    counter_font_align: '',
    counter_font_style: '',
    counter_font_decoration: '',
    counter_font_capitalize: '',
    counter_font_break: '',
    counter_font_wrap: '',
    counter_letter_spacing: '',
    counter_word_spacing: '',
    counter_text_stroke: '',
    counter_stroke_color: '',
    title_position: '',
    title_horizontal_alignment: '',
    title_vertical_alignment: '',
    number_position: '',
    title_spacing: '',
  });

  const [tabDesign, setTabDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_active_font_color: '',
  });

  const [accordianDesign, setAccordianDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_active_font_color: '',
  });

  const [ratingDesign, setRatingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    icon_color: '',
    unmarked_color: '',
    icon_size: '',
    icon_spacing: '',
  });

  const [firstContentDesign, setFirstContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    sub_title_font_weight: '500',
    sub_title_font_size: '',
    sub_title_font_height: '',
    sub_title_font_color: '#6366f1',
    sub_title_font_align: '',
    sub_title_font_style: '',
    sub_title_font_decoration: '',
    sub_title_font_capitalize: '',
    sub_title_font_break: '',
    sub_title_font_wrap: '',
    sub_title_letter_spacing: '1.6',
    sub_title_word_spacing: '',
    sub_title_text_stroke: '',
    sub_title_stroke_color: '',
    sub_title_text_shadow_color: '',
    sub_title_text_shadow_blur: '',
    sub_title_text_shadow_horizontal: '',
    sub_title_text_shadow_vertical: '',
    sub_title_text_rotate: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#111827',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '1.6',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [secondContentDesign, setSecondContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdContentDesign, setThirdContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
  });

  const [forthContentDesign, setForthContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    sub_title_font_weight: '',
    sub_title_font_size: '',
    sub_title_font_height: '',
    sub_title_font_color: '#28282B',
    sub_title_font_align: '',
    sub_title_font_style: '',
    sub_title_font_decoration: '',
    sub_title_font_capitalize: '',
    sub_title_font_break: '',
    sub_title_font_wrap: '',
    sub_title_letter_spacing: '',
    sub_title_word_spacing: '',
    sub_title_text_stroke: '',
    sub_title_stroke_color: '',
    sub_title_text_shadow_color: '',
    sub_title_text_shadow_blur: '',
    sub_title_text_shadow_horizontal: '',
    sub_title_text_shadow_vertical: '',
    sub_title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
  });

  const [fifthContentDesign, setFifthContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [sixContentDesign, setSixContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    content_font_weight: '',
    content_font_size: '',
    content_font_height: '',
    content_font_color: '#6B7280',
    content_font_align: '',
    content_font_style: '',
    content_font_decoration: '',
    content_font_capitalize: '',
    content_font_break: '',
    content_font_wrap: '',
    content_letter_spacing: '',
    content_word_spacing: '',
    content_text_stroke: '',
    content_stroke_color: '',
    content_text_shadow_color: '',
    content_text_shadow_blur: '',
    content_text_shadow_horizontal: '',
    content_text_shadow_vertical: '',
    content_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [sevenContentDesign, setSevenContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [eightContentDesign, setEightContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstFeatureDesign, setFirstFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondFeatureDesign, setSecondFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [sevenFeatureDesign, setSevenFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [eightFeatureDesign, setEightFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstStepDesign, setFirstStepDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdStepDesign, setThirdStepDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdFeatureDesign, setThirdFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [forthFeatureDesign, setForthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [fifthFeatureDesign, setFifthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    sub_title_font_weight: '500',
    sub_title_font_size: '',
    sub_title_font_height: '',
    sub_title_font_color: '#6366f1',
    sub_title_font_align: '',
    sub_title_font_style: '',
    sub_title_font_decoration: '',
    sub_title_font_capitalize: '',
    sub_title_font_break: '',
    sub_title_font_wrap: '',
    sub_title_letter_spacing: '1.6',
    sub_title_word_spacing: '',
    sub_title_text_stroke: '',
    sub_title_stroke_color: '',
    sub_title_text_shadow_color: '',
    sub_title_text_shadow_blur: '',
    sub_title_text_shadow_horizontal: '',
    sub_title_text_shadow_vertical: '',
    sub_title_text_rotate: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#111827',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '1.6',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [sixthFeatureDesign, setSixthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstTeamDesign, setFirstTeamDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const updateSectionStyle = (section_id, newContent) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      Object.keys(updatedSections).forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey]?.map((sectionArray) => {
            if (Array.isArray(sectionArray)) {
              return sectionArray?.map((section) => {
                if (section.id === section_id) {
                  return { ...section, style: { ...section?.style, ...newContent } };
                }
                return section;
              });
            }
            return sectionArray;
          });
        }
      });

      return updatedSections;
    });
  };

  const handleContentChange = (section_id, newTitle) => {
    setHeadingSetting((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstContentTagChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFifthFeatureTagChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSixthFeatureTagChange = (section_id, newTitle) => {
    setSixthFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSecondContentTagChange = (section_id, newTitle) => {
    setSecondContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleThirdContentTagChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstTeamTagChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleForthContentTagChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFifthContentTagChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSixContentTagChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstContentSubTitleTagChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      subHtmlTag: newTitle,
    }));

    updateSectionContent(section_id, { subHtmlTag: newTitle });
  };

  const handleFifthFeatureSubTitleTagChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      subHtmlTag: newTitle,
    }));

    updateSectionContent(section_id, { subHtmlTag: newTitle });
  };

  const handleEightContentTitleTagChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleFirstFeatureTitleTagChange = (section_id, newTitle) => {
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSecondFeatureTitleTagChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleSevenFeatureTitleTagChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleEightFeatureTitleTagChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      htmlTag: newTitle,
    }));

    updateSectionContent(section_id, { htmlTag: newTitle });
  };

  const handleEightContentDescriptionChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleSecondFeatureDescriptionChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleSevenFeatureDescriptionChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleEightFeatureDescriptionChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      description: newTitle,
    }));

    updateSectionContent(section_id, { description: newTitle });
  };

  const handleFirstContentButtonValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSecondFeatureButtonValueChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleSevenFeatureButtonValueChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleEightFeatureButtonValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleThirdContentButtonValueChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleForthContentButtonValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleFifthContentButtonValueChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      button: newTitle,
    }));

    updateSectionContent(section_id, { button: newTitle });
  };

  const handleForthContentSubTitleValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      sub_title: newTitle,
    }));

    updateSectionContent(section_id, { sub_title: newTitle });
  };

  const handleFifthContentSubButtonValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      button_link: newTitle,
    }));

    updateSectionContent(section_id, { button_link: newTitle });
  };

  const handleFifthContentIconValueChange = (section_id, icon) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      icon: icon,
    }));

    updateSectionContent(section_id, { icon: icon });
  };

  const handleFifthContentIconLinkValueChange = (section_id, link) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      link: link,
    }));

    updateSectionContent(section_id, { link: link });
  };

  const handleSixContentSubButtonValueChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      button_link: newTitle,
    }));

    updateSectionContent(section_id, { button_link: newTitle });
  };

  const handleSixContentButtonLinkChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      link_url: newTitle,
    }));

    updateSectionContent(section_id, { link_url: newTitle });
  };

  const handleSixContentImageChange = (section_id, newImage) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      image: newImage,
    }));

    updateSectionContent(section_id, { image: newImage });
  };

  const handleSixContentProfileChange = (section_id, newProfile) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      profile: newProfile,
    }));

    updateSectionContent(section_id, { profile: newProfile });
  };

  const handleSixContentObjectFitChange = (section_id, objectFit) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      objectFit: objectFit,
    }));

    updateSectionContent(section_id, { objectFit: objectFit });
  };

  const handleFirstContentGridValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstTeamGridValueChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFifthFeatureGridValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondContentGridValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=->>>');
    setSecondContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleThirdContentGridValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=->>>');
    setThirdContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleForthContentGridValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=->>>');
    setForthContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSevenContentGridValueChange = (section_id, newTitle) => {
    setSevenContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleEightContentGridValueChange = (section_id, newTitle) => {
    setEightContentValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstFeatureGridValueChange = (section_id, newTitle) => {
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSecondFeatureGridValueChange = (section_id, newTitle) => {
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleSevenFeatureGridValueChange = (section_id, newTitle) => {
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleEightFeatureGridValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      gridValue: newTitle,
    }));

    updateSectionContent(section_id, { gridValue: newTitle });
  };

  const handleFirstContentTitleValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFifthFeatureTitleValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleEightContentTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setEightContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setFirstFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setSecondFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSevenFeatureTitleValueChange = (section_id, newTitle) => {
    console.log(newTitle, 'newTitle=-=-=>');
    setSevenFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleEightFeatureTitleValueChange = (section_id, newTitle) => {
    setEightFeatureValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSecondContentTitleValueChange = (section_id, newTitle) => {
    setSecondContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleThirdContentTitleValueChange = (section_id, newTitle) => {
    setThirdContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstTeamTitleValueChange = (section_id, newTitle) => {
    setFirstTeamValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleForthContentTitleValueChange = (section_id, newTitle) => {
    setForthContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFifthContentTitleValueChange = (section_id, newTitle) => {
    setFifthContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleSixContentTitleValueChange = (section_id, newTitle) => {
    setSixContentValue((prevState) => ({
      ...prevState,
      title: newTitle,
    }));

    updateSectionContent(section_id, { title: newTitle });
  };

  const handleFirstContentSubTitleValueChange = (section_id, newTitle) => {
    setFirstContentValue((prevState) => ({
      ...prevState,
      subTitle: newTitle,
    }));

    updateSectionContent(section_id, { subTitle: newTitle });
  };

  const handleFifthFeatureSubTitleValueChange = (section_id, newTitle) => {
    setFifthFeatureValue((prevState) => ({
      ...prevState,
      subTitle: newTitle,
    }));

    updateSectionContent(section_id, { subTitle: newTitle });
  };

  const handleHtmlTagChange = (section_id, newTag) => {
    setHeadingSetting((prevState) => ({
      ...prevState,
      htmlTag: newTag,
    }));

    updateSectionContent(section_id, { htmlTag: newTag });
  };
  const [bgImage, setBgImage] = useState({ url: bgImageDefault, imageSize: '', link: '' });
  const [imageBox, setImageBox] = useState({
    url: bgImageDefault,
    imageSize: '',
    link: '',
    title: '',
    htmlTag: '',
    description: '',
  });
  const [imageIcon, setImageIcon] = useState({
    icon: bgImageDefault,
    link: '',
    title: '',
    htmlTag: '',
    description: '',
  });
  const [textEditorValue, setTextEditorValue] = useState({
    textEditorValue:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
  });
  const [buttonValue, setButtonValue] = useState({ text: 'Click Here', link: '#', icon: '' });

  const [videoValue, setVideoValue] = useState();

  const [dividerValue, setDividerValue] = useState({
    style: 'solid',
    width: '100',
    align: 'left',
    element: 'none',
    text: 'Divider',
  });

  const [spacerValue, setSpacerValue] = useState({
    width: '100',
  });

  const [locationValue, setLocationValue] = useState({
    location: '',
    latitude: '',
    longitude: '',
  });

  const [iconValue, setIconValue] = useState({
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='50'
        height='50'
        fill='currentColor'
        class='bi bi-star-fill'
        viewBox='0 0 16 16'
      >
        <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
      </svg>
    ),
    link: '#',
  });

  const [iconListData, setIconListData] = useState({
    item: [
      {
        id: 1,
        text: 'List Item #1',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-check'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path>
          </svg>
        ),
      },
      {
        id: 2,
        text: 'List Item #2',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-times'
            viewBox='0 0 352 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path>
          </svg>
        ),
      },
      {
        id: 3,
        text: 'List Item #3',
        link: '#',
        icon: (
          <svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-dot-circle'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path>
          </svg>
        ),
      },
    ],
  });

  const [socialIconData, setsocialIconData] = useState();

  const [numberCounter, setNumberCounter] = useState({
    start: '',
    end: '',
    duration: '',
    title: '',
    htmlTag: '',
    prefix: '',
    suffix: '',
  });

  const [testiMonialValue, setTestiMonialValue] = useState({
    url: bgImageDefault,
    imageSize: '',
    name: '',
    title: '',
    position: '',
    alignment: '',
    description: '',
  });

  const [ratingValue, setRatingValue] = useState({
    icon: bgImageDefault,
    alignment: '',
    initialRate: 2,
    totalRate: 5,
  });

  const [tabValue, setTabValue] = useState();
  const [accordionValue, setAccordionValue] = useState();

  const [alertValue, setAlertValue] = useState({
    alertType: '',
    title: '',
    content: '',
    showIcon: '',
    icon: '',
  });

  const [htmlValue, setHtmlValue] = useState();

  const [firstContentValue, setFirstContentValue] = useState({
    title: 'ROOF PARTY POLAROID',
    htmlTag: 'h6',
    subTitle: 'Master Cleanse Reliac Heirloom',
    subHtmlTag: 'h2',
    button: 'Button',
    gridValue: 4,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                   fill='none'
                   stroke='currentColor'
                   stroke-linecap='round'
                   stroke-linejoin='round'
                   stroke-width='2'
                   class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                   fill='none'
                   stroke='currentColor'
                   stroke-linecap='round'
                   stroke-linejoin='round'
                   stroke-width='2'
                   class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                   fill='none'
                   stroke='currentColor'
                   stroke-linecap='round'
                   stroke-linejoin='round'
                   stroke-width='2'
                   class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
        link: '',
      },
      {
        id: 4,
        title: 'Melanchole',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        icon: `<svg
                   fill='none'
                   stroke='currentColor'
                   stroke-linecap='round'
                   stroke-linejoin='round'
                   stroke-width='2'
                   class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
        link: '',
      },
    ],
  });

  const [secondContentValue, setSecondContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 4,
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Chichen Itza',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 2,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Colosseum Roma',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 3,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'Great Pyramid of Giza',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
      {
        id: 4,
        sub_title: 'SUBTITLE',
        sub_title_htmlTag: 'h3',
        title: 'San Francisco',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        image: 'https://dummyimage.com/720x400',
        objectFit: 'fill',
      },
    ],
  });

  const [thirdContentValue, setThirdContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 3,
    button: 'Button',
    description:
      '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                            </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                               <circle cx="6" cy="6" r="3"></circle>
              <circle cx="6" cy="18" r="3"></circle>
              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                            </svg>`,
        link: '',
      },
      {
        id: 4,
        title: 'Melanchole',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                                 <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                            </svg>`,
        link: '',
      },
      {
        id: 5,
        title: 'Bunker',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                            </svg>`,
        link: '',
      },
      {
        id: 6,
        title: 'Ramona Falls',
        htmlTag: 'h2',
        description:
          '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
        isEdit: false,
        icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>`,
        link: '',
      },
    ],
  });

  const [forthContentValue, setForthContentValue] = useState({
    title: 'Pitchfork Kickstarter Taxidermy',
    htmlTag: 'h1',
    gridValue: 3,
    button: 'Learn more',
    description:
      '<p>Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.</p>',
    sub_title: 'CATEGORIES',
    sub_title_htmlTag: 'h2',
    item: [
      {
        id: 1,
        link: 'First link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 2,
        link: 'Second link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 3,
        link: 'Third link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 4,
        link: 'Forth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 5,
        link: 'Fifth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 6,
        link: 'Sixth link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 7,
        link: 'Seventh link',
        link_url: '#',
        isEdit: false,
      },
      {
        id: 8,
        link: 'Eighth link',
        link_url: '#',
        isEdit: false,
      },
    ],
  });

  const [fifthContentValue, setFifthContentValue] = useState({
    title: 'Kickstarter Actually Pinterest Brunch Bitters Occupy',
    htmlTag: 'h1',
    button: 'Button',
    description:
      '<p>Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos.</p>',
    button_link: 'Learn more',
    icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
              viewBox='0 0 24 24'
          >
           <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
    link: '',
  });

  const [sixContentValue, setSixContentValue] = useState({
    title: 'Phoebe Caulfield',
    htmlTag: 'h1',
    description:
      '<p>Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>',
    content:
      '<p>Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90 scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>',
    button_link: 'Learn more',
    link_url: '#',
    image: 'https://dummyimage.com/1200x500',
    profile: `<svg
                fill='none'
                 stroke='currentColor'
                 stroke-linecap='round'
                 stroke-linejoin='round'
                 stroke-width='2'
                 class='w-10 h-10'
                 viewBox='0 0 24 24'
                >
                  <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
                <circle cx='12' cy='7' r='4'></circle>
              </svg>`,
    objectFit: 'fill',
  });

  const [sevenContentValue, setSevenContentValue] = useState({
    gridValue: 2,
    item: [
      {
        id: 1,
        title: 'Buy YouTube Videos',
        htmlTag: 'h2',
        description:
          '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
        button: 'Button',
        isEdit: false,
        image: 'https://dummyimage.com/1201x501',
        objectFit: 'fill',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
        button: 'Button',
        isEdit: false,
        image: 'https://dummyimage.com/1201x501',
        objectFit: 'fill',
      },
    ],
  });

  const [eightContentValue, setEightContentValue] = useState({
    gridValue: 3,
    title: 'Space The Final Frontier',
    htmlTag: 'h2',
    description:
      '<p>Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p>',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
        objectFit: 'fill',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
        objectFit: 'fill',
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h2',
        description:
          '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        image: 'https://dummyimage.com/1203x503',
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
        objectFit: 'fill',
      },
    ],
  });

  const [firstFeatureValue, setFirstFeatureValue] = useState({
    gridValue: 3,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                      <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                  </svg>`,
        linkIcon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
          <circle cx="6" cy="6" r="3"></circle>
<circle cx="6" cy="18" r="3"></circle>
<path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
       </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
    ],
  });

  const [secondFeatureValue, setSecondFeatureValue] = useState({
    gridValue: 3,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
          <circle cx="6" cy="6" r="3"></circle>
<circle cx="6" cy="18" r="3"></circle>
<path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
       </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
        linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
        link: '',
      },
    ],
  });

  const [thirdFeatureValue, setThirdFeatureValue] = useState({
    image: 'https://dummyimage.com/460x500',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
          <circle cx="6" cy="6" r="3"></circle>
<circle cx="6" cy="18" r="3"></circle>
<path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
       </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
        linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
        link: '',
      },
    ],
    objectFit: 'fill',
  });

  const [forthFeatureValue, setForthFeatureValue] = useState({
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
        button: 'Learn more',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
        linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
        link: '',
      },
    ],
  });

  const [fifthFeatureValue, setFifthFeatureValue] = useState({
    title: 'ROOF PARTY POLAROID',
    htmlTag: 'h6',
    subTitle: 'Master Cleanse Reliac Heirloom',
    subHtmlTag: 'h2',
    gridValue: 3,
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
        linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'Neptune',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
          <circle cx="6" cy="6" r="3"></circle>
<circle cx="6" cy="18" r="3"></circle>
<path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
       </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
    ],
  });

  const [sixthFeatureValue, setSixthFeatureValue] = useState({
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
       >
          <circle cx="6" cy="6" r="3"></circle>
<circle cx="6" cy="18" r="3"></circle>
<path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
       </svg>`,
        linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
        link: '',
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h2',
        description:
          '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
        button_name: 'Learn More',
        button_link: '#',
        isEdit: false,
        titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
        linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
        link: '',
      },
    ],
  });

  const [sevenFeatureValue, setSevenFeatureValue] = useState({
    gridValue: 2,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'Authentic Cliche Forage',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
                  viewBox='0 0 24 24'
               >
                  <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                  <path d='M22 4L12 14.01l-3-3'></path>
              </svg>`,
      },
      {
        id: 2,
        title: 'Kinfolk Chips Snackwave',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
        class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
        viewBox='0 0 24 24'
     >
        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
        <path d='M22 4L12 14.01l-3-3'></path>
    </svg>`,
      },
      {
        id: 3,
        title: 'Coloring Book Ethical',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
        class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
        viewBox='0 0 24 24'
     >
        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
        <path d='M22 4L12 14.01l-3-3'></path>
    </svg>`,
      },
      {
        id: 4,
        title: 'Typewriter Polaroid Cray',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
        class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
        viewBox='0 0 24 24'
     >
        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
        <path d='M22 4L12 14.01l-3-3'></path>
    </svg>`,
      },
      {
        id: 5,
        title: 'Pack Truffaut Blue',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
        class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
        viewBox='0 0 24 24'
     >
        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
        <path d='M22 4L12 14.01l-3-3'></path>
    </svg>`,
      },
      {
        id: 6,
        title: 'The Catcher In The Rye',
        htmlTag: 'h2',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
        class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
        viewBox='0 0 24 24'
     >
        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
        <path d='M22 4L12 14.01l-3-3'></path>
    </svg>`,
      },
    ],
  });

  const [eightFeatureValue, setEightFeatureValue] = useState({
    gridValue: 2,
    title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
    htmlTag: 'h2',
    description:
      '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
    button: 'Button',
    item: [
      {
        id: 1,
        title: 'SHOOTING STARS',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Second Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Third Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
        ],
      },
      {
        id: 2,
        title: 'THE 400 BLOWS',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Second Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Third Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
        ],
      },
      {
        id: 3,
        title: 'THE CATALYZER',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Second Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Third Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
        ],
      },
      {
        id: 4,
        title: 'NEPTUNE',
        htmlTag: 'h2',
        isEdit: false,
        subItem: [
          {
            subLink: 'First Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Second Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Third Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fourth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
          {
            subLink: 'Fifth Link',
            icon: `<svg
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3'
            class='w-3 h-3'
            viewBox='0 0 24 24'
          >
          <path d='M20 6L9 17l-5-5'></path>
        </svg>`,
          },
        ],
      },
    ],
  });

  const [firstStepValue, setFirstStepValue] = useState({
    item: [
      {
        id: 1,
        title: 'Step 1',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-5 h-5'
        viewBox='0 0 24 24'
      >
        <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
     </svg>`,
      },
      {
        id: 2,
        title: 'Step 2',
        htmlTag: 'h5',
        description:
          'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        isEdit: false,
        icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
      },
      {
        id: 3,
        title: 'Step 3',
        htmlTag: 'h5',
        description:
          'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        isEdit: false,
        icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
      },
      {
        id: 4,
        title: 'Step 4',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
      },
      {
        id: 5,
        title: 'FINISH',
        htmlTag: 'h5',
        description:
          'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
        isEdit: false,
        icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
           </svg>`,
      },
    ],
    image: 'https://dummyimage.com/1200x500',
    objectFit: 'fill',
  });

  const [thirdStepValue, setThirdStepValue] = useState({
    item: [
      {
        id: 1,
        title: 'Shooting Stars',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
        <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
     </svg>`,
      },
      {
        id: 2,
        title: 'The Catalyzer',
        htmlTag: 'h5',
        description:
          'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
       <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
     </svg>`,
      },
      {
        id: 3,
        title: 'The 400 Blows',
        htmlTag: 'h5',
        description:
          'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
        isEdit: false,
        icon: `  <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
       <circle cx="12" cy="5" r="3"></circle>
        <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
     </svg>`,
      },
      {
        id: 4,
        title: 'Neptune',
        htmlTag: 'h5',
        description:
          'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
        isEdit: false,
        icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
     </svg>`,
      },
    ],
  });

  const [firstTeamValue, setFirstTeamValue] = useState({
    title: 'Our Team',
    htmlTag: 'h2',
    gridValue: 3,
    description:
      'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.',
    item: [
      {
        id: 1,
        title: 'Holden Caulfield',
        htmlTag: 'h2',
        description: 'UI Designer',
        isEdit: false,
      },
      {
        id: 2,
        title: 'Henry Letham',
        htmlTag: 'h2',
        description: 'CTO',
        isEdit: false,
      },
      {
        id: 3,
        title: 'Oskar Blinde',
        htmlTag: 'h2',
        description: 'Founder',
        isEdit: false,
      },
      {
        id: 4,
        title: 'John Doe',
        htmlTag: 'h2',
        description: 'DevOps',
        isEdit: false,
      },
      {
        id: 5,
        title: 'Martin Eden',
        htmlTag: 'h2',
        description: 'Software Engineer',
        isEdit: false,
      },
      {
        id: 6,
        title: 'Boris Kitua',
        htmlTag: 'h2',
        description: 'UX Researcher',
        isEdit: false,
      },
      {
        id: 7,
        title: 'Atticus Finch',
        htmlTag: 'h2',
        description: 'QA Engineer',
        isEdit: false,
      },
      {
        id: 8,
        title: 'Alper Kamu',
        htmlTag: 'h2',
        description: 'System',
        isEdit: false,
      },
      {
        id: 9,
        title: 'Rodrigo Monchi',
        htmlTag: 'h2',
        description: 'Product Manager',
        isEdit: false,
      },
    ],
  });

  const handleImageUpload = (event, section_id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageUrl = reader.result;

      setBgImage({ url: imageUrl });

      updateSectionContent(section_id, { url: imageUrl });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleIconBoxImageUpload = (event, section_id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const imageUrl = reader.result;

      setImageBox({ url: imageUrl });

      updateSectionContent(section_id, { url: imageUrl });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageBoxImageSizeChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      imageSize: newSize,
    }));

    updateSectionContent(section_id, { imageSize: newSize });
  };

  const handleImageBoxTitleChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      title: newSize,
    }));

    updateSectionContent(section_id, { title: newSize });
  };

  const handleImageBoxHtmlTagChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      htmlTag: newSize,
    }));

    updateSectionContent(section_id, { htmlTag: newSize });
  };

  const handleImageBoxDescriptionChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      description: newSize,
    }));

    updateSectionContent(section_id, { description: newSize });
  };

  const handleImageBoxImageLinkChange = (section_id, newSize) => {
    setImageBox((prev) => ({
      ...prev,
      link: newSize,
    }));

    updateSectionContent(section_id, { link: newSize });
  };

  const handleImageSizeChange = (section_id, newSize) => {
    setBgImage((prev) => ({
      ...prev,
      imageSize: newSize,
    }));

    updateSectionContent(section_id, { imageSize: newSize });
  };

  const handleImageLinkChange = (section_id, newValue) => {
    setBgImage((prev) => ({
      ...prev,
      link: newValue,
    }));

    updateSectionContent(section_id, { link: newValue });
  };

  const handleTextEditorChange = (section_id, newValue) => {
    setTextEditorValue((prev) => ({
      ...prev,
      textEditorValue: newValue,
    }));

    updateSectionContent(section_id, { textEditorValue: newValue });
  };

  const handleFirstContentDescriptionChange = (section_id, newValue) => {
    setFirstContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSecondContentDescriptionChange = (section_id, newValue) => {
    setSecondContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleThirdContentDescriptionChange = (section_id, newValue) => {
    setThirdContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFirstTeamDescriptionChange = (section_id, newValue) => {
    setFirstTeamValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleForthContentDescriptionChange = (section_id, newValue) => {
    setForthContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleFifthContentDescriptionChange = (section_id, newValue) => {
    setFifthContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSixContentDescriptionChange = (section_id, newValue) => {
    setSixContentValue((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleSixContentContentChange = (section_id, newValue) => {
    setSixContentValue((prev) => ({
      ...prev,
      content: newValue,
    }));

    updateSectionContent(section_id, { content: newValue });
  };

  const handleVideoChange = (section_id, newValue, type) => {
    setVideoValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: newValue,
      };
      updateSectionContent(section_id, { item: updatedValue });
      return updatedValue;
    });
  };

  const handleButtonChange = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      text: newValue,
    }));

    updateSectionContent(section_id, { text: newValue });
  };

  const handleDividerStyleChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      style: newValue,
    }));

    updateSectionContent(section_id, { style: newValue });
  };

  const handleDividerWidthChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      width: newValue,
    }));

    updateSectionContent(section_id, { width: newValue });
  };

  const handleAlignmentChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      align: newValue,
    }));

    updateSectionContent(section_id, { align: newValue });
  };

  const handleAddElementChange = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      element: newValue,
    }));

    updateSectionContent(section_id, { element: newValue });
  };

  const handleAddElementValue = (section_id, newValue) => {
    setDividerValue((prev) => ({
      ...prev,
      text: newValue,
    }));

    updateSectionContent(section_id, { text: newValue });
  };

  const handleButtonLinkChange = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      link: newValue,
    }));

    updateSectionContent(section_id, { link: newValue });
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleIconSelectValue = (section_id, newValue) => {
    setButtonValue((prev) => ({
      ...prev,
      icon: newValue,
    }));

    setIsOpen(false);

    updateSectionContent(section_id, { icon: newValue });
  };

  const handleSpacerWidthChange = (section_id, newValue) => {
    setSpacerValue((prev) => ({
      ...prev,
      width: newValue,
    }));

    updateSectionContent(section_id, { width: newValue });
  };

  const handleImageIconTitleChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      title: newValue,
    }));

    updateSectionContent(section_id, { title: newValue });
  };

  const handleImageIconHtmlTagChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      htmlTag: newValue,
    }));

    updateSectionContent(section_id, { htmlTag: newValue });
  };

  const handleImageIconDescriptionChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      description: newValue,
    }));

    updateSectionContent(section_id, { description: newValue });
  };

  const handleImageIconImageLinkChange = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      link: newValue,
    }));

    updateSectionContent(section_id, { link: newValue });
  };

  const fetchCoordinates = async (address, latitude, longitude) => {
    const formattedAddress = encodeURIComponent(address);
    let url;
    const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
    if (address) {
      url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${apiKey}`;
    } else if (latitude && longitude) {
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const getAddress = data.results[0].formatted_address;
        setLocationValue({ location: getAddress || address, latitude: lat, longitude: lng });
        return { location: getAddress || address, latitude: lat, longitude: lng };
      } else {
        setLocationValue({ location: '', latitude: '', longitude: '' });
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleLocationChange = async (section_id, newValue, type) => {
    setLocationValue((prev) => ({
      ...prev,
      [type]: newValue,
    }));
    const handler = setTimeout(async () => {
      if (type === 'location' && newValue) {
        const coordinates = await fetchCoordinates(newValue, '', '');
        updateSectionContent(section_id, coordinates);
      } else if (type === 'latitude' && locationValue.longitude) {
        const coordinates = await fetchCoordinates('', newValue, locationValue.longitude);
        updateSectionContent(section_id, coordinates);
      } else if (type === 'longitude' && locationValue.latitude) {
        const coordinates = await fetchCoordinates('', locationValue.latitude, newValue);
        updateSectionContent(section_id, coordinates);
      }
    }, 3000);
    return () => {
      clearTimeout(handler);
    };
  };

  const handleIconElementorValue = (section_id, newValue) => {
    setIconValue((prev) => ({
      ...prev,
      icon: newValue,
    }));

    setIsOpen(false);

    updateSectionContent(section_id, { icon: newValue });
  };

  const handleIconLinkChange = (section_id, newValue) => {
    setIconValue((prev) => ({
      ...prev,
      link: newValue,
    }));

    updateSectionContent(section_id, { link: newValue });
  };

  const handleIconBoxImageValue = (section_id, newValue) => {
    setImageIcon((prev) => ({
      ...prev,
      icon: newValue,
    }));

    setIsOpen(false);

    updateSectionContent(section_id, { icon: newValue });
  };

  const handleIconListDataValue = (section_id, newValue) => {
    setIconListData((prev) => {
      const updatedList = [...(prev.item || []), newValue];
      return { item: updatedList };
    });
    updateSectionContent(section_id, { item: [...iconListData?.item, newValue] });
  };

  const handleSocialIconDataValue = (section_id, newValue) => {
    setsocialIconData((prev) => {
      const updatedList = [...(prev.item || []), newValue];
      return { item: updatedList };
    });
    updateSectionContent(section_id, { item: [...socialIconData?.item, newValue] });
  };

  const handleIconListDataRemoveValue = (section_id, idToRemove) => {
    setIconListData((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleSocialIconDataRemoveValue = (section_id, idToRemove) => {
    setsocialIconData((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleIconListDataCopyValue = (section_id, itemToCopy, index) => {
    const newId = iconListData?.item?.length
      ? Math.max(...iconListData.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
    };

    setIconListData((prev) => {
      const updatedList = [...(prev.item || [])];
      updatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: updatedList });
      return { item: updatedList };
    });
  };

  const handleSocialIconCopyValue = (section_id, itemToCopy, index) => {
    const currentItemIds = socialIconData?.item?.map((item) => item.id) || [];
    const newId = currentItemIds.length ? Math.max(...currentItemIds) + 1 : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      // text: `${itemToCopy.text}`,
    };

    setsocialIconData((prev) => {
      const updatedList = [...(prev.item || [])];
      updatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: updatedList });
      return { item: updatedList };
    });
  };
  const handleIconListValue = (section_id, updatedTabValue) => {
    setIconListData((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    setIsOpen(false);
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleSocialIconListValue = (section_id, updatedTabValue) => {
    setsocialIconData((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    setIsOpen(false);
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleIconListIconValue = (section_id, newIcon, tabIndex) => {
    setIconListData((prev) => {
      const updatedItems = prev?.item?.map((item, index) => {
        if (index === tabIndex) {
          return {
            ...item,
            icon: newIcon,
          };
        }
        return item;
      });

      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });

    setIsOpen(false);
  };

  const handleSocialIconValue = (section_id, newIcon, tabIndex) => {
    setsocialIconData((prev) => {
      const updatedItems = prev?.item?.map((item, index) => {
        if (index === tabIndex) {
          return {
            ...item,
            icon: newIcon,
          };
        }
        return item;
      });

      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });

    setIsOpen(false);
  };

  const handleRatingIconSelectValue = (section_id, newValue) => {
    setRatingValue((prev) => ({
      ...prev,
      icon: newValue,
    }));

    setIsOpen(false);

    updateSectionContent(section_id, { icon: newValue });
  };

  const handleCounterChange = (section_id, type, value) => {
    const numericFields = ['start', 'end', 'duration'];
    const updatedValue = numericFields.includes(type) ? parseInt(value, 10) : value;
    setNumberCounter((prev) => {
      const updatedCounter = {
        ...prev,
        [type]: updatedValue,
      };
      updateSectionContent(section_id, updatedCounter);
      return updatedCounter;
    });
  };

  const HandleTestiMonialChange = (section_id, type, value) => {
    setTestiMonialValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updatedValue);
      return updatedValue;
    });
  };

  const HandleRatingChange = (section_id, type, value) => {
    setRatingValue((prev) => {
      const updatedValue = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updatedValue);
      return updatedValue;
    });
  };

  const handleTabValue = (section_id, updatedTabValue) => {
    setTabValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleFirstContentValue = (section_id, updatedfirstContentValue) => {
    setFirstContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handlefifthFeatureValue = (section_id, updatedfirstContentValue) => {
    setFifthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleSixthFeatureValue = (section_id, updatedfirstContentValue) => {
    setSixthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedfirstContentValue,
      };
    });
    console.log(updatedfirstContentValue, 'updatedfirstContentValue=-=->>');
    updateSectionContent(section_id, updatedfirstContentValue);
  };

  const handleSecondContentValue = (section_id, updatedsecondContentValue) => {
    setSecondContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdContentValue = (section_id, updatedsecondContentValue) => {
    setThirdContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleForthContentValue = (section_id, updatedsecondContentValue) => {
    console.log(updatedsecondContentValue, 'updatedsecondContentValue=-=-=->>');
    setForthContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSevenContentValue = (section_id, updatedsecondContentValue) => {
    setSevenContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleEightContentValue = (section_id, updatedsecondContentValue) => {
    setEightContentValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstFeatureValue = (section_id, updatedsecondContentValue) => {
    setFirstFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSecondFeatureValue = (section_id, updatedsecondContentValue) => {
    setSecondFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleSevenFeatureValue = (section_id, updatedsecondContentValue) => {
    setSevenFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleEightFeatureValue = (section_id, updatedsecondContentValue) => {
    setEightFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstStepValue = (section_id, updatedsecondContentValue) => {
    setFirstStepValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdStepValue = (section_id, updatedsecondContentValue) => {
    setThirdStepValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleFirstTeamValue = (section_id, updatedsecondContentValue) => {
    setFirstTeamValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdFeatureValue = (section_id, updatedsecondContentValue) => {
    setThirdFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleThirdFeatureImageValue = (section_id, image) => {
    setThirdFeatureValue((prevState) => ({
      ...prevState,
      image: image,
    }));
    updateSectionContent(section_id, { image: image });
  };

  const handleThirdFeatureObjectFitValue = (section_id, objectFit) => {
    setThirdFeatureValue((prevState) => ({
      ...prevState,
      objectFit: objectFit,
    }));
    updateSectionContent(section_id, { objectFit: objectFit });
  };

  const handleForthFeatureValue = (section_id, updatedsecondContentValue) => {
    setForthFeatureValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedsecondContentValue,
      };
    });
    updateSectionContent(section_id, updatedsecondContentValue);
  };

  const handleAddTabsItem = (section_id, newValue) => {
    setTabValue((prev) => {
      return { item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstContentItem = (section_id, newValue) => {
    setFirstContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFifthFeatureItem = (section_id, newValue) => {
    setFifthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSixthFeatureItem = (section_id, newValue) => {
    setSixthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondContentItem = (section_id, newValue) => {
    setSecondContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdContentItem = (section_id, newValue) => {
    setThirdContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddForthContentItem = (section_id, newValue) => {
    setForthContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleSevenContentItem = (section_id, newValue) => {
    setSevenContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddEightContentItem = (section_id, newValue) => {
    setEightContentValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstFeatureItem = (section_id, newValue) => {
    setFirstFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSecondFeatureItem = (section_id, newValue) => {
    setSecondFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddSevenFeatureItem = (section_id, newValue) => {
    setSevenFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddEightFeatureItem = (section_id, newValue) => {
    setEightFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstStepItem = (section_id, newValue) => {
    setFirstStepValue((prev) => {
      return { ...prev,item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdStepItem = (section_id, newValue) => {
    setThirdStepValue((prev) => {
      return { ...prev,item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddFirstTeamItem = (section_id, newValue) => {
    setFirstTeamValue((prev) => {
      return { ...prev,item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddThirdFeatureItem = (section_id, newValue) => {
    setThirdFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleAddForthFeatureItem = (section_id, newValue) => {
    setForthFeatureValue((prev) => {
      return { ...prev, item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleCopyTabsItem = (section_id, itemToCopy, index) => {
    const newId = tabValue?.item?.length
      ? Math.max(...tabValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setTabValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { item: newUpdatedList };
    });
  };

  const handleCopyFirstContentItem = (section_id, itemToCopy, index) => {
    const newId = firstContentValue?.item?.length
      ? Math.max(...firstContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFifthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = fifthFeatureValue?.item?.length
      ? Math.max(...fifthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFifthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySixthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = sixthFeatureValue?.item?.length
      ? Math.max(...sixthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSixthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondContentItem = (section_id, itemToCopy, index) => {
    const newId = secondContentValue?.item?.length
      ? Math.max(...secondContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdContentItem = (section_id, itemToCopy, index) => {
    const newId = thirdContentValue?.item?.length
      ? Math.max(...thirdContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyForthContentItem = (section_id, itemToCopy, index) => {
    const newId = forthContentValue?.item?.length
      ? Math.max(...forthContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setForthContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySevenContentItem = (section_id, itemToCopy, index) => {
    const newId = sevenContentValue?.item?.length
      ? Math.max(...sevenContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSevenContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyEightContentItem = (section_id, itemToCopy, index) => {
    const newId = eightContentValue?.item?.length
      ? Math.max(...eightContentValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setEightContentValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySecondFeatureItem = (section_id, itemToCopy, index) => {
    const newId = secondFeatureValue?.item?.length
      ? Math.max(...secondFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSecondFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopySevenFeatureItem = (section_id, itemToCopy, index) => {
    const newId = sevenFeatureValue?.item?.length
      ? Math.max(...sevenFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setSevenFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyEightFeatureItem = (section_id, itemToCopy, index) => {
    const newId = eightFeatureValue?.item?.length
      ? Math.max(...eightFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setEightFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstStepItem = (section_id, itemToCopy, index) => {
    const newId = firstStepValue?.item?.length
      ? Math.max(...firstStepValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstStepValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev,item: newUpdatedList };
    });
  };

  const handleCopyThirdStepItem = (section_id, itemToCopy, index) => {
    const newId = thirdStepValue?.item?.length
      ? Math.max(...thirdStepValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdStepValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev,item: newUpdatedList };
    });
  };

  const handleCopyFirstTeamItem = (section_id, itemToCopy, index) => {
    const newId = firstTeamValue?.item?.length
      ? Math.max(...firstTeamValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstTeamValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return {...prev, item: newUpdatedList };
    });
  };

  const handleCopyThirdFeatureItem = (section_id, itemToCopy, index) => {
    const newId = thirdFeatureValue?.item?.length
      ? Math.max(...thirdFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setThirdFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyForthFeatureItem = (section_id, itemToCopy, index) => {
    const newId = forthFeatureValue?.item?.length
      ? Math.max(...forthFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setForthFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleCopyFirstFeatureItem = (section_id, itemToCopy, index) => {
    const newId = firstFeatureValue?.item?.length
      ? Math.max(...firstFeatureValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setFirstFeatureValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { ...prev, item: newUpdatedList };
    });
  };

  const handleTabsDataRemoveValue = (section_id, idToRemove) => {
    setTabValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleFirstContentDataRemoveValue = (section_id, idToRemove) => {
    setFirstContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFifthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setFifthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSixthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSixthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondContentDataRemoveValue = (section_id, idToRemove) => {
    setSecondContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return {
        ...prev,
        item: updatedItems,
      };
    });
  };

  const handleThirdContentDataRemoveValue = (section_id, idToRemove) => {
    setThirdContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleForthContentDataRemoveValue = (section_id, idToRemove) => {
    setForthContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSevenContentDataRemoveValue = (section_id, idToRemove) => {
    setSevenContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleEightContentDataRemoveValue = (section_id, idToRemove) => {
    setEightContentValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstFeatureDataRemoveValue = (section_id, idToRemove) => {
    setFirstFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSecondFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSecondFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleSevenFeatureDataRemoveValue = (section_id, idToRemove) => {
    setSevenFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleEightFeatureDataRemoveValue = (section_id, idToRemove) => {
    setEightFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleFirstStepDataRemoveValue = (section_id, idToRemove) => {
    setFirstStepValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev,item: updatedItems };
    });
  };

  const handleThirdStepDataRemoveValue = (section_id, idToRemove) => {
    setThirdStepValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev,item: updatedItems };
    });
  };

  const handleFirstTeamDataRemoveValue = (section_id, idToRemove) => {
    setFirstTeamValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return {...prev, item: updatedItems };
    });
  };

  const handleThirdFeatureDataRemoveValue = (section_id, idToRemove) => {
    setThirdFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleForthFeatureDataRemoveValue = (section_id, idToRemove) => {
    setForthFeatureValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { ...prev, item: updatedItems };
    });
  };

  const handleAccordionValue = (section_id, updatedTabValue) => {
    setAccordionValue((prevTabValue) => {
      return {
        ...prevTabValue,
        ...updatedTabValue,
      };
    });
    updateSectionContent(section_id, updatedTabValue);
  };

  const handleAddAccordionItem = (section_id, newValue) => {
    setAccordionValue((prev) => {
      return { item: newValue };
    });
    updateSectionContent(section_id, { item: newValue });
  };

  const handleCopyAccordionItem = (section_id, itemToCopy, index) => {
    const newId = accordionValue?.item?.length
      ? Math.max(...accordionValue.item.map((item) => item.id)) + 1
      : 1;

    const copiedItem = {
      ...itemToCopy,
      id: newId,
      text: `${itemToCopy.text}`,
      isEdit: false,
    };

    setAccordionValue((prev) => {
      const updatedList = [...(prev.item || [])];
      const newUpdatedList = updatedList.map((item) => ({
        ...item,
        isEdit: false,
      }));
      newUpdatedList.splice(index + 1, 0, copiedItem);
      updateSectionContent(section_id, { item: newUpdatedList });
      setTabIndex(null);
      return { item: newUpdatedList };
    });
  };

  const handleAccordionDataRemoveValue = (section_id, idToRemove) => {
    setAccordionValue((prev) => {
      const updatedItems = prev?.item?.filter((item) => item?.id !== idToRemove) || [];
      updateSectionContent(section_id, { item: updatedItems });
      return { item: updatedItems };
    });
  };

  const handleAlertChange = (section_id, type, value) => {
    setAlertValue((prev) => {
      const updateAlert = {
        ...prev,
        [type]: value,
      };
      updateSectionContent(section_id, updateAlert);
      return updateAlert;
    });
    setIsOpen(false);
  };

  const handleHtmlChange = (section_id, value) => {
    // setHtmlValue(value);
    updateSectionContent(section_id, { item: value });
  };
  const [pending, setPending] = useState(true);
  const [isAddSection, setIsAddSection] = useState(false);
  const closeAddSection = () => {
    setIsAddSection(false);
  };

  const params = useParams();
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data);
      const sections = result?.data?.sections || [];

      setFlexBoxValue([]);
      setSectionAdd({
        firstGrid: [],
        secondGridRow: [],
        secondGrid: [],
        thirdGrid: [],
        forthGrid: [],
        fifthGrid: [],
        sixGrid: [],
        subFirstGrid: [],
        subSecondGrid: [],
        subThirdGrid: [],
        subForthGrid: [],
        subFifthGrid: [],
        subSixGrid: [],
        subSecondSectionFirstGrid: [],
        subSecondSectionSecondGrid: [],
        subSecondSectionThirdGrid: [],
        subSecondSectionForthGrid: [],
        subSecondSectionFifthGrid: [],
        subSecondSectionSixGrid: [],
        subThirdSectionFirstGrid: [],
        subThirdSectionSecondGrid: [],
        subThirdSectionThirdGrid: [],
        subThirdSectionForthGrid: [],
        subThirdSectionFifthGrid: [],
        subThirdSectionSixGrid: [],
        subForthSectionFirstGrid: [],
        subForthSectionSecondGrid: [],
        subForthSectionThirdGrid: [],
        subForthSectionForthGrid: [],
        subForthSectionFifthGrid: [],
        subForthSectionSixGrid: [],
        subFifthSectionFirstGrid: [],
        subFifthSectionSecondGrid: [],
        subFifthSectionThirdGrid: [],
        subFifthSectionForthGrid: [],
        subFifthSectionFifthGrid: [],
        subFifthSectionSixGrid: [],
        subSixSectionFirstGrid: [],
        subSixSectionSecondGrid: [],
        subSixSectionThirdGrid: [],
        subSixSectionForthGrid: [],
        subSixSectionFifthGrid: [],
        subSixSectionSixGrid: [],
        firstContentComponment: [],
        secondContentComponment: [],
        thirdContentComponment: [],
        forthContentComponment: [],
        fifthContentComponment: [],
        sixContentComponment: [],
        sevenContentComponment: [],
        eightContentComponment: [],
        firstFeatureComponment: [],
        secondFeatureComponment: [],
        thirdFeatureComponment: [],
        forthFeatureComponment: [],
        fifthFeatureComponment: [],
        sixFeatureComponment: [],
        sevenFeatureComponment: [],
        eightFeatureComponment: [],
        firstStepComponment: [],
        secondStepComponment: [],
        thirdStepComponment: [],
        firstTeamComponment: [],
        secondTeamComponment: [],
        thirdTeamComponment: [],
        firstTestimonialComponment: [],
        secondTestimonialComponment: [],
        thirdTestimonialComponment: [],
      });
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };
        const matchedItems = sections?.filter(
          (item) =>
            item.section_type === 'flex_cols' ||
            item.section_type === 'flex_rows' ||
            item.section_type === 'flex_two_grid' ||
            item.section_type === 'static_content-1' ||
            item.section_type === 'static_content-2' ||
            item.section_type === 'static_content-3' ||
            item.section_type === 'static_content-4' ||
            item.section_type === 'static_content-5' ||
            item.section_type === 'static_content-6' ||
            item.section_type === 'static_content-7' ||
            item.section_type === 'static_content-8' ||
            item.section_type === 'static_feature-1' ||
            item.section_type === 'static_feature-2' ||
            item.section_type === 'static_feature-3' ||
            item.section_type === 'static_feature-4' ||
            item.section_type === 'static_feature-5' ||
            item.section_type === 'static_feature-6' ||
            item.section_type === 'static_feature-7' ||
            item.section_type === 'static_feature-8' ||
            item.section_type === 'static_step-1' ||
            item.section_type === 'static_step-2' ||
            item.section_type === 'static_step-3' ||
            item.section_type === 'static_team-1' ||
            item.section_type === 'flex_grid_two' ||
            item.section_type === 'flex_grid_two' ||
            item.section_type === 'flex_four_grid' ||
            item.section_type === 'flex_three_grid_three' ||
            item.section_type === 'flex_four_grid_four' ||
            item.section_type === 'flex_two_grid_one' ||
            item.section_type === 'flex_one_grid_two' ||
            item.section_type === 'flex_six_grid_six' ||
            item.section_type === 'flex_five_grid' ||
            item.section_type === 'flex_two_grid_two' ||
            item.section_type === 'grid_two_rows' ||
            item.section_type === 'grid_two_cols' ||
            item.section_type === 'grid_three_cols' ||
            item.section_type === 'grid_three_rows' ||
            item.section_type === 'grid_two_for_two' ||
            item.section_type === 'grid_three_for_three',
        );
        const matchedSectionIds = matchedItems?.map((item) => item?.section_id);
        matchedItems?.forEach((item, index) => {
          const { section_id, section_type } = item;
          const section = item?.section;
          setFlexBoxValue((prevFlexBoxValue) => {
            const filteredFlexBoxValue = prevFlexBoxValue?.filter(
              (value) => !(value?.type === section_type && value?.id === section_id),
            );
            return [...filteredFlexBoxValue, { type: section_type, id: section_id }];
          });

          for (const key in section) {
            if (!updatedSections[key]) {
              updatedSections[key] = [];
            }
            updatedSections[key][index] = section[key];
          }
        });
        return updatedSections;
      });
      setGetLayout(result?.data?.sections);
      closeAddSection();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    handleGetLayout();
  }, [params?.layoutName]);
  const [loadingSections, setLoadingSections] = useState({});

  const UpdateSectionStatus = async (e, id, value) => {
    e.stopPropagation();
    setLoadingSections((prev) => ({ ...prev, [id]: true }));
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data?.status === 200 || data?.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setLoadingSections((prev) => ({ ...prev, [id]: false }));
    }
  };

  // const handleSort = (result) => {
  //   const newSections = [...getLayout];
  //   const [removed] = newSections?.splice(result?.source?.index, 1);
  //   newSections?.splice(result?.destination?.index, 0, removed);
  //   newSections?.forEach((section, index) => {
  //     section.sort_order = index;
  //   });
  //   setGetLayout(newSections);
  //   sortSection(result?.destination?.index, result?.draggableId);
  // };

  const handleSort = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...getLayout];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      newItems?.forEach((section, index) => {
        section.sort_order = index;
      });
      setGetLayout(newItems);
      if (filterId) {
        sortSection(toIndex, filterId);
      }
      setFilterId(null);
      setHoverId(null);
    },
    nodeSelector: '.draggable-main-item',
    handleSelector: '.draggable-main-item',
    scrollSpeed: 3,
  };

  const [subSecId, setSubSecId] = useState(null);

  const handleSubItemSort = {
    async onDragEnd(fromIndex, toIndex) {
      const sectionTypes = [
        'flex_cols',
        'flex_rows',
        'flex_two_grid',
        'flex_grid_two',
        'flex_four_grid',
        'static_content-1',
        'static_content-2',
        'static_content-3',
        'flex_three_grid_three',
        'flex_four_grid_four',
        'flex_two_grid_one',
        'flex_one_grid_two',
        'flex_six_grid_six',
        'flex_five_grid',
        'flex_two_grid_two',
        'grid_two_rows',
        'grid_two_cols',
        'grid_three_cols',
        'grid_three_rows',
        'grid_two_for_two',
        'grid_three_for_three',
      ];
      const matchedItems = [];

      // Get the new layout, preserving existing structure
      const newItems = getLayout.map((elem) => {
        const filteredBlocks = Object.keys(sectionAdd).flatMap((key) => {
          const blocks =
            elem?.section?.[key]?.filter((block) => sectionTypes.includes(block.type)) || [];
          const matchingBlocks = blocks.filter((block) => block.sectionId === subSecId);
          matchedItems.push(...matchingBlocks);
          return blocks;
        });

        return {
          ...elem,
        };
      });

      if (matchedItems.length === 0) {
        console.log('No items matched for reordering');
        return newItems;
      }

      const reorderedMatchedItems = [...matchedItems];
      const [movedItem] = reorderedMatchedItems.splice(fromIndex, 1);
      reorderedMatchedItems.splice(toIndex, 0, movedItem);

      const updatedLayout = newItems.map((elem) => {
        Object.keys(sectionAdd).forEach((key) => {
          const sectionBlocks = elem.section[key];

          if (sectionBlocks) {
            elem.section[key] = sectionBlocks.map((block) => {
              if (block.sectionId === subSecId) {
                return reorderedMatchedItems.shift();
              }
              return block;
            });
          }
        });
        return elem;
      });

      const modifiedItems = updatedLayout.find((item) => item.section_id === subSecId);
      console.log('Specific Updated Item:', modifiedItems?.section?.[gridValue]);
      console.log('Updated Layout:', updatedLayout);
      setSectionAdd((prevState) => {
        const newState = { ...prevState };
        newState[gridValue][editSection?.section_index] = modifiedItems?.section?.[gridValue];
        return newState;
      });
      // Uncomment and use this API call if necessary
      // await API.post(`/admin/layout/addSection`, {
      //   layout: 'home',
      //   section:[modifiedItems],
      // });
      //  handleGetLayout()
      setSubSecId(null);
      return updatedLayout;
    },
    nodeSelector: '.draggable-subMenu-item',
    handleSelector: '.draggable-subMenu-item',
  };

  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/layout/updateSection/${id}`, payload);
      handleGetLayout();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const [announcement, setAnnouncement] = useState();
  const handleChangeAnnouncement = (value) => {
    setAnnouncement(value);
  };
  const [visualData, setVisualData] = useState({
    product: null,
    blog: null,
    category: null,
    manufacture: null,
    reassurance: null,
    cmsoffer: null,
    slider: null,
    banner: null,
  });
  const getSectionData = (value) => {
    if (value === 'call layout') {
      handleGetLayout();
      setVisualData({
        product: null,
        blog: null,
        category: null,
        manufacture: null,
        reassurance: null,
        cmsoffer: null,
        slider: null,
        banner: null,
      });
      setEditSection({ section_id: '', section_type: '' });
      setOpen(false);
    } else if (value === 'call common setting') {
      getSettingData();
    } else if (value === 'get client') {
      GetClient();
    } else {
      if (editSection?.section_type === 'product') {
        setVisualData({
          ...visualData,
          product: value,
        });
      } else if (editSection?.section_type === 'blog') {
        setVisualData({
          ...visualData,
          blog: value,
        });
      } else if (editSection?.section_type === 'category') {
        setVisualData({
          ...visualData,
          category: value,
        });
      } else if (editSection?.section_type === 'manufacture') {
        setVisualData({
          ...visualData,
          manufacture: value,
        });
      } else if (editSection?.section_type === 'reassurance') {
        setVisualData({
          ...visualData,
          reassurance: value,
        });
      } else if (editSection?.section_type === 'cmsoffer') {
        setVisualData({
          ...visualData,
          cmsoffer: value,
        });
      } else if (editSection?.section_type === 'slider') {
        setVisualData({
          ...visualData,
          slider: value,
        });
      } else if (editSection?.section_type === 'banner') {
        setVisualData({
          ...visualData,
          banner: value,
        });
      }
    }
  };

  const [manufactureDesign, setManufactureDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [categoryDesign, setCategoryDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDesign, setProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    font_weight_mrp: '',
    font_size_mrp: '',
    font_height_mrp: '',
    font_color_mrp: '',
    font_style_mrp: '',
    font_decoration_mrp: '',
    font_capitalize_mrp: '',
  });

  const [reassuranceDesign, setReassuranceDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_align_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    font_break_description: '',
    font_wrap_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [bannerDesign, setBannerDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [sliderDesign, setSliderDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cmsOfferDesign, setCmsOfferDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [blogDesign, setBlogDesign] = useState({
    // display: 'flex',
    // direction: 'column',
    // align_item: '',
    // gap: 0,
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });
  const [headingSetting, setHeadingSetting] = useState({
    title: 'Your heading content here...',
    htmlTag: '<h2>',
  });

  useEffect(() => {
    try {
      if (clientData && clientData?.home_layout_css) {
        const clientDetails = JSON.parse(clientData?.home_layout_css);

        const applyClientDetails = () => {
          setManufactureDesign(clientDetails?.manufactureDesign);
          setCategoryDesign(clientDetails?.categoryDesign);
          setProductDesign(clientDetails?.productDesign);
          setReassuranceDesign(clientDetails?.reassuranceDesign);
          setBannerDesign(clientDetails?.bannerDesign);
          setSliderDesign(clientDetails?.sliderDesign);
          setCmsOfferDesign(clientDetails?.cmsOfferDesign);
          setBlogDesign(clientDetails?.blogDesign);
        };

        applyClientDetails();
      } else {
        console.warn('clientData or home_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  const handleUpdateCss = async () => {
    const payload = {
      manufactureDesign,
      categoryDesign,
      productDesign,
      reassuranceDesign,
      bannerDesign,
      sliderDesign,
      cmsOfferDesign,
      blogDesign,
    };

    try {
      const body = encryptData({
        clientInfo: {
          home_layout_css: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Style Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  const Puller = styled('div')(() => ({
    width: 30,
    height: 6,
    backgroundColor: 'gray',
    borderRadius: 3,
    position: 'absolute',
    top: 10,
    left: 'calc(50% - 15px)',
  }));

  const [flexBoxValue, setFlexBoxValue] = useState([]);
  const [removedIndices, setRemovedIndices] = useState(new Set());

  const [sectionAdd, setSectionAdd] = useState({
    firstGrid: [],
    secondGridRow: [],
    secondGrid: [],
    thirdGrid: [],
    forthGrid: [],
    fifthGrid: [],
    sixGrid: [],
    subFirstGrid: [],
    subSecondGrid: [],
    subThirdGrid: [],
    subForthGrid: [],
    subFifthGrid: [],
    subSixGrid: [],
    subSecondSectionFirstGrid: [],
    subSecondSectionSecondGrid: [],
    subSecondSectionThirdGrid: [],
    subSecondSectionForthGrid: [],
    subSecondSectionFifthGrid: [],
    subSecondSectionSixGrid: [],
    subThirdSectionFirstGrid: [],
    subThirdSectionSecondGrid: [],
    subThirdSectionThirdGrid: [],
    subThirdSectionForthGrid: [],
    subThirdSectionFifthGrid: [],
    subThirdSectionSixGrid: [],
    subForthSectionFirstGrid: [],
    subForthSectionSecondGrid: [],
    subForthSectionThirdGrid: [],
    subForthSectionForthGrid: [],
    subForthSectionFifthGrid: [],
    subForthSectionSixGrid: [],
    subFifthSectionFirstGrid: [],
    subFifthSectionSecondGrid: [],
    subFifthSectionThirdGrid: [],
    subFifthSectionForthGrid: [],
    subFifthSectionFifthGrid: [],
    subFifthSectionSixGrid: [],
    subSixSectionFirstGrid: [],
    subSixSectionSecondGrid: [],
    subSixSectionThirdGrid: [],
    subSixSectionForthGrid: [],
    subSixSectionFifthGrid: [],
    subSixSectionSixGrid: [],
    firstContentComponment: [],
    secondContentComponment: [],
    thirdContentComponment: [],
    forthContentComponment: [],
    fifthContentComponment: [],
    sixContentComponment: [],
    sevenContentComponment: [],
    eightContentComponment: [],
    firstFeatureComponment: [],
    secondFeatureComponment: [],
    thirdFeatureComponment: [],
    forthFeatureComponment: [],
    fifthFeatureComponment: [],
    sixFeatureComponment: [],
    sevenFeatureComponment: [],
    eightFeatureComponment: [],
    firstStepComponment: [],
    secondStepComponment: [],
    thirdStepComponment: [],
    firstTeamComponment: [],
    secondTeamComponment: [],
    thirdTeamComponment: [],
    firstTestimonialComponment: [],
    secondTestimonialComponment: [],
    thirdTestimonialComponment: [],
  });

  const [previewData, setPreviewData] = useState([]);
  const generateCombinedPayloads = () => {
    const updatedPreviewData = [...previewData]; // Clone previewData to work with

    Object.keys(sectionAdd).forEach((key) => {
      const gridData = sectionAdd[key];

      if (Array.isArray(gridData) && gridData.length > 0) {
        gridData.forEach((gridItems, index) => {
          gridItems.forEach((gridItem) => {
            const sectionId = gridItem?.sectionId;
            const gridType = gridItem?.type;
            const flexBoxIndex = index;
            // Find the existing section by sectionId
            const existingSectionIndex = updatedPreviewData.findIndex(
              (previewSection) => previewSection.section_id === sectionId,
            );

            if (existingSectionIndex !== -1) {
              // If sectionId exists, replace or append data to the existing section
              const existingSection = updatedPreviewData[existingSectionIndex];
              const existingGridItems = existingSection[key] || [];

              const itemExists = existingGridItems.some((item) => item.id === gridItem.id);

              if (!itemExists) {
                // Append new grid items if they don't already exist
                updatedPreviewData[existingSectionIndex] = {
                  ...existingSection,
                  [key]: [...existingGridItems, gridItem], // Append new gridItem to existing items
                };
              } else {
                // Replace the section data with the new gridItem
                updatedPreviewData[existingSectionIndex] = {
                  section_id: sectionId,
                  gridType: gridType,
                  flexBoxIndex: flexBoxIndex,
                  [key]: [gridItem], // Replace with new gridItem
                };
              }
            } else {
              // If sectionId not found, add a new section
              updatedPreviewData.push({
                section_id: sectionId,
                gridType: gridType,
                flexBoxIndex: flexBoxIndex,
                [key]: [gridItem],
              });
            }
          });
        });
      }
    });
    updatedPreviewData.sort((a, b) => a.flexBoxIndex - b.flexBoxIndex);
    const finalPayload = {
      layout: 'home',
      section: updatedPreviewData.map((data) => {
        const sectionContent = Object.keys(data).reduce((acc, gridKey) => {
          if (Array.isArray(data[gridKey])) {
            acc[gridKey] = data[gridKey];
          }
          return acc;
        }, {});

        console.log(sectionContent, 'sectionContent=-=-=->>');

        return {
          section_type: data.gridType,
          section_id: data.section_id || '',
          flexBoxIndex: data.flexBoxIndex,
          section: sectionContent,
        };
      }),
    };

    setPreviewData(updatedPreviewData);

    console.log('Final Payload:', finalPayload);
    return finalPayload;
  };

  const saveElementorData = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const availableKeys = generateCombinedPayloads(sectionAdd, flexBoxValue, prevSecId);
      console.log(availableKeys, 'Available keys with data:');
      await API.post(`/admin/layout/addSection`, availableKeys);
      handleGetLayout();
      setPreviewData([]);
    } catch (error) {
      console.log(error);
    }
    setBtnLoading(false);
  };

  console.log(sectionAdd, 'sectionAdd=-=-=-=>>');
  console.log(flexBoxValue, 'flexBox=-=-=-=>>');

  const [activeIndex, setActiveIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [gridValue, setGridValue] = useState();
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [isFlexBoxVisible, setIsFlexBoxVisible] = useState(false);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isPlusButtonVisible, setIsPlusButtonVisible] = useState(true);
  const [isLayoutVisible, setIsLayoutVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  console.log(gridValue, 'v=-=-=->>>>>');

  console.log(flexBoxValue, 'flexBoxValue=-=-=->>>');

  useEffect(() => {
    // Check if clickedItem is not null and activeIndex is valid before executing logic
    if (clickedItem && activeIndex !== null) {
      console.log(clickedItem.item, 'clickedItem.item?.type=-=-=>>');
      handleFlexBoxClick(clickedItem.event, clickedItem.item?.type, activeIndex); //

      //  setTimeout(()=>{
      //   handleAddSection(activeIndex, clickedItem.item?.blockName);
      //  },300)
      setClickedItem(null);
    }
  }, [clickedItem, activeIndex]);

  const handleItemClick = (e, item, index, value) => {
    e.stopPropagation();

    console.log(item, 'value-=-=-=->>>>');

    setClickedItem({ event: e, item });
    setActiveIndex(index);
    setGridValue(value);
    setOpenTab('setting');
    // handleFlexBoxClick(e,item?.blockName,index,);
  };

  const handleAddSection = (index, sectionType) => {
    console.log(flexBoxValue, 'flexBoxValue=-=-=->>>');
    console.log(sectionType, 'sectionType=-=-=->>>');

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);

    let newSection;

    if (sectionType === 'heading') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'grid') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: '',
        blockName: 'grid',
        sectionId: elementorId,
      };
    } else if (sectionType === 'image') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { url: bgImageDefault, imageSize: '', link: '' },
        blockName: 'image',
        sectionId: elementorId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'textEditor') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          textEditorValue:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
        },
        blockName: 'textEditor',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'video') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      };
    } else if (sectionType === 'button') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'divider') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
        },
      };
    } else if (sectionType === 'spacer') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { width: '40' },
        blockName: 'spacer',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'googleMap') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      };
    } else if (sectionType === 'icon') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      };
    } else if (sectionType === 'imageBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          url: bgImageDefault,
          imageSize: '',
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconList') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'counter') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          number_shadow_color: '',
          number_shadow_blur: '',
          number_shadow_horizontal: '',
          number_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          counter_font_weight: '',
          counter_font_size: '',
          counter_font_height: '',
          counter_font_color: '',
          counter_font_align: '',
          counter_font_style: '',
          counter_font_decoration: '',
          counter_font_capitalize: '',
          counter_font_break: '',
          counter_font_wrap: '',
          counter_letter_spacing: '',
          counter_word_spacing: '',
          counter_text_stroke: '',
          counter_stroke_color: '',
          title_position: '',
          title_horizontal_alignment: '',
          title_vertical_alignment: '',
          number_position: '',
          title_spacing: '',
        },
      };
    } else if (sectionType === 'testimonial') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'tabs') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'accordion') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'socialIcon') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      };
    } else if (sectionType === 'alert') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      };
    } else if (sectionType === 'html') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'rating') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          icon_size: '',
          icon_spacing: '',
        },
      };
    } else if (sectionType === 'textPath') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textPath',
      };
    } else if (sectionType === 'mediaImage') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaImage',
      };
    } else if (sectionType === 'mediaVideo') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaVideo',
      };
    } else if (sectionType === 'youtube') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'youtube',
      };
    } else if (sectionType === 'formBlock') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formBlock',
      };
    } else if (sectionType === 'label') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'label',
      };
    } else if (sectionType === 'input') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'input',
      };
    } else if (sectionType === 'fileUpload') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'fileUpload',
      };
    } else if (sectionType === 'textArea') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textArea',
      };
    } else if (sectionType === 'checkBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'checkBox',
      };
    } else if (sectionType === 'radioButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'radioButton',
      };
    } else if (sectionType === 'select') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'select',
      };
    } else if (sectionType === 'formButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formButton',
      };
    }

    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      console.log(updatedSections, 'updatedSections=-=-=->>');
      console.log(gridValue, 'updatedSections=-=-=->>');

      if (!updatedSections[gridValue]) {
        updatedSections[gridValue] = [];
      }

      if (!updatedSections[gridValue][index]) {
        updatedSections[gridValue][index] = [];
      }
      const sectionArray = updatedSections[gridValue][index];
      const newSectionWithProps = {
        ...newSection,
        orderId: sectionArray?.length + 1,
      };

      if (sectionType === 'grid') {
        //one section add only
        // const existingSections = updatedSections[gridValue][index];
        // const hasHeading = existingSections?.some(section => section.blockName === 'heading');
        // if (hasHeading) {
        //   if (sectionType !== 'heading') {
        //     return prevSections;
        //   }
        // }
        const gridSectionExists = updatedSections[gridValue][index]?.some(
          (section) => section?.blockName === 'grid',
        );

        if (!gridSectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else if (
        selectedIndex === 'grid_two_rows' ||
        selectedIndex === 'grid_two_cols' ||
        selectedIndex === 'grid_three_cols' ||
        selectedIndex === 'grid_three_rows' ||
        selectedIndex === 'grid_two_for_two' ||
        selectedIndex === 'grid_three_for_three'
      ) {
        const sectionExists = updatedSections[gridValue][index]?.some(
          (section) =>
            section?.type === 'grid_two_rows' ||
            section?.type === 'grid_two_cols' ||
            section?.type === 'grid_three_cols' ||
            section?.type === 'grid_three_rows' ||
            section?.type === 'grid_two_for_two' ||
            section?.type === 'grid_three_for_three',
        );

        if (!sectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else {
        updatedSections[gridValue][index]?.push(newSectionWithProps);
      }

      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) => {
          if (item.section_id === newSectionWithProps.sectionId) {
            return {
              ...item,
              section: {
                ...item.section,
                [gridValue]: item.section?.[gridValue]
                  ? [...item.section[gridValue], newSectionWithProps]
                  : [newSectionWithProps],
              },
            };
          }
          return item;
        });

        return updatedLayout;
      });

      return updatedSections;
    });
  };

  const handleApplyChangesElementor = async (section_id, value) => {
    const payload = {
      blogDesign,
    };

    updateSectionStyle(section_id, value);

    // try {
    //   const body = encryptData({
    //     clientInfo: {
    //       home_layout_css: JSON.stringify(payload),
    //     },
    //   });
    //   await API.put('/admin/client/updateClient', body);
    //   setIsNotification(true);
    //   setNotificationMsg('Style Updated Successfully!');
    //   setSeverity('success');
    //   GetClient();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const removeSection = async (sectionIndex, sectionType) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      let gridKeys = [];
      switch (sectionType) {
        case 'flex_cols':
          gridKeys?.push('firstGrid');
          break;
        case 'flex_rows':
          gridKeys?.push('secondGridRow');
          break;
        case 'flex_two_grid':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_four_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_three_grid_three':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_four_grid_four':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_two_grid_one':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_one_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_six_grid_six':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        case 'flex_five_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid', 'fifthGrid');
          break;
        case 'flex_two_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_two_rows':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_two_cols':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_three_cols':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_three_rows':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_two_for_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_three_for_three':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        default:
          return prevSections;
      }

      gridKeys?.forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey]?.map((sections, idx) => {
            if (idx === sectionIndex) {
              return sections?.filter((section) => section?.type !== sectionType);
            }
            return sections;
          });

          if (updatedSections[gridKey]?.every((sections) => sections?.length === 0)) {
            delete updatedSections[gridKey];
          }
        }
      });
      return updatedSections;
    });

    setFlexBoxValue((prevValues) => {
      const updatedValues = prevValues.map((value, index) => {
        return index === sectionIndex ? [] : value;
      });
      const allEmpty = updatedValues.every((value) => value.length === 0);
      return allEmpty ? [] : updatedValues;
    });

    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout.filter((layoutItem) => {
        return !(
          layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex
        );
      });

      const removedItem = prevLayout.find((layoutItem) => {
        return layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex;
      });
      // if (removedItem) {
      //   setFlexBoxValue((prevValues) => {
      //     // Ensure we have a valid index to remove
      //     if (sectionIndex < prevValues.length) {
      //       const updatedValues = prevValues.filter((_, index) => index !== sectionIndex); // Remove by index
      //       return updatedValues;
      //     }
      //     return prevValues; // Return previous values if index is out of range
      //   });
      // }
      return updatedLayout;
    });
    const removedItem = getLayout.find((layoutItem) => {
      return layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex;
    });
    if (removedItem?._id) {
      try {
        const payload = encryptData({ id: [removedItem._id] });
        const deleteResponse = await API.post(`/admin/layout/deleteSection`, payload);
        if (deleteResponse.status === 200 || deleteResponse.status === 304) {
          handleGetLayout();
        }
      } catch (error) {
        console.error('Error deleting section:', error);
      }
    }

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      newSet?.add(sectionIndex);
      return newSet;
    });
  };

  const getPrevSecId = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setPrevSecId(id);
  };

  const sectionMap = {
    flex_cols: 'firstGrid',
    flex_rows: 'secondGridRow',
    flex_two_grid_firstGrid: 'firstGrid',
    flex_two_grid_secondGrid: 'secondGrid',
    flex_grid_two_firstGrid: 'firstGrid',
    flex_grid_two_secondGrid: 'secondGrid',
    flex_four_grid_firstGrid: 'firstGrid',
    flex_four_grid_secondGrid: 'secondGrid',
    flex_four_grid_thirdGrid: 'thirdGrid',
    flex_four_grid_forthGrid: 'forthGrid',
    flex_three_grid_three_firstGrid: 'firstGrid',
    flex_three_grid_three_secondGrid: 'secondGrid',
    flex_three_grid_three_thirdGrid: 'thirdGrid',
    flex_four_grid_four_firstGrid: 'firstGrid',
    flex_four_grid_four_secondGrid: 'secondGrid',
    flex_four_grid_four_thirdGrid: 'thirdGrid',
    flex_four_grid_four_forthGrid: 'forthGrid',
    flex_two_grid_one_firstGrid: 'firstGrid',
    flex_two_grid_one_secondGrid: 'secondGrid',
    flex_two_grid_one_thirdGrid: 'thirdGrid',
    flex_one_grid_two_firstGrid: 'firstGrid',
    flex_one_grid_two_secondGrid: 'secondGrid',
    flex_one_grid_two_thirdGrid: 'thirdGrid',
    flex_six_grid_six_firstGrid: 'firstGrid',
    flex_six_grid_six_secondGrid: 'secondGrid',
    flex_six_grid_six_thirdGrid: 'thirdGrid',
    flex_six_grid_six_forthGrid: 'forthGrid',
    flex_six_grid_six_fifthGrid: 'fifthGrid',
    flex_six_grid_six_sixGrid: 'sixGrid',
    flex_five_grid_firstGrid: 'firstGrid',
    flex_five_grid_secondGrid: 'secondGrid',
    flex_five_grid_thirdGrid: 'thirdGrid',
    flex_five_grid_forthGrid: 'forthGrid',
    flex_five_grid_fifthGrid: 'fifthGrid',
    flex_two_grid_two_firstGrid: 'firstGrid',
    flex_two_grid_two_secondGrid: 'secondGrid',
    flex_two_grid_two_thirdGrid: 'thirdGrid',
    flex_two_grid_two_forthGrid: 'forthGrid',
    subFirstGrid: 'subFirstGrid',
    subSecondGrid: 'subSecondGrid',
    subThirdGrid: 'subThirdGrid',
    subForthGrid: 'subForthGrid',
    subFifthGrid: 'subFifthGrid',
    subSixGrid: 'subSixGrid',
    subSecondSectionFirstGrid: 'subSecondSectionFirstGrid',
    subSecondSectionSecondGrid: 'subSecondSectionSecondGrid',
    subSecondSectionThirdGrid: 'subSecondSectionThirdGrid',
    subSecondSectionForthGrid: 'subSecondSectionForthGrid',
    subSecondSectionFifthGrid: 'subSecondSectionFifthGrid',
    subSecondSectionSixGrid: 'subSecondSectionSixGrid',
    subThirdSectionFirstGrid: 'subThirdSectionFirstGrid',
    subThirdSectionSecondGrid: 'subThirdSectionSecondGrid',
    subThirdSectionThirdGrid: 'subThirdSectionThirdGrid',
    subThirdSectionForthGrid: 'subThirdSectionForthGrid',
    subThirdSectionFifthGrid: 'subThirdSectionFifthGrid',
    subThirdSectionSixGrid: 'subThirdSectionSixGrid',
    subForthSectionFirstGrid: 'subForthSectionFirstGrid',
    subForthSectionSecondGrid: 'subForthSectionSecondGrid',
    subForthSectionThirdGrid: 'subForthSectionThirdGrid',
    subForthSectionForthGrid: 'subForthSectionForthGrid',
    subForthSectionFifthGrid: 'subForthSectionFifthGrid',
    subForthSectionSixGrid: 'subForthSectionSixGrid',
    subFifthSectionFirstGrid: 'subFifthSectionFirstGrid',
    subFifthSectionSecondGrid: 'subFifthSectionSecondGrid',
    subFifthSectionThirdGrid: 'subFifthSectionThirdGrid',
    subFifthSectionForthGrid: 'subFifthSectionForthGrid',
    subFifthSectionFifthGrid: 'subFifthSectionFifthGrid',
    subFifthSectionSixGrid: 'subFifthSectionSixGrid',
    subSixSectionFirstGrid: 'subSixSectionFirstGrid',
    subSixSectionSecondGrid: 'subSixSectionSecondGrid',
    subSixSectionThirdGrid: 'subSixSectionThirdGrid',
    subSixSectionForthGrid: 'subSixSectionForthGrid',
    subSixSectionFifthGrid: 'subSixSectionFifthGrid',
    subSixSectionSixGrid: 'subSixSectionSixGrid',
    grid_two_rows_firstGrid: 'firstGrid',
    grid_two_rows_secondGrid: 'secondGrid',
    grid_two_cols_firstGrid: 'firstGrid',
    grid_two_cols_secondGrid: 'secondGrid',
    grid_three_cols_firstGrid: 'firstGrid',
    grid_three_cols_secondGrid: 'secondGrid',
    grid_three_cols_thirdGrid: 'thirdGrid',
    grid_three_rows_firstGrid: 'firstGrid',
    grid_three_rows_secondGrid: 'secondGrid',
    grid_three_rows_thirdGrid: 'thirdGrid',
    grid_two_for_two_firstGrid: 'firstGrid',
    grid_two_for_two_secondGrid: 'secondGrid',
    grid_two_for_two_thirdGrid: 'thirdGrid',
    grid_two_for_two_forthGrid: 'forthGrid',
    grid_three_for_three_firstGrid: 'firstGrid',
    grid_three_for_three_secondGrid: 'secondGrid',
    grid_three_for_three_thirdGrid: 'thirdGrid',
    grid_three_for_three_forthGrid: 'forthGrid',
    grid_three_for_three_fifthGrid: 'fifthGrid',
    grid_three_for_three_sixGrid: 'sixGrid',
  };

  const handleRemoveSingleItem = (item) => {
    const { obj, sectionArray } = item;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    console.log('sectionKey', sectionKey);
    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey].map((innerArray) =>
        innerArray.filter((item) => item.id !== obj?.id),
      );
      const isAllEmpty = updatedArray.every((innerArray) => innerArray.length === 0);
      return {
        ...prevState,
        [sectionKey]: isAllEmpty ? [] : updatedArray,
      };
    });
    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout.map((layoutItem) => {
        if (layoutItem.section_id === obj.sectionId) {
          return {
            ...layoutItem,
            section: {
              ...layoutItem.section,
              [sectionKey]: layoutItem.section[sectionKey]
                ? layoutItem.section[sectionKey].filter((sectionItem) => sectionItem.id !== obj?.id)
                : [],
            },
          };
        }
        return layoutItem;
      });
      return updatedLayout;
    });
  };

  const handleDuplicateSingleItem = (item) => {
    const { obj, sectionArray, itemIndex } = item;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);
      const randomValue = Math.random().toString(16).substr(2, 10);
      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    const copiedItem = { ...obj, id: id };
    const gridTypes = [
      'grid_two_rows',
      'grid_two_cols',
      'grid_three_cols',
      'grid_three_rows',
      'grid_two_for_two',
      'grid_three_for_three',
    ];

    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey]?.map((innerArray) => [...innerArray]);

      const targetArray =
        updatedArray?.find((innerArray) =>
          innerArray?.some((innerItem) => innerItem?.id === obj?.id),
        ) || [];

      const gridTypeExists = targetArray?.some((existingItem) =>
        gridTypes?.includes(existingItem?.type),
      );

      if (gridTypeExists) {
        return prevState;
      }

      if (itemIndex + 1 <= targetArray?.length) {
        targetArray?.splice(itemIndex + 1, 0, copiedItem);
      } else {
        targetArray?.push(copiedItem);
      }
      return {
        ...prevState,
        [sectionKey]: updatedArray,
      };
    });
    setGetLayout((prevLayout) =>
      prevLayout.map((layoutItem) => {
        if (layoutItem?.section_id === copiedItem?.sectionId) {
          const existingItems = layoutItem?.section[sectionKey] || [];
          const gridTypeExists = existingItems?.some((existingItem) =>
            gridTypes?.includes(existingItem?.type),
          );

          if (gridTypeExists) {
            return layoutItem;
          }

          const updatedItems = [...existingItems];
          const originalIndex = updatedItems?.findIndex(
            (existingItem) => existingItem?.id === obj?.id,
          );
          if (originalIndex !== -1) {
            updatedItems?.splice(originalIndex + 1, 0, copiedItem);
          } else {
            updatedItems?.push(copiedItem);
          }
          return {
            ...layoutItem,
            section: {
              ...layoutItem?.section,
              [sectionKey]: updatedItems,
            },
          };
        }
        return layoutItem;
      }),
    );
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleCopySingleItem = debounce((item) => {
    const { selectedSingleItem, type } = item;
    console.log('selectedSingleItem', type);
    const { obj } = selectedSingleItem;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    const newSectionType = selectContextItem?.copy_type;

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);
      const randomValue = Math.random().toString(16).substr(2, 10);
      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    const newItem = {
      ...obj,
      id: id,
      type: newSectionType,
      sectionId: selectContextItem?.section_id,
    };

    const gridTypes = [
      'grid_two_rows',
      'grid_two_cols',
      'grid_three_cols',
      'grid_three_rows',
      'grid_two_for_two',
      'grid_three_for_three',
    ];

    if (type === 'copy' || type === 'cut') {
      setSectionAdd((prevState) => {
        const updatedGrid = [...prevState[sectionKey]];
        for (let i = 0; i <= selectContextItem?.section_index; i++) {
          if (!updatedGrid[i]) {
            updatedGrid[i] = [];
          }
        }
        const sectionArray = updatedGrid[selectContextItem?.section_index] || [];

        const sectionExists = sectionArray?.some((existingItem) =>
          gridTypes?.includes(existingItem?.type),
        );

        if (sectionExists) {
          return prevState;
        }

        sectionArray?.push(newItem);
        updatedGrid[selectContextItem?.section_index] = sectionArray;
        return {
          ...prevState,
          [sectionKey]: updatedGrid,
        };
      });
      setGetLayout((prevLayout) =>
        prevLayout?.map((layoutItem) => {
          if (layoutItem?.section_id === newItem?.sectionId) {
            const existingItems = layoutItem?.section[sectionKey] || [];

            const gridTypeExists = existingItems?.some((existingItem) =>
              gridTypes?.includes(existingItem?.type),
            );
            if (gridTypeExists) {
              return layoutItem;
            }

            const itemExists = existingItems?.some(
              (existingItem) => existingItem?.id === newItem?.id,
            );
            if (!itemExists) {
              return {
                ...layoutItem,
                section: {
                  ...layoutItem.section,
                  [sectionKey]: [...existingItems, newItem],
                },
              };
            }
          }
          return layoutItem;
        }),
      );
    }
  }, 300);
  console.log('selectContextItem', selectContextItem);
  const handleCutSingleItem = (item) => {
    const { selectedSingleItem } = item;
    const { obj } = selectedSingleItem;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    console.log('sectionKeysectionKey', selectContextItem?.section_type, sectionKey, obj);
    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey]?.map((innerArray) =>
        innerArray?.filter((item) => item?.id !== obj?.id),
      );
      const isAllEmpty = updatedArray?.every((innerArray) => innerArray?.length === 0);
      return {
        ...prevState,
        [sectionKey]: isAllEmpty ? [] : updatedArray,
      };
    });

    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout?.map((layoutItem) => {
        if (layoutItem?.section_id === obj?.sectionId) {
          return {
            ...layoutItem,
            section: {
              ...layoutItem.section,
              [sectionKey]: layoutItem?.section[sectionKey]
                ? layoutItem?.section[sectionKey]?.filter(
                    (sectionItem) => sectionItem.id !== obj?.id,
                  )
                : [],
            },
          };
        }
        return layoutItem;
      });
      return updatedLayout;
    });
  };

  const [activeHeading, setActiveHeading] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const handleHeadingClick = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleHeadingClickLocal = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    handleHeadingClick(e, sectionIndex, itemIndex, sectionIndex);
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  const handleFlexBoxClick = (e, sectionType) => {
    console.log(sectionType, 'sectionType=-=-=>>');
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const flexId = generateCustomObjectId(24);
    console.log(sectionType, 'value=-=-=-=>>>');
    // console.log(flexId, 'value=-=-=-=>>>');
    e.stopPropagation();
    const newSections = [...flexBoxValue, { type: sectionType, id: flexId }];
    const index = newSections?.length - 1;
    console.log(newSections, 'newSections=-=-=-=>>>');
    setFlexBoxValue(newSections);
    setElementorId(flexId);
    const updatedGetLayout = [
      ...getLayout,
      {
        section_id: flexId,
        section_type: sectionType,
        status: true,
        sort_order: getLayout.length,
        section: { [gridValue]: [] },
        flexBoxIndex: newSections?.length - 1,
      },
    ];
    setGetLayout(updatedGetLayout);

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      return newSet;
    });

    setEditSection({
      section_id: flexId,
      section_type: sectionType,
    });

    setOpenTab('setting');
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
    setIsPlusButtonVisible(true);

    setActiveIndex(newSections?.length - 1);
    setActiveHeadingIndex({ sectionIndex: null, itemIndex: null });

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    let newSection;
    if (sectionType === 'static_content-1') {
      newSection = {
        id: id,
        type: 'static_content-1',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          button: 'Button',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.<p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'content-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-2') {
      newSection = {
        id: id,
        type: 'static_content-2',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Chichen Itza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 2,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Colosseum Roma',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 3,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Great Pyramid of Giza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 4,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'San Francisco',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-3') {
      newSection = {
        id: id,
        type: 'static_content-3',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Button',
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                      >
                        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                    </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                     >
                        <circle cx="6" cy="6" r="3"></circle>
              <circle cx="6" cy="18" r="3"></circle>
              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                     </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              link: '',
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                         stroke-linejoin='round'
                         stroke-width='2'
                        class='w-6 h-6'
                         viewBox='0 0 24 24'
                     >
                            <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                      </svg>`,
              link: '',
            },
            {
              id: 5,
              title: 'Bunker',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                            </svg>`,
              link: '',
            },
            {
              id: 6,
              title: 'Ramona Falls',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'content-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-4') {
      newSection = {
        id: id,
        type: 'static_content-4',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Learn more',
          description:
            '<p>Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.</p>',
          sub_title: 'CATEGORIES',
          sub_title_htmlTag: 'h2',
          item: [
            {
              id: 1,
              link: 'First link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 2,
              link: 'Second link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 3,
              link: 'Third link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 4,
              link: 'Forth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 5,
              link: 'Fifth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 6,
              link: 'Sixth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 7,
              link: 'Seventh link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 8,
              link: 'Eighth link',
              link_url: '#',
              isEdit: false,
            },
          ],
        },
        blockName: 'content-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-5') {
      newSection = {
        id: id,
        type: 'static_content-5',
        sectionId: flexId,
        content: {
          title: 'Kickstarter Actually Pinterest Brunch Bitters Occupy',
          htmlTag: 'h1',
          button: 'Button',
          description:
            '<p>Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos.</p>',
          button_link: 'Learn more',
          icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
              viewBox='0 0 24 24'
          >
           <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
          link: '',
        },
        blockName: 'content-5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-6') {
      newSection = {
        id: id,
        type: 'static_content-6',
        sectionId: flexId,
        content: {
          title: 'Phoebe Caulfield',
          htmlTag: 'h1',
          description:
            '<p>Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>',
          content:
            '<p>Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90 scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>',
          button_link: 'Learn more',
          link_url: '#',
          image: 'https://dummyimage.com/1200x500',
          profile: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-10 h-10'
                      viewBox='0 0 24 24'
                      >
                        <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
                      <circle cx='12' cy='7' r='4'></circle>
                    </svg>`,
          objectFit: 'fill',
        },
        blockName: 'content-6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          content_font_weight: '',
          content_font_size: '',
          content_font_height: '',
          content_font_color: '#6B7280',
          content_font_align: '',
          content_font_style: '',
          content_font_decoration: '',
          content_font_capitalize: '',
          content_font_break: '',
          content_font_wrap: '',
          content_letter_spacing: '',
          content_word_spacing: '',
          content_text_stroke: '',
          content_stroke_color: '',
          content_text_shadow_color: '',
          content_text_shadow_blur: '',
          content_text_shadow_horizontal: '',
          content_text_shadow_vertical: '',
          content_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-7') {
      newSection = {
        id: id,
        type: 'static_content-7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          item: [
            {
              id: 1,
              title: 'Buy YouTube Videos',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content-7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-8') {
      newSection = {
        id: id,
        type: 'static_content-8',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Space The Final Frontier',
          htmlTag: 'h2',
          description:
            '<p>Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content-8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-1') {
      newSection = {
        id: id,
        type: 'static_feature-1',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                      <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                  </svg>`,
              linkIcon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                viewBox='0 0 24 24'
                >
                <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'feature-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-2') {
      newSection = {
        id: id,
        type: 'static_feature-2',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
          fill='none'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          class='w-4 h-4 ml-2'
        viewBox='0 0 24 24'
        >
        <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'feature-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-3') {
      newSection = {
        id: id,
        type: 'static_feature-3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
              linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
              linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
              link: '',
            },
          ],
          image: 'https://dummyimage.com/460x500',
          objectFit: 'fill',
        },
        blockName: 'feature-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-4') {
      newSection = {
        id: id,
        type: 'static_feature-4',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'feature-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-5') {
      newSection = {
        id: id,
        type: 'static_feature-5',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          gridValue: 3,
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
          ],
        },
        blockName: 'feature-5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-6') {
      newSection = {
        id: id,
        type: 'static_feature-6',
        sectionId: flexId,
        content: {
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
              linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
              link: '',
            },
          ],
        },
        blockName: 'feature-6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-7') {
      newSection = {
        id: id,
        type: 'static_feature-7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Authentic Cliche Forage',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='3'
                            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
                            viewBox='0 0 24 24'
                          >
                            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                            <path d='M22 4L12 14.01l-3-3'></path>
                          </svg>`,
            },
            {
              id: 2,
              title: 'Kinfolk Chips Snackwave',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
            },
            {
              id: 3,
              title: 'Coloring Book Ethical',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
            },
            {
              id: 4,
              title: 'Typewriter Polaroid Cray',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
            },
            {
              id: 5,
              title: 'Pack Truffaut Blue',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
            },
            {
              id: 6,
              title: 'The Catcher In The Rye',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
            },
          ],
        },
        blockName: 'feature-7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-8') {
      newSection = {
        id: id,
        type: 'static_feature-8',
        sectionId: flexId,
        content: {
          gridValue: 2,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'SHOOTING STARS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
              ],
            },
            {
              id: 2,
              title: 'THE 400 BLOWS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
              ],
            },
            {
              id: 3,
              title: 'THE CATALYZER',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
              ],
            },
            {
              id: 4,
              title: 'NEPTUNE',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                <path d='M20 6L9 17l-5-5'></path>
              </svg>`,
                },
              ],
            },
          ],
        },
        blockName: 'feature-8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-1') {
      newSection = {
        id: id,
        type: 'static_step-1',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Step 1',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
            },
            {
              id: 2,
              title: 'Step 2',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
            },
            {
              id: 3,
              title: 'Step 3',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
            },
            {
              id: 4,
              title: 'Step 4',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
            },
            {
              id: 5,
              title: 'FINISH',
              htmlTag: 'h5',
              description:
                'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
           </svg>`,
            },
          ],
          image: 'https://dummyimage.com/1200x500',
          objectFit: 'fill',
        },
        blockName: 'step-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-2') {
      newSection = {
        id: id,
        type: 'static_step-2',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Step 1',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
            },
            {
              id: 2,
              title: 'Step 2',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
            },
            {
              id: 3,
              title: 'Step 3',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
            },
            {
              id: 4,
              title: 'Step 4',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
            },
            {
              id: 5,
              title: 'FINISH',
              htmlTag: 'h5',
              description:
                'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
              isEdit: false,
            },
          ],
        },
        blockName: 'step-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-3') {
      newSection = {
        id: id,
        type: 'static_step-3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
            },
            {
              id: 4,
              title: 'Neptune',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
            },
          ],
        },
        blockName: 'step-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_team-1') {
      newSection = {
        id: id,
        type: 'static_team-1',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 3,
          description:
            'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              description: 'UI Designer',
              isEdit: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              description: 'CTO',
              isEdit: false,
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              description: 'Founder',
              isEdit: false,
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              description: 'DevOps',
              isEdit: false,
            },
            {
              id: 5,
              title: 'Martin Eden',
              htmlTag: 'h2',
              description: 'Software Engineer',
              isEdit: false,
            },
            {
              id: 6,
              title: 'Boris Kitua',
              htmlTag: 'h2',
              description: 'UX Researcher',
              isEdit: false,
            },
            {
              id: 7,
              title: 'Atticus Finch',
              htmlTag: 'h2',
              description: 'QA Engineer',
              isEdit: false,
            },
            {
              id: 8,
              title: 'Alper Kamu',
              htmlTag: 'h2',
              description: 'System',
              isEdit: false,
            },
            {
              id: 9,
              title: 'Rodrigo Monchi',
              htmlTag: 'h2',
              description: 'Product Manager',
              isEdit: false,
            },
          ],
        },
        blockName: 'team-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    }

    if (sectionType === 'static_content-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-1',
      });
    } else if (sectionType === 'static_content-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-2',
      });
    } else if (sectionType === 'static_content-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-3',
      });
    } else if (sectionType === 'static_content-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-4',
      });
    } else if (sectionType === 'static_content-5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-5',
      });
    } else if (sectionType === 'static_content-6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-6',
      });
    } else if (sectionType === 'static_content-7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-7',
      });
    } else if (sectionType === 'static_content-8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-8',
      });
    } else if (sectionType === 'static_feature-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-1',
      });
    } else if (sectionType === 'static_feature-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-2',
      });
    } else if (sectionType === 'static_feature-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-3',
      });
    } else if (sectionType === 'static_feature-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-4',
      });
    } else if (sectionType === 'static_feature-5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-5',
      });
    } else if (sectionType === 'static_feature-6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-6',
      });
    } else if (sectionType === 'static_feature-7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-7',
      });
    } else if (sectionType === 'static_feature-8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-8',
      });
    } else if (sectionType === 'static_step-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-1',
      });
    } else if (sectionType === 'static_step-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-2',
      });
    } else if (sectionType === 'static_step-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-3',
      });
    } else if (sectionType === 'static_team-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team-1',
      });
    }
  };

  const openAddSection = (e, value, item) => {
    console.log(value, 'value=-=-=->>>123');
    console.log(item, 'value=-=-=->>>123');
    e.stopPropagation();
    setGridValue(value);
    setOpenTab(item);
  };

  const handleSectionClick = (e, sectionIndex, itemIndex) => {
    e.stopPropagation();
    setActiveIndex(sectionIndex);
    setSelectedIndex(itemIndex);
  };

  const [dragItems, setDragItems] = useState(styleSection);

  const createNewSection = (item, selectedIndex) => {
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);

    const commonProps = {
      id: id,
      type: selectedIndex,
    };

    const sectionMap = {
      heading: {
        ...commonProps,
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      grid: { ...commonProps, content: '', blockName: 'grid' },
      image: {
        ...commonProps,
        content: { url: bgImageDefault, imageSize: '', link: '' },
        blockName: 'image',
        sectionId: elementorId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      textEditor: {
        ...commonProps,
        content: {
          textEditorValue:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
        },
        blockName: 'textEditor',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      video: {
        ...commonProps,
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      },
      button: {
        ...commonProps,
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      },
      divider: {
        ...commonProps,
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
        },
      },
      spacer: {
        ...commonProps,
        content: { width: '40' },
        blockName: 'spacer',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      googleMap: {
        ...commonProps,
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      },
      icon: {
        ...commonProps,
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      },
      imageBox: {
        ...commonProps,
        content: {
          url: bgImageDefault,
          imageSize: '',
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      },
      generalIconBox: {
        ...commonProps,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      },
      generalIconList: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
      },
      counter: {
        ...commonProps,
        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      },
      testimonial: {
        ...commonProps,
        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      },
      tabs: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      },
      accordion: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      },
      socialIcon: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      },
      alert: {
        ...commonProps,
        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      },
      html: {
        ...commonProps,
        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      },
      rating: {
        ...commonProps,
        blockName: 'rating',
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          icon_size: '',
          icon_spacing: '',
        },
      },
      textPath: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'textPath',
      },
      mediaImage: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'mediaImage',
      },
      mediaVideo: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'mediaVideo',
      },
      youtube: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'youtube',
      },
      formBlock: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'formBlock',
      },
      label: { ...commonProps, content: 'Lorem Ipsum is simply dummy text...', blockName: 'label' },
      input: { ...commonProps, content: 'Lorem Ipsum is simply dummy text...', blockName: 'input' },
      fileUpload: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'fileUpload',
      },
      textArea: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'textArea',
      },
      checkBox: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'checkBox',
      },
      radioButton: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'radioButton',
      },
      select: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'select',
      },
      formButton: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'formButton',
      },
    };

    return sectionMap[item.id] || null;
  };
  const dropContainerRef = useRef(null);

  const [destinationId, setDestinationId] = useState(null);
  const [gridDestinationId, setGridDestinationId] = useState(null);
  const [dropPosition, setDropPosition] = useState(null);

  const dragProps = {
    onDragEnd(sourceIndex, destinationIndex) {
      setDestinationId(null);
      setGridDestinationId(null);
      const getListToReorder = (sectionKey) => {
        const section = sectionAdd[sectionKey];
        return Array.isArray(section) ? section : Object.values(section || {});
      };
      if (
        !editSection ||
        editSection.section_index === undefined ||
        editSection.section_index < 0
      ) {
        console.error('Invalid editSection or section_index');
        return;
      }

      const sectionKey = sectionMap[editSection?.section_type];

      if (!sectionKey) {
        console.error('Unknown section_type:', editSection?.section_type);
        return;
      }

      const listToReorder = getListToReorder(sectionKey);

      if (!Array.isArray(listToReorder) || listToReorder.length <= editSection.section_index) {
        console.error('Invalid listToReorder or section_index out of bounds');
        return;
      }

      // Reordering logic
      const reorderedList = [...listToReorder[editSection?.section_index]];

      if (
        sourceIndex < 0 ||
        sourceIndex >= reorderedList.length ||
        destinationIndex < 0 ||
        destinationIndex > reorderedList.length
      ) {
        console.error('Invalid sourceIndex or destinationIndex');
        return;
      }

      const [removedItem] = reorderedList.splice(sourceIndex, 1);
      reorderedList.splice(destinationIndex, 0, removedItem);

      setSectionAdd((prevState) => {
        const newState = { ...prevState };
        newState[sectionKey][editSection?.section_index] = reorderedList;
        return newState;
      });
      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) => {
          if (item.section_id === reorderedList[0]?.sectionId) {
            return {
              ...item,
              section: {
                ...item.section,
                [sectionKey]: reorderedList,
              },
            };
          }
          return item;
        });
        return updatedLayout;
      });
    },
    nodeSelector: '.draggable-item',
    handleSelector: 'div',
    scrollSpeed: 5,
  };
  console.log('editSection', editSection);

  const handleDrop = (e) => {
    e.preventDefault();

    const itemData = e.dataTransfer.getData('basic-item');
    if (!itemData) {
      console.error('No data found for basic-item');
      return;
    }
    try {
      const item = JSON.parse(itemData);
      const newSection = createNewSection(item, selectedIndex);
      const destinationIndex = destinationId ? destinationId : gridDestinationId;
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][editSection?.section_index]) {
          updatedSections[gridValue][editSection?.section_index] = [];
        }
        const sectionArray = updatedSections[gridValue][editSection?.section_index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
          sectionId: elementorId,
          // style: 'style',
        };
        const itemExists = sectionArray?.some((section) => section?.id === newSection?.id);
        if (itemExists) {
          console.warn('Item already exists:', newSection);
          return prevSections;
        }
        if (item.blockName === 'grid') {
          const gridSectionExists = updatedSections[gridValue][editSection?.section_index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][editSection?.section_index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][editSection?.section_index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            if (dropPosition === 'below') {
              updatedSections[gridValue][editSection?.section_index]?.splice(
                destinationIndex + 1,
                0,
                newSectionWithProps,
              );
            } else {
              updatedSections[gridValue][editSection?.section_index]?.splice(
                destinationIndex,
                0,
                newSectionWithProps,
              );
            }
          }
        } else {
          if (dropPosition === 'below') {
            updatedSections[gridValue][editSection?.section_index]?.splice(
              destinationIndex + 1,
              0,
              newSectionWithProps,
            );
          } else {
            updatedSections[gridValue][editSection?.section_index]?.splice(
              destinationIndex,
              0,
              newSectionWithProps,
            );
          }
        }
        setDestinationId(null);
        setGridDestinationId(null);
        // updatedSections[gridValue][editSection?.section_index].sort((a, b) => a.orderId - b.orderId);
        return updatedSections;
      });

      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((layoutItem) => {
          if (layoutItem.section_id === elementorId) {
            const updatedSection = { ...layoutItem.section };
            if (!updatedSection[gridValue]) {
              updatedSection[gridValue] = [];
            }
            const exists = updatedSection[gridValue].some(
              (existingItem) => existingItem.id === newSection.id,
            );
            if (!exists) {
              updatedSection[gridValue].splice(destinationIndex, 0, {
                ...newSection,
                orderId: updatedSection[gridValue].length + 1,
                sectionId: layoutItem.section_id,
              });
            } else {
              console.warn('Item already exists:', newSection);
            }
            return {
              ...layoutItem,
              section: updatedSection,
            };
          }
          return layoutItem;
        });
        return updatedLayout;
      });
    } catch (error) {
      console.error('Invalid JSON data:', error);
      return;
    }
  };

  return (
    <>
      <div className='overflow-hidden relative h-[92.5%]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div
          className='relative live-editor w-full flex items-start h-full'
          ref={dropContainerRef}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {windowWidth > 768 && windowWidth < 1280 && editSection?.section_type ? (
            <div className='hidden  edit-style-section md:block xl:hidden md:w-[30%] xl:w-[16%] overflow-y-auto p-3 secondary-bg-color h-full'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-medium text-primary text-base capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <EditHeadingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHeadingDesign={setHeadingDesign}
                      headingDesign={headingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={headingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <EditImageStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageDesign={setImageDesign}
                      imageDesign={imageDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <EditTextEditorStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTextEditorDesign={setTextEditorDesign}
                      textEditorDesign={textEditorDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={textEditorDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <EditVideoStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setVideoDesign={setVideoDesign}
                      videoDesign={videoDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={videoDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <EditButtonStyleSection
                      setButtonDesign={setButtonDesign}
                      buttonDesign={buttonDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={buttonDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <EditDividerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setDividerDesign={setDividerDesign}
                      dividerDesign={dividerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={dividerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <EditSpacerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSpacerDesign={setSpacerDesign}
                      spacerDesign={spacerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={spacerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <EditTestiMonialStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTestiMonialDesign={setTestiMonialDesign}
                      testiMonialDesign={testiMonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={testiMonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <EditSocialIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSocialIconDesign={setSocialIconDesign}
                      socialIconDesign={socialIconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={socialIconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <EditAlertStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAlertDesign={setAlertDesign}
                      alertDesign={alertDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={alertDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <EditHtmlStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHtmlDesign={setHtmlDesign}
                      htmlDesign={htmlDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={htmlDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <EditImageBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageBoxDesign={setImageBoxDesign}
                      imageBoxDesign={imageBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <EditIconBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconBoxDesign={setIconBoxDesign}
                      iconBoxDesign={iconBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <EditIconListStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconListDesign={setIconListDesign}
                      iconListDesign={iconListDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconListDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <EditMapStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setMapDesign={setMapDesign}
                      mapDesign={mapDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={mapDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <EditIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconDesign={setIconDesign}
                      iconDesign={iconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <EditCounterStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setCounterDesign={setCounterDesign}
                      counterDesign={counterDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={counterDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <EditTabStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTabDesign={setTabDesign}
                      tabDesign={tabDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={tabDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <EditAccordianStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAccordianDesign={setAccordianDesign}
                      accordianDesign={accordianDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={accordianDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <EditRatingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setRatingDesign={setRatingDesign}
                      ratingDesign={ratingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={ratingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <EditFirstContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstContentDesign={setFirstContentDesign}
                      firstContentDesign={firstContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' ? (
                //  && editSection?.section_type
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'>
                      {' '}
                      cvbgdsfx fghdcf vfdx fdsx fgdfrg sxd gfs gsfgs sgsdg sdggs fs sf
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}{' '}
              :
            </div>
          ) : (
            <div className='hidden md:block md:w-[30%] xl:w-[16%] overflow-y-auto py-3 secondary-bg-color h-full'>
              {pending && !getLayout ? (
                <div className='w-full px-3 h-[800px]'>
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <hr className='my-4 main-border-color' />
                  <div className='grid grid-cols-1 space-y-2'>
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                  </div>
                  <hr className='my-4 main-border-color' />
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              ) : (
                <div className='theme-setting'>
                  <p className='font-medium text-primary text-base px-3'>{t('lang_Home_Page')}</p>
                  <hr className='my-3 border-t main-border-color'></hr>
                  <span className='font-medium text-primary text-xs px-3'>{t('lang_Header')}</span>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Announcement_Bar')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
                  </details>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Header')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <HeaderSection isChangeHeader={isChangeHeader} />
                  </details>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <div className='w-full'>
                    <>
                      <HomePageSectionList
                        getLayout={getLayout}
                        editSection={editSection}
                        selectSection={selectSection}
                        UpdateSectionStatus={UpdateSectionStatus}
                        handleSort={handleSort}
                        handleGetLayout={handleGetLayout}
                        hoverId={hoverId}
                        setHoverId={setHoverId}
                        setFilterId={setFilterId}
                        sectionAdd={sectionAdd}
                        handleSubItemSort={handleSubItemSort}
                        setSubSecId={setSubSecId}
                        loadingSections={loadingSections}
                        openAddSection={openAddSection}
                        handleSectionClick={handleSectionClick}
                        setElementorId={setElementorId}
                        getPrevSecId={getPrevSecId}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAddSection(true);
                        }}
                        className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
                      >
                        <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Section')}
                      </button>
                    </>
                  </div>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Footer')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <FooterSection isChangeFooter={isChangeFooter} />
                  </details>
                </div>
              )}
            </div>
          )}
          <div className={`p-0 md:ps-2 lg:px-4 bg-transparent h-full w-full md:w-[70%] xl:w-[68%]`}>
            {pending && !getLayout ? (
              <div className='w-full'>
                <div>
                  <Skeleton
                    className='min-h-[90px] max-h-[90px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[500px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
                <div className='grid mt-6 mx-auto grid-cols-1'>
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[400px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                className='h-full overflow-auto'
              >
                <VisualAnnounccementBar colors={commonSetting} announcement={announcement} />
                <StoreNavbar
                  clientInfo={clientData}
                  colors={commonSetting}
                  groupHeaderData={groupHeaderData}
                />
                {/* <HomePageSection
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  selectSection={selectSection}
                  handleSort={handleSort}
                  editSection={editSection}
                  manufactureDesign={manufactureDesign}
                  categoryDesign={categoryDesign}
                  productDesign={productDesign}
                  reassuranceDesign={reassuranceDesign}
                  bannerDesign={bannerDesign}
                  sliderDesign={sliderDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  blogDesign={blogDesign}
                  setEditSection={setEditSection}
                  setEditProductOtherSection={setEditProductOtherSection}
                  hoverId={hoverId}
                  setHoverId={setHoverId}
                  setFilterId={setFilterId}
                /> */}
                <SelectedStructureSection
                  flexBoxValue={flexBoxValue}
                  // handleSubDivClick={handleSubDivClick}
                  setSectionAdd={setSectionAdd}
                  removeSection={removeSection}
                  selectedIndex={selectedIndex}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  editSection={editSection}
                  handleSectionClick={handleSectionClick}
                  activeIndex={activeIndex}
                  sectionAdd={sectionAdd}
                  setActiveIndex={setActiveIndex}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  gridValue={gridValue}
                  activeHeading={activeHeading}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  removedIndices={removedIndices}
                  activeImageIndex={activeImageIndex}
                  handleImageClick={handleImageClick}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                  handleRemoveSingleItem={handleRemoveSingleItem}
                  handleDuplicateSingleItem={handleDuplicateSingleItem}
                  handleCopySingleItem={handleCopySingleItem}
                  handleCutSingleItem={handleCutSingleItem}
                  getPrevSecId={getPrevSecId}
                  setElementorId={setElementorId}
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  handleSort={handleSort}
                  manufactureDesign={manufactureDesign}
                  categoryDesign={categoryDesign}
                  productDesign={productDesign}
                  reassuranceDesign={reassuranceDesign}
                  bannerDesign={bannerDesign}
                  sliderDesign={sliderDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  blogDesign={blogDesign}
                  setEditProductOtherSection={setEditProductOtherSection}
                  hoverId={hoverId}
                  setHoverId={setHoverId}
                  setFilterId={setFilterId}
                  setFlexBoxValue={setFlexBoxValue}
                  dropPosition={dropPosition}
                  setDropPosition={setDropPosition}
                  selectContextItem={selectContextItem}
                  getContextMenuItem={getContextMenuItem}
                />
                <AddSectionElementor
                  handleFlexBoxClick={handleFlexBoxClick}
                  gridValue={gridValue}
                  setIsFlexBoxVisible={setIsFlexBoxVisible}
                  setIsGridVisible={setIsGridVisible}
                  isGridVisible={isGridVisible}
                  isFlexBoxVisible={isFlexBoxVisible}
                  isPlusButtonVisible={isPlusButtonVisible}
                  setIsPlusButtonVisible={setIsPlusButtonVisible}
                  isLayoutVisible={isLayoutVisible}
                  setIsLayoutVisible={setIsLayoutVisible}
                />
                <Footer colors={commonSetting} groupFooterData={groupFooterData} />
              </div>
            )}
          </div>
          {windowWidth > 1280 && (
            <div className='hidden  edit-style-section xl:block xl:w-[16%] h-full p-5 secondary-bg-color overflow-y-auto'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <HeadingSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sectionContent={headingSetting?.title}
                      headingLevel={headingSetting?.htmlTag}
                      handleContentChange={handleContentChange}
                      handleHtmlTagChange={handleHtmlTagChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <ImageSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleImageUpload={handleImageUpload}
                      previewImage={bgImage}
                      handleImageSizeChange={handleImageSizeChange}
                      handleImageLinkChange={handleImageLinkChange}
                      currentImageSize={bgImage?.imageSize}
                      currentImageLink={bgImage?.link}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <TextEditorSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleTextEditorChange={handleTextEditorChange}
                      textEditorValue={textEditorValue?.textEditorValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <VideoSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      videoValue={videoValue}
                      handleVideoChange={handleVideoChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <ButtonSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleButtonChange={handleButtonChange}
                      buttonValue={buttonValue}
                      handleButtonLinkChange={handleButtonLinkChange}
                      handleIconSelectValue={handleIconSelectValue}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <DividerSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleDividerStyleChange={handleDividerStyleChange}
                      handleDividerWidthChange={handleDividerWidthChange}
                      dividerValue={dividerValue}
                      handleAlignmentChange={handleAlignmentChange}
                      handleAddElementChange={handleAddElementChange}
                      handleAddElementValue={handleAddElementValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <SpacerSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleSpacerWidthChange={handleSpacerWidthChange}
                      spacerValue={spacerValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <GoogleMapSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleLocationChange={handleLocationChange}
                      locationValue={locationValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <IconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleIconElementorValue={handleIconElementorValue}
                      handleIconLinkChange={handleIconLinkChange}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      iconValue={iconValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <ImageBoxSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleIconBoxImageUpload={handleIconBoxImageUpload}
                      previewImage={imageBox}
                      handleImageBoxImageSizeChange={handleImageBoxImageSizeChange}
                      handleImageBoxTitleChange={handleImageBoxTitleChange}
                      handleImageBoxHtmlTagChange={handleImageBoxHtmlTagChange}
                      currentImageSize={imageBox?.imageSize}
                      sectionContent={imageBox?.title}
                      currentImageLink={imageBox?.link}
                      headingLevel={imageBox?.htmlTag}
                      sectionContentDescription={imageBox?.description}
                      handleImageBoxDescriptionChange={handleImageBoxDescriptionChange}
                      handleImageBoxImageLinkChange={handleImageBoxImageLinkChange}
                      setImageBox={setImageBox}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <ImageIconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      previewImage={imageIcon}
                      handleIconBoxImageUpload={handleIconBoxImageUpload}
                      handleImageIconTitleChange={handleImageIconTitleChange}
                      handleImageIconHtmlTagChange={handleImageIconHtmlTagChange}
                      sectionContent={imageIcon?.title}
                      currentImageLink={imageIcon?.link}
                      headingLevel={imageIcon?.htmlTag}
                      sectionContentDescription={imageIcon?.description}
                      imageIcon={imageIcon}
                      handleImageIconDescriptionChange={handleImageIconDescriptionChange}
                      handleImageIconImageLinkChange={handleImageIconImageLinkChange}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      handleIconBoxImageValue={handleIconBoxImageValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <IconListSettingApply
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      items={iconListData?.item}
                      setIconListData={setIconListData}
                      handleIconListDataValue={handleIconListDataValue}
                      handleIconListDataRemoveValue={handleIconListDataRemoveValue}
                      handleIconListDataCopyValue={handleIconListDataCopyValue}
                      setTabIndex={setTabIndex}
                      iconListData={iconListData}
                      tabIndex={tabIndex}
                      handleIconListValue={handleIconListValue}
                      handleIconListIconValue={handleIconListIconValue}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <CounterSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      numberCounter={numberCounter}
                      handleCounterChange={handleCounterChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <TestimonialSetting
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      testiMonialValue={testiMonialValue}
                      HandleTestiMonialChange={HandleTestiMonialChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <TabSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleTabValue={handleTabValue}
                      handleAddTabsItem={handleAddTabsItem}
                      handleCopyTabsItem={handleCopyTabsItem}
                      handleTabsDataRemoveValue={handleTabsDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <AccordionSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      accordionValue={accordionValue}
                      setAccordionValue={setAccordionValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleAccordionValue={handleAccordionValue}
                      handleAddAccordionItem={handleAddAccordionItem}
                      handleCopyAccordionItem={handleCopyAccordionItem}
                      handleAccordionDataRemoveValue={handleAccordionDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <AlertSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      alertValue={alertValue}
                      handleAlertChange={handleAlertChange}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <SocialIconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      items={socialIconData?.item}
                      setsocialIconData={setsocialIconData}
                      handleSocialIconDataValue={handleSocialIconDataValue}
                      handleSocialIconDataRemoveValue={handleSocialIconDataRemoveValue}
                      handleSocialIconCopyValue={handleSocialIconCopyValue}
                      setTabIndex={setTabIndex}
                      socialIconData={socialIconData}
                      tabIndex={tabIndex}
                      handleSocialIconListValue={handleSocialIconListValue}
                      handleSocialIconValue={handleSocialIconValue}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <HtmlSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      htmlValue={htmlValue}
                      setHtmlValue={setHtmlValue}
                      handleHtmlChange={handleHtmlChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <RatingSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      ratingValue={ratingValue}
                      HandleRatingChange={HandleRatingChange}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      handleRatingIconSelectValue={handleRatingIconSelectValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <StaticFirstContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstContentValue={firstContentValue}
                      setFirstContentValue={setFirstContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={firstContentValue?.title}
                      headingLevel={firstContentValue?.htmlTag}
                      descriptionContent={firstContentValue?.description}
                      subTitleContent={firstContentValue?.subTitle}
                      subTitleheadingLevel={firstContentValue?.subHtmlTag}
                      sectionButtonContent={firstContentValue?.button}
                      sectionGridContent={firstContentValue?.gridValue}
                      handleFirstContentValue={handleFirstContentValue}
                      handleAddFirstContentItem={handleAddFirstContentItem}
                      handleCopyFirstContentItem={handleCopyFirstContentItem}
                      handleFirstContentDataRemoveValue={handleFirstContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstContentTagChange={handleFirstContentTagChange}
                      handleFirstContentTitleValueChange={handleFirstContentTitleValueChange}
                      handleFirstContentSubTitleValueChange={handleFirstContentSubTitleValueChange}
                      handleFirstContentSubTitleTagChange={handleFirstContentSubTitleTagChange}
                      handleFirstContentDescriptionChange={handleFirstContentDescriptionChange}
                      handleFirstContentButtonValueChange={handleFirstContentButtonValueChange}
                      handleFirstContentGridValueChange={handleFirstContentGridValueChange}
                    />
                  ) : editSection?.section_type === 'content-2' ? (
                    <StaticSecondContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondContentValue={secondContentValue}
                      setSecondContentValue={setSecondContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={secondContentValue?.title}
                      headingLevel={secondContentValue?.htmlTag}
                      descriptionContent={secondContentValue?.description}
                      sectionGridContent={secondContentValue?.gridValue}
                      handleSecondContentValue={handleSecondContentValue}
                      handleAddSecondContentItem={handleAddSecondContentItem}
                      handleCopySecondContentItem={handleCopySecondContentItem}
                      handleSecondContentDataRemoveValue={handleSecondContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondContentTagChange={handleSecondContentTagChange}
                      handleSecondContentTitleValueChange={handleSecondContentTitleValueChange}
                      handleSecondContentDescriptionChange={handleSecondContentDescriptionChange}
                      handleSecondContentGridValueChange={handleSecondContentGridValueChange}
                    />
                  ) : editSection?.section_type === 'content-3' ? (
                    <StaticThirdContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdContentValue={thirdContentValue}
                      setThirdContentValue={setThirdContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={thirdContentValue?.title}
                      headingLevel={thirdContentValue?.htmlTag}
                      descriptionContent={thirdContentValue?.description}
                      sectionGridContent={thirdContentValue?.gridValue}
                      sectionButtonContent={thirdContentValue?.button}
                      handleThirdContentValue={handleThirdContentValue}
                      handleAddThirdContentItem={handleAddThirdContentItem}
                      handleCopyThirdContentItem={handleCopyThirdContentItem}
                      handleThirdContentDataRemoveValue={handleThirdContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdContentTagChange={handleThirdContentTagChange}
                      handleThirdContentTitleValueChange={handleThirdContentTitleValueChange}
                      handleThirdContentDescriptionChange={handleThirdContentDescriptionChange}
                      handleThirdContentGridValueChange={handleThirdContentGridValueChange}
                      handleThirdContentButtonValueChange={handleThirdContentButtonValueChange}
                    />
                  ) : editSection?.section_type === 'content-4' ? (
                    <StaticForthContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      forthContentValue={forthContentValue}
                      setForthContentValue={setForthContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={forthContentValue?.title}
                      headingLevel={forthContentValue?.htmlTag}
                      subTitleHeadingLevel={forthContentValue?.sub_title_htmlTag}
                      descriptionContent={forthContentValue?.description}
                      sectionGridContent={forthContentValue?.gridValue}
                      sectionButtonContent={forthContentValue?.button}
                      sectionSubTitleContent={forthContentValue?.sub_title}
                      handleForthContentValue={handleForthContentValue}
                      handleAddForthContentItem={handleAddForthContentItem}
                      handleCopyForthContentItem={handleCopyForthContentItem}
                      handleForthContentDataRemoveValue={handleForthContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleForthContentTagChange={handleForthContentTagChange}
                      handleForthContentTitleValueChange={handleForthContentTitleValueChange}
                      handleForthContentGridValueChange={handleForthContentGridValueChange}
                      handleForthContentButtonValueChange={handleForthContentButtonValueChange}
                      handleForthContentSubTitleValueChange={handleForthContentSubTitleValueChange}
                      handleForthContentDescriptionChange={handleForthContentDescriptionChange}
                    />
                  ) : editSection?.section_type === 'content-5' ? (
                    <StaticFifthContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFifthContentTagChange={handleFifthContentTagChange}
                      sectionContent={fifthContentValue?.title}
                      headingLevel={fifthContentValue?.htmlTag}
                      handleFifthContentTitleValueChange={handleFifthContentTitleValueChange}
                      handleFifthContentDescriptionChange={handleFifthContentDescriptionChange}
                      descriptionContent={fifthContentValue?.description}
                      sectionButtonContent={fifthContentValue?.button}
                      handleFifthContentButtonValueChange={handleFifthContentButtonValueChange}
                      handleFifthContentSubButtonValueChange={
                        handleFifthContentSubButtonValueChange
                      }
                      sectionSubButtonContent={fifthContentValue?.button_link}
                      sectionIconContent={fifthContentValue?.icon}
                      handleFifthContentIconValueChange={handleFifthContentIconValueChange}
                      sectionIconLinkContent={fifthContentValue?.link}
                      handleFifthContentIconLinkValueChange={handleFifthContentIconLinkValueChange}
                    />
                  ) : editSection?.section_type === 'content-6' ? (
                    <StaticSixContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSixContentTagChange={handleSixContentTagChange}
                      sectionContent={sixContentValue?.title}
                      headingLevel={sixContentValue?.htmlTag}
                      valueContent={sixContentValue?.content}
                      sectionButtonLink={sixContentValue?.link_url}
                      handleSixContentTitleValueChange={handleSixContentTitleValueChange}
                      handleSixContentDescriptionChange={handleSixContentDescriptionChange}
                      handleSixContentContentChange={handleSixContentContentChange}
                      descriptionContent={sixContentValue?.description}
                      sectionButtonContent={sixContentValue?.button}
                      handleSixContentSubButtonValueChange={handleSixContentSubButtonValueChange}
                      handleSixContentButtonLinkChange={handleSixContentButtonLinkChange}
                      sectionSubButtonContent={sixContentValue?.button_link}
                      sectionImageContent={sixContentValue?.image}
                      handleSixContentImageChange={handleSixContentImageChange}
                      sectionProfileContent={sixContentValue?.profile}
                      handleSixContentProfileChange={handleSixContentProfileChange}
                      sectionObjectFitContent={sixContentValue?.objectFit}
                      handleSixContentObjectFitChange={handleSixContentObjectFitChange}
                    />
                  ) : editSection?.section_type === 'content-7' ? (
                    <StaticSevenContentSection
                      section_id={editSection?.section_id}
                      sevenContentValue={sevenContentValue}
                      setSevenContentValue={setSevenContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={sevenContentValue?.gridValue}
                      handleSevenContentValue={handleSevenContentValue}
                      handleSevenContentItem={handleSevenContentItem}
                      handleCopySevenContentItem={handleCopySevenContentItem}
                      handleSevenContentDataRemoveValue={handleSevenContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSevenContentGridValueChange={handleSevenContentGridValueChange}
                    />
                  ) : editSection?.section_type === 'content-8' ? (
                    <StaticEightContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      eightContentValue={eightContentValue}
                      setEightContentValue={setEightContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={eightContentValue?.gridValue}
                      sectionContent={eightContentValue?.title}
                      subTitleheadingLevel={eightContentValue?.htmlTag}
                      descriptionContent={eightContentValue?.description}
                      handleEightContentValue={handleEightContentValue}
                      handleAddEightContentItem={handleAddEightContentItem}
                      handleCopyEightContentItem={handleCopyEightContentItem}
                      handleEightContentDataRemoveValue={handleEightContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleEightContentGridValueChange={handleEightContentGridValueChange}
                      handleEightContentTitleValueChange={handleEightContentTitleValueChange}
                      handleEightContentTitleTagChange={handleEightContentTitleTagChange}
                      handleEightContentDescriptionChange={handleEightContentDescriptionChange}
                    />
                  ) : editSection?.section_type === 'feature-1' ? (
                    <StaticFirstFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstFeatureValue={firstFeatureValue}
                      setFirstFeatureValue={setFirstFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={firstFeatureValue?.gridValue}
                      sectionContent={firstFeatureValue?.title}
                      subTitleheadingLevel={firstFeatureValue?.htmlTag}
                      handleFirstFeatureValue={handleFirstFeatureValue}
                      handleAddFirstFeatureItem={handleAddFirstFeatureItem}
                      handleCopyFirstFeatureItem={handleCopyFirstFeatureItem}
                      handleFirstFeatureDataRemoveValue={handleFirstFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstFeatureGridValueChange={handleFirstFeatureGridValueChange}
                      handleFirstFeatureTitleValueChange={handleFirstFeatureTitleValueChange}
                      handleFirstFeatureTitleTagChange={handleFirstFeatureTitleTagChange}
                    />
                  ) : editSection?.section_type === 'feature-2' ? (
                    <StaticSecondFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondFeatureValue={secondFeatureValue}
                      setSecondFeatureValue={setSecondFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={secondFeatureValue?.gridValue}
                      sectionContent={secondFeatureValue?.title}
                      subTitleheadingLevel={secondFeatureValue?.htmlTag}
                      descriptionContent={secondFeatureValue?.description}
                      sectionButtonContent={secondFeatureValue?.button}
                      handleSecondFeatureValue={handleSecondFeatureValue}
                      handleAddSecondFeatureItem={handleAddSecondFeatureItem}
                      handleCopySecondFeatureItem={handleCopySecondFeatureItem}
                      handleSecondFeatureDataRemoveValue={handleSecondFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondFeatureGridValueChange={handleSecondFeatureGridValueChange}
                      handleSecondFeatureTitleValueChange={handleSecondFeatureTitleValueChange}
                      handleSecondFeatureTitleTagChange={handleSecondFeatureTitleTagChange}
                      handleSecondFeatureDescriptionChange={handleSecondFeatureDescriptionChange}
                      handleSecondFeatureButtonValueChange={handleSecondFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'feature-3' ? (
                    <StaticThirdFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdFeatureValue={thirdFeatureValue}
                      setThirdFeatureValue={setThirdFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdFeatureValue={handleThirdFeatureValue}
                      handleAddThirdFeatureItem={handleAddThirdFeatureItem}
                      handleCopyThirdFeatureItem={handleCopyThirdFeatureItem}
                      handleThirdFeatureDataRemoveValue={handleThirdFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdFeatureImageValue={handleThirdFeatureImageValue}
                      handleThirdFeatureObjectFitValue={handleThirdFeatureObjectFitValue}
                    />
                  ) : editSection?.section_type === 'feature-4' ? (
                    <StaticForthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      forthFeatureValue={forthFeatureValue}
                      setForthFeatureValue={setForthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleForthFeatureValue={handleForthFeatureValue}
                      handleAddForthFeatureItem={handleAddForthFeatureItem}
                      handleCopyForthFeatureItem={handleCopyForthFeatureItem}
                      handleForthFeatureDataRemoveValue={handleForthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-5' ? (
                    <StaticFifthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      fifthFeatureValue={fifthFeatureValue}
                      setFifthFeatureValue={setFifthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={fifthFeatureValue?.title}
                      headingLevel={fifthFeatureValue?.htmlTag}
                      subTitleContent={fifthFeatureValue?.subTitle}
                      subTitleheadingLevel={fifthFeatureValue?.subHtmlTag}
                      sectionGridContent={fifthFeatureValue?.gridValue}
                      handlefifthFeatureValue={handlefifthFeatureValue}
                      handleAddFifthFeatureItem={handleAddFifthFeatureItem}
                      handleCopyFifthFeatureItem={handleCopyFifthFeatureItem}
                      handleFifthFeatureDataRemoveValue={handleFifthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFifthFeatureTagChange={handleFifthFeatureTagChange}
                      handleFifthFeatureTitleValueChange={handleFifthFeatureTitleValueChange}
                      handleFifthFeatureSubTitleValueChange={handleFifthFeatureSubTitleValueChange}
                      handleFifthFeatureSubTitleTagChange={handleFifthFeatureSubTitleTagChange}
                      handleFifthFeatureGridValueChange={handleFifthFeatureGridValueChange}
                    />
                  ) : editSection?.section_type === 'feature-6' ? (
                    <StaticSixthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sixthFeatureValue={sixthFeatureValue}
                      setSixthFeatureValue={setSixthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionButtonContent={sixthFeatureValue?.button}
                      handleSixthFeatureValue={handleSixthFeatureValue}
                      handleAddSixthFeatureItem={handleAddSixthFeatureItem}
                      handleCopySixthFeatureItem={handleCopySixthFeatureItem}
                      handleSixthFeatureDataRemoveValue={handleSixthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSixthFeatureTagChange={handleSixthFeatureTagChange}
                    />
                  ) : editSection?.section_type === 'feature-7' ? (
                    <StaticSevenFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sevenFeatureValue={sevenFeatureValue}
                      setSevenFeatureValue={setSevenFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={sevenFeatureValue?.gridValue}
                      sectionContent={sevenFeatureValue?.title}
                      subTitleheadingLevel={sevenFeatureValue?.htmlTag}
                      descriptionContent={sevenFeatureValue?.description}
                      sectionButtonContent={sevenFeatureValue?.button}
                      handleSevenFeatureValue={handleSevenFeatureValue}
                      handleAddSevenFeatureItem={handleAddSevenFeatureItem}
                      handleCopySevenFeatureItem={handleCopySevenFeatureItem}
                      handleSevenFeatureDataRemoveValue={handleSevenFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSevenFeatureGridValueChange={handleSevenFeatureGridValueChange}
                      handleSevenFeatureTitleValueChange={handleSevenFeatureTitleValueChange}
                      handleSevenFeatureTitleTagChange={handleSevenFeatureTitleTagChange}
                      handleSevenFeatureDescriptionChange={handleSevenFeatureDescriptionChange}
                      handleSevenFeatureButtonValueChange={handleSevenFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'feature-8' ? (
                    <StaticEightFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      eightFeatureValue={eightFeatureValue}
                      setEightFeatureValue={setEightFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={eightFeatureValue?.gridValue}
                      sectionContent={eightFeatureValue?.title}
                      subTitleheadingLevel={eightFeatureValue?.htmlTag}
                      descriptionContent={eightFeatureValue?.description}
                      sectionButtonContent={eightFeatureValue?.button}
                      handleEightFeatureValue={handleEightFeatureValue}
                      handleAddEightFeatureItem={handleAddEightFeatureItem}
                      handleCopyEightFeatureItem={handleCopyEightFeatureItem}
                      handleEightFeatureDataRemoveValue={handleEightFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleEightFeatureGridValueChange={handleEightFeatureGridValueChange}
                      handleEightFeatureTitleValueChange={handleEightFeatureTitleValueChange}
                      handleEightFeatureTitleTagChange={handleEightFeatureTitleTagChange}
                      handleEightFeatureDescriptionChange={handleEightFeatureDescriptionChange}
                      handleEightFeatureButtonValueChange={handleEightFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'step-1' ? (
                    <StaticFirstStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstStepValue={firstStepValue}
                      setFirstStepValue={setFirstStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstStepValue={handleFirstStepValue}
                      handleAddFirstStepItem={handleAddFirstStepItem}
                      handleCopyFirstStepItem={handleCopyFirstStepItem}
                      handleFirstStepDataRemoveValue={handleFirstStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'step-2' ? (
                    <StaticSecondStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstStepValue={firstStepValue}
                      setFirstStepValue={setFirstStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstStepValue={handleFirstStepValue}
                      handleAddFirstStepItem={handleAddFirstStepItem}
                      handleCopyFirstStepItem={handleCopyFirstStepItem}
                      handleFirstStepDataRemoveValue={handleFirstStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'step-3' ? (
                    <StaticThirdStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdStepValue={thirdStepValue}
                      setThirdStepValue={setThirdStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdStepValue={handleThirdStepValue}
                      handleAddThirdStepItem={handleAddThirdStepItem}
                      handleCopyThirdStepItem={handleCopyThirdStepItem}
                      handleThirdStepDataRemoveValue={handleThirdStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'team-1' ? (
                    <StaticFirstTeamSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstTeamValue={firstTeamValue}
                      setFirstTeamValue={setFirstTeamValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstTeamValue={handleFirstTeamValue}
                      handleAddFirstTeamItem={handleAddFirstTeamItem}
                      handleCopyFirstTeamItem={handleCopyFirstTeamItem}
                      handleFirstTeamDataRemoveValue={handleFirstTeamDataRemoveValue}
                      sectionGridContent={firstTeamValue?.gridValue}
                      sectionContent={firstTeamValue?.title}
                      subTitleheadingLevel={firstTeamValue?.htmlTag}
                      descriptionContent={firstTeamValue?.description}
                      sectionButtonContent={firstTeamValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstTeamDescriptionChange={handleFirstTeamDescriptionChange}
                      handleFirstTeamTagChange={handleFirstTeamTagChange}
                      handleFirstTeamTitleValueChange={handleFirstTeamTitleValueChange}
                      handleFirstTeamGridValueChange={handleFirstTeamGridValueChange}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <EditHeadingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHeadingDesign={setHeadingDesign}
                      headingDesign={headingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={headingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <EditImageStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageDesign={setImageDesign}
                      imageDesign={imageDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <EditTextEditorStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTextEditorDesign={setTextEditorDesign}
                      textEditorDesign={textEditorDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={textEditorDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <EditVideoStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setVideoDesign={setVideoDesign}
                      videoDesign={videoDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={videoDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <EditButtonStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setButtonDesign={setButtonDesign}
                      buttonDesign={buttonDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={buttonDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <EditDividerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setDividerDesign={setDividerDesign}
                      dividerDesign={dividerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={dividerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <EditSpacerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSpacerDesign={setSpacerDesign}
                      spacerDesign={spacerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={spacerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <EditTestiMonialStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTestiMonialDesign={setTestiMonialDesign}
                      testiMonialDesign={testiMonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={testiMonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <EditSocialIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSocialIconDesign={setSocialIconDesign}
                      socialIconDesign={socialIconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={socialIconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <EditAlertStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAlertDesign={setAlertDesign}
                      alertDesign={alertDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={alertDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <EditHtmlStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHtmlDesign={setHtmlDesign}
                      htmlDesign={htmlDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={htmlDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <EditImageBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageBoxDesign={setImageBoxDesign}
                      imageBoxDesign={imageBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <EditIconBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconBoxDesign={setIconBoxDesign}
                      iconBoxDesign={iconBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <EditIconListStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconListDesign={setIconListDesign}
                      iconListDesign={iconListDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconListDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <EditMapStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setMapDesign={setMapDesign}
                      mapDesign={mapDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={mapDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <EditIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconDesign={setIconDesign}
                      iconDesign={iconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <EditCounterStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setCounterDesign={setCounterDesign}
                      counterDesign={counterDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={counterDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <EditTabStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTabDesign={setTabDesign}
                      tabDesign={tabDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={tabDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <EditAccordianStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAccordianDesign={setAccordianDesign}
                      accordianDesign={accordianDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={accordianDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <EditRatingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setRatingDesign={setRatingDesign}
                      ratingDesign={ratingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={ratingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <EditFirstContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstContentDesign={setFirstContentDesign}
                      firstContentDesign={firstContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-2' ? (
                    <EditSecondContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondContentDesign={setSecondContentDesign}
                      secondContentDesign={secondContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-3' ? (
                    <EditThirdContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdContentDesign={setThirdContentDesign}
                      thirdContentDesign={thirdContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-4' ? (
                    <EditForthContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthContentDesign={setForthContentDesign}
                      forthContentDesign={forthContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-5' ? (
                    <EditFifthContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFifthContentDesign={setFifthContentDesign}
                      fifthContentDesign={fifthContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={fifthContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-6' ? (
                    <EditSixContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSixContentDesign={setSixContentDesign}
                      sixContentDesign={sixContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sixContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-7' ? (
                    <EditSevenContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSevenContentDesign={setSevenContentDesign}
                      sevenContentDesign={sevenContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sevenContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-8' ? (
                    <EditEightContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setEightContentDesign={setEightContentDesign}
                      eightContentDesign={eightContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={eightContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-1' ? (
                    <EditFirstFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstFeatureDesign={setFirstFeatureDesign}
                      firstFeatureDesign={firstFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-2' ? (
                    <EditSecondFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondFeatureDesign={setSecondFeatureDesign}
                      secondFeatureDesign={secondFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-3' ? (
                    <EditThirdFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdFeatureDesign={setThirdFeatureDesign}
                      thirdFeatureDesign={thirdFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-4' ? (
                    <EditForthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthFeatureDesign={setForthFeatureDesign}
                      forthFeatureDesign={forthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-5' ? (
                    <EditFifthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFifthFeatureDesign={setFifthFeatureDesign}
                      fifthFeatureDesign={fifthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={fifthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-6' ? (
                    <EditSixthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSixthFeatureDesign={setSixthFeatureDesign}
                      sixthFeatureDesign={sixthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sixthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-7' ? (
                    <EditSevenFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSevenFeatureDesign={setSevenFeatureDesign}
                      sevenFeatureDesign={sevenFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sevenFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-8' ? (
                    <EditEightFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setEightFeatureDesign={setEightFeatureDesign}
                      eightFeatureDesign={eightFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={eightFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'step-1' ? (
                    <EditFirstStepStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstStepDesign={setFirstStepDesign}
                      firstStepDesign={firstStepDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstStepDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'step-3' ? (
                    <EditThirdStepStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdStepDesign={setThirdStepDesign}
                      thirdStepDesign={thirdStepDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdStepDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'team-1' ? (
                    <EditFirstTeamStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstTeamDesign={setFirstTeamDesign}
                      firstTeamDesign={firstTeamDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstTeamDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' ? (
                // && editSection?.section_type
                <>
                  <AddElementsStyleSection
                    handleAddSection={handleAddSection}
                    activeIndex={activeIndex}
                    editSection={editSection}
                    gridValue={gridValue}
                    dragItems={dragItems}
                    saveElementorData={saveElementorData}
                    btnLoading={btnLoading}
                    handleFlexBoxClick={handleFlexBoxClick}
                    openAddSection={openAddSection}
                    setGridValue={setGridValue}
                    setOpenTab={setOpenTab}
                    handleItemClick={handleItemClick}
                  />
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!open && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: '9999',
          }}
          className='shadow-inner block md:hidden'
        >
          <div className='bg-[#222222] text-white text-xs text-center rounded-t-xl p-2'>
            {t('lang_Select_Section_Details')}
          </div>
          <div className='relative h-[65px]  secondary-bg-color flex items-center'>
            <div onMouseDown={() => setOpen(true)} onTouchMove={() => setOpen(true)}>
              <Puller />
            </div>
            {editSection?.section_type ? (
              <div className='flex relative items-center w-full'>
                <button
                  onClick={(e) => selectSection(e, '', '')}
                  className='flex absolute left-[20px] top-[20px] -translate-y-[20px] text-sm items-center'
                >
                  <ChevronLeft className='w-4 h-4' />
                  {t('lang_Sections')}
                </button>
                <p
                  className='text-center w-full capitalize'
                  onMouseDown={() => setOpen(true)}
                  onTouchMove={() => setOpen(true)}
                >
                  {t('lang_edit')} {editSection?.section_type} {t('lang_Section')}
                </p>
              </div>
            ) : (
              <div
                className='flex relative items-center w-full'
                onMouseDown={() => setOpen(true)}
                onTouchMove={() => setOpen(true)}
              >
                <p className='text-center block w-full'>{t('lang_Show_All_Sections')}</p>
              </div>
            )}
          </div>
        </div>
      )}
      <Dialog
        open={isAddSection}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsAddSection(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <AddLayout closeAddSection={closeAddSection} getAllLayout={handleGetLayout} />
      </Dialog>
    </>
  );
};

export default HomePageEditor;
