import React from 'react';
import ReactDragListView from 'react-drag-listview';
import { mapPositionToCSS } from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const FifthStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.fifthContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-5',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');

  return (
    <div className='w-full'>
      <ReactDragListView {...dragProps}>
        {filteredSections?.map((obj, itemIndex) => {
          const isActive =
            editSection?.section_type === obj?.blockName &&
            editSection?.section_id === obj?.id &&
            editSection?.section_index === itemIndex;

          console.log(obj?.content?.button_link, 'obj?.content?.button_link=-=-=>>');

          return (
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
            >
              <div
                class='container mx-auto flex flex-wrap'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                        ? `url(${obj?.style?.background_image})`
                        : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                      obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                {obj?.content?.htmlTag === 'h1' ? (
                  <h1
                    class='mb-2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h1>
                ) : obj?.content?.htmlTag === 'h2' ? (
                  <h2
                    class='sm:2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h2>
                ) : obj?.content?.htmlTag === 'h3' ? (
                  <h3
                    class='sm:2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h3>
                ) : obj?.content?.htmlTag === 'h4' ? (
                  <h4
                    class='sm:2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h4>
                ) : obj?.content?.htmlTag === 'h5' ? (
                  <h5
                    class='sm:2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h5>
                ) : obj?.content?.htmlTag === 'h6' ? (
                  <h6
                    class='sm:2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h6>
                ) : obj?.content?.htmlTag === 'div' ? (
                  <div
                    class='sm2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'span' ? (
                  <span
                    class='smb-2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </span>
                ) : obj?.content?.htmlTag === 'p' ? (
                  <p
                    class='sm:t2 md:w-2/5'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </p>
                ) : (
                  <></>
                )}
                <div class='md:w-3/5 md:pl-6'>
                  <span
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    class='lg:w-1/2 w-full leading-relaxed text-gray-500'
                    dangerouslySetInnerHTML={{ __html: obj?.content?.description || '-' }}
                  ></span>
                  <div class='flex md:mt-4 mt-6'>
                    <button
                      style={{
                        color: obj?.style?.button_text_color,
                        backgroundColor: obj?.style?.button_color,
                      }}
                      class='inline-flex text-white bg-indigo-500 border-0 py-1 px-4 focus:outline-none hover:bg-indigo-600 rounded'
                    >
                      {obj?.content?.button}
                    </button>
                    <a
                      class='inline-flex items-center ml-4 gap-x-1'
                      style={{
                        color: obj?.style?.button_link_text_color,
                      }}
                    >
                      {obj?.content?.button_link}
                      {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                        React.cloneElement(obj?.content?.icon, {
                          width: '50px',
                          height: '50px',
                          color: '#000',
                        })
                      ) : (
                        <SvgSection
                          svgContent={obj?.content?.icon}
                          width={'24px'}
                          height={'24px'}
                          fill={'#000'}
                          stroke={'#000'}
                        />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </ReactDragListView>
    </div>
  );
};

export default FifthStaticContentSection;
