import React from 'react';
import EditTabStyleContainer from './EditTabStyleContainer';

const EditTabStyleSection = ({previewImage, tabDesign, setTabDesign, handleApplyChangesElementor ,section_id,updateSectionStyle,saveElementorData,
  btnLoading,}) => {
  return (
    <>
      <EditTabStyleContainer
        tabDesign={tabDesign}
        setTabDesign={setTabDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditTabStyleSection;
