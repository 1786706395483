import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import API from '../../API';
import moment from 'moment-timezone';
import Notification from '../Notification';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { PlanPermission } from '../../App';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';
const QuantityChart = () => {
  const [AllDates, setAllDates] = useState([]);
  const [AllCount, setAllCount] = useState([]);
  const [Products, setAllProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const originalDate = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState(2);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);

  const GetAllProducts = async () => {
    try {
      let products = await API.get(`/admin/product/GetProductsName`);
      if (products.status === 200 || products.status === 304) {
        const result = decryptData(products?.data)
        setAllProduct(result);
        setSelectedProduct(result?.data?.records[0]?._id);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Analytics' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllProducts();
  }, []);
  const GetProductInfo = async () => {
    try {
      if (selectedProduct) {
        let TotalOrders = await API.get(
          `/admin/analytics/TotalProductSellingQuantity/${selectedProduct}?status=${status}${
            startDate?.toString()?.length > 0
              ? `&startdate=${moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`
              : ''
          }${
            endDate?.toString()?.length > 0
              ? `&enddate=${moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`
              : ''
          }`,
        );
        const result = decryptData(TotalOrders?.data)
        setAllDates(result?.data?.map((x) => x?.date));
        setAllCount(result?.data?.map((x) => x?.count));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      type: 'date',
      categories: AllDates?.length > 0 ? AllDates : [],
    },
    chart: {
      width: '100%',
      type: 'order',
    },
    stroke: {
      curve: 'smooth',
      width: [3],
    },
    colors: ['#bdc0c5'],
    tooltip: {
      x: {
        format: 'yyyy/MM/dd ',
      },
    },
  };
  const series = [
    {
      name: 'Quantity',
      data: AllCount?.length > 0 ? AllCount : [],
    },
  ];
  useEffect(() => {
    GetProductInfo();
    window.dispatchEvent(new Event('resize'));
  }, [startDate, endDate, status, selectedProduct]);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color border rounded-[6px] main-border-color'>
        <h6 className='border-b main-border-color font-medium py-3 px-4 text-lg'>
          {t('lang_quantity')}
        </h6>
        <div className='display-grid py-3'>
          <div className='grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 px-4'>
            <div className='w-full'>
              <label htmlFor='productType' className='block text-xs font-medium table-text mb-1'>
                {t('lang_products')}
              </label>
              <div className='relative'>
                <select
                  name='productType'
                  className='w-full border main-border-color focus:border-gray-500 rounded-[6px] py-1 outline-none px-3 text-sm'
                  value={selectedProduct}
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                  }}
                >
                  {Products?.data?.records?.map((elem, index) => {
                    return (
                      <option key={index} value={elem._id}>
                        {elem?.product_name}
                      </option>
                    );
                  })}
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_from')}</span>
              <label className='relative w-full block cursor-pointer'>
                <DatePicker
                  className={`${startDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_to')}</span>
              <label className='relative block w-full cursor-pointer'>
                <DatePicker
                  className={`${endDate && "bold-date"} border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='w-full'>
              <label htmlFor='product' className='block text-xs font-medium table-text mb-1'>
                {t('lang_filter_by')}
              </label>
              <button
                className={`relative inline-block px-3 py-1 font-medium rounded-md  text-gray-600 bg-gray-100 leading-tight
                  ${status === 4 ? 'bg-[#28282B] text-white' : 'bg-gray-100 text-gray-600'}`}
                onClick={() => setStatus(4)}
              >
                1W
              </button>
              <button
                className={`relative inline-block px-3 py-1 font-medium rounded-md  text-gray-600 bg-gray-100 leading-tight
                  ${status === 1 ? 'bg-[#28282B] text-white' : 'bg-gray-100 text-gray-600'}`}
                onClick={() => setStatus(1)}
              >
                1M
              </button>
              <button
                className={`relative inline-block px-3 py-1 font-medium rounded-md  text-gray-600 bg-gray-100 leading-tight
                  ${status === 2 ? 'bg-[#28282B] text-white' : 'bg-gray-100 text-gray-600'}`}
                onClick={() => setStatus(2)}
              >
                1Y
              </button>
            </div>
          </div>
        </div>
        <Chart options={options} series={series} type='line' height={300} />
      </div>
    </>
  );
};

export default QuantityChart;
