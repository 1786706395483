import { Dialog, Tooltip } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react';
import { iconDetails } from './IconDetails';

const IconList = ({ isOpen, setIsOpen, section_id, handleIconSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredIcons = iconDetails?.container?.filter((icon) =>
    icon?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Dialog keepMounted open={isOpen}>
      <div className='p-4 w-[700px]'>
        <div className='flex justify-between'>
          <h6>Icon Library</h6>
          <X className='cursor-pointer' onClick={handleDialogClose} />
        </div>

        <div className='my-2'>
          <input
            type='text'
            placeholder='Search icons...'
            className='p-2 border rounded-[6px] w-full'
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        <div className='grid grid-cols-5 gap-2 h-[70vh] overflow-x-auto p-1'>
          {filteredIcons?.length > 0 ? (
            filteredIcons?.map((icon) => (
              <div
                className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0]'
                onClick={() => handleIconSelect(icon?.component)}
              >
                <div className='p-2'>
                  <div className='flex flex-col items-center text-center'>
                  <div dangerouslySetInnerHTML={{ __html: icon?.component }} />
                    <Tooltip title={icon.name} key={icon.id}>
                      <p className='text-center mt-1 element-title'>{icon?.name}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No icons found</p>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default IconList;
