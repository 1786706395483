import React from 'react';
import ReactDragListView from 'react-drag-listview';
import { mapPositionToCSS } from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const SixStaticContentSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.sixContentComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_content-6',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');
  return (
    <div>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        return (
          <section
            class='text-gray-600 body-font'
            key={itemIndex}
            className={`cursor-pointer border-[2px] transition-all duration-300 ${
              isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
            }  hover:border-2 hover:border-blue-500 overflow-auto`}
            onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
          >
            <div class='container mx-auto flex flex-col'>
              <div class='lg:w-4/6 mx-auto'>
                <div class='rounded-lg h-64 overflow-hidden'>
                  <img
                    alt='content'
                    class='object-cover object-center h-full w-full'
                    src={obj?.content?.image}
                    style={{
                      objectFit: obj?.content?.objectFit,
                    }}
                  />
                </div>
                <div class='flex flex-col sm:flex-row mt-10'>
                  <div class='sm:w-1/3 text-center sm:pr-8 sm:py-8'>
                    <div class='w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400'>
                      {obj?.content?.profile &&
                      (obj?.content?.profile.startsWith('data:image/svg+xml') ||
                        obj?.content?.profile.includes('<svg')) ? (
                        <SvgSection
                          svgContent={obj?.content?.profile}
                          width={'50px'}
                          height={'50px'}
                          fill={'#9ca3af'}
                          stroke={'#9ca3af'}
                        />
                      ) : (
                        <img
                          alt='profile content'
                          className='object-cover object-center h-full w-full'
                          src={obj?.content?.profile}
                        />
                      )}
                    </div>
                    <div class='flex flex-col items-center text-center justify-center'>
                      {obj?.content?.htmlTag === 'h1' ? (
                        <h1
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h1>
                      ) : obj?.content?.htmlTag === 'h2' ? (
                        <h2
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h2>
                      ) : obj?.content?.htmlTag === 'h3' ? (
                        <h3
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h3>
                      ) : obj?.content?.htmlTag === 'h4' ? (
                        <h4
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h4>
                      ) : obj?.content?.htmlTag === 'h5' ? (
                        <h5
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h5>
                      ) : obj?.content?.htmlTag === 'h6' ? (
                        <h6
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </h6>
                      ) : obj?.content?.htmlTag === 'div' ? (
                        <div
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </div>
                      ) : obj?.content?.htmlTag === 'span' ? (
                        <span
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </span>
                      ) : obj?.content?.htmlTag === 'p' ? (
                        <p
                          class='mt-4'
                          style={{
                            fontWeight: obj?.style?.title_font_weight,
                            fontSize: `${obj?.style?.title_font_size}px`,
                            lineHeight: `${obj?.style?.title_font_height}px`,
                            color: obj?.style?.title_font_color,
                            textAlign: obj?.style?.title_font_align,
                            fontStyle: obj?.style?.title_font_style,
                            textDecoration: obj?.style?.title_font_decoration,
                            textTransform: obj?.style?.title_font_capitalize,
                            wordBreak: obj?.style?.title_font_break,
                            textWrap: obj?.style?.title_font_wrap,
                            letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                            wordSpacing: `${obj?.style?.title_word_spacing}px`,
                            WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                            textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                            transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                          }}
                        >
                          {obj?.content?.title}
                        </p>
                      ) : (
                        <></>
                      )}
                      <div class='w-12 h-1 bg-indigo-500 rounded mt-2 mb-4'></div>
                      <span
                        style={{
                          fontWeight: obj?.style?.description_font_weight,
                          fontSize: `${obj?.style?.description_font_size}px`,
                          lineHeight: `${obj?.style?.description_font_height}px`,
                          color: obj?.style?.description_font_color,
                          textAlign: obj?.style?.description_font_align,
                          fontStyle: obj?.style?.description_font_style,
                          textDecoration: obj?.style?.description_font_decoration,
                          textTransform: obj?.style?.description_font_capitalize,
                          wordBreak: obj?.style?.description_font_break,
                          textWrap: obj?.style?.description_font_wrap,
                          letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                          wordSpacing: `${obj?.style?.description_word_spacing}px`,
                          WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                          textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                          textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                          transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                        }}
                        dangerouslySetInnerHTML={{ __html: obj?.content?.description || '-' }}
                      ></span>
                    </div>
                  </div>
                  <div class='sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left'>
                    <span
                      style={{
                        fontWeight: obj?.style?.content_font_weight,
                        fontSize: `${obj?.style?.content_font_size}px`,
                        lineHeight: `${obj?.style?.content_font_height}px`,
                        color: obj?.style?.content_font_color,
                        textAlign: obj?.style?.content_font_align,
                        fontStyle: obj?.style?.content_font_style,
                        textDecoration: obj?.style?.content_font_decoration,
                        textTransform: obj?.style?.content_font_capitalize,
                        wordBreak: obj?.style?.content_font_break,
                        textWrap: obj?.style?.content_font_wrap,
                        letterSpacing: `${obj?.style?.content_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.content_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.content_text_stroke}px ${obj?.style?.content_stroke_color}`,
                        textStroke: `${obj?.style?.content_text_stroke}px ${obj?.style?.content_stroke_color}`,
                        textShadow: `${obj?.style?.content_text_shadow_horizontal}px ${obj?.style?.content_text_shadow_vertical}px ${obj?.style?.content_text_shadow_blur}px ${obj?.style?.content_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.content_text_rotate}deg)`,
                      }}
                      class='leading-relaxed text-lg mb-4'
                      dangerouslySetInnerHTML={{ __html: obj?.content?.content || '-' }}
                    ></span>
                    <a
                      class='inline-flex items-center'
                      style={{
                        color: obj?.style?.button_link_text_color,
                      }}
                    >
                      {obj?.content?.button_link}
                      <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default SixStaticContentSection;
