import React from 'react';
import EditAccordianStyleContainer from './EditAccordianStyleContainer';

const EditAccordianStyleSection = ({previewImage, accordianDesign, setAccordianDesign, handleApplyChangesElementor ,section_id,updateSectionStyle,saveElementorData,
  btnLoading,}) => {
  return (
    <>
      <EditAccordianStyleContainer
        accordianDesign={accordianDesign}
        setAccordianDesign={setAccordianDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default EditAccordianStyleSection;
