import React from 'react';
import { mapPositionToCSS } from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const EightStaticFeatureSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.eightFeatureComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_feature-8',
  );

  console.log(filteredSections, 'filteredSections=-=-=->>');
  return (
    <div>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        return (
          <section
            class='text-gray-600 body-font'
            key={itemIndex}
            className={`cursor-pointer border-[2px] transition-all duration-300 ${
              isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
            }  hover:border-2 hover:border-blue-500 overflow-auto`}
            onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
          >
            <div
              class='container mx-auto'
              style={{
                paddingTop: `${obj?.style?.padding_top}px`,
                paddingBottom: `${obj?.style?.padding_bottom}px`,
                paddingLeft: `${obj?.style?.padding_left}px`,
                paddingRight: `${obj?.style?.padding_right}px`,
                marginTop: `${obj?.style?.margin_top}px`,
                marginBottom: `${obj?.style?.margin_bottom}px`,
                marginLeft: `${obj?.style?.margin_left}px`,
                marginRight: `${obj?.style?.margin_right}px`,
                borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                background:
                  obj?.style?.background_gradient === 'gradient'
                    ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                    : obj?.style?.background_image
                    ? `url(${obj?.style?.background_image})`
                    : obj?.style?.background,
                backgroundRepeat: obj?.style?.background_repeat,
                backgroundAttachment: obj?.style?.background_attachment,
                objectFit: obj?.style?.background_fit,
                backgroundSize:
                  obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
                    ? obj?.style?.background_fit
                    : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
              }}
            >
              <div class='text-center mb-20'>
                {obj?.content?.htmlTag === 'h1' ? (
                  <h1
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h1>
                ) : obj?.content?.htmlTag === 'h2' ? (
                  <h2
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h2>
                ) : obj?.content?.htmlTag === 'h3' ? (
                  <h3
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h3>
                ) : obj?.content?.htmlTag === 'h4' ? (
                  <h4
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h4>
                ) : obj?.content?.htmlTag === 'h5' ? (
                  <h5
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h5>
                ) : obj?.content?.htmlTag === 'h6' ? (
                  <h6
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </h6>
                ) : obj?.content?.htmlTag === 'div' ? (
                  <div
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </div>
                ) : obj?.content?.htmlTag === 'p' ? (
                  <p
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </p>
                ) : obj?.content?.htmlTag === 'span' ? (
                  <span
                    class='mb-4'
                    style={{
                      fontWeight: obj?.style?.title_font_weight,
                      fontSize: `${obj?.style?.title_font_size}px`,
                      lineHeight: `${obj?.style?.title_font_height}px`,
                      color: obj?.style?.title_font_color,
                      textAlign: obj?.style?.title_font_align,
                      fontStyle: obj?.style?.title_font_style,
                      textDecoration: obj?.style?.title_font_decoration,
                      textTransform: obj?.style?.title_font_capitalize,
                      wordBreak: obj?.style?.title_font_break,
                      textWrap: obj?.style?.title_font_wrap,
                      letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.title_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                      textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.title}
                  </span>
                ) : (
                  ''
                )}
                <span
                  class='leading-relaxed text-base'
                  style={{
                    fontWeight: obj?.style?.description_font_weight,
                    fontSize: `${obj?.style?.description_font_size}px`,
                    lineHeight: `${obj?.style?.description_font_height}px`,
                    color: obj?.style?.description_font_color,
                    textAlign: obj?.style?.description_font_align,
                    fontStyle: obj?.style?.description_font_style,
                    textDecoration: obj?.style?.description_font_decoration,
                    textTransform: obj?.style?.description_font_capitalize,
                    wordBreak: obj?.style?.description_font_break,
                    textWrap: obj?.style?.description_font_wrap,
                    letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                    wordSpacing: `${obj?.style?.description_word_spacing}px`,
                    WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                    textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                    textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                    transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                  }}
                  dangerouslySetInnerHTML={{ __html: obj?.content?.description || '-' }}
                ></span>
              </div>
              <div class='flex flex-wrap -m-4'>
                {obj?.content?.item?.map((value) => {
                  return (
                    <div class='p-4 lg:w-1/4 sm:w-1/2 w-full'>
                      <h2 class='font-medium title-font tracking-widest text-gray-900 mb-4 text-sm text-center sm:text-left'>
                        {value?.title}
                      </h2>
                      <nav class='flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2.5'>
                        {value?.subItem?.map((subVal) => {
                          return (
                            <a>
                              <span class='bg-indigo-100 text-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center'>
                                {subVal?.icon && React.isValidElement(subVal?.icon) ? (
                                  React.cloneElement(subVal?.icon, {
                                    width: '50px',
                                    height: '50px',
                                    color: '#6366f1',
                                  })
                                ) : (
                                  <SvgSection
                                    svgContent={subVal?.icon}
                                    width={'24px'}
                                    height={'24px'}
                                    fill={'#6366f1'}
                                    stroke={'#6366f1'}
                                  />
                                )}
                              </span>
                              {subVal?.subLink}
                            </a>
                          );
                        })}
                      </nav>
                    </div>
                  );
                })}
              </div>
              <button class='flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg'>
                {obj?.content?.button}
              </button>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default EightStaticFeatureSection;
