import React from 'react';
import { ElementorComponmentData } from '../../../../Assets/StaticData/ElementorComponmentData';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import ReactDragListView from 'react-drag-listview';

const ElementorComponment = ({
  dragProps,
  handleFlexBoxClick,
  handleAddSection,
  activeIndex,
  openAddSection,
  setGridValue,
  setOpenTab,
  setClickedItem,
  handleItemClick,
}) => {
  return (
    <div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Blog</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.blog?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  // onClick={(e) => {
                  //   handleFlexBoxClick(e, item?.blockName);
                  // }}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Contact</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.contact?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Content</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.content?.map((item, index) => (
                <div
                  draggable
                  onClick={(e) => handleItemClick(e, item, index, item?.value)}
                  className='cursor-pointer'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>CTA</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.cta?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Ecommerce</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.ecommerce?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Feature</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.feature?.map((item, index) => (
                <div
                  draggable
                  onClick={(e) => handleItemClick(e, item, index, item?.value)}
                  className='cursor-pointer'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Footer</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.footer?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Gallery</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.gallery?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Header</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.header?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Hero</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.hero?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Pricing</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.pricing?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Statistic</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.statistic?.map((item, index) => (
                <div
                  //   onClick={() => handleAddSection(activeIndex, item?.blockName)}
                  //   onDragStart={handleDragStart(item)}
                  draggable
                  onClick={(e) => {
                    handleFlexBoxClick(e, item?.blockName);
                  }}
                  className='cursor-pointer'
                  //   className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0] draggable-item'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Step</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.step?.map((item, index) => (
                <div
                  draggable
                  onClick={(e) => handleItemClick(e, item, index, item?.value)}
                  className='cursor-pointer'
                >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Team</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.team?.map((item, index) => (
                <div
                draggable
                onClick={(e) => handleItemClick(e, item, index, item?.value)}
                className='cursor-pointer'
              >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Testimonial</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <ReactDragListView {...dragProps}>
            <div className='grid grid-rows-1 grid-cols-1 gap-0 w-full mt-2'>
              {ElementorComponmentData?.testimonial?.map((item, index) => (
               <div
               draggable
               onClick={(e) => handleItemClick(e, item, index, item?.value)}
               className='cursor-pointer'
             >
                  <div>
                    <div className='flex flex-col items-center text-center'>
                      <div className='icon' dangerouslySetInnerHTML={{ __html: item?.svg }} />
                      {/* <p className='text-center mt-1 element-title'>{item?.title}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ReactDragListView>
        </details>
      </div>
    </div>
  );
};

export default ElementorComponment;
