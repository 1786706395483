const SvgSection = ({ svgContent, width, height, fill, stroke }) => {
  const modifySvg = (svgString, width, height, fill, stroke) => {
    const cleanedSvgString = svgString.replace(/<\?xml[^>]*\?>/, '');
    let modifiedSvg = cleanedSvgString
      .replace(/width=".*?"/, `width="${width}"`)
      .replace(/height=".*?"/, `height="${height}"`);

    if (!/width=".*?"/.test(modifiedSvg)) {
      modifiedSvg = modifiedSvg.replace(/<svg /, `<svg width="${width}" `);
    }

    if (!/height=".*?"/.test(modifiedSvg)) {
      modifiedSvg = modifiedSvg.replace(/<svg /, `<svg height="${height}" `);
    }

    if (fill === undefined || stroke === undefined) {
      fill = '#000000';
      stroke = '#000000';
    }
    modifiedSvg = modifiedSvg.replace(/class="[^"]*"/g, `fill="${fill}"`);

    // If no fill exists and there's no text color class, apply fill
    const hasTextColorClass = /class=["'][^"']*text-[a-zA-Z]+[^"']*["']/.test(svgString);

    modifiedSvg = modifiedSvg.replace(
      /(<(path|circle|svg|polygon)([^>]*?))(\/?>)/g,
      (match, tag, type, attrs, closeTag) => {
        const fillExists = /fill=/.test(attrs);
        if (!fillExists && !hasTextColorClass) {
          return `${tag}${attrs} fill="${fill}"${closeTag}`;
        }
        return match;
      },
    );

    const inlineFillExists = /style="[^"]*fill:/.test(svgString);
    if (inlineFillExists) {
      modifiedSvg = modifiedSvg.replace(/fill:[^;"]*/g, `fill:${fill}`);
    }

    modifiedSvg = modifiedSvg.replace(/fill="none"/g, `fill="${fill}"`);
    modifiedSvg = modifiedSvg.replace(/fill=".*?"/g, `fill="${fill}"`);

    modifiedSvg = modifiedSvg.replace(
      /(<(path|rect|circle|svg|polygon)([^>]*?))(\/?>)/g,
      (match, tag, type, attrs, closeTag) => {
        const strokeExists = /stroke=/.test(attrs);
        if (!strokeExists) {
          return `${tag}${attrs} stroke="${stroke}"${closeTag}`;
        }
        return match;
      },
    );

    const inlineStrokeExists = /style="[^"]*stroke:/.test(svgString);
    if (inlineStrokeExists) {
      modifiedSvg = modifiedSvg.replace(/stroke:[^;"]*/g, `stroke:${stroke}`);
    }

    modifiedSvg = modifiedSvg.replace(/stroke="none"/g, `stroke="${stroke}"`);
    modifiedSvg = modifiedSvg.replace(/stroke=".*?"/g, `stroke="${stroke}"`);

    return modifiedSvg;
  };

  const updatedSvgContent = modifySvg(svgContent, width, height, fill, stroke);

  return <div dangerouslySetInnerHTML={{ __html: updatedSvgContent }} />;
};

export default SvgSection;
